import { LinearGradient } from "expo-linear-gradient"
import * as React from "react"
import { StyleSheet, View } from "react-native"
import { ThemeContext } from "../shared/ThemeContext"

export default function TemperatureRangeBar(props) {
    const { minTemperature = 50, maxTemperature = 95, rangeMin = 68, rangeMax = 78, containerStyle = {} } = props
    const { theme } = React.useContext(ThemeContext)

    let totalRange = maxTemperature - minTemperature
    let minPercent = (rangeMin - minTemperature) / totalRange
    let maxPercent = (maxTemperature - rangeMax) / totalRange
    let barPercent = 1.0 - minPercent - maxPercent

    return (
        <View style={[styles.container, containerStyle]}>
            <LinearGradient
                style={styles.bar}
                start={[0, 0.5]}
                end={[1, 0.5]}
                colors={theme.gradient.blueAquaYellow.colors}
                locations={theme.gradient.blueAquaYellow.locations}
            />
            <View style={[styles.spacer, { flex: minPercent }]} />
            <View style={[styles.rangeBar, { flex: barPercent, backgroundColor: theme.accentDark }]} />
            <View style={[styles.spacer, { flex: maxPercent }]} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: 23,
        overflow: "hidden",
        borderRadius: 1000,
        flexDirection: "row",
        justifyContent: "center",
    },
    rangeBar: {
        marginTop: 1,
        marginBottom: 1,
        borderRadius: 1000,
        opacity: 0.3,
        overflow: "hidden",
        minWidth: 22,
    },
    bar: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
    },
    spacer: {
        flex: 0,
        height: "100%",
    },
})
