import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, Platform, ActivityIndicator, TouchableOpacity } from "react-native"
import { activeOpacity } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { useEffect, useState } from "react"

/* Expected props:
    type: fill/ghost/outline
    icon: resource
    arrow: true/false
    buttonTintColor: color
    contentTintColor: color
*/
export default function Button(props) {
    const { theme } = React.useContext(ThemeContext)

    let {
        type = "fill",
        title = "",
        icon,
        arrow = false,
        arrowIcon = theme.icons.arrowRight,
        buttonTintColor = theme.surface1,
        contentTintColor = theme.textPrimary,
        spinnerTintColor = theme.textSecondary,
        onPress,
        containerStyle,
        imageStyle,
        showSpinner = false,
    } = props

    const [spinnerVisible, setSpinnerVisible] = useState(showSpinner)

    useEffect(() => {
        setSpinnerVisible(showSpinner)
    }, [showSpinner])

    return (
        <TouchableOpacity
            onPress={onPress}
            activeOpacity={activeOpacity}
            style={[
                {
                    flexDirection: "row",
                    paddingHorizontal: 24,
                    paddingVertical: 12,
                    justifyContent: arrow == true ? "space-between" : "center",
                    alignItems: "center",
                    borderRadius: 1000,
                    backgroundColor: type == "fill" ? buttonTintColor : null,
                    borderWidth: type == "outline" ? 1 : 0,
                    borderColor: type == "outline" ? buttonTintColor : null,
                },
                containerStyle,
            ]}
        >
            {icon != null && <Image source={icon} style={[{ width: 24, height: 24, marginRight: 8 }, imageStyle]} />}
            <Text
                style={[TextStyles.buttonText, { flexShrink: 1, color: contentTintColor }]}
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                minimumFontScale={0.5}
            >
                {title}
            </Text>
            {spinnerVisible && <ActivityIndicator size={"small"} animating={true} color={spinnerTintColor} style={{ marginLeft: 8 }} />}
            {arrow == true && <Image source={arrowIcon} style={[{ width: 16, height: 16, marginLeft: 8 }, imageStyle]} />}
        </TouchableOpacity>
    )
}
