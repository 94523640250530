import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { ScrollView, Text, View } from "react-native"
import { useSelector } from "react-redux"
import { CarbonComparison, DataMeasurement, DataSource } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import { formatWithUnits } from "../../shared/Utils"
import CarbonComparisonCard from "./CarbonComparisonCard"
import CardView from "./CardView"

export default function EnvironmentalImpactCard(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)
    const { icon = theme.icons.book, title, description, onPress } = props

    const { total_kwh_reduction: usage = 0, total_carbon_reduction: carbon = 0 } = useSelector((state) => state.primaryData.neighborhoodPerformanceData) || {}

    if ((usage == null || usage <= 0) && (carbon == null || carbon <= 0)) {
        // If the neighborhood hasn't done anything or has had a negative impact, don't show this card.
        return null
    }

    return (
        <CardView includesBorder={true} includesPadding={false} {...props}>
            <View style={{ marginHorizontal: 20, marginTop: 20 }}>
                <Text style={[TextStyles.h5, { color: theme.textSecondary, marginBottom: 16 }]}>{i18n.t("thisSeason")}</Text>
                <Text style={[TextStyles.h3, { color: theme.blue, marginBottom: 4 }]}>
                    {i18n.t("amountOfElectricity", { amount: formatWithUnits(usage, DataSource.electricity, DataMeasurement.use) })}
                </Text>
                <Text style={[TextStyles.caption, { color: theme.textHint, marginBottom: 16 }]}>{i18n.t("savedDuringEvents")}</Text>
                <Text style={[TextStyles.h3, { color: theme.blue, marginBottom: 4 }]}>
                    {formatWithUnits(carbon, DataSource.electricity, DataMeasurement.carbon)}
                </Text>
                <Text style={[TextStyles.caption, { color: theme.textHint, marginBottom: 16 }]}>{i18n.t("carbonPreventedDuringEvents")}</Text>
                <Text style={[TextStyles.body2Medium, { color: theme.textPrimary, marginBottom: 8 }]}>{i18n.t("thatsTheSameAs")}</Text>
            </View>
            <ScrollView
                style={{}}
                horizontal={true}
                alwaysBounceHorizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingHorizontal: 20, alignItems: "flex-start", marginBottom: 20 }}
            >
                {CarbonComparison.map((comparison, index) => {
                    return <CarbonComparisonCard key={comparison.after + index.toString()} value={usage} comparison={comparison} />
                })}
            </ScrollView>
        </CardView>
    )
}
