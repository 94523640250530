import { useNavigation } from "@react-navigation/native"
import moment from "moment-timezone"
import { prop } from "ramda"
import * as React from "react"
import { Image, Text, View, TouchableOpacity } from "react-native"
import { PieChart } from "react-native-svg-charts"
import { useSelector } from "react-redux"
import { CarbonProducingFuelNames, CleanEnergyFuelNames, FuelTypeDisplayNames } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import CardView from "./CardView"

export default function GridFuelMixCard(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)
    const { stepType } = props

    const mainData = useSelector((state) => state.primaryData.mainData)
    const carbonData = prop("carbonData", mainData)
    const fuelMixData = prop("fuelMix", carbonData)

    const timeString = moment(prop("timestamp", carbonData)).format("h:mma")

    const carbonProducingFuelMixData = fuelMixData != null ? fuelMixData.filter((item) => CarbonProducingFuelNames.includes(item.fuelName)) : []
    const cleanFuelMixData = fuelMixData != null ? fuelMixData.filter((item) => CleanEnergyFuelNames.includes(item.fuelName)) : []
    const carbonPieData = carbonProducingFuelMixData.map((item, index) => ({
        value: item.percent,
        svg: { fill: theme.fuelMix[item.fuelName] },
        key: `carbonpie-${index}`,
    }))
    const cleanPieData = cleanFuelMixData.map((item, index) => ({
        value: item.percent,
        svg: { fill: theme.fuelMix[item.fuelName] },
        key: `cleanpie-${index}`,
    }))

    const pieData = fuelMixData != null ? [...carbonPieData, ...cleanPieData] : []
    const regionName = prop("marketName", carbonData) ?? i18n.t("carbonGraphRegionPlaceholder")

    return (
        <CardView includesBorder={true} includesPadding={false} {...props}>
            <View style={{ flexDirection: "row", marginHorizontal: 16, marginTop: 20, marginBottom: 16, alignItems: "center" }}>
                <Text style={[TextStyles.h5, { flexShrink: 1, color: theme.textSecondary }]}>
                    {i18n.t("regionElectricitySourcesTitle", {
                        regionName: regionName,
                    })}
                </Text>
            </View>
            <View style={{ marginHorizontal: 16, marginBottom: 32 }}>
                <Text style={[TextStyles.h2, { color: theme.blue, marginBottom: 4 }]}>
                    {i18n.t("percentRenewable", {
                        percent: 100 - Number(prop("carbonFuelPercent", carbonData) || 0).toFixed(0),
                    })}
                </Text>
                <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>
                    {i18n.t("fuelMixDescription", {
                        time: timeString,
                        percentRenewable: 100 - Number(prop("carbonFuelPercent", carbonData) || 0).toFixed(0),
                        regionName: regionName,
                        percentCarbon: Number(prop("carbonFuelPercent", carbonData) || 0).toFixed(0),
                    })}
                </Text>
            </View>
            <View style={[{ flexDirection: "row", marginHorizontal: 16, alignSelf: "center", marginBottom: 20 }]}>
                <PieChart
                    style={{ height: 118, width: 116, marginRight: 32 }}
                    data={pieData}
                    innerRadius={0}
                    padAngle={0}
                    sort={(a, b) => b.key - a.key}
                ></PieChart>
                <View style={{ flexShrink: 1 }}>
                    <View style={{ flex: 1 }}>
                        {carbonProducingFuelMixData
                            .sort((a, b) => (a.percent <= b.percent ? 1 : -1))
                            .map((item, index) => {
                                return (
                                    <View key={"legendCarbon" + index} style={[{ flexDirection: "row", marginBottom: 17 }]}>
                                        <View
                                            style={{
                                                width: 16,
                                                height: 16,
                                                borderRadius: 1000,
                                                backgroundColor: theme.fuelMix[item.fuelName],
                                                marginRight: 8,
                                            }}
                                        />
                                        <Text style={[TextStyles.body4, { flexShrink: 1, color: theme.textHint }]}>
                                            {FuelTypeDisplayNames[item.fuelName] + " (" + Number(item.percent).toFixed(0) + "%)"}
                                        </Text>
                                    </View>
                                )
                            })}
                    </View>
                    <View style={{ flex: 1 }}>
                        {cleanFuelMixData
                            .sort((a, b) => (a.percent <= b.percent ? 1 : -1))
                            .map((item, index) => {
                                return (
                                    <View key={"legendClean" + index} style={[{ flexDirection: "row", marginBottom: 17 }]}>
                                        <View
                                            style={{
                                                width: 16,
                                                height: 16,
                                                borderRadius: 1000,
                                                backgroundColor: theme.fuelMix[item.fuelName],
                                                marginRight: 8,
                                            }}
                                        />
                                        <Text style={[TextStyles.body4, { flexShrink: 1, color: theme.textHint }]}>
                                            {FuelTypeDisplayNames[item.fuelName] + " (" + Number(item.percent).toFixed(0) + "%)"}
                                        </Text>
                                    </View>
                                )
                            })}
                    </View>
                </View>
            </View>
        </CardView>
    )
}
