import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { useEffect, useState } from "react"
import { Platform, Switch, Text, View } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { clearThermostatHold, updateThermostat } from "../../model/primaryDataActions"
import { ThermostatModesGet, ThermostatModesSet } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import SegmentedControl from "../SegmentedControl"
import { checkNetwork, thermostatModeDisplayName } from "../../shared/Utils"
import CardView from "./CardView"
import TemperatureIndicator from "../TemperatureIndicator"
import TemperatureRangeBar from "../TemperatureRangeBar"
import ThermostatControlRow from "../ThermostatControlRow"
import { path, prop } from "ramda"

export default function ThermostatCard(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    const {
        id: thermostatId,
        thermostat_name = "",
        last_temp,
        current_heating_setpoint,
        current_cooling_setpoint,
        mode = ThermostatModesGet.off,
        active_hold = {},
    } = prop("thermostat", props) || {}

    const thermostatModesGetOrdered = [ThermostatModesGet.cool, ThermostatModesGet.heat, ThermostatModesGet.both, ThermostatModesGet.off]
    const thermostatModesSetOrdered = [ThermostatModesSet.cool, ThermostatModesSet.heat, ThermostatModesSet.both, ThermostatModesSet.off]

    const thermostatsLastUpdate = useSelector((state) => state.primaryData.thermostatsLastUpdate)

    const [modeTitleUpper, setModeTitleUpper] = useState(null)
    const [rangeMin, setRangeMin] = useState(current_heating_setpoint)
    const [rangeMax, setRangeMax] = useState(current_cooling_setpoint)
    const dispatch = useDispatch()

    useEffect(() => {
        switch (mode) {
            case ThermostatModesGet.cool:
                setModeTitleUpper(i18n.t("cardThermostatCoolTo"))
                setRangeMin(current_cooling_setpoint)
                setRangeMax(current_cooling_setpoint)
                break
            case ThermostatModesGet.heat:
                setModeTitleUpper(i18n.t("cardThermostatHeatTo"))
                setRangeMin(current_heating_setpoint)
                setRangeMax(current_heating_setpoint)
                break
            case ThermostatModesGet.both:
                setModeTitleUpper(i18n.t("cardThermostatStayBetween"))
                setRangeMin(current_heating_setpoint)
                setRangeMax(current_cooling_setpoint)
                break
            default:
                setModeTitleUpper(null)
                setRangeMin(last_temp)
                setRangeMax(last_temp)
                break
        }
    }, [mode, thermostatId, last_temp, thermostatsLastUpdate])

    return (
        <CardView includesBorder={true} includesPadding={true} {...props}>
            <Text style={[TextStyles.h5, { color: theme.textPrimary, marginBottom: 16 }]}>{thermostat_name}</Text>
            <SegmentedControl
                {...props}
                onPress={(selectedIndex) => {
                    let newMode = thermostatModesSetOrdered[selectedIndex]
                    dispatch(
                        updateThermostat(thermostatId, {
                            mode: newMode,
                        })
                    )
                }}
                selectedIndex={thermostatModesGetOrdered.indexOf(mode)}
                labels={thermostatModesGetOrdered.map((mode) => thermostatModeDisplayName(mode))}
                containerStyle={{ marginBottom: 16 }}
            />
            {/* Not sure why Android is rendering this two points too low, but here we are. */}
            <TemperatureIndicator temperature={last_temp} containerStyle={{ marginBottom: Platform.OS == "android" ? 4 : 2 }} />
            <TemperatureRangeBar rangeMin={rangeMin} rangeMax={rangeMax} />
            <View style={{ marginTop: 24, marginBottom: 16, alignItems: "center", justifyContent: "center" }}>
                {modeTitleUpper != null && <Text style={[TextStyles.caption, { color: theme.textSecondary }]}>{modeTitleUpper}</Text>}
                <ThermostatControlRow
                    key={thermostatId + "first"}
                    thermostatId={thermostatId}
                    isHeatingSetpoint={[ThermostatModesGet.heat, ThermostatModesGet.both].includes(mode)}
                    temperature={rangeMin}
                    isOff={mode == ThermostatModesGet.off}
                />
                {mode == ThermostatModesGet.both && <Text style={[TextStyles.caption, { marginTop: 8, color: theme.textSecondary }]}>{i18n.t("and")}</Text>}
                {mode == ThermostatModesGet.both && (
                    <ThermostatControlRow key={thermostatId + "second"} thermostatId={thermostatId} isHeatingSetpoint={false} temperature={rangeMax} />
                )}
            </View>
            {/* For the moment, we're removing the hold switch. Changing temperature creates a hold, and because of delays talking to Ecobee's API,
                we're not going to be able to report that a user has deleted a hold until minutes after it has happened. Clearer this way. */}
            {/* {mode != ThermostatModesGet.off && (
                <View
                    style={{
                        marginTop: 16,
                        flexDirection: "row-reverse",
                        alignSelf: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Switch
                        value={path(["hold", "until"], active_hold) != null}
                        onValueChange={async (value) => {
                            // Make sure we can get to the network (this will notify the user otherwise).
                            let networkAvailable = await checkNetwork()
                            if (networkAvailable != true) {
                                // Nothing to do
                                return
                            }
                            if (value == true) {
                                dispatch(
                                    updateThermostat(thermostatId, {
                                        hold_until: "manual",
                                    })
                                )
                            } else {
                                // Clear the hold
                                dispatch(clearThermostatHold(thermostatId))
                            }
                        }}
                    />
                    <Text style={[TextStyles.caption, { color: theme.textSecondary, marginRight: 8 }]}>{i18n.t("cardThermostatHold")}</Text>
                </View>
            )} */}
        </CardView>
    )
}
