import moment from "moment-timezone"
import * as React from "react"
import { Image, StyleSheet, Text, View, TouchableOpacity } from "react-native"
import { ProgressCircle } from "react-native-svg-charts"
import i18n from "../shared/i18n"
import { activeOpacity } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

function GridRewardsHistoryRow(props) {
    const { theme } = React.useContext(ThemeContext)

    const { historyData: { timestamp, startTime, endTime, performanceFactor = 0, finalCalculation = false } = {}, maxValue = 1.0, containerStyle } = props

    if (performanceFactor == 0 && maxValue == 0) {
        return null
    }

    const performanceFactorClamped = Math.min(1.0, Math.max(0.0, performanceFactor))

    let progressColor = theme.blue
    let textColor = theme.blue
    if (finalCalculation != true) {
        progressColor = theme.textHint
        textColor = theme.textHint
    } else if (performanceFactorClamped < 0.25) {
        progressColor = theme.red
        textColor = theme.red
    } else if (performanceFactorClamped < 0.5) {
        progressColor = theme.orange
        textColor = theme.orange
    }

    const barDisplayString = finalCalculation == true ? (performanceFactorClamped * 100).toFixed(0) + i18n.t("percentOfGoal") : i18n.t("pendingAnalysis")
    const rotationStart = (-3.1 * Math.PI) / 4
    const rotationEnd = (3.1 * Math.PI) / 4
    // Clamp this performance value to a reasonable minimum and maximum for displaying the ring around the outside of the content.
    const performanceFactorDial = Math.max(0.025, performanceFactorClamped)

    let dateString = ""
    if (timestamp) {
        let date = moment(timestamp)
        let format = "dddd"
        dateString = date.format(format)
    } else if (startTime != null && endTime != null) {
        let start = moment(startTime)
        let end = moment(endTime)
        if (start.isSame(end, "day")) {
            // In this case, only show the day once.
            dateString = start.format("MMMM Do h") + "–" + end.format("ha")
        } else {
            // Show both days
            dateString = start.format("MMMM Do ha") + " – " + end.format("MMMM Do ha")
        }
    }

    return (
        <TouchableOpacity
            onPress={props.onPress}
            activeOpacity={props.onPress != null && finalCalculation == true ? activeOpacity : 1.0}
            style={containerStyle}
        >
            <View style={{}}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {/* Ring */}
                    {finalCalculation == true ? (
                        <ProgressCircle
                            style={{ height: 74, width: 74 }}
                            startAngle={rotationStart}
                            endAngle={rotationEnd}
                            strokeWidth={12}
                            backgroundColor={theme.surface1}
                            progress={performanceFactorDial}
                            progressColor={progressColor}
                        />
                    ) : (
                        <View style={{ justifyContent: "center", alignItems: "center", width: 74, height: 74 }}>
                            <ProgressCircle
                                style={{ height: 74, width: 74 }}
                                startAngle={rotationStart}
                                endAngle={rotationEnd}
                                strokeWidth={12}
                                backgroundColor={theme.surface1}
                                progress={0}
                                progressColor={progressColor}
                            />
                            <Image style={{ position: "absolute", width: 32, height: 32, opacity: 0.12 }} source={theme.icons.clock} />
                        </View>
                    )}
                    {/* Text and actions stack */}
                    <View style={{ marginLeft: 16, flex: 1 }}>
                        <Text style={[TextStyles.body1, { color: theme.textPrimary }]}>{dateString}</Text>
                        <Text style={[TextStyles.caption, { color: textColor, marginTop: 4 }]}>{barDisplayString}</Text>
                    </View>
                    
                </View>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    horizontalContainer: {
        marginTop: 1,
        flexDirection: "row",
        alignItems: "center",
    },

    barContainer: {
        flex: 1,
        height: 23,
        marginTop: 2,
    },
    actionsContainer: {
        // flex: 0.5,
        marginTop: 5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
})

// Make this a memo-ized component so we only re-render when props actually change.
export default GridRewardsHistoryRow = React.memo(GridRewardsHistoryRow)
