import moment from "moment-timezone"
import { prop } from "ramda"
import React, { useState, useEffect } from "react"
import { Text, Image, View } from "react-native"
import { useSelector } from "react-redux"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import { CarbonThresholdManual, EventLikelihoodThresholdManual } from "../../shared/AppConstants"
import CardView from "./CardView"

export default function EventLikelihoodCard(props) {
    const { theme } = React.useContext(ThemeContext)

    const eventLikelihoodData = useSelector((state) => state.primaryData.eventLikelihoodData)
    const [daysSorted, setDaysSorted] = useState([])
    const [likelyDay, setLikelyDay] = useState(null)

    // Sort the upcoming days by likelihood.
    useEffect(() => {
        if (eventLikelihoodData == null || eventLikelihoodData.length == 0) {
            // nothing to do.
            return
        }

        const firstLikelyDay = eventLikelihoodData.find((possibleItem) => prop("event_likelihood", possibleItem) >= EventLikelihoodThresholdManual.likely)
        setLikelyDay(firstLikelyDay)
    }, [eventLikelihoodData])

    let likelihoodString = ""
    let dateString = ""
    if (likelyDay != null) {
        dateString = moment(prop("timestamp", likelyDay)).format("dddd")
        const likelihood = prop("event_likelihood", likelyDay) || 0
        if (likelihood >= EventLikelihoodThresholdManual.veryLikely) {
            likelihoodString = i18n.t("veryLikely")
        } else if (likelihood >= EventLikelihoodThresholdManual.likely) {
            likelihoodString = i18n.t("likely")
        } else {
            likelihoodString = i18n.t("unlikely")
        }
    }

    if (eventLikelihoodData == null) {
        return null
    }

    return (
        <CardView includesBorder={false} includesPadding={false} containerStyle={{ paddingHorizontal: 4, marginBottom: 24 }} {...props}>
            <View style={{ flexDirection: "row" }}>
                <Image source={theme.icons.clock} style={{ width: 24, height: 24, marginRight: 16 }} />
                <Text style={[TextStyles.bodyLarge, { flexShrink: 1, color: theme.textPrimary }]}>
                    {likelyDay != null ? i18n.t("eventLikelihood", { likelihood: likelihoodString, day: dateString }) : i18n.t("eventLikelihoodNone")}
                </Text>
            </View>
        </CardView>
    )
}
