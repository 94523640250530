import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { useEffect, useState } from "react"
import { Image, Text, View, ScrollView, TouchableOpacity, StyleSheet, TextInput, Keyboard } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import SegmentedControl from "../components/SegmentedControl"
import { updatePaymentAddress, updateProfileData } from "../model/primaryDataActions"
import AppConstants, { PaymentOption, PaymentOptions, ScreenNames } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { isEmpty, showToast } from "../shared/Utils"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import Button from "../components/Button"
import { verifyAddress } from "../model/directAPICalls"
import { prop } from "ramda"

export default function PaymentPrefsDetailScreen(props) {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const { theme } = React.useContext(ThemeContext)

    const profileData = useSelector((state) => state.primaryData.profileData)
    const { payment_preference } = profileData || {}

    // Local state that we'll link to the API if we have it, and fall back to async storage
    const [paymentPrefLocal, setPaymentPrefLocal] = useState(payment_preference)

    // Stored payment address info
    const paymentAddressData = useSelector((state) => state.primaryData.paymentAddressData)
    const {
        line1: storedLine1,
        line2: storedLine2,
        city: storedCity,
        provinceOrState: storedProvinceOrState,
        postalOrZip: storedPostalOrZip,
    } = prop("address", paymentAddressData) || {}

    // Local state for the payment address
    const [line1, setLine1] = useState(storedLine1)
    const [line2, setLine2] = useState(storedLine2)
    const [city, setCity] = useState(storedCity)
    const [provinceOrState, setProvinceOrState] = useState(storedProvinceOrState)
    const [postalOrZip, setPostalOrZip] = useState(storedPostalOrZip)

    const [addressChanged, setAddressChanged] = useState(false)
    const [addressValidationPending, setAddressValidationPending] = useState(false)

    // Any time our local preference changes, see if we need to write it to async storage and/or the API.
    useEffect(() => {
        if (payment_preference == null && paymentPrefLocal == null) {
            // If we don't have anything in the profile, try to get the local value.
            AsyncStorage.getItem(AppConstants.profilePaymentPreference).then((storedPref) => {
                // If we have something stored, use it.
                if (storedPref != null) {
                    setPaymentPrefLocal(storedPref)
                } else {
                    // Use a default value.
                    setPaymentPrefLocal(PaymentOption.physical.type)
                }
            })
        }
    }, [paymentPrefLocal])

    useEffect(() => {
        setPaymentPrefLocal(payment_preference)
    }, [payment_preference])

    useEffect(() => {
        if (!isEmpty(paymentAddressData)) {
            // Replace our local state with the newly updated remote data.
            setLine1(storedLine1)
            setLine2(storedLine2)
            setCity(storedCity)
            setProvinceOrState(storedProvinceOrState)
            setPostalOrZip(storedPostalOrZip)

            // In this case, we have no changes
            setAddressChanged(false)
        } else {
            // See if our local data is different from the current stored payment address.
            const changed =
                storedLine1 != line1 ||
                storedLine2 != line2 ||
                storedCity != city ||
                storedProvinceOrState != provinceOrState ||
                storedPostalOrZip != postalOrZip

            setAddressChanged(changed)
        }
    }, [paymentAddressData])

    useEffect(() => {
        // See if our local data is different from the current stored payment address.
        const changed =
            storedLine1 != line1 || storedLine2 != line2 || storedCity != city || storedProvinceOrState != provinceOrState || storedPostalOrZip != postalOrZip

        setAddressChanged(changed)
    }, [line1, line2, city, provinceOrState, postalOrZip])

    const updateButtonPressed = () => {
        setAddressValidationPending(true)
        Keyboard.dismiss()

        const originalAddress = {
            line1: line1,
            line2: line2,
            city: city,
            provinceOrState: provinceOrState,
            postalOrZip: postalOrZip,
        }

        verifyAddress(
            line1,
            line2,
            city,
            provinceOrState,
            postalOrZip,
            null,
            (data) => {
                console.log("Payment prefs screen thinks verified address data is " + JSON.stringify(data))
                setAddressValidationPending(false)

                switch (prop("status", data)) {
                    case "verified":
                        dispatch(updatePaymentAddress(data, prop("id", paymentAddressData)))
                        showToast(i18n.t("mailingAddress.prefs.updateSuccessful"), theme.icons.checkmark32)
                        break
                    case "corrected":
                        navigation.navigate(ScreenNames.AddressVerification.routeName, {
                            originalAddress,
                            correctedAddress: data,
                            existingAddressId: prop("id", paymentAddressData),
                        })
                        break
                    case "failed":
                        console.log("Couldn't validate address: " + JSON.stringify(data))
                        navigation.navigate(ScreenNames.AddressVerification.routeName, {
                            originalAddress,
                            correctedAddress: data,
                            existingAddressId: prop("id", paymentAddressData),
                        })
                        break
                    default:
                        break
                }
            },
            (error) => {
                console.log("Payment prefs couldn't validate address: " + JSON.stringify(error))
                setAddressValidationPending(false)
                showToast(i18n.t("mailingAddress.prefs.validationError"), theme.icons.warning)
            }
        )
    }

    return (
        <KeyboardAwareScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background, paddingTop: 24 }]}
            contentContainerStyle={[CommonStyles.mainScreenContent, { paddingHorizontal: 24 }]}
            keyboardShouldPersistTaps={"handled"}
        >
            <SegmentedControl
                onPress={(selectedIndex) => {
                    let newPref = PaymentOptions[selectedIndex]
                    setPaymentPrefLocal(newPref.type)

                    // Write the local value to async storage.
                    AsyncStorage.setItem(AppConstants.profilePaymentPreference, newPref.type)

                    // Write to the profile
                    let newProfileInfo = {
                        payment_preference: newPref.type,
                    }

                    dispatch(updateProfileData(newProfileInfo, true))

                    showToast(i18n.t("paymentPrefUpdated", { pref: newPref.displayName }), theme.icons.checkmark32)
                }}
                selectedIndex={() => {
                    // Check for the stored payment pref value in our list. There's a case where we have an old selection from previous app versions
                    // and need to fall back to the new default.
                    const existingIndex = PaymentOptions.findIndex((item) => item.type == paymentPrefLocal)
                    if (existingIndex < 0) {
                        return 0
                    } else {
                        return existingIndex
                    }
                }}
                labels={PaymentOptions.map((item) => item.displayName)}
                containerStyle={{ marginBottom: 32 }}
            />

            {/* Detailed information */}
            <View style={{ flexDirection: "row", marginBottom: 24 }}>
                <Image source={theme.icons.moneyEnvelopeGreen} style={{ width: 32, height: 32, marginRight: 16 }} />
                <View style={{ flexShrink: 1 }}>
                    <Text style={[TextStyles.body2Medium, { color: theme.textPrimary, marginBottom: 8 }]}>{i18n.t("paymentPrefMailTitle")}</Text>
                    <Text style={[TextStyles.body2, { color: theme.textSecondary, marginBottom: 8 }]}>{i18n.t("paymentPrefMailSubtitle")}</Text>
                </View>
            </View>

            <View style={{ flexDirection: "row", marginBottom: 32 }}>
                <Image source={theme.icons.coinGreen} style={{ width: 32, height: 32, marginRight: 16 }} />
                <View style={{ flexShrink: 1 }}>
                    <Text style={[TextStyles.body2Medium, { color: theme.textPrimary, marginBottom: 8 }]}>{i18n.t("paymentPrefDonateTitle")}</Text>
                    <Text style={[TextStyles.body2, { color: theme.textSecondary, marginBottom: 8 }]}>{i18n.t("paymentPrefDonateSubtitle")}</Text>
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate(ScreenNames.DonationsDetail.routeName)
                        }}
                    >
                        <Text style={[TextStyles.caption, { color: theme.green }]}>{i18n.t("paymentPrefDonateSeePartners")}</Text>
                    </TouchableOpacity>
                </View>
            </View>

            {/* Payment address */}
            <Text style={[TextStyles.body2Medium, { color: theme.textPrimary, marginBottom: 16 }]}>{i18n.t("mailingAddress.prefs.title")}</Text>
            <TextInput
                placeholder={i18n.t("mailingAddress.line1")}
                placeholderTextColor={theme.textHint}
                underlineColorAndroid={"transparent"}
                returnKeyType={"next"}
                autoCorrect={false}
                style={[
                    TextStyles.body2,
                    {
                        flexShrink: 1,
                        height: 50,
                        borderRadius: 8,
                        borderWidth: 1,
                        paddingHorizontal: 16,
                        justifyContent: "center",
                        color: theme.textPrimary,
                        marginBottom: 16,
                        borderColor: theme.border,
                    },
                ]}
                onChangeText={(value) => setLine1(value.length > 0 ? value : null)}
                autoComplete="street-address"
                textContentType="streetAddressLine1"
                value={line1}
            />
            <TextInput
                placeholder={i18n.t("mailingAddress.line2")}
                placeholderTextColor={theme.textHint}
                underlineColorAndroid={"transparent"}
                returnKeyType={"next"}
                autoCorrect={false}
                style={[
                    TextStyles.body2,
                    {
                        flexShrink: 1,
                        height: 50,
                        borderRadius: 8,
                        borderWidth: 1,
                        paddingHorizontal: 16,
                        justifyContent: "center",
                        color: theme.textPrimary,
                        marginBottom: 16,
                        borderColor: theme.border,
                    },
                ]}
                onChangeText={(value) => setLine2(value.length > 0 ? value : null)}
                autoComplete="street-address"
                textContentType="streetAddressLine2"
                value={line2}
            />
            <TextInput
                placeholder={i18n.t("mailingAddress.city")}
                placeholderTextColor={theme.textHint}
                underlineColorAndroid={"transparent"}
                returnKeyType={"next"}
                autoCorrect={false}
                style={[
                    TextStyles.body2,
                    {
                        flexShrink: 1,
                        height: 50,
                        borderRadius: 8,
                        borderWidth: 1,
                        paddingHorizontal: 16,
                        justifyContent: "center",
                        color: theme.textPrimary,
                        marginBottom: 16,
                        borderColor: theme.border,
                    },
                ]}
                onChangeText={(value) => setCity(value.length > 0 ? value : null)}
                autoComplete="postal-address-locality"
                textContentType="addressCity"
                value={city}
            />
            <View style={{ flexDirection: "row" }}>
                <TextInput
                    placeholder={i18n.t("mailingAddress.provinceOrState")}
                    placeholderTextColor={theme.textHint}
                    underlineColorAndroid={"transparent"}
                    returnKeyType={"next"}
                    autoCorrect={false}
                    style={[
                        TextStyles.body2,
                        {
                            flex: 1,
                            height: 50,
                            borderRadius: 8,
                            borderWidth: 1,
                            paddingHorizontal: 16,
                            justifyContent: "center",
                            color: theme.textPrimary,
                            marginBottom: 16,
                            marginRight: 8,
                            borderColor: theme.border,
                        },
                    ]}
                    onChangeText={(value) => setProvinceOrState(value.length > 0 ? value : null)}
                    autoComplete="postal-address-region"
                    textContentType="addressState"
                    value={provinceOrState}
                />
                <TextInput
                    placeholder={i18n.t("mailingAddress.postalOrZip")}
                    placeholderTextColor={theme.textHint}
                    underlineColorAndroid={"transparent"}
                    returnKeyType={"done"}
                    autoCorrect={false}
                    style={[
                        TextStyles.body2,
                        {
                            width: 96,
                            height: 50,
                            borderRadius: 8,
                            borderWidth: 1,
                            paddingHorizontal: 16,
                            justifyContent: "center",
                            color: theme.textPrimary,
                            marginBottom: 16,
                            borderColor: theme.border,
                        },
                    ]}
                    onChangeText={(value) => setPostalOrZip(value.length > 0 ? value : null)}
                    autoComplete="postal-code"
                    textContentType="postalCode"
                    value={postalOrZip}
                />
            </View>
            <Button
                title={i18n.t("mailingAddress.prefs.buttonUpdate")}
                contentTintColor={addressChanged ? theme.textPrimary : theme.textHint}
                containerStyle={{ marginBottom: 32 }}
                onPress={updateButtonPressed}
                showSpinner={addressValidationPending}
            />
            <Text style={[TextStyles.body4, { color: theme.textHint, marginHorizontal: 8 }]}>{i18n.t("paymentPrefFinePrint")}</Text>
        </KeyboardAwareScrollView>
    )
}
