export const translationStrings = {
    translation: {
        aboutTheSame: "примерно так же",
        accountStale: "Мы давно не получали новых данных с вашего лицевого счета ЖКХ. Если вы переехали, то можете подключить новый лицевой счет. Если нет, дайте нам знать.",
        achievement: {
            betterGoal: {
                description: "Вы показали хорошие результаты в ходе акции GridRewards.",
                title: "Хорошая результативность"
            },
            connectedAccount: {
                description: "Вы подключили лицевой счет ЖКХ. Теперь вы можете видеть данные о расходах электричества в приложении GridRewards и начать зарабатывать деньги!",
                title: "Подключенный"
            },
            countMeIn: {
                description: "Вы зарегистрировались для участия в акции GridRewards.",
                title: "Ваш голос имеет значение"
            },
            countNone: "Вы еще не получили это достижение",
            countPlural: "Получено {{date}} и еще {{count}} раз",
            countMultipleSingular: "Получено {{date}} и еще 1 раз",
            countSingular: "Получено {{date}}",
            eventCount10: {
                description: "Вы заработали уже на 10 акциях GridRewards. Так держать!",
                title: "10 успешных акций"
            },
            eventCount25: {
                description: "Вы заработали уже на 25 акциях GridRewards. Так держать!",
                title: "25 успешных акций"
            },
            eventCount50: {
                description: "Вы заработали уже на 50 акциях GridRewards. Так держать!",
                title: "50 успешных акций"
            },
            goalReached: {
                description: "Вы превзошли свою цель по экономии электроэнергии в ходе акции GridRewards!",
                title: "Электрониндзя"
            },
            graphViewed: {
                description: "Вы впервые просмотрели энергетический график.",
                title: "Исследователь данных"
            },
            minimumGoal: {
                description: "Вы достигли своей минимальной цели по экономии электроэнергии в ходе акции GridRewards",
                title: "Минимальная цель достигнута"
            },
            neighborJoined: {
                description: "Вы убедили присоединиться к GridRewards вашего соседа по району.",
                title: "Районный лидер"
            },
            referralSent: {
                description: "Вы пригласили друга, получив возможность совместно больше зарабатывать во время акций.",
                title: "Пригласил(-а) друга"
            },
            topNeighborhood: {
                description: "Ваш район был одним из лучших по экономии электроэнергии во время акции GridRewards.",
                title: "Лучший район"
            },
            topNeighborhoodPerCapita: {
                description: "Ваш район имел один из лучших показателей экономии электроэнергии на человека во время акции GridRewards.",
                title: "Лучший район по индивидуальной эффективности"
            },
            topPerformer: {
                description: "Вы сэкономили больше всего электроэнергии в своем районе во время акции GridRewards.",
                title: "Самый результативный"
            }
        },
        achievementsTitle: "Достижения",
        acknowledgements: "Благодарности",
        actions: {
            appliances: {
                subtitle: "Перенесите запуск посудомоечной машины, стирку и приготовление кофе на время после акции.",
                subtitleAdvanced: "Да здравствует грязь!",
                title: "Отложите пользование крупными электроприборами"
            },
            ev: {
                subtitle: "Электромобили могут потреблять около 30% всего домашнего электричества, поэтому перенесите время их зарядки, чтобы сильно сэкономить. У вас еще нет электромобиля? Значит этот пункт уже выполнен ;-).",
                subtitleAdvanced: "Еще не ездите на электромобиле? Купите его, чтобы не кашлять в клубах дыма.",
                title: "Перенесите время зарядки своего электромобиля"
            },
            fridge: {
                subtitle: "Установите более высокую температуру в холодильнике и морозильной камере. Старайтесь держать дверцы закрытыми, чтобы холодный воздух оставался внутри.",
                subtitleAdvanced: "Теплые мясные деликатесы — вполне съедобно, верно? Скорее всего, вы не умрете... сегодня.",
                title: "Установите более высокую температуру в холодильнике и морозильной камере"
            },
            lights: {
                subtitle: "Выключите всё ненужное освещение. Кроме того, для значительной экономии электроэнергии вы можете заменить лампы накаливания на светодиодные.",
                subtitleAdvanced: "Возможно, пару раз врежетесь в стену, но шрамы украшают человека.",
                title: "Вырубите свет"
            },
            preCool: {
                subtitle: "За 4 часа до акции установите на термостате температуру ниже обычной, а затем пусть она поднимается во время акции.",
                subtitleAdvanced: "Приготовьтесь к надвигающемуся энергетическому кризису.",
                title: "Заранее охладите свое помещение"
            },
            raiseAC: {
                subtitle: "На время акции установите свой термостат на несколько градусов выше обычного.",
                subtitleAdvanced: "Зарабатывайте на собственном дискомфорте.",
                title: "Убавьте кондиционер"
            },
            unplug: {
                subtitle: "Выключите телевизор, приставку кабельного ТВ и другие крупные бытовые приборы. Сходите погулять!",
                subtitleAdvanced: "Оторвитесь от бездумного листания соцсетей, выйдите на улицу и получите немного витамина D. Боритесь со стрессом с помощью GridRewards™.",
                title: "Вырубаемся"
            }
        },
        actionsInfoSubtitle: "Мелочи накапливаются! Вот перечень самых важных действий, которые вы можете выполнить перед каждой акцией GridRewards.",
        addButtonTitle: "Добавить",
        addPhoto: "Добавить фото",
        alreadyHaveAnAccountPrompt: "Уже есть учетная запись? Войдите",
        amiOnlyWarning: "Еще один шаг к тому, чтобы начать зарабатывать! Завершите подключение вашего личного кабинета в энергокомпании к GridRewards.",
        amountOfCO2: "{{amount}} CO₂",
        amountOfElectricity: "{{amount}} электричества",
        avatarUpdateFailed: "Не удалось обновить ваше фото профиля, попробуйте снова",
        and: "и",
        backToProfile: "Вернуться в профиль",
        backToSignIn: "Вернуться к окну входа",
        bad: "Плохо",
        beforeEvent: "До акции",
        billingCycle: "Платежный цикл",
        bonuses: "Дополнительные начисления",
        calendarEventConfirmation: "Добавлено в ваш календарь",
        calendarEventErrorBody: "При создании мероприятия в календаре произошла ошибка. Убедитесь, что календарь настроен и повторите попытку.",
        calendarEventErrorTitle: "Не удалось добавить в календарь",
        calendarEventTitle: "Акция GridRewards: экономьте электричество прямо сейчас!",
        callUs: "Позвоните нам",
        cancel: "Отмена",
        carbonComparison1After: "саженцы деревьев на 10 лет",
        carbonComparison1Before: "растет",
        carbonComparison2After: "миль",
        carbonComparison2Before: "не ехать на автомобиле",
        carbonComparison3After: "сотовых телефонов от зарядки",
        carbonComparison3Before: "предотвратить",
        carbonComparison4After: "тонн отходов, вместо выброса их на свалку",
        carbonComparison4Before: "переработка",
        carbonComparison5After: "акров леса, процветающего целый год",
        carbonComparison6After: "домохозяйств использовали бы за год",
        carbonComparison6Before: "электроэнергия, которая",
        carbonComparison7After: "газовых баллонов от использования в домашних барбекю",
        carbonComparison8After: "машин с дорог на целый год",
        carbonComparison8Before: "убрать",
        carbonFootprintTitle: "Углеродный след",
        carbonFootprintTitleCase: "Углеродный след",
        carbonGraphAnalysisForecast: "вероятно выбросит",
        carbonGraphAnalysisGrid: " — электросеть",
        carbonGraphAnalysisPast: "выбросила",
        carbonGraphAnalysisUnits: "г CO₂/кВтч",
        carbonGraphInfo: "Электричество электричеству рознь! Высокими столбцами отмечены часы с самыми высокими выбросами углекислого газа на единицу потребляемой электроэнергии — экономьте электричество в это время, чтобы добиться наибольшего эффекта.",
        carbonGraphRegionPlaceholder: "Ваш регион",
        carbonPreventedDuringEvents: "не попало в атмосферу",
        carbonTabTitle: "CO₂",
        cardThermostatCoolTo: "Охладите до",
        cardThermostatHeatTo: "Обогрейте до",
        cardThermostatHold: "Удерживать",
        cardThermostatStayBetween: "Удерживать между",
        categoryClimateAction: "Борьба с изменением климата",
        categoryEnvironmentalJustice: "Экологическая справедливость",
        categoryFoodSecurity: "Продовольственная безопасность",
        categoryLandConservancy: "Охрана земельных ресурсов",
        categorySustainability: "Экологичность",
        cityHarvestDescription: "Крупнейшая в Нью-Йорке организация по оказанию продовольственной помощи, помогающая накормить более чем 1,5 млн ньюйоркцев, которым не хватает средств на пропитание. City Harvest спасет 50 тысяч тонн еды и бесплатно развезет ее по сотням точек, среди которых пункты раздачи продовольствия, благотворительные столовые и другие общественные пункты питания в пяти районах.",
        cityHarvestLinkName: "cityharvest.org",
        cityHarvestTitle: "City Harvest",
        community: "Район",
        completeAccountSetup: "Завершить настройку учетной записи",
        connectNewAccount: "Подключить новый лицевой счет",
        connectYourAccount: "Подключить ваш лицевой счет",
        connectAccount: {
            title: "Давайте начнем",
            description: "Подключите свой лицевой счет энергетической компании, чтобы начать участвовать в акциях GridRewards и получать за это деньги.",
            noOnlineAccountTitle: "Нет личного кабинета на сайте компании ЖКХ?",
            noOnlineAccountDescription: "Если вы еще этого не сделали: создайте личный кабинет на сайте своей энергетической компании и привяжите номер своего лицевого счета к этому личному кабинету — так вы сможете подтвердить, что ваш счетчик подключен и активен.",
            cta: "Подключить личный кабинет ЖКХ"
        },
        createAccount: "Создать учетную запись",
        createAccountErrorMissingFields: "Введите адрес электронной почты и пароль, затем нажмите «Создать учетную запись».",
        createGridRewardsAccount: "Создайте учетную запись GridRewards",
        currentGridCO2: "Текущий выброс CO₂ от электросети",
        currentStreak: "Текущая серия",
        dataFetchTitle: "Получаем ваши данные. Это может занять до 15 минут.",
        dataSourceNameElectricity: "Электричество",
        dataSourceNameGas: "Газ",
        dataTimeframeDay: "День",
        dataTimeframeHour: "Час",
        defaultDisplayName: "Пользователь GridRewards",
        deleteAccount: {
            title: "Удалить вашу учетную запись GridRewards",
            descriptionBeforeDisconnect: "При удалении учетной записи вы потеряете доступ к программе GridRewards и лишитесь возможности зарабатывать в этом и будущих сезонах.\n\nВместе с удалением учетной записи GridRewards вы также можете",
            descriptionDisconnectLink: "отключить передачу данных из вашего личного кабинета на сайте компании ЖКХ.",
            descriptionAfterDisconnect: "\n\nЕсли вы уверены, то ниже можете ввести свой пароль и удалить свою учетную запись.",
            button: "Удалить учетную запись",
            passwordMissing: "Введите пароль."
        },
        deleteAccountLinkTitle: "Удалить вашу учетную запись GridRewards",
        digitalPaymentTimeoutInstructions: "Если вы получите цифровой платеж в течение 30 дней, мы вышлем бумажный чек на ваш физический адрес:",
        donationsDetailSubtitle: "Это некоммерческие партнеры GridRewards. Если вы решите пожертвовать свои доходы, полученные от GridRewards, они будут в полном объеме разделены между этими организациями.",
        donationsDetailTitle: "Кому мы жертвуем",
        done: "Готово",
        duringEvent: "В ходе акции",
        earnings: "Заработки",
        earningsPotential: "Потенциальный заработок",
        edit: "Изменить",
        editPhoto: "Изменить фото",
        editProfileTitle: "Изменить профиль",
        editYourInfo: "Изменить вашу информацию",
        electricitySources: "Источники электроэнергии",
        email: "Эл. адрес",
        emailUs: "Напишите нам",
        energySavingActions: "Действия по экономии электроэнергии",
        energyTabTitle: "Электроэнергия",
        energyTitle: "Электроэнергия",
        enrollmentComplete: {
            carbon: {
                description: "Отслеживайте потребление электроэнергии и углеродный след. Получите персональные аналитические данные и узнайте, когда лучше всего пользоваться электроприборами.",
                title: "Уменьшите углеродный след"
            },
            community: {
                description: "Получите $10 за каждого друга, которого вы пригласили. Работайте в связке с соседями, чтобы заработать дополнительные награды и умножить эффект!",
                title: "Вовлекайте своих соседей"
            },
            events: {
                description: "Акции GridRewards — это самое важные периоды для экономии электроэнергии. Мы сообщим вам об их проведении — обычно это случается жаркими летними днями, примерно 5–10 раз в год.",
                title: "Следите за предстоящими акциями"
            },
            title: "Регистрация завершена!\nЧто дальше?"
        },
        estimated: "приблизительно",
        eventCountPlural: "Акции: {{count}}",
        eventCountSingular: "1 акция",
        eventLikelihood: "{{likelihood}}, что акция пройдет {{day}}.",
        eventLikelihoodNone: "Маловероятно, что акция пройдет на этой неделе.",
        eventPerformance: "Результативность в акции",
        eventPerformanceDisclaimer: "Чтобы заработать деньги за определенный месяц, выполните свои цели по экономии электроэнергии как минимум на 25% во всех акциях этого месяца.",
        events: {
            current: {
                timeFormat: "до {{endTime}}",
                title: "Акция GridRewards"
            },
            future: {
                timeFormat: "{{startTime}} — {{endTime}}",
                title: "Предстоящая акция GridRewards"
            },
            cta: "Я в деле",
            seeAll: "Все акции GridRewards"
        },
        eventsTabTitle: "Акции",
        eventsTitle: "Акции",
        expertMode: "Режим эксперта",
        expertModeWarning: "Мы вас предупреждали...",
        faqs: "Частые вопросы",
        findSomeActions: "Узнайте, какие действия позволяют экономить электроэнергию",
        firstName: "Имя",
        fuelMixDescription: "По состоянию на {{time}} {{percentRenewable}}% электроэнергии в регионе {{regionName}} поступало из возобновляемых источников, а {{percentCarbon}}% — из топлива, выделяющего углекислый газ.",
        fuelTypeDisplayDualFuel: "Двухтипное",
        fuelTypeDisplayHydro: "Гидро",
        fuelTypeDisplayNaturalGas: "Природный газ",
        fuelTypeDisplayNuclear: "Ядерное",
        fuelTypeDisplayOtherFossil: "Другие ископаемые виды топлива",
        fuelTypeDisplayOtherRenewables: "Другие возобновляемые источники энергии",
        fuelTypeDisplayWind: "Ветряное",
        getInTouch: "Связь с нами",
        getYourFriendsInvolved: "Вовлекайте своих друзей",
        gettingStarted: "С чего начать",
        good: "Хорошо",
        gramsCO2: "г CO₂",
        graph: {
            label: {
                day: "Предыдущий день",
                month: "Прошлый месяц",
                multiYear: "Прошлые 3 года",
                week: "Прошлая неделя",
                year: "Прошлый год"
            },
            span: {
                day: "1Д",
                month: "1М",
                multiYear: "3Г",
                week: "1Н",
                year: "1Г"
            }
        },
        gridRewards: "GridRewards",
        gridRewardsEarnings: "Заработки в GridRewards",
        gridRewardsEventPerformanceDescriptionBad: "В следующей акции постарайтесь набрать 100%, чтобы принести максимальную пользу окружающей среде и заработать еще больше.",
        gridRewardsEventPerformanceDescriptionGood: "Хорошо – в следующей акции постарайтесь достичь 100%, чтобы принести максимальную пользу окружающей среде и заработать еще больше.",
        gridRewardsEventPerformanceDescriptionGreat: "Поздравляем, отличные результаты! Вы достигли своей цели, помогли планете и подзаработали.",
        gridRewardsEventPerformanceDescriptionOkay: "Неплохо – в следующей акции постарайтесь достичь 100%, чтобы принести максимальную пользу окружающей среде и заработать еще больше денег.",
        gridRewardsEventTitle: "Акция GridRewards",
        gridRewardsNoEventsInList: "Как только в теплое время года начнут проходить акции, ваша результативность будет отображаться здесь.",
        gridRewardsSupport: "Служба поддержки GridRewards",
        haveQuestions: "Есть вопросы? Мы можем помочь.",
        high: "Высокое",
        homeTabTitle: "Главная",
        imageSaved: "Изображение сохранено в фото",
        incorrectAddressOrPaymentLate: "Неверный адрес или платеж запаздывает?",
        initialFetchDescription: "Мы все еще выполняем получение информации с вашего лицевого счета ЖКХ. Это может занять несколько минут.",
        insight: {
            highUsage: "Высокое потребление",
            lastBill: "Прошлая квитанция",
            overallRank: "Общий рейтинг",
            perCapitaRank: "Рейтинг на душу населения",
            projectedBill: "Прогнозируемые расходы в этом месяце",
            vsProjected: "Сравнение с прогнозом",
            vsSimilar: "Сравнение со схожими домами",
            vsYearAgo: "Сравнение с прошлым годом",
            yearAgo: "Год назад"
        },
        inviteExplanation: "Вы по приглашению?",
        inviteExplanationSubtitle: "Перейдите по пригласительной ссылке или введите код",
        invitePlaceholder: "Пригласительный код",
        kilowatts: "киловатт",
        lastName: "Фамилия",
        learnAboutEvents: "Подробнее об акциях GridRewards ->",
        less: "меньше",
        lesson: {
            actions: {
                description: "Узнайте, что вы можете сделать, чтобы преуспеть в акциях GridRewards. ->",
                title: "Узнайте, какие действия позволяют экономить электроэнергию"
            },
            moreTogether: {
                description: "Узнайте о том, как совместная работа помогает нам сильнее влиять на электросеть и всю планету ->",
                title: "Вместе мы можем больше"
            },
            referral: {
                description: "Умножайте эффективность своего района, приглашая друзей. Каждый из вас получит по 10 долларов и вы сможете побороться за первое место в рейтинге! ->",
                title: "Приглашайте друзей. Получайте по $10"
            }
        },
        licensesTitle: "Лицензии",
        likely: "Возможно",
        locationsTitle: "Локации",
        loginButtonCreate: "Создать новую учетную запись",
        loginButtonLoggingIn: "Выполняем вход",
        loginButtonResetPassword: "Сбросить пароль",
        loginErrorMissingFields: "Введите эл. адрес и пароль.",
        loginHeaderSubtitle: "Примечание: не путайте эту запись с личным кабинетом на сайте компании ЖКХ, это разные вещи.",
        loginResetPassword: "Введите адрес электронной почты, который вы использовали при регистрации, и мы вышлем вам ссылку для сброса пароля.",
        low: "Низкое",
        mailingAddress: {
            noData: "Адрес не найден",
            line1: "Адрес",
            line2: "Адрес 2",
            city: "Город",
            provinceOrState: "Штат",
            postalOrZip: "Индекс",
            prompt: {
                title: "Подтвердите свой почтовый адрес, чтобы мы могли отправить вам чек.",
                buttonConfirm: "Да, это мой почтовый адрес",
                buttonChange: "Нет, изменить адрес",
                buttonAdd: "Добавить почтовый адрес"
            },
            confirmation: {
                correctedTitle: "Подтвердите адрес",
                entered: "Введенный адрес",
                found: "Адрес найден",
                useSelected: "Использовать выбранный адрес",
                notFound: "Адрес не найден",
                edit: "Изменить адрес",
                useAnyway: "Использовать все равно",
                confirmed: "Адрес подтвержден"
            },
            prefs: {
                title: "Почтовый адрес (обязателен)",
                buttonUpdate: "Обновить адрес",
                validationError: "Не удалось подтвердить ваш адрес, попробуйте внести еще раз.",
                updateSuccessful: "Ваш платежный адрес обновлен",
                updateError: "Не удалось обновить ваш адрес, повторите попытку."
            }
        },
        mapApproximateLocation: "Приблизительное местоположение",
        mapCalloutHeader: "В часы пиковой нагрузки эта локация может сэкономить",
        mapDetailHeader: "Вместе мы можем обеспечить эффект такой же силы, как…",
        mapHeaderSubtitle: "В часы пиковой нагрузки пользователи GridRewards в этом районе могут экономить",
        mapInfoBody1: "Виртуальная электростанция (ВЭС) объединяет все районные энергоресурсы в единую сеть, которая может генерировать или экономить электроэнергию для всего района. Эти ресурсы, способные генерировать электричество (такие как солнечные панели и ветряные мельницы) или экономить его (например, жильцы, выключающие свет). В виртуальной электростанции GridRewards мы объединяем усилия домохозяйств и компаний, которые изъявили желание экономить электроэнергию в ходе акций GridRewards.",
        mapInfoBody2: "По нашим оценкам, во время акции GridRewards каждый участвующий дом или предприятие может экономить до 30% от своего максимального уровня потребления электроэнергии. Например, если среднестатистический дом способен потреблять до 1 кВт, то, если принять эффективные меры по энергосбережению, такой дом может сэкономить 0,3 кВт.",
        mapInfoBody3: "В периоды пикового спроса на электроэнергию электрическим компаниям приходится запускать старые низкоэффективные электростанции, которые выбрасывают больше вредных парниковых газов на киловатт-час электроэнергии. Экономя электроэнергию во время акций GridRewards, вы предотвращаете излишний выброс CO₂ в атмосферу.",
        mapInfoTitle1: "Что такое виртуальная электростанция?",
        mapInfoTitle2: "Как мы рассчитываем потенциальный коллективный эффект от наших действий?",
        mapInfoTitle3: "Как ВЭС GridRewards помогает окружающей среде?",
        mapLargeCommercial: "Крупный коммерческий объект",
        mapLargeResidential: "Крупный жилой объект",
        mapLegendButtonTitle: "Обозначения",
        mapLegendGroupTitle: "Группа локаций",
        mapLegendTitle: "Потенциальная экономия электроэнергии во время акций GridRewards",
        mapSmallCommercial: "Небольшой коммерческий объект",
        mapSmallResidential: "Небольшой жилой объект",
        megawatts: "мегаватт",
        minutePlural: "мин",
        minuteSingular: "минута",
        moderate: "Умеренное",
        more: "еще",
        mothersOutFrontDescription: "Эта НКО основана на убеждении, что нет более мощной движущей силы перемен, чем женщины, движимые материнскими чувствами. Мы знаем, что обязательно добьемся поставленных целей социальной, расовой и климатической справедливости во имя будущего наших детей.",
        mothersOutFrontLinkName: "mothersoutfront.org",
        mothersOutFrontTitle: "Mothers Out Front",
        naturalAreasConservancyDescription: "Сфера ответственности Natural Areas Conservancy — 8000 гектар лесов и водно-болотных угодий Нью-Йорка. Занятые в организации ученые и эксперты занимаются охраной природы в пяти районах города, работая в тесном сотрудничестве с городской администрацией.",
        naturalAreasConservancyLinkName: "naturalareasnyc.org",
        naturalAreasConservancyTitle: "Natural Areas Conservancy",
        neighborhood: {
            cta: "Ваш район",
            description: {
                plural: "Вы входите в район {{name}} вместе с еще {{count}} чел.",
                singular: "Вы входите в район {{name}} вместе с еще {{count}} человеком."
            },
            message: {
                captainTitle: "Подготовьте своих соседей!",
                captainDescription: "Как районный капитан, вы можете отправить сообщение об этой акции, которое увидят все.",
                captainPlaceholder: "Пример: привет, {{neighborhoodName}}, акция {{eventTime}}! Если наш район станет одним из лучших, мы сможем заработать дополнительные деньги, так что предлагаю вырубить всё и пойти гулять!",
                captainButtonTitle: "Обновить сообщение",
                attribution: "Капитан вашего района",
                updateSuccess: "Уведомление соседей об акции для соседей обновлено",
                updateFailed: "Не удалось обновить сообщение для соседей. Повторите попытку",
                videoUpload: {
                    retry: "Повторить загрузку",
                    recordNew: "Записать новое видео",
                    clear: "Сбросить видео",
                    error: "Ой, при загрузке этого видео произошла ошибка.",
                    remove: "Удалить видео"
                }
            }
        },
        networkUnavailableBody: "Нет сетевого подключения. Подключитесь к сотовой сети или Wi-Fi и повторите попытку.",
        networkUnavailableTitle: "Сеть недоступна",
        newYorkAndDC: "Г. Нью-Йорк и округ Колумбия",
        newYorkAndNational: "Нью-Йорк и остальные территории",
        newYorkCity: "Г. Нью-Йорк",
        nextGoodTime: "Следующее подходящее время для использования электричества —",
        nextOkayCO2Level: "Следующий допустимый уровень CO₂",
        noneInNext24Hours: "В следующие 24 часа нет",
        notification: {
            currentlyViewingTitle: "В данный момент просматривает уведомления для",
            authCompleted: "Поздравляем, все готово! Вот что вам нужно знать, чтобы принять участие в акциях GridRewards",
            eventEnd: "Поздравляем! Ваша акция GridRewards завершена. Зайдите через несколько дней, чтобы узнать о своих успехах!",
            eventPerformanceAvailable: "Узнайте, насколько результативны вы были в акции {{timeString}}.",
            eventScheduled: "Поехали! {{timeRangeString}} пройдет акция GridRewards",
            eventStart: "Поехали! Ваша акция GridRewards начнется {{atTimeString}}",
            fullDataAvailable: "Ваши данные по электричеству готовы!",
            initialDataAvailable: "Вши данные о недавних расходах электричества готовы к просмотру. Получение полной истории может занять до 15 минут.",
            learnMore: "Подробнее о {{subject}}",
            link: "Подробнее: {{- linkTitle}}",
            newNeighborhoodMessage: "Новое сообщение от вашего районного капитана",
            paymentAvailable: "Ваш платеж {{amount}} готов!",
            promptSurvey: "Сделайте анализ потребления электроэнергии более эффективным, приняв участие в опросе о вашем доме",
            promptThermostat: "У вас есть умный термостат Ecobee? Подключите его к GridRewards и управляйте им из приложения",
            referralPickedUp: "{{userDisplayName}} забрал(-а) ваше приглашение",
            referralSummary: "Некоторые из ваших приглашенных ({{number}}) в этом сезоне показали хорошие результаты, поэтому вы получаете премию!",
            reminderAuth: "Подключите свой лицевой счет ЖКХ, чтобы начать зарабатывать уже сегодня!",
            reminderReferral: "Пригласите друга и каждый из вас получит по $10!",
            seasonEarnings: "Доступен прогноз по вашему заработку в этом сезоне",
            seasonStart: "Сезон GridRewards в этом году начинается {{date}}. Следите за акциями GridRewards, это ваш шанс заработать деньги на экономии электроэнергии!",
            title: "Уведомления",
            unusualUsage: "Обнаружено необычное потребление. ПОДРОБНЕЕ ЗДЕСЬ."
        },
        now: "Сейчас",
        off: "Выключено",
        ok: "OK",
        okay: "Неплохо",
        onboarding: {
            carbon: {
                description: "Узнайте, когда лучше всего пользоваться электричеством.",
                title: "Не вся электроэнергия одинакова — проверьте вкладку «Выбросы», чтобы понять, когда потребление электроэнергии будет создавать наименьший углеродный след."
            },
            energy: {
                description: "",
                title: "Здесь будут отображаться ваши данные об электроэнергии, чтобы вы могли контролировать свои расходы."
            },
            events: {
                description: "Присоединяйтесь к своему району! Зарабатывайте и усиливайте коллективный вклад в защиту экологии.",
                title: "Акции — это интервалы продолжительностью 3–4 часа, которые случаются жаркими летними днями."
            },
            home: {
                description: "Сократите расход электроэнергии всего лишь на несколько часов в несколько важных дней, и вы получите деньги в конце года! Среднестатистическая семья может зарабатывать более 100 долларов ежегодно.",
                title: "Экономьте электроэнергию во время акций GridRewards, чтобы заработать деньги и помочь окружающей среде."
            }
        },
        onboardingLocationError: "Мы еще не работаем с указанным индексом.",
        onboardingLocationPlaceholder: "Введите свой почтовый индекс",
        onboardingWelcomeSubtitle: "GridRewards сотрудничает с вашей компанией ЖКХ, чтобы помочь вам заработать деньги за счет экономии электроэнергии.",
        participants: {
            othersPlural: "Соседи: участвуют уже {{count}}",
            othersSingular: "Уже участвует 1 ваш сосед",
            youAndOthersPlural: "Участвуете вы и ваши соседи ({{count}})!",
            youAndOthersSingular: "Участвуете вы и 1 ваш сосед!",
            youOnly: "Вы участвуете!"
        },
        password: "Пароль",
        paymentPrefDonateSeePartners: "Узнайте, кому мы помогаем в этом сезоне",
        paymentPrefDonateSubtitle: "Мы пожертвуем 100% вашего заработка некоммерческим организациям, которые занимаются полезными делами.",
        paymentPrefDonateTitle: "Сделать пожертвование",
        paymentPrefFinePrint: "Изменения применяются к предстоящим платежам, которые еще не были инициированы или запланированы.",
        paymentPrefInstantFee: "Комиссия $0,25 с каждого платежа",
        paymentPrefInstantSubtitle: "Мы вышлем вам платежную ссылку. Вы можете забрать платеж, используя свою учетную запись PayPal.",
        paymentPrefInstantTitle: "Моментальный платеж",
        paymentPrefMailSubtitle: "Платежи отправляются в конце календарного года.",
        paymentPrefMailTitle: "Получите чек по почте",
        paymentPrefShortDonate: "Пожертвовать",
        paymentPrefShortInstant: "Моментальный",
        paymentPrefShortMail: "Чек",
        paymentPrefUpdated: "Теперь выбран способ оплаты {{pref}}",
        paymentPreference: "Способ оплаты",
        paymentPrefsSubtitle: "Выберите способ получения платежей GridRewards. Платежи рассчитываются и рассылаются в конце года.",
        paymentsAtEndOfYear: "Ваш платеж будет отправлен в конце календарного года через {{paymentMethod}}.",
        paymentGeneralTimeframe: "Платежи рассчитываются и рассылаются в конце года.",
        payments: {
            title: "Ваши незавершенные начисления",
            rowTitles: {
                performance: "Результативность в акции",
                bonus: "Дополнительные начисления",
                bonusDescription: "Чтобы заработать эту дополнительную сумму, убедитесь, что приглашенные вами друзья тоже покажут хорошие результаты во всех акциях этого сезона!",
                estimated: "Прогнозируемый заработок",
                previous: "Предыдущие платежи"
            }
        },
        pendingAnalysis: "Ожидает анализа",
        percentLess: "На {{value}}% меньше",
        percentMore: "На {{value}}% больше",
        percentOfGoal: "% от вашей цели",
        percentOfGoalShort: "{{percent}}% от цели",
        percentRenewable: "{{percent}}% возобновляемой",
        performance: "Результативность",
        phoneNumber: "Номер телефона",
        phoneNumberGenericError: "Введите корректный номер телефона.",
        phoneNumberPlaceholder: "Номер телефона",
        phoneNumberPrompt: "Укажите для получения SMS-уведомлений",
        photoTake: "Сделать фото",
        photoLibrary: "Выбрать из библиотеки",
        photoDelete: "Удалить фото",
        photoPermissionMissingTitle: "Нет доступа к фото",
        photoPermissionMissing: "Откройте Настройки, чтобы разрешить GridRewards доступ к камере и библиотеке фото",
        powerGrid: "электросеть",
        powerInYourHands: "Энергия наконец-то в ваших руках.",
        profileFirstNameMissing: "Введите свое имя.",
        profileLastNameMissing: "Введите свою фамилию.",
        profilePhoneNumberDescription: "Получайте уведомления об акциях GridRewards по SMS. Обычно в год проходит 5-10 акций.\n\nЗа получение SMS и загрузку данных может взиматься плата. Частота сообщений не является постоянной. Ответьте HELP, чтобы получить помощь; ответьте STOP, чтобы отказаться от уведомлений.",
        profileRateReview: "Оставить оценку и отзыв",
        profileSignOut: "Выйти",
        profileUpdateGenericError: "Ой! Не удалось обновить профиль.",
        recent: "недавняя",
        referral: {
            codeCopied: "Пригласительный код скопирован!",
            copyYourCode: "Копировать код",
            description: "Попросите друга использовать ваш пригласительный код во время регистрации — когда он(-а) сэкономит электроэнергию во время сезона GridRewards, каждый из вас получит премию 10 долларов!\n\nПриглашайте столько друзей, сколько хотите.",
            downloadHere: "Скачать GridRewards можно здесь:",
            emailSubject: "Зарабатывайте деньги за экономию электроэнергии!",
            literalPlural: "приглашенные",
            literalSingular: "приглашенный",
            prompt: "Пригласить друга",
            screenTitle: "Приглашенные",
            shareMessage: "Отключайте приборы и зарабатывайте. Получайте деньги за экономию электроэнергии в ключевые моменты с GridRewards!\n\nИспользуйте код {{code}} при создании учетной записи (или используйте эту пригласительную ссылку для автоматического ввода кода), чтобы получить премию в размере 10 долларов США!",
            shareYourCode: "Поделиться пригласительным кодом",
            title: "Подарите $10, получите $10",
            errorCreatingLink: "Не удалось создать ссылку для общего доступа. Попробуйте еще раз."
        },
        regionElectricitySourcesTitle: "Энергоисточники региона {{regionName}}",
        resetPasswordCompletePlaceholder: "ваш эл. адрес",
        resetPasswordCompleteSubtitleAfter: "для получения ссылки на сброс пароля.",
        resetPasswordCompleteSubtitleBefore: "Проверьте адрес",
        resetPasswordCompleteTitle: "Ссылка на сброс пароля отправлена!",
        resetPasswordTitle: "Сбросить пароль",
        reviewPromptRate: "Просто класс!",
        reviewPromptRemind: "Напомнить позже",
        reviewPromptTitle: "Как вам нравится GridRewards?",
        saveImage: "Сохранить изображение",
        savedDuringEvents: "сэкономлено в ходе акций GridRewards",
        search: "Поиск",
        seePaymentPreferences: "Настройки выплат",
        seeTerms: "Условия использования",
        seeYourAchievements: "Ваши достижения",
        seeYourCarbonFootprint: "Ваш углеродный след",
        seeYourEnergyUse: "Ваше потребление электричества",
        send: "Отправить",
        sendFeedback: "Отправить отзыв",
        serviceAddressMissing: "Чтобы отправить чек, нам необходим ваш реальный почтовый адрес.",
        setNickname: "Присвоить обозначение",
        setNicknameDescription: "Присвойте этому месту обозначение, чтобы его легче было запомнить.",
        settings: "Настройки",
        setYourPaymentPref: "Настройте свои выплаты",
        share: "Поделиться",
        shareAndTag: "Поделитесь и отметьте @getgridrewards, чтобы засветиться в нашей подборке!",
        signIn: "Войти",
        signInCreatePrompt: "Впервые здесь? Создайте учетную запись",
        signInDescription: "Войдите в свою учетную запись GridRewards — она отличается от личного кабинета на сайте компании ЖКХ.",
        skip: "Пропустить",
        special: "Специальное",
        sustainableWestchesterDescription: "Sustainable Westchester — это некоммерческий консорциум местных администраций округа Уэстчестер, созданный для эффективного сотрудничества в рамках экологических проектов. Цель консорциума — предоставить социально ответственные, экологически безопасные и экономически целесообразные решения для создания районов с устойчивыми, высокими показателями экологичности.",
        sustainableWestchesterLinkName: "sustainablewestchester.org",
        sustainableWestchesterTitle: "Sustainable Westchester",
        tbd: "БОП",
        thatsTheSameAs: "Это то же самое, что…",
        theme: {
            dark: "Темная",
            light: "Светлая",
            system: "Системная",
            title: "Тема"
        },
        thermostat: {
            mode: {
                off: "Выключен",
                cool: "Охлаждение",
                heat: "Обогрев",
                both: "Оба"
            }
        },
        thermostatConnectPrompt: "У вас есть умный термостат Ecobee? Им можно управлять из приложения.",
        thermostatConnectButtonTitle: "Подключите свой Ecobee",
        thisBillingCycle: "этот платежный цикл",
        thisSeason: "Этот сезон",
        timeRangeFormatGeneral: "с {{startTime}} до {{endTime}}",
        timeAt: "в {{time}}",
        timeZoneInfo: "Время для этого помещения указывается в часовом поясе {{name}}",
        tip: "Совет",
        today: "Сегодня",
        tomorrow: "завтра",
        university: {
            courseEvents: {
                mostMoney: {
                    body: "В часы проведения акций GridRewards уменьшите потребление электроэнергии как минимум на треть. Вы заработаете максимальную сумму денег, если сделаете это во всех акциях одного лета.",
                    title: "Как зарабатывать наибольшее количество денег?"
                },
                participate: {
                    body: "В часы проведения акции просто уменьшите потребление электроэнергии настолько, насколько сможете. Ваша экономия измеряется автоматически с помощью счетчика электроэнергии.\n\nОхладите свое помещение за 4 часа до начала акции, а затем воспользуйтесь нашими инструкциями по энергосбережению, чтобы вывести потребление электроэнергии за интервалы акции.",
                    title: "Как участвовать в акциях?",
                    tipSave: "В ходе акции GridRewards старайтесь уменьшить свое обычное потребление электроэнергии хотя бы на треть, чтобы заработать больше денег."
                },
                performance: {
                    body: "Ваша экономия электроэнергии будет отслеживаться автоматически и сравниваться с вашим обычным потреблением в эти часы. В ходе акции GridRewards старайтесь уменьшить свое обычное потребление электроэнергии хотя бы на треть, чтобы заработать больше денег.",
                    title: "Как измеряется моя результативность?"
                },
                title: "Акции GridRewards",
                whatIsEvent: {
                    body: "Акции GridRewards — это интервалы продолжительностью 3–4 часа, в течение которых вы можете зарабатывать, экономя электроэнергию. Как правило, акции проходят жаркими летними днями, примерно 5–10 раз в году. Мы сообщим о них заранее, чтобы вы могли подготовиться.",
                    title: "Что такое акция GridRewards?"
                },
                whyImportant: {
                    body: "Акции проходят в периоды набольшей нагрузки на электросеть, когда, чтобы удовлетворить высокий спрос, поставщики электроэнергии вынуждены запускать вредные для экологии электростанции, работающие на нефти и газе. Акции позволяют снизить нагрузку на электросеть, предотвратить отключение электричества и провалы напряжения, а также предотвратить запуск загрязняющих природу резервных электростанций.",
                    title: "В чем важность акций GridRewards?"
                }
            },
            courseMultiplyImpact: {
                neighborhoodPride: {
                    body: "Свой вклад в нагрузку на электросеть вносят все жители вашего района. Присоединяйтесь к другим пользователям GridRewards в вашем районе, чтобы получать дополнительные вознаграждения за совместную работу. Соревнуйтесь с другими районами и убедитесь, насколько мы сильнее, когда работаем вместе.",
                    title: "Гордость района"
                },
                referral: {
                    body: "Убедили кого-то зарегистрироваться в GridRewards? Если ваш приглашенный сэкономит достаточно электроэнергии, чтобы заработать деньги в следующем сезоне GridRewards, каждый из вас получит премию в размере 10 долларов и усиленный полезный эффект. Количество приглашенных не ограничено.",
                    title: "Пригласите друга"
                },
                shiftThePower: {
                    body: "Впервые в истории вы и ваши соседи можете контролировать потребление энергии и углеродный след своего района. Сдвигая потребление электричества за пределы определенных периодов высокой нагрузки, вы поможете сделать энергию, которой пользуетесь, более чистой (и заработаете на этом деньги).",
                    title: "Вы — часть движения."
                },
                title: "Умножайте свою эффективность"
            },
            courseOther: {
                carbonFootprint: {
                    body: "Не вся электроэнергия одинакова — проверьте вкладку «Выбросы», чтобы понять, когда потребление электроэнергии будет иметь наименьший углеродный след.",
                    title: "Уменьшите свой углеродный след"
                },
                checkYourself: {
                    body: "Отслеживайте расход электроэнергии и получайте прогнозы по ежемесячным счетам на вкладке «Электроэнергия».",
                    title: "Проверьте себя"
                },
                title: "Что еще я могу делать с помощью этого приложения?"
            },
            coursePowerGrid: {
                peakerPlants: {
                    body: "При перегрузке электросети энергокомпании вводят в действие резервные электростанции, работающие на ископаемом топливе. Энергокомпании тратят много денег на поддержание этих электростанций в рабочем состоянии, эти расходы отражаются и на вас.",
                    title: "Резервные электростанции — что это такое?"
                },
                timeWindow: {
                    body: "Благодаря историческим данным о потреблении электричества и другой полезной информации, такой как данные о погоде, поставщики электроэнергии могут спрогнозировать, когда случится пиковое потребление электроэнергии и каков будет его объем. Эти пики могут быть довольно высокими, достигая порядка 200% от обычного уровня потребления. Если поставщики электроэнергии могут хотя бы немного уменьшить пиковую нагрузку, это позволяет снизить расходы на весь год.",
                    title: "Чем поможет моя экономия электричества?"
                },
                title: "Электроэнергия и электросеть",
                unplugging: {
                    body: "Отключить телефон или поменять время сушки одежды — кажется мелочью, однако такие мелочи способны давать накопительный эффект. Снижение потребления электричества в ходе акций GridRewards имеет особо значимое влияние на углеродный след всей энергосети. Снижение пиковой нагрузки позволяет не задействовать резервные электростанции, что выгодно сказывается на качестве воздуха и вашем кошельке.",
                    title: "Отключение электроприборов действительно помогает?"
                },
                whatIsTheGrid: {
                    body: "«Сеть» — это совокупность электростанций, которые снабжают вас электричеством. У каждой локации свой набор источников энергии — одни из них чистые, другие… не очень.",
                    title: "Что такое «электросеть»?"
                }
            },
            courseTitle: "Урок {{index}}/{{total}}"
        },
        universityTitle: "Университет GridRewards",
        unknown: "Неизвестно",
        unlikely: "Маловероятно",
        usageThisWeek: "Потребление на этой неделе",
        valuePropEventsBody: "Это самые ценные периоды для экономии электроэнергии. Как правило, они приходятся на полуденное время в жаркие летние дни.\n\nХотя электричество полезно экономить круглый год, акции GridRewards настолько важны, что компания ЖКХ готова платить вам реальные деньги, чтобы снизить потребление в периоды пиковой нагрузки.\n\nПочему? Когда электрической компании нужно удовлетворить пиковую потребность вашего региона в электроэнергии, она запускает старые, менее эффективные электростанции, которые дороже в обслуживании и выделяют в воздух больше углекислого газа.",
        veryBad: "Очень плохо",
        veryHigh: "Очень высокое",
        veryLikely: "Вероятно",
        virtualPowerPlant: "Виртуальная электростанция",
        weActDescription: "Миссия WE ACT состоит в том, чтобы создавать жизнеспособные общины, гарантирующие активное участие цветного населения и/или жителей с низким доходом в разработке справедливых и сбалансированных политик и практик в области охраны окружающей среды.",
        weActLinkName: "weact.org",
        weActTitle: "WE ACT",
        welcome: "Добро пожаловать",
        welcomeBack: "Рады видеть вас снова",
        welcomeToGridRewards: "Добро пожаловать в GridRewards",
        westchesterCounty: "Округ Уэстчестер",
        yesterday: "вчера",
        yesterdayVsProjection: "Вчера по сравнению с прогнозом",
        yourAddress: "Ваш адрес",
        yourCarbonFootprint: "Ваш углеродный след",
        yourEarningsShort: "Ваш заработок за {{timePeriod}}",
        yourNeighborhood: "Ваш район",
        yourPerformance: "Ваша результативность",
        yourProjectedBill: "Ваши прогнозируемые расходы",
        zipCode: "Почтовый индекс",
        zipCodeExplanation: "Давайте убедимся, что мы работаем с вашим районом",
        zipCodeInvalid: "Пожалуйста, введите верный почтовый индекс."
    }
};