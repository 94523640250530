import { useActionSheet } from "@expo/react-native-action-sheet"
import { useHeaderHeight } from "@react-navigation/elements"
import { useNavigation } from "@react-navigation/native"
import { StatusBar } from "expo-status-bar"
import moment from "moment-timezone"
import { path, prop } from "ramda"
import React, { useEffect, useRef } from "react"
import { Image, Platform, Text, View, Share } from "react-native"
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import * as Sharing from "expo-sharing"

// This isn't supported on web, so we'll disable the share button.
let captureRef = null
if (Platform.OS != "web") {
    captureRef = require("react-native-view-shot").captureRef
}

export default function AchievementDetailScreen(props) {
    const navigation = useNavigation()

    const insets = useSafeAreaInsets()
    const shareableView = useRef()
    const headerHeight = useHeaderHeight()

    const { theme } = React.useContext(ThemeContext)

    const { achievements, achievementConfig } = path(["route", "params"], props) || {}

    const iconSize = 326 // The actual icon includes extra space for the on-state's glow
    let iconLayoutSize = 278

    const completedCount = (achievements || []).length
    const completed = completedCount > 0
    let completedString = i18n.t("achievement.countNone")
    if (completed) {
        let createdDate = moment(prop("createdAt", achievements[0]))
        // If we're in the same year, don't show the year in the date string.
        let dateString = createdDate.format(createdDate.isSame(moment(), "year") ? "MMMM Do" : "MMMM Do, YYYY")
        if (completedCount == 1) {
            completedString = i18n.t("achievement.countSingular", { date: dateString })
        } else if (completedCount == 2) {
            completedString = i18n.t("achievement.countMultipleSingular", { date: dateString })
        } else {
            completedString = i18n.t("achievement.countPlural", { date: dateString, count: completedCount - 1 })
        }
    }

    async function shareImage(uri) {
        try {
            if (Platform.OS == "ios") {
                const content = {
                    // IMPORTANT: This message is ignored in many sharers. For example, on iOS, it's never passed to Messages, though it IS included as the first line in an email.
                    // message: "This is a test of the share process: " + (prop("title", achievementConfig) || "unknown config"),
                    url: uri,
                }
                const response = await Share.share(content)
                console.log("Share response was " + JSON.stringify(response))
            } else {
                // In this case, we have to use Expo's sharing logic, because RN's Share library doesn't support image content on Android.
                await Sharing.shareAsync(uri)
                console.log("Shared achievement")
            }
        } catch (error) {
            console.log("Error while sharing: " + JSON.stringify(error))
        }
    }

    const sharePressed = () => {
        console.log("Share pressed")
        captureRef(shareableView, {
            format: "jpg",
            quality: 0.8,
            result: "tmpfile",
        }).then(
            async (uri) => {
                console.log("Shareable uri is " + uri)
                shareImage(uri)
            },
            (error) => console.error("Oops, snapshot failed", error)
        )
    }

    useEffect(() => {
        if (Platform.OS != "web") {
            navigation.setOptions({
                headerRight: (props) => (
                    <TouchableOpacity style={{ paddingRight: 20, paddingLeft: 8, justifyContent: "center", alignItems: "center" }} onPress={sharePressed}>
                        <Image source={rawThemes.dark.icons.share} style={{ height: 24, width: 24 }} />
                    </TouchableOpacity>
                ),
            })
        }
    }, [])

    // NOTE: Layout is different here! We need to move the padding/margins around to make sure that our share image looks correct.
    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: "#000", paddingTop: headerHeight }]}
            contentContainerStyle={[CommonStyles.mainScreenContent, {}]}
        >
            <View style={{ minHeight: "100%", alignItems: "center" }}>
                <View ref={shareableView} style={{ backgroundColor: "#000", alignItems: "center", marginTop: 48, paddingHorizontal: 20 }}>
                    <View
                        style={{
                            width: iconLayoutSize,
                            height: iconLayoutSize,
                            marginTop: 40,
                            marginBottom: 40,
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "visible",
                        }}
                    >
                        <Image
                            source={rawThemes.dark.icons.achievements.large[prop("icon", achievementConfig) + (!completed ? "Off" : "")]}
                            style={{
                                width: iconSize,
                                height: iconSize,
                            }}
                        />
                    </View>
                    <Text
                        style={[
                            TextStyles.h1kobe,
                            { color: rawThemes.dark.textPrimary, marginBottom: 16, textAlign: "center", opacity: completed ? 1.0 : 0.7 },
                        ]}
                    >
                        {i18n.t(`achievement.${prop("type", achievementConfig)}.title`)}
                    </Text>
                    <Text
                        style={[
                            TextStyles.body2,
                            { color: rawThemes.dark.textSecondary, textAlign: "center", marginBottom: 16, opacity: completed ? 1.0 : 0.7 },
                        ]}
                    >
                        {i18n.t(`achievement.${prop("type", achievementConfig)}.description`)}
                    </Text>
                    <Text
                        style={[
                            TextStyles.caption,
                            { color: completed ? rawThemes.dark.textHint : rawThemes.dark.teal, textAlign: "center", marginBottom: 40 },
                        ]}
                    >
                        {completedString}
                    </Text>
                </View>
                {Platform.OS != "web" && (
                    <TouchableOpacity
                        style={{
                            marginTop: 56,
                            paddingHorizontal: 16,
                            paddingVertical: 8,
                            borderWidth: 1,
                            borderColor: rawThemes.dark.border,
                            borderRadius: 1000,
                        }}
                        onPress={sharePressed}
                    >
                        <Text style={[TextStyles.caption, { color: rawThemes.dark.textDisabled }]}>{i18n.t("shareAndTag")}</Text>
                    </TouchableOpacity>
                )}
            </View>
            <StatusBar hidden={true} />
        </ScrollView>
    )
}
