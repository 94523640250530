import { useNavigation } from "@react-navigation/native"
import * as Linking from "expo-linking"
import * as React from "react"
import { FlatList, Image, StyleSheet, Text, View, TouchableOpacity } from "react-native"
import Colors from "../shared/Colors"
import { CommonStyles } from "../shared/CommonStyles"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { capitalizeFirstLetter, extractNameFromGithubUrl } from "../shared/Utils"

const licensesData = require("../licenses.json")

export default function LicensesScreen(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    let licenses = Object.keys(licensesData).map((key) => {
        let { licenses, ...license } = licensesData[key]
        // Some repos start with @ in their name, which we want to remove.
        let [title, version] = key.slice(key.startsWith("@") ? 1 : 0).split("@")

        let username = extractNameFromGithubUrl(license.repository) || extractNameFromGithubUrl(license.licenseUrl)

        let userUrl
        let image
        if (username) {
            username = capitalizeFirstLetter(username)
            image = `http://github.com/${username}.png`
            userUrl = `http://github.com/${username}`
        }

        return {
            key,
            title,
            image,
            userUrl,
            username,
            licenses: licenses.slice(0, 405),
            version,
            ...license,
        }
    })

    return (
        <FlatList
            keyboardShouldPersistTaps={"handled"}
            data={licenses}
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background, paddingTop: 24 }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
            renderItem={({ item }) => {
                const { key, licenseUrl, title, licenses, version, image, repository } = item
                return (
                    <TouchableOpacity
                        style={{
                            backgroundColor: Colors.gray6,
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 16,
                            paddingHorizontal: 16,
                            paddingVertical: 8,
                            borderRadius: 8,
                        }}
                        onPress={() => {
                            console.log("Trying to open license at " + licenseUrl)
                            Linking.openURL(licenseUrl)
                        }}
                    >
                        {image != null && (
                            <TouchableOpacity
                                onPress={() => {
                                    Linking.openURL(repository)
                                }}
                            >
                                <Image source={{ uri: image }} resizeMode="cover" style={{ width: 44, height: 44, marginRight: 16, borderRadius: 12 }} />
                            </TouchableOpacity>
                        )}
                        <View style={{ width: 0, flex: 1, flexGrow: 1, marginRight: 8 }}>
                            <Text style={[TextStyles.content3Strong, { marginBottom: 2 }]}>{title}</Text>
                            <Text style={TextStyles.content4}>{licenses + " license"}</Text>
                            <Text style={TextStyles.content4}>{version}</Text>
                        </View>
                        <Image source={theme.icons.arrowRight} style={{ opacity: 0.7, width: 16, height: 16 }} />
                    </TouchableOpacity>
                )
            }}
            keyExtractor={(item, index) => index.toString()}
        />
    )
}

const styles = StyleSheet.create({})
