import { useIsFocused, useNavigation } from "@react-navigation/native"
import { prop } from "ramda"
import React, { useEffect, useState } from "react"
import { Text } from "react-native"
import { useSelector } from "react-redux"
import { ScreenNames } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import { pastEvents } from "../../shared/Utils"
import GridRewardsHistoryRow from "../GridRewardsHistoryRow"
import CardView from "./CardView"

export default function EventHistoryCard(props) {
    const mainData = useSelector((state) => state.primaryData.mainData)
    const { theme } = React.useContext(ThemeContext)

    const navigation = useNavigation()
    const isFocused = useIsFocused()

    const gridRewardsHistoryData = useSelector((state) => state.primaryData.gridRewardsHistoryData) || []
    const [visibleEvents, setVisibleEvents] = useState([])

    useEffect(() => {
        if (isFocused) {
            setVisibleEvents(pastEvents(gridRewardsHistoryData))
        }
    }, [gridRewardsHistoryData])

    // There's a weird visual artifact of the rows containing incomplete circles. The result appears to be that we need a few extra points of padding to
    // make things line up, perceptually, with other cards.
    return (
        <CardView includesBorder={true} containerStyle={{ padding: 24 }} {...props}>
            {visibleEvents.length == 0 && <Text style={[TextStyles.body2, { color: theme.textSecondary }]}>{i18n.t("gridRewardsNoEventsInList")}</Text>}

            {visibleEvents != null &&
                visibleEvents.map((historyItem, index) => {
                    return (
                        <GridRewardsHistoryRow
                            containerStyle={{ marginBottom: index < visibleEvents.length - 1 ? 24 : 0 }}
                            key={prop("timestamp", historyItem) + index.toString()}
                            historyData={historyItem}
                            onPress={() => {
                                prop("finalCalculation", historyItem) == true
                                    ? navigation.navigate(ScreenNames.GridRewardsEventDetail.routeName, {
                                          gridRewardsEvent: historyItem,
                                      })
                                    : null
                            }}
                        />
                    )
                })}

            {/* <Button type="fill" title={i18n.t("seeEventHistory")} onPress={null} /> */}
        </CardView>
    )
}
