import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
import { path, prop } from "ramda"
import * as React from "react"
import { useEffect, useState } from "react"
import { Dimensions, ScrollView, Text, View, TouchableOpacity } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useDispatch } from "react-redux"
import Button from "../components/Button"
import CloseButton from "../components/CloseButton"
import ErrorMessageInline from "../components/ErrorMessageInline"
import { updatePaymentAddress } from "../model/primaryDataActions"
import AppConstants from "../shared/AppConstants"
import i18n from "../shared/i18n"
import { activeOpacity, maxContentWidth } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { formattedAddress, isEmpty, showToast } from "../shared/Utils"
import moment from "moment-timezone"

export default function AddressVerificationScreen(props) {
    const insets = useSafeAreaInsets()
    const { theme } = React.useContext(ThemeContext)
    const navigation = useNavigation()
    const dispatch = useDispatch()

    const { originalAddress, correctedAddress, existingAddressId } = path(["route", "params"], props) || {}
    const [useOriginalAddress, setUseOriginalAddress] = useState(false)

    const contentWidth = maxContentWidth - 32
    const maxHeight = Dimensions.get("window").height - insets.top - insets.bottom - 32

    const useSelectedButtonPressed = () => {
        //FIXME: Send "user" as the validation service for originalAddress cases as soon as the API supports it.
        const notValidated = useOriginalAddress || !hasValidCorrection
        dispatch(
            updatePaymentAddress(
                notValidated ? originalAddress : correctedAddress,
                existingAddressId,
                notValidated ? false : true
                /*, useOriginalAddress ? "user" : "PostGrid"*/
            )
        )
        showToast(i18n.t("mailingAddress.prefs.updateSuccessful"), theme.icons.checkmark32)
        // Update our last confirm date.
        AsyncStorage.setItem(AppConstants.paymentAddressLastConfirmDate, moment().toISOString())
        navigation.goBack()
    }

    const errors = prop("errors", correctedAddress)
    const hasValidCorrection = !isEmpty(correctedAddress) && isEmpty(errors)
    const [errorDisplayString, setErrorDisplayString] = useState(null)

    useEffect(() => {
        // Show the first error we encounter.
        const errorArrays = Object.values(errors)
        if (errorArrays != null && errorArrays.length > 0) {
            setErrorDisplayString(errorArrays[0][0])
        }
    }, [errors])

    return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <View
                style={{
                    backgroundColor: theme.background,
                    width: contentWidth,
                    maxHeight: maxHeight,
                    marginTop: insets.top + 24,
                    marginBottom: insets.bottom + 72,
                    alignSelf: "center",
                    alignItems: "center",
                    borderRadius: 24,
                    overflow: "hidden",
                }}
            >
                <ScrollView
                    style={{
                        flexGrow: 0,
                        padding: 24,
                        paddingTop: 64,
                        width: "100%",
                    }}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                >
                    {errorDisplayString != null && <ErrorMessageInline text={errorDisplayString} />}

                    <Text style={[TextStyles.body2Medium, { color: theme.textSecondary, marginBottom: 8 }]}>
                        {i18n.t("mailingAddress.confirmation.entered")}
                    </Text>
                    <TouchableOpacity
                        style={{
                            padding: 16,
                            borderWidth: 1,
                            borderColor: useOriginalAddress ? theme.greenDark : theme.border,
                            borderRadius: 8,
                            marginBottom: 16,
                        }}
                        activeOpacity={activeOpacity}
                        onPress={() => {
                            setUseOriginalAddress(true)
                        }}
                    >
                        <Text style={[TextStyles.body2, { color: theme.textPrimary }]}>{formattedAddress(originalAddress)}</Text>
                    </TouchableOpacity>
                    {hasValidCorrection && (
                        <View>
                            <Text style={[TextStyles.body2Medium, { color: theme.textSecondary, marginBottom: 8 }]}>
                                {i18n.t("mailingAddress.confirmation.found")}
                            </Text>
                            <TouchableOpacity
                                style={{
                                    padding: 16,
                                    borderWidth: 2,
                                    borderColor: useOriginalAddress ? theme.border : theme.greenDark,
                                    borderRadius: 8,
                                    marginBottom: 16,
                                }}
                                activeOpacity={activeOpacity}
                                onPress={() => {
                                    setUseOriginalAddress(false)
                                }}
                            >
                                <Text style={[TextStyles.body2, { color: theme.textPrimary }]}>{formattedAddress(correctedAddress)}</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                    {!hasValidCorrection && (
                        <Button
                            title={i18n.t("mailingAddress.confirmation.edit")}
                            contentTintColor={theme.textPrimary}
                            containerStyle={{ marginBottom: 8 }}
                            onPress={() => navigation.goBack()}
                        />
                    )}
                    <Button
                        title={i18n.t(hasValidCorrection ? "mailingAddress.confirmation.useSelected" : "mailingAddress.confirmation.useAnyway")}
                        contentTintColor={theme.textPrimary}
                        onPress={useSelectedButtonPressed}
                    />
                </ScrollView>
                <View
                    style={{
                        position: "absolute",
                        backgroundColor: theme.background,
                        height: 64,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 24,
                        left: 0,
                        top: 0,
                    }}
                >
                    <Text style={[TextStyles.body2Semi, { color: theme.textPrimary, width: "100%", textAlign: "center" }]}>
                        {i18n.t("mailingAddress.confirmation.correctedTitle")}
                    </Text>

                    <CloseButton
                        showBackgroundCircle={true}
                        containerStyle={{ position: "absolute", right: 20, paddingLeft: 0, paddingRight: 0 }}
                        imageStyle={{ tintColor: theme.textSecondary }}
                    />
                </View>
            </View>
        </View>
    )
}
