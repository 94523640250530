export const translationStrings = {
    translation: {
        aboutTheSame: "Aproximadamente lo mismo",
        accountStale: "Hace algún tiempo que no recibimos información nueva de tu cuenta de servicios públicos. Si te mudaste, puedes conectar tu cuenta nueva, de lo contrario, infórmanos.",
        achievement: {
            betterGoal: {
                description: "Lo hiciste bien durante un evento GridRewards.",
                title: "Buen desempeño"
            },
            connectedAccount: {
                description: "Conectaste una cuenta de servicios públicos. ¡Ahora puedes ver tus datos de energía en GridRewards y comenzar a ganar dinero!",
                title: "Estás conectado"
            },
            countMeIn: {
                description: "Te inscribiste para participar en un evento GridRewards.",
                title: "Tu voz cuenta"
            },
            countNone: "Todavía no obtienes este logro",
            countPlural: "Logrado el {{date}} y en {{count}} ocasiones más",
            countMultipleSingular: "Logrado el {{date}} y una ocasión más",
            countSingular: "Logrado el {{date}}",
            eventCount10: {
                description: "Lo hiciste lo suficientemente bien como para ganar dinero en 10 eventos GridRewards. ¡Sigue así!",
                title: "10 eventos exitosos"
            },
            eventCount25: {
                description: "Lo hiciste lo suficientemente bien como para ganar dinero en 25 eventos GridRewards. ¡Así se hace!",
                title: "25 eventos exitosos"
            },
            eventCount50: {
                description: "Lo hiciste lo suficientemente bien como para ganar dinero en 50 eventos GridRewards. ¡Sigue así!",
                title: "50 eventos exitosos"
            },
            goalReached: {
                description: "¡Superaste tu meta de ahorro de electricidad durante un evento GridRewards!",
                title: "Ninja de la energía"
            },
            graphViewed: {
                description: "Viste la gráfica de energía por primera vez.",
                title: "Explorador de datos"
            },
            minimumGoal: {
                description: "Alcanzaste tu meta mínima de ahorro de electricidad durante un evento GridRewards",
                title: "Meta mínima alcanzada"
            },
            neighborJoined: {
                description: "Lograste que alguien de tu vecindario se uniera a GridRewards.",
                title: "Líder del vecindario"
            },
            referralSent: {
                description: "Enviaste una invitación de referencia para que tú y tu amigo puedan ganar dinero adicional durante los eventos.",
                title: "Refirió un amigo"
            },
            topNeighborhood: {
                description: "Tu vecindario fue uno de los mejores en ahorro de electricidad durante un evento GridRewards.",
                title: "Mejor vecindario"
            },
            topNeighborhoodPerCapita: {
                description: "Tu vecindario logró algunos de los mejores ahorros de electricidad por persona durante un evento GridRewards.",
                title: "Mejor vecindario por persona"
            },
            topPerformer: {
                description: "Ahorraste la mayor cantidad de electricidad de tu vecindario durante un evento GridRewards.",
                title: "Mejor desempeño"
            }
        },
        achievementsTitle: "Logros",
        acknowledgements: "Reconocimientos",
        actions: {
            appliances: {
                subtitle: "Lava los platos, la ropa y no uses la cafetera hasta después del evento.",
                subtitleAdvanced: "Quédense sucios, amigos.",
                title: "Pospón el uso de los electrodomésticos grandes"
            },
            ev: {
                subtitle: "Los vehículos eléctricos pueden consumir aproximadamente el 30% de la electricidad total de una casa, por esto, esperar para cargar tiene gran impacto. ¿Aún no tienes un vehículo eléctrico? Ya los inventaron ;-).",
                subtitleAdvanced: "¿Aún no tienes un vehículo eléctrico? Consigue uno y haz que llevar los niños a la escuela no sea como un campo de batalla de contaminación.",
                title: "Espera para cargar tu vehículo eléctrico"
            },
            fridge: {
                subtitle: "Ajusta la temperatura de tu refrigerador y congelador a un nivel más alto. Intenta mantener las puertas cerradas para que el aire frío no se escape.",
                subtitleAdvanced: "Las carnes frías calientes son buenas, ¿cierto? Probablemente no mueras, hoy.",
                title: "Eleva la temperatura del refrigerador y del congelador"
            },
            lights: {
                subtitle: "Apaga todas las luces que puedas. Para que ahorres mucha energía, también puedes cambiar las bombillas incandescentes por LED.",
                subtitleAdvanced: "Puede que te golpees con algunas paredes, pero finalmente, nada importa.",
                title: "Apaga las luces"
            },
            preCool: {
                subtitle: "Cuatro horas antes del evento, ajusta el termostato a una temperatura más baja de lo normal, y luego déjala subir durante el evento.",
                subtitleAdvanced: "Prepárate para la crisis energética que viene.",
                title: "Enfría previamente tu espacio"
            },
            raiseAC: {
                subtitle: "Durante el evento, ajusta el termostato unos cuantos grados más alto de lo normal.",
                subtitleAdvanced: "Saca provecho de tu incomodidad.",
                title: "Eleva la temperatura del aire acondicionado"
            },
            unplug: {
                subtitle: "Apaga el televisor, el decodificador de cable y los demás electrodomésticos grandes. ¡Vete a caminar!",
                subtitleAdvanced: "Aléjate de las pantallas, sal al exterior y consigue un poco de vitamina D. Lucha contra tu ansiedad, con GridRewards™.",
                title: "Desconecta"
            }
        },
        actionsInfoSubtitle: "¡Los pequeños cambios suman! Estos son los cambios que tienen el mayor impacto, que puedes hacer para cada evento GridRewards.",
        addButtonTitle: "Añadir",
        addPhoto: "Añadir foto",
        alreadyHaveAnAccountPrompt: "¿Ya tienes una cuenta? Inicia sesión",
        amiOnlyWarning: "¡Solo un paso más para comenzar a ganar dinero! Termina de conectar tu cuenta de servicios públicos a GridRewards.",
        amountOfCO2: "{{amount}} de CO₂",
        amountOfElectricity: "{{amount}} de electricidad",
        avatarUpdateFailed: "No pudimos cargar tu foto de perfil. Intenta de nuevo",
        and: "y",
        backToProfile: "Regresar al perfil",
        backToSignIn: "Regresar a iniciar sesión",
        bad: "Malo",
        beforeEvent: "Antes del evento",
        billingCycle: "Ciclo de facturación",
        bonuses: "Ganancias de bonificación",
        calendarEventConfirmation: "Añadido a tu calendario",
        calendarEventErrorBody: "No pudimos crear el evento en el calendario, Asegúrate de que esté configurado e intenta de nuevo.",
        calendarEventErrorTitle: "No se pudo añadir al calendario",
        calendarEventTitle: "Evento GridRewards: ¡ahorra electricidad ahora!",
        callUs: "Llámanos",
        cancel: "Cancelar",
        carbonComparison1After: "plántulas de árboles durante 10 años",
        carbonComparison1Before: "creciendo",
        carbonComparison2After: "millas",
        carbonComparison2Before: "Evita conducir un auto",
        carbonComparison3After: "que los teléfonos inteligentes carguen",
        carbonComparison3Before: "evita que",
        carbonComparison4After: "toneladas de basura, en vez de arrojarla a un relleno sanitario",
        carbonComparison4Before: "reciclaje",
        carbonComparison5After: "acres de bosques prosperando durante un año",
        carbonComparison6After: "hogares usarían en un año",
        carbonComparison6Before: "la energía que",
        carbonComparison7After: "tanques de propano que no se usarán en los asadores domésticos",
        carbonComparison8After: "autos sin usar durante un año",
        carbonComparison8Before: "sacando",
        carbonFootprintTitle: "Huella de carbono",
        carbonFootprintTitleCase: "Huella de carbono",
        carbonGraphAnalysisForecast: "posiblemente emita",
        carbonGraphAnalysisGrid: ": red eléctrica",
        carbonGraphAnalysisPast: "emitidos",
        carbonGraphAnalysisUnits: "gramos de CO₂/kWh",
        carbonGraphInfo: "No toda la electricidad se genera de la misma manera. Las barras más altas indican las horas con mayor emisión de carbono por unidad de electricidad usada: ahorra energía durante esas horas para hacer el mayor impacto.",
        carbonGraphRegionPlaceholder: "Tu región",
        carbonPreventedDuringEvents: "no emitido a la atmósfera",
        carbonTabTitle: "CO₂",
        cardThermostatCoolTo: "Enfriar a",
        cardThermostatHeatTo: "Calentar a",
        cardThermostatHold: "Mantener",
        cardThermostatStayBetween: "Mantener entre",
        categoryClimateAction: "Acción climática",
        categoryEnvironmentalJustice: "Justicia ambiental",
        categoryFoodSecurity: "Seguridad alimentaria",
        categoryLandConservancy: "Conservación de la tierra",
        categorySustainability: "Sostenibilidad",
        cityHarvestDescription: "Es la organización de rescate de alimentos más grande de Nueva York, que ayuda a alimentar a más de 1.5 millones de sus habitantes con dificultad para alimentar a sus familias. Este año City Harvest rescatará 110 millones de libras de alimentos y los entregará sin costo a miles de bancos de alimentos, comedores comunitarios y otros aliados de la comunidad en cinco distritos.",
        cityHarvestLinkName: "cityharvest.org",
        cityHarvestTitle: "City Harvest",
        community: "Comunidad",
        completeAccountSetup: "Completa la configuración de la cuenta",
        connectNewAccount: "Conectar una cuenta nueva",
        connectYourAccount: "Conecta tu cuenta",
        connectAccount: {
            title: "Comencemos",
            description: "Conecta tu cuenta de la empresa de energía para que puedas comenzar a participar en eventos GridRewards y recibir pagos.",
            noOnlineAccountTitle: "¿No tienes una cuenta en línea de la empresa de servicios públicos?",
            noOnlineAccountDescription: "Si aún no lo has hecho, primero debes configurar tu cuenta en línea de la empresa de energía y añadir el número de cuenta existente a la cuenta en línea: esto confirma que tu contador está conectado activamente.",
            cta: "Conectar cuenta de servicio público"
        },
        createAccount: "Crear cuenta",
        createAccountErrorMissingFields: "Introduce un correo electrónico y una contraseña, y luego pulsa en \'Crear cuenta\'.",
        createGridRewardsAccount: "Crear una cuenta GridRewards",
        currentGridCO2: "CO₂ de la red actual",
        currentStreak: "Racha actual",
        dataFetchTitle: "Recuperando tus datos. Esto puede tardar hasta 15 minutos.",
        dataSourceNameElectricity: "Electricidad",
        dataSourceNameGas: "Gas",
        dataTimeframeDay: "Día",
        dataTimeframeHour: "Hora",
        defaultDisplayName: "Usuario de GridRewards",
        deleteAccount: {
            title: "Eliminar tu cuenta de GridRewards",
            descriptionBeforeDisconnect: "Eliminar tu cuenta cancela tu acceso al programa GridRewards y evita que ganes dinero por esta y las siguientes temporadas.\n\nAdemás de eliminar tu cuenta de GridRewards, puede que desees",
            descriptionDisconnectLink: "desactivar la función de compartir los datos de servicios públicos desde tu cuenta de la empresa de servicios públicos.",
            descriptionAfterDisconnect: "\n\nSi estás seguro, puedes introducir tu contraseña y eliminar tu cuenta a continuación.",
            button: "Eliminar tu cuenta",
            passwordMissing: "Introduce la contraseña."
        },
        deleteAccountLinkTitle: "Eliminar tu cuenta de GridRewards",
        digitalPaymentTimeoutInstructions: "Si no reclamas tu pago digital a más tardar en 30 días, enviaremos un cheque físico a tu dirección postal:",
        donationsDetailSubtitle: "Estas son las organizaciones sin fines de lucro que reciben los GridRewards. Si eliges donar tus ganancias de GridRewards, el 100% se dividirá entre estas.",
        donationsDetailTitle: "Acerca de nuestras donaciones",
        done: "Hecho",
        duringEvent: "Durante el evento",
        earnings: "Ganancias",
        earningsPotential: "Ganancias posibles",
        edit: "Editar",
        editPhoto: "Editar foto",
        editProfileTitle: "Editar perfil",
        editYourInfo: "Editar tu información",
        electricitySources: "Fuentes de electricidad",
        email: "Correo electrónico",
        emailUs: "Escríbenos por correo electrónico",
        energySavingActions: "Acciones que ahorran energía",
        energyTabTitle: "Energía",
        energyTitle: "Energía",
        enrollmentComplete: {
            carbon: {
                description: "Vigila tu consumo de electricidad y tu huella de carbono. Recibe información de energía personalizada y conoce cuáles son los mejores momentos para ahorrar energía.",
                title: "Reduce tu huella de carbono"
            },
            community: {
                description: "Recibe $10 por cada amigo que refieras. ¡Trabaja en conjunto con tu vecindario para ganar recompensas adicionales y multiplicar el impacto que haces!",
                title: "Involucra a tu comunidad"
            },
            events: {
                description: "Los eventos GridRewards son los momentos más importantes para ahorrar electricidad. Te avisaremos cuando sucedan: generalmente durante las tardes cálidas de verano, aproximadamente de 5 a 10 veces al año.",
                title: "Espera los eventos próximos"
            },
            title: "¡Ya estás inscrito!\n¿Qué sigue?"
        },
        estimated: "estimado",
        eventCountPlural: "{{count}} eventos",
        eventCountSingular: "1 evento",
        eventLikelihood: "Hay una posibilidad de {{likelihood}} de que el evento suceda el {{day}}.",
        eventLikelihoodNone: "Hay baja posibilidad de que suceda un evento esta semana.",
        eventPerformance: "Desempeño durante el evento",
        eventPerformanceDisclaimer: "Para ganar dinero un determinado mes, debes lograr un promedio de ahorro de 25% o más de tu meta de ahorro de electricidad para todos los eventos de ese mes en particular.",
        events: {
            current: {
                timeFormat: "hasta {{endTime}}",
                title: "Evento GridRewards"
            },
            future: {
                timeFormat: "{{startTime}} hasta {{endTime}}",
                title: "Próximo evento GridRewards"
            },
            cta: "Cuenten conmigo",
            seeAll: "Todos los eventos GridRewards"
        },
        eventsTabTitle: "Eventos",
        eventsTitle: "Eventos",
        expertMode: "Modo experto",
        expertModeWarning: "Te lo advertimos...",
        faqs: "Preguntas frecuentes",
        findSomeActions: "Busca algunas acciones que ahorran energía",
        firstName: "Nombre",
        fuelMixDescription: "Para {{time}}, el {{percentRenewable}}% de la electricidad de {{regionName}} provenía de fuentes renovables, y el {{percentCarbon}}% de combustibles que producen carbono.",
        fuelTypeDisplayDualFuel: "Combustible dual",
        fuelTypeDisplayHydro: "Hidroeléctricas",
        fuelTypeDisplayNaturalGas: "Gas natural",
        fuelTypeDisplayNuclear: "Nuclear",
        fuelTypeDisplayOtherFossil: "Otros combustibles fósiles",
        fuelTypeDisplayOtherRenewables: "Otras fuentes renovables",
        fuelTypeDisplayWind: "Eólica",
        getInTouch: "Comunicarse",
        getYourFriendsInvolved: "Involucra a tus amigos",
        gettingStarted: "Cómo comenzar",
        good: "Bueno",
        gramsCO2: "g CO₂",
        graph: {
            label: {
                day: "Día anterior",
                month: "Mes anterior",
                multiYear: "Últimos 3 años",
                week: "Semana pasada",
                year: "Año pasado"
            },
            span: {
                day: "1 D",
                month: "1 M",
                multiYear: "3 A",
                week: "1 S",
                year: "1 A"
            }
        },
        gridRewards: "GridRewards",
        gridRewardsEarnings: "Ganancias GridRewards",
        gridRewardsEventPerformanceDescriptionBad: "En el siguiente evento, intenta llegar al 100% para lograr el máximo beneficio ambiental y ganar la mayor cantidad de dinero.",
        gridRewardsEventPerformanceDescriptionGood: "Buen trabajo. En el siguiente evento, intenta llegar al 100% para lograr el máximo beneficio ambiental y ganar la mayor cantidad de dinero.",
        gridRewardsEventPerformanceDescriptionGreat: "Felicitaciones, ¡hiciste un trabajo excelente! Lograste tu meta para ayudar al planeta y ganar dinero.",
        gridRewardsEventPerformanceDescriptionOkay: "Lo hiciste bien. En el siguiente evento, intenta llegar al 100% para lograr el máximo beneficio ambiental y ganar la mayor cantidad de dinero.",
        gridRewardsEventTitle: "Evento GridRewards",
        gridRewardsNoEventsInList: "Como todos los eventos suceden durante los meses más calientes, tu desempeño se mostrará aquí.",
        gridRewardsSupport: "Soporte de GridRewards",
        haveQuestions: "¿Tienes preguntas? Podemos ayudarte.",
        high: "Alto",
        homeTabTitle: "Inicio",
        imageSaved: "Imagen guardada en las fotos",
        incorrectAddressOrPaymentLate: "¿Dirección incorrecta o pago retrasado?",
        initialFetchDescription: "Todavía estamos recopilando información de tu cuenta de servicios públicos. Esto puede tardar algunos minutos en completarse.",
        insight: {
            highUsage: "Uso alto",
            lastBill: "Última factura",
            overallRank: "Clasificación general",
            perCapitaRank: "Clasificación per cápita",
            projectedBill: "Factura proyectada este mes",
            vsProjected: "Versus proyectada",
            vsSimilar: "Versus hogares similares",
            vsYearAgo: "Versus hace 1 año",
            yearAgo: "Hace un año"
        },
        inviteExplanation: "¿Alguien te invitó?",
        inviteExplanationSubtitle: "Pulsa su enlace de referido o introduce su código",
        invitePlaceholder: "Código de referido",
        kilowatts: "kilovatios",
        lastName: "Apellido",
        learnAboutEvents: "Aprende más sobre los eventos GridRewards ->",
        less: "menos",
        lesson: {
            actions: {
                description: "Aprende lo que puedes hacer para dominar los eventos GridRewards. ->",
                title: "Descubre las acciones que ahorran energía"
            },
            moreTogether: {
                description: "Aprende cómo unirnos multiplica el impacto que hacemos en la red y el planeta ->",
                title: "Juntos podemos hacer más"
            },
            referral: {
                description: "Invita a tus amigos para multiplicar el desempeño de tu vecindario. Cada uno podrá recibir $10 y puedes tratar de obtener la mayor clasificación. ->",
                title: "Invita a tus amigos, y recibe $10"
            }
        },
        licensesTitle: "Licencias",
        likely: "posible",
        locationsTitle: "Lugares",
        loginButtonCreate: "Crear una cuenta nueva",
        loginButtonLoggingIn: "Iniciando sesión",
        loginButtonResetPassword: "Restablecer contraseña",
        loginErrorMissingFields: "Introduce el correo electrónico y la contraseña.",
        loginHeaderSubtitle: "Nota: las credenciales no son las mismas de tu cuenta de servicios públicos.",
        loginResetPassword: "Introduce el correo electrónico que usaste para registrarte y te enviaremos un enlace para reestablecer tu contraseña.",
        low: "Bajo",
        mailingAddress: {
            noData: "No se encontró la dirección",
            line1: "Dirección",
            line2: "Dirección 2",
            city: "Ciudad",
            provinceOrState: "Estado",
            postalOrZip: "Código postal",
            prompt: {
                title: "Confirma tu dirección postal para que podamos enviarte un cheque.",
                buttonConfirm: "Sí, esta es mi dirección postal",
                buttonChange: "No, cambiar la dirección",
                buttonAdd: "Añadir dirección postal"
            },
            confirmation: {
                correctedTitle: "Confirma la dirección",
                entered: "Dirección introducida",
                found: "Dirección encontrada",
                useSelected: "Usar la dirección seleccionada",
                notFound: "Dirección no encontrada",
                edit: "Editar dirección",
                useAnyway: "Usar de todas maneras",
                confirmed: "Dirección confirmada"
            },
            prefs: {
                title: "Dirección postal (obligatorio)",
                buttonUpdate: "Cambiar dirección",
                validationError: "No pudimos validar tu dirección. Intenta enviarla de nuevo.",
                updateSuccessful: "Actualizamos tu dirección de pago",
                updateError: "No pudimos actualizar tu dirección. Intenta de nuevo."
            }
        },
        mapApproximateLocation: "Dirección aproximada",
        mapCalloutHeader: "En horas pico, este lugar puede ahorrar",
        mapDetailHeader: "Juntos, podemos tener el mismo impacto de…",
        mapHeaderSubtitle: "En horas pico, los usuarios de GridRewards de esta zona pueden ahorrar",
        mapInfoBody1: "Una central eléctrica virtual (VPP) combina todos los recursos energéticos de una comunidad en una sola red que puede generar o ahorrar energía para toda la comunidad. Esos recursos pueden generar energía (como paneles solares y generadores eólicos) o ahorrar electricidad (como cuando las personas apagan las luces). En la central eléctrica virtual GridRewards, combinamos el ahorro de energía de los hogares y negocios que se han comprometido con realizar acciones que ahorran energía durante los eventos GridRewards.",
        mapInfoBody2: "Estimamos que durante un evento GridRewards, cada hogar o negocio participante puede ahorrar hasta el 30% de su uso máximo de electricidad. Por ejemplo, si un hogar típico puede usar hasta 1 kW, podemos estimar que puede ahorrar 0.3 kW cuando tome acciones efectivas para ahorrar energía.",
        mapInfoBody3: "Durante las horas pico de demanda de electricidad, las empresas de energía pueden encender centrales antiguas e ineficientes que emiten mayor cantidad de gases de efecto invernadero por kWh de electricidad que durante las horas normales. Al ahorrar electricidad durante los eventos GridRewards, tus acciones evitan que se emita a la atmósfera más CO₂ de lo usual.",
        mapInfoTitle1: "¿Qué es una central eléctrica virtual?",
        mapInfoTitle2: "¿Cómo calculamos el potencial de nuestro impacto colectivo?",
        mapInfoTitle3: "¿Cómo ayuda la central eléctrica virtual (VPP) de GridRewards al medio ambiente?",
        mapLargeCommercial: "Propiedad comercial grande",
        mapLargeResidential: "Propiedad residencial grande",
        mapLegendButtonTitle: "Convenciones",
        mapLegendGroupTitle: "Grupo de lugares",
        mapLegendTitle: "Potencial de ahorro de electricidad durante los eventos GridRewards",
        mapSmallCommercial: "Propiedad comercial pequeña",
        mapSmallResidential: "Propiedad residencial pequeña",
        megawatts: "megavatios",
        minutePlural: "minutos",
        minuteSingular: "minuto",
        moderate: "Moderado",
        more: "más",
        mothersOutFrontDescription: "Su fundamento es la convicción de que no hay fuerza más poderosa para el cambio que movilizar a las mujeres para proteger a sus hijos. Sabemos que no podemos fracasar cuando nos unimos para luchar por la justicia climática, racial y social contando nuestras historias y compartiendo los sueños que tenemos para nuestros hijos.",
        mothersOutFrontLinkName: "mothersoutfront.org",
        mothersOutFrontTitle: "Mothers Out Front",
        naturalAreasConservancyDescription: "El Natural Areas Conservancy es el campeón de los 20,000 acres de bosques y humedales de la ciudad de Nueva York, para beneficio y disfrute de todos. Su equipo de científicos y expertos promueve la diversidad y resiliencia en los cinco distritos, trabajando en alianza estrecha con el gobierno de la ciudad.",
        naturalAreasConservancyLinkName: "naturalareasnyc.org",
        naturalAreasConservancyTitle: "Natural Areas Conservancy",
        neighborhood: {
            cta: "Mira tu comunidad",
            description: {
                plural: "Tú eres parte del vecindario {{name}}, junto con {{count}} otras personas.",
                singular: "Tú eres parte del vecindario {{name}}, junto con otra persona."
            },
            message: {
                captainTitle: "¡Prepara a tus vecinos!",
                captainDescription: "Como eres el capitán del vecindario, puedes enviar un mensaje sobre el evento que todos podrán ver.",
                captainPlaceholder: "Ejemplo: Hola {{neighborhoodName}}, es la hora de {{eventTime}}. Si logramos ser uno de los mejores vecindarios, ganaremos dinero adicional, por esto, ¡apaguemos todo y vamos a caminar!",
                captainButtonTitle: "Actualizar mensaje",
                attribution: "El capitán de tu vecindario",
                updateSuccess: "Se actualizó el mensaje de este evento para el vecindario",
                updateFailed: "No pudimos actualizar tu mensaje para el vecindario. Intenta de nuevo",
                videoUpload: {
                    retry: "Reintentar carga",
                    recordNew: "Grabar un video nuevo",
                    clear: "Borrar video",
                    error: "Vaya, ocurrió un problema al cargar este video.",
                    remove: "Quitar video"
                }
            }
        },
        networkUnavailableBody: "No es posible conectarse a la red. Conéctate mediante celular o Wi-Fi e intenta de nuevo.",
        networkUnavailableTitle: "No hay red disponible",
        newYorkAndDC: "Ciudad de Nueva York y DC",
        newYorkAndNational: "Nueva York y nacional",
        newYorkCity: "Ciudad de Nueva York",
        nextGoodTime: "El siguiente buen momento para usar electricidad es",
        nextOkayCO2Level: "Siguiente nivel bueno de CO₂",
        noneInNext24Hours: "Ninguno en las próximas 24 horas",
        notification: {
            currentlyViewingTitle: "Actualmente estás viendo notificaciones de",
            authCompleted: "Felicitaciones, ¡ya hemos configurado todo! Esto es lo que debes saber para comenzar con los eventos GridRewards",
            eventEnd: "¡Felicitaciones! Ya terminó tu evento GridRewards. ¡Vuelve en unos días para que veas cómo te fue!",
            eventPerformanceAvailable: "Averigua cómo te fue en el evento {{timeString}}.",
            eventScheduled: "¡Atención! El evento GridRewards se llevará a cabo de las {{timeRangeString}}",
            eventStart: "¡Vamos! Tu evento GridRewards comienza a las {{atTimeString}}",
            fullDataAvailable: "¡Ya puedes ver tus datos de energía!",
            initialDataAvailable: "Ya puedes ver tus datos de uso de energía reciente. Puede tardar hasta 15 minutos obtener todo tu histórico de uso de energía.",
            learnMore: "Conoce más sobre {{subject}}",
            link: "Más información: {{- linkTitle}}",
            newNeighborhoodMessage: "Nuevo mensaje del capitán de tu vecindario",
            paymentAvailable: "¡Tienes disponible un pago de {{amount}}!",
            promptSurvey: "Mejora el análisis de tu uso de energía respondiendo una encuesta de la propiedad",
            promptThermostat: "¿Tienes un termostato inteligente Ecobee? Conéctalo a GridRewards y contrólalo desde la aplicación",
            referralPickedUp: "{{userDisplayName}} aceptó ser tu referido",
            referralSummary: "Esta temporada, ¡{{number}} {{referralsSingularOrPlural}} lograron hacerlo lo suficientemente bien como para ganar una bonificación!",
            reminderAuth: "¡Conecta tu cuenta de servicios públicos para que puedas empezar a ganar dinero hoy mismo!",
            reminderReferral: "Refiere un amigo, ¡cada uno recibirá $10!",
            seasonEarnings: "Ya está disponible el estimado de tus ganancias de la temporada",
            seasonStart: "La temporada GridRewards comienza este año el {{date}}. Debes estar pendiente de los eventos GridRewards, ¡son tu oportunidad para ganar dinero por ahorrar electricidad!",
            title: "Notificaciones",
            unusualUsage: "Observamos algo de uso inusual. MIRA LOS DETALLES AQUÍ."
        },
        now: "Ahora",
        off: "Apagado",
        ok: "Bien",
        okay: "Aceptable",
        onboarding: {
            carbon: {
                description: "Aprende cuáles son los mejores momentos para usar electricidad.",
                title: "¡No toda la electricidad se genera de la misma manera! La huella de carbono de tu electricidad cambia."
            },
            energy: {
                description: "",
                title: "Los datos de tu consumo de electricidad se mostrarán aquí, para que puedas hacer seguimiento a tu uso de energía."
            },
            events: {
                description: "¡Únete a tu comunidad! Puedes ganar dinero adicional y multiplicar tu impacto colectivo en el medio ambiente.",
                title: "Los eventos son bloques de 3 a 4 horas que suceden durante algunas tardes cálidas de verano."
            },
            home: {
                description: "Reduce tu uso de energía por solo unas horas durante algunos días importantes, ¡y recibirás un pago al final del año! El hogar promedio puede ganar más de $100 por año.",
                title: "Ahorra electricidad durante los eventos GridRewards para ganar dinero y ayudar al medio ambiente."
            }
        },
        onboardingLocationError: "Aún no admitimos ese código postal.",
        onboardingLocationPlaceholder: "Introduce tu código postal",
        onboardingWelcomeSubtitle: "GridRewards trabaja con tu empresa de servicios públicos para ayudarte a ganar dinero por ahorrar electricidad.",
        participants: {
            othersPlural: "Hasta ahora {{count}} vecinos están participando",
            othersSingular: "Hasta ahora 1 vecino está participando",
            youAndOthersPlural: "¡Tú y {{count}} vecinos están participando!",
            youAndOthersSingular: "¡Tú y 1 vecino están participando!",
            youOnly: "¡Estás participando!"
        },
        password: "Contraseña",
        paymentPrefDonateSeePartners: "Mira dónde estamos donando esta temporada",
        paymentPrefDonateSubtitle: "Donaremos el 100% de tus ganancias a las beneficencias que hagan un trabajo excelente.",
        paymentPrefDonateTitle: "Haz una donación",
        paymentPrefFinePrint: "Los cambios se aplicarán a los pagos futuros que todavía no hayan sido iniciados o programados.",
        paymentPrefInstantFee: "Tarifa de $0.25 por pago",
        paymentPrefInstantSubtitle: "Te enviaremos un enlace para el pago. Puedes cobrar tu pago usando tu cuenta de PayPal.",
        paymentPrefInstantTitle: "Pago inmediato",
        paymentPrefMailSubtitle: "Los pagos serán enviados al final del año calendario.",
        paymentPrefMailTitle: "Recibir un cheque por correo",
        paymentPrefShortDonate: "Donar",
        paymentPrefShortInstant: "Inmediato",
        paymentPrefShortMail: "Cheque",
        paymentPrefUpdated: "Tu medio de pago preferido ahora está configurado en {{pref}}",
        paymentPreference: "Medio de pago preferido",
        paymentPrefsSubtitle: "Elige cómo te gustaría recibir los pagos de GridRewards. Estos se calculan y envían al final del año.",
        paymentsAtEndOfYear: "Tu pago será enviado al final del año calendario mediante {{paymentMethod}}.",
        paymentGeneralTimeframe: "Los pagos se calculan y envían al final del año.",
        payments: {
            title: "Tus ganancias en curso",
            rowTitles: {
                performance: "Desempeño durante el evento",
                bonus: "Ganancias de bonificación",
                bonusDescription: "Para lograr esta cantidad de bonificación, ¡asegúrate de que los amigos que referiste también hagan un buen trabajo durante cada evento de esta temporada!",
                estimated: "Ganancias estimadas",
                previous: "Pagos anteriores"
            }
        },
        pendingAnalysis: "Pendiente de análisis",
        percentLess: "{{value}}% menos",
        percentMore: "{{value}}% más",
        percentOfGoal: "% de tu meta",
        percentOfGoalShort: "{{percent}}% de la meta",
        percentRenewable: "{{percent}}% renovable",
        performance: "Desempeño",
        phoneNumber: "Número de teléfono",
        phoneNumberGenericError: "Introduce un número de teléfono válido.",
        phoneNumberPlaceholder: "Número de teléfono",
        phoneNumberPrompt: "Añadir para recordatorios por texto",
        photoTake: "Tomar una foto",
        photoLibrary: "Elegir de la biblioteca",
        photoDelete: "Eliminar foto",
        photoPermissionMissingTitle: "Fotos desactivadas",
        photoPermissionMissing: "Ve a la Configuración para otorgar permisos de acceso a la cámara y biblioteca a GridRewards",
        powerGrid: "red eléctrica",
        powerInYourHands: "Finalmente la energía está en tus manos.",
        profileFirstNameMissing: "Introduce tu nombre.",
        profileLastNameMissing: "Introduce tu apellido.",
        profilePhoneNumberDescription: "Recibe notificaciones por mensaje de texto cuando sucedan los eventos GridRewards. Por lo general hay de 5 a 10 eventos por año.\n\nPueden aplicarse tarifas por datos y mensajes. La frecuencia de los mensajes varía. Responde AYUDA para recibir asistencia y CANCELAR para cancelar la suscripción.",
        profileRateReview: "Calificar y reseñar",
        profileSignOut: "Cerrar sesión",
        profileUpdateGenericError: "¡Vaya! No se pudo guardar la actualización de tu perfil.",
        recent: "reciente",
        referral: {
            codeCopied: "¡Código de referido copiado!",
            copyYourCode: "Copiar tu código",
            description: "Pide a tu amigo que utilice tu código de referido cuando se inscriba: ¡los dos recibirán una bonificación de $10 cuando ahorren electricidad durante la temporada GridRewards!\n\nPuedes referir a todos los amigos que quieras.",
            downloadHere: "Descarga GridRewards aquí:",
            emailSubject: "¡Gana dinero por ahorrar electricidad!",
            literalPlural: "referidos",
            literalSingular: "referido",
            prompt: "Refiere un amigo",
            screenTitle: "Referidos",
            shareMessage: "Desenchufa. Recibe pagos. Gana dinero por ahorrar electricidad en momentos clave con GridRewards.\n\nUsa el código {{code}} al crear tu cuenta (o utiliza este enlace de referido para llenar automáticamente el código) y recibirás una bonificación de $10.",
            shareYourCode: "Compartir tu código de referido",
            title: "Da $10, recibe $10",
            errorCreatingLink: "No se pudo crear el enlace para compartir. Intenta de nuevo."
        },
        regionElectricitySourcesTitle: "Fuentes de electricidad de {{regionName}}",
        resetPasswordCompletePlaceholder: "tu correo electrónico",
        resetPasswordCompleteSubtitleAfter: "para recibir un enlace para restablecer tu contraseña.",
        resetPasswordCompleteSubtitleBefore: "Revisa",
        resetPasswordCompleteTitle: "¡Se envió el enlace para restablecer la contraseña!",
        resetPasswordTitle: "Restablece tu contraseña",
        reviewPromptRate: "¡Es excelente!",
        reviewPromptRemind: "Recordarme luego",
        reviewPromptTitle: "Hasta ahora, ¿cómo te ha parecido GridRewards?",
        saveImage: "Guardar imagen",
        savedDuringEvents: "ahorrado durante eventos GridRewards",
        search: "Buscar",
        seePaymentPreferences: "Ver preferencias de pago",
        seeTerms: "Ver términos",
        seeYourAchievements: "Ver tus logros",
        seeYourCarbonFootprint: "Ver tu huella de carbono",
        seeYourEnergyUse: "Ver tu uso de energía",
        send: "Enviar",
        sendFeedback: "Enviar retroalimentación",
        serviceAddressMissing: "Necesitaremos una dirección postal para enviarte un cheque por correo.",
        setNickname: "Configurar sobrenombre",
        setNicknameDescription: "Añade un sobrenombre a este lugar para que sea más fácil de recordar.",
        settings: "Configuración",
        setYourPaymentPref: "Configura tu preferencia de pago",
        share: "Compartir",
        shareAndTag: "¡Comparte y etiqueta @getgridrewards para destacarte!",
        signIn: "Iniciar sesión",
        signInCreatePrompt: "¿Nuevo aquí? Crea una cuenta",
        signInDescription: "Inicia sesión en tu cuenta GridRewards: las credenciales no son las mismas de tu cuenta de la empresa de servicios públicos.",
        skip: "Omitir",
        special: "Especial",
        sustainableWestchesterDescription: "Sustainable Westchester es un consorcio sin fines de lucro de los gobiernos locales del condado de Westchester que facilita la colaboración efectiva en iniciativas de sostenibilidad. Su meta es proporcionar soluciones socialmente responsables, ambientalmente acertadas y económicamente viables con el objetivo de crear comunidades saludables, resilientes y sostenibles.",
        sustainableWestchesterLinkName: "sustainablewestchester.org",
        sustainableWestchesterTitle: "Sustainable Westchester",
        tbd: "Por definir",
        thatsTheSameAs: "Equivale a…",
        theme: {
            dark: "Oscuro",
            light: "Claro",
            system: "Sistema",
            title: "Tema"
        },
        thermostat: {
            mode: {
                off: "Apagado",
                cool: "Enfriar",
                heat: "Calentar",
                both: "Ambos"
            }
        },
        thermostatConnectPrompt: "¿Tienes un termostato inteligente Ecobee? Puedes controlarlo desde la aplicación GridRewards.",
        thermostatConnectButtonTitle: "Conecta tu Ecobee",
        thisBillingCycle: "este ciclo de facturación",
        thisSeason: "Esta temporada",
        timeRangeFormatGeneral: "desde {{startTime}} hasta {{endTime}}",
        timeAt: "a las {{time}}",
        timeZoneInfo: "Todas las horas para esta propiedad están en {{name}}",
        tip: "Consejo",
        today: "Hoy",
        tomorrow: "mañana",
        university: {
            courseEvents: {
                mostMoney: {
                    body: "Reduce tu uso de electricidad al menos una tercera parte durante las horas de los eventos GridRewards. Ganarás la máxima cantidad de dinero si lo haces en todos los eventos durante un verano en particular.",
                    title: "¿Cómo gano la mayor cantidad de dinero?"
                },
                participate: {
                    body: "Simplemente reduce el uso de electricidad todo lo que puedas durante las horas de los eventos. Tu ahorro se mide automáticamente mediante tu contador de electricidad.\n\nEnfría previamente tu lugar comenzado 4 horas antes del evento, luego usa nuestras acciones para ahorrar energía como una guía útil para cambiar tu consumo de energía por fuera de las horas del evento.",
                    title: "¿Cómo participo en los eventos?",
                    tipSave: "Intenta ahorrar un tercio de tu uso de electricidad típico durante cada evento GridRewards para ganar la mayor cantidad de dinero."
                },
                performance: {
                    body: "Tu ahorro de electricidad será monitoreado automáticamente y comparado con tu uso típico durante ese tiempo. Intenta ahorrar un tercio de tu uso de electricidad típico durante cada evento GridRewards para ganar la mayor cantidad de dinero.",
                    title: "¿Cómo se mide mi desempeño?"
                },
                title: "Eventos GridRewards",
                whatIsEvent: {
                    body: "Los eventos GridRewards son bloques de 3 a 4 horas durante los cuales puedes ganar dinero ahorrando electricidad. Usualmente tienen lugar en algunas tardes cálidas de verano, aproximadamente 5 a 10 veces por año. Te notificaremos por adelantado para que te puedas preparar.",
                    title: "¿Qué es un evento GridRewards?"
                },
                whyImportant: {
                    body: "Los eventos ocurren cuando la red eléctrica está sobrecargada y para manejar la demanda adicional las empresas de servicios públicos deben encender las \'centrales pico\', que usan petróleo y gas, y que son más contaminantes. Los eventos reducen la carga de la red, ayudan a prevenir cortes y reducciones de tensión, y evitan que se enciendan estas centrales muy contaminantes. \n\nLos eventos GridRewards son tan importantes que su empresa de servicios públicos está dispuesta a pagarle dinero real para reducir el consumo durante esos momentos.",
                    title: "¿Por qué son importantes los eventos GridRewards?"
                }
            },
            courseMultiplyImpact: {
                neighborhoodPride: {
                    body: "Todas las personas de tu vecindario contribuyen a la cantidad de carga que se exige a la red eléctrica. Únete a otros usuarios GridRewards de tu vecindario para obtener recompensas adicionales al trabajar juntos, compite contra otros vecindarios y mira cuánto más impacto podemos hacer al trabajar juntos.",
                    title: "Orgullo del vecindario"
                },
                referral: {
                    body: "Si logras que alguien se inscriba en GridRewards y ahorra suficiente electricidad para ganar dinero durante la siguiente temporada GridRewards, cada uno ganará una bonificación de $10 y multiplicarás tu impacto positivo. Puedes invitar a todas las personas que desees.",
                    title: "Refiere un amigo"
                },
                shiftThePower: {
                    body: "Por primera vez, tú y tu comunidad podrán controlar su uso de energía y huella de carbono. Al cambiar el uso para no consumir durante algunos momentos clave, ayudas a que la energía que todos usamos sea más limpia (y ganas dinero por hacerlo).",
                    title: "Tu eres parte del movimiento."
                },
                title: "Multiplica tu impacto"
            },
            courseOther: {
                carbonFootprint: {
                    body: "No toda la electricidad se genera de la misma manera. Consulta la pestaña \'Carbono\' para comprender en qué momento el uso de electricidad tiene la menor huella de carbono.",
                    title: "Reduce tu huella de carbono"
                },
                checkYourself: {
                    body: "Vigila tu uso de electricidad y las facturas mensuales proyectadas en la pestaña \'Energía\'.",
                    title: "Mira tú mismo"
                },
                title: "¿Qué más puedo hacer con la aplicación?"
            },
            coursePowerGrid: {
                peakerPlants: {
                    body: "Cuando la red eléctrica está sobrecargada, las empresas de energía tienen que encender otras centrales de energía que funcionan con combustibles fósiles para suplir rápidamente la demanda. Mantener estas centrales de energía pico disponibles no solo es costoso para las empresas de energía, sino que ese costo te lo cobran. \n\nEstas centrales también son más antiguas, menos eficientes, dependen de combustibles contaminantes y emiten el doble de carbono.",
                    title: "¿Qué es una central de energía pico?"
                },
                timeWindow: {
                    body: "Al estudiar el uso histórico e incluir otros datos como información del clima, las empresas predicen cuándo tendrán que usar electricidad pico, y cuánta será necesaria. Estos picos son bastante grandes, aproximadamente el 200% del uso normal de electricidad. Si pueden reducir este pico un poco, se disminuyen los costos todo el año.",
                    title: "¿Por qué es importante el momento en que reduzco el consumo de electricidad?"
                },
                title: "Energía y la red eléctrica",
                unplugging: {
                    body: "Aunque parece que desconectar el teléfono o cambiar el momento para secar la ropa sea algo sin importancia, estos pequeños cambios suman. Reducir tu uso durante los eventos GridRewards tiene un impacto mayor de lo normal en la huella de carbono de toda la red. Aplanar la demanda pico significa que las centrales de electricidad pico se mantienen apagadas, lo que conserva nuestro aire y te ahorra dinero.",
                    title: "¿Desconectar realmente ayuda?"
                },
                whatIsTheGrid: {
                    body: "\'La red\' es una combinación de centrales eléctricas que te suministran electricidad. Cada instalación consume electricidad de diferentes conjuntos de fuentes de energía, unas limpias, y otras… lo son menos.",
                    title: "¿Qué es \'la red\'?"
                }
            },
            courseTitle: "Lección {{index}}/{{total}}"
        },
        universityTitle: "Universidad GridRewards",
        unknown: "Desconocido",
        unlikely: "improbable",
        usageThisWeek: "Uso esta semana",
        valuePropEventsBody: "Estos son los momentos más valiosos para ahorrar electricidad. Frecuentemente suceden durante algunas tardes cálidas de verano.\n\nAunque es bueno ahorrar electricidad todo el año, los eventos GridRewards son tan importantes que tu empresa de servicios públicos está dispuesta a pagarte dinero real para que reduzcas el consumo en horas pico.\n\n¿Por qué? Cuando la empresa de servicios públicos necesita satisfacer la demanda pico de electricidad de tu región, enciende centrales eléctricas más antiguas y menos eficientes que cuesta más dinero mantener y liberan más carbono al aire por unidad de electricidad.",
        veryBad: "Muy malo",
        veryHigh: "Muy alto",
        veryLikely: "probable",
        virtualPowerPlant: "Central eléctrica virtual",
        weActDescription: "La misión de WE ACT es construir comunidades saludables garantizando que las personas de color y los residentes de bajos ingresos participen significativamente en la creación de políticas y prácticas de salud y protección ambiental acertadas y justas.",
        weActLinkName: "weact.org",
        weActTitle: "WE ACT",
        welcome: "Bienvenido",
        welcomeBack: "Bienvenido de nuevo",
        welcomeToGridRewards: "Bienvenido a GridRewards",
        westchesterCounty: "Condado de Westchester",
        yesterday: "ayer",
        yesterdayVsProjection: "Ayer versus la proyección",
        yourAddress: "Tu dirección",
        yourCarbonFootprint: "Tu huella de carbono",
        yourEarningsShort: "Tus ganancias de {{timePeriod}}",
        yourNeighborhood: "Tu vecindario",
        yourPerformance: "Tu desempeño",
        yourProjectedBill: "Tu factura proyectada",
        zipCode: "Código postal",
        zipCodeExplanation: "Vamos a confirmar que estés en una zona participante",
        zipCodeInvalid: "Introduce un código postal válido."
    }
};