import { useNavigation } from "@react-navigation/native"
import React, { useContext } from "react"
import { Image, Text, TouchableOpacity, View } from "react-native"
import { activeCardOpacity } from "../../shared/Layout"
import { TextStyles } from "../../shared/TextStyles"
import { rawThemes, ThemeContext } from "../../shared/ThemeContext"
import CardView from "./CardView"

export default function SplashCard(props) {
    const navigation = useNavigation()
    const { theme } = useContext(ThemeContext)

    const { title = "", buttonText, gradientSource = require("../../assets/images/gradient05.jpg"), containerStyle, onPress, onClosePress } = props

    return (
        <CardView
            includesPadding={false}
            backgroundImage={gradientSource}
            // We do want the border logic (rounding, external margins, etc.), but no visible border in this case.
            includesBorder={true}
            containerStyle={[{ borderWidth: 0 }, containerStyle]}
            contentContainerStyle={[{ paddingHorizontal: 24, paddingTop: 24 }]}
            {...props}
        >
            <TouchableOpacity activeOpacity={activeCardOpacity} onPress={onPress}>
                <TouchableOpacity onPress={onClosePress}>
                    <Image source={rawThemes.dark.icons.close} style={{ width: 24, height: 24, opacity: 0.7 }} />
                </TouchableOpacity>
                <View style={{ flex: 1, marginVertical: 104 }}>
                    <Text style={[TextStyles.headerExtraLargeKobe, { color: theme.textLight, marginBottom: 24 }]}>{title}</Text>
                    {buttonText != null && <Text style={[TextStyles.buttonText, { color: theme.textLight, marginBottom: 24 }]}>{buttonText}</Text>}
                </View>
            </TouchableOpacity>
        </CardView>
    )
}
