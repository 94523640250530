import { useHeaderHeight } from "@react-navigation/elements"
import { useNavigation, useScrollToTop } from "@react-navigation/native"
import * as Application from "expo-application"
import Constants from "expo-constants"
import * as Linking from "expo-linking"
import { path } from "ramda"
import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { Platform, Text, View, Image, Dimensions } from "react-native"
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useDispatch, useSelector } from "react-redux"
import Button from "../components/Button"
import Divider from "../components/Divider"
import SegmentedControl from "../components/SegmentedControl"
import TappableRow from "../components/TappableRow"
import { performSignOut } from "../model/primaryDataActions"
import AppConstants, { PaymentOption, PaymentOptions, ScreenNames, ThemeOptions } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
const axios = require("axios").default

export default function ProfileScreen() {
    // Allow scrolling to top when tapping our tab bar icon
    const scrollRef = React.useRef(null)
    useScrollToTop(scrollRef)
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const headerHeight = useHeaderHeight()

    const { theme, userThemeName, setUserThemePreference } = useContext(ThemeContext)

    const insets = useSafeAreaInsets()

    const mainData = useSelector((state) => state.primaryData.mainData)
    const enrollmentComplete = useSelector((state) => state.primaryData.enrollmentComplete)

    let [userEmail, setUserEmail] = useState("")
    const profileData = useSelector((state) => state.primaryData.profileData)
    const { first_name = "", last_name = "", zip_code = "", email, phone_number, payment_preference = "", avatar } = profileData || {}
    const displayName = first_name.length > 0 && last_name.length > 0 ? first_name + " " + last_name : i18n.t("defaultDisplayName")

    // console.log("Profile data is " + JSON.stringify(profileData))

    const sendFeedback = () => {
        Linking.openURL("mailto:gridrewards@logicalbuildings.com?subject=GridRewards%20app%20feedback")
    }

    const rateAndReview = () => {
        if (Platform.OS == "ios") {
            Linking.openURL("https://apps.apple.com/us/app/gridrewards/id1523704980?action=write-review")
        } else if (Platform.OS == "android") {
            Linking.openURL("http://play.google.com/store/apps/details?id=com.logicalbuildings.gridrewards.store")
        }
    }

    // FIXME: Extract the first part of the hostname. This shouldn't stay in the app once we're confident about prod/staging split.
    let hostnameStart = AppConstants.apiBase.split("//")[1]
    if (hostnameStart.indexOf(".") > -1) {
        hostnameStart = hostnameStart.slice(0, hostnameStart.indexOf("."))
    }

    useEffect(() => {
        // FIXME: For some reason, we can't use useContext in the LoginStack containing this component, so we can't set the
        // screen options that require theme context out there. All other stacks work fine... ???
        // For now, set them inside this component.
        navigation.setOptions({
            headerRight: (props) => {
                return enrollmentComplete ? (
                    <Button
                        containerStyle={{ flex: 1, marginRight: 16, maxHeight: 34, paddingVertical: 0 }}
                        type="fill"
                        contentTintColor={rawThemes.light.textPrimary}
                        buttonTintColor={theme.teal}
                        title={i18n.t("referral.prompt")}
                        onPress={() => {
                            navigation.navigate(ScreenNames.ReferralDetail.routeName)
                        }}
                        {...props}
                    />
                ) : null
            },
        })
    }, [])

    let screenHeight = Dimensions.get("window").height

    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, {maxHeight: screenHeight, backgroundColor: theme.background, paddingTop: 24 }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
            ref={scrollRef}
        >
            <View style={{ flexShrink: 1, alignItems: "center", alignSelf: "center", marginHorizontal: 20, marginBottom: 24 }}>
                <View
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 1000,
                        width: 80,
                        height: 80,
                        backgroundColor: theme.surface1,
                        marginBottom: 24,
                        overflow: "hidden",
                    }}
                >
                    <Image
                        source={avatar ? { uri: avatar } : theme.icons.user32}
                        style={{ width: 80, height: 80, opacity: avatar ? 1.0 : 0.4 }}
                        resizeMode={avatar ? "cover" : "center"}
                    />
                </View>
                <Text style={[TextStyles.h1, { color: theme.textPrimary, marginBottom: 8, textAlign: "center" }]}>{displayName}</Text>
                <Text style={[TextStyles.body2Medium, { color: theme.textHint, textAlign: "center" }]}>{email}</Text>
            </View>

            <View style={{ flex: 1, marginHorizontal: 20 }}>
                {/* <TappableRow title={i18n.t("zipCode")} description={zip_code} onPress={() => navigation.navigate(ScreenNames.EditProfile.routeName)} /> */}
                <TappableRow
                    title={i18n.t("phoneNumber")}
                    description={phone_number ? i18n.t("phoneNumberPrompt") : "—"}
                    onPress={() => navigation.navigate(ScreenNames.EditProfile.routeName)}
                />

                <Button
                    type="outline"
                    title={i18n.t("editYourInfo")}
                    buttonTintColor={theme.divider}
                    onPress={() => navigation.navigate(ScreenNames.EditProfile.routeName)}
                />
                <Divider style={{ marginVertical: 24 }} />
                <TappableRow
                    title={i18n.t("paymentPreference")}
                    description={(PaymentOptions.find((item) => item.type == payment_preference) ?? PaymentOption.physical).displayName}
                    onPress={() => navigation.navigate(ScreenNames.PaymentPrefsDetail.routeName)}
                />
                <View style={{ paddingHorizontal: 8, paddingVertical: 8, marginBottom: 16 }}>
                    <Text style={[TextStyles.body2Medium, { flexShrink: 1, color: theme.textPrimary, marginBottom: 16 }]}>{i18n.t("theme.title")}</Text>

                    <SegmentedControl
                        onPress={(selectedIndex) => {
                            let newTheme = ThemeOptions[selectedIndex].type
                            setUserThemePreference(newTheme)
                        }}
                        selectedIndex={userThemeName == null ? 2 : ThemeOptions.findIndex((item) => item.type == userThemeName)}
                        labels={ThemeOptions.map((item) => item.displayName)}
                        containerStyle={{}}
                    />
                </View>
                <TappableRow title={i18n.t("gridRewardsSupport")} onPress={sendFeedback} />
                <TappableRow title={i18n.t("profileRateReview")} onPress={rateAndReview} />
                <TappableRow title={i18n.t("acknowledgements")} onPress={() => navigation.navigate(ScreenNames.Licenses.routeName)} />
                <Button
                    type="fill"
                    title={i18n.t("profileSignOut")}
                    buttonTintColor={theme.surface1}
                    onPress={() => {
                        dispatch(performSignOut())
                        navigation.getParent()?.goBack()
                    }}
                />
                <TouchableOpacity
                    style={{ marginTop: 32, paddingVertical: 8, width: "100%" }}
                    onPress={() => navigation.navigate(ScreenNames.DeleteAccount.routeName)}
                >
                    <Text style={[TextStyles.body3Medium, { textAlign: "center", color: theme.textHint }]}>{i18n.t("deleteAccountLinkTitle")}</Text>
                </TouchableOpacity>
                <Text
                    style={[
                        TextStyles.body4,
                        { alignSelf: "center", textAlign: "center", marginTop: 100, marginBottom: 40, opacity: 0.2, marginHorizontal: 30 },
                    ]}
                >
                    {"Version " +
                        Application.nativeApplicationVersion +
                        " | build " +
                        Application.nativeBuildVersion +
                        " | rc: " +
                        (path(["manifest", "releaseChannel"], Constants) ?? "no OTA update yet") +
                        " | " +
                        hostnameStart}
                </Text>
            </View>
        </ScrollView>
    )
}
