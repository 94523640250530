import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { AppState, Text, View, FlatList, ScrollView, TouchableOpacity } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import NotificationRow from "../components/NotificationRow"
import { fetchNotifications } from "../model/primaryDataActions"
import { CommonStyles } from "../shared/CommonStyles"
import { ThemeContext } from "../shared/ThemeContext"
import AsyncStorage from "@react-native-async-storage/async-storage"
import AppConstants, { ScreenNames } from "../shared/AppConstants"
import { prop, path } from "ramda"
import { TextStyles } from "../shared/TextStyles"
import i18n from "../shared/i18n"
import { titleCase } from "../shared/Utils"
import { activeOpacity } from "../shared/Layout"

export default function NotificationsScreen(props) {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const { theme } = React.useContext(ThemeContext)
    const scrollRef = useRef()
    const appState = useRef(AppState.currentState)
    const notificationsData = useSelector((state) => state.primaryData.notificationsData)
    const [visibleNotifications, setVisibleNotifications] = useState([])
    const [currentUtilityAccount, setCurrentUtilityAccount] = useState(null)
    const accountsList = useSelector((state) => state.primaryData.accountsData) || []
    const utilityAccountNicknames = useSelector((state) => state.primaryData.utilityAccountNicknames) || {}

    // TODO: Improve this so that we're making better use of infinite scrolling.
    // https://app.shortcut.com/logical-buildings/story/1796/consider-improving-notifications-page-to-make-real-use-of-infinite-scrolling

    const pageSize = 500

    AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((currentAccount) => {
        setCurrentUtilityAccount(currentAccount)
    })

    // Initial setup
    useEffect(() => {
        let appStateListener = AppState.addEventListener("change", _handleAppStateChange)

        dispatch(fetchNotifications(1, pageSize))

        return () => {
            if (appStateListener != null) {
                appStateListener.remove()
            }
        }
    }, [])

    useEffect(() => {
        // Whenever we load new notifications data here, mark the newest notification as our starting point for highlighting the icon.
        if (notificationsData != null && notificationsData.length > 0) {
            AsyncStorage.setItem(AppConstants.notificationLastDateSeen, prop("created_at", notificationsData[0]))
            const filteredNotifications = notificationsData.filter((notification) => {
                return (
                    prop("electric_utility_account", notification) == null ||
                    path(["electric_utility_account", "public_id"], notification) == currentUtilityAccount
                )
            })
            setVisibleNotifications(filteredNotifications || [])
        }
    }, [notificationsData])

    const locationChooser = () => {
        let matchingAccount = accountsList.find((possibleAccount) => prop("accountId", possibleAccount) == currentUtilityAccount)
        if (accountsList.length <= 1 || matchingAccount == null) {
            return <View style={{ height: 16 }} />
        }

        let displayName = utilityAccountNicknames[currentUtilityAccount]
        if (displayName == null || displayName.length == 0) {
            displayName = titleCase(prop("serviceAddress", matchingAccount) || prop("utilityAccountId", matchingAccount))
        }
        return (
            <TouchableOpacity
                style={{ marginTop: 8, marginBottom: 24, paddingHorizontal: 8, paddingVertical: 16, borderRadius: 8, backgroundColor: theme.surface1 }}
                activeOpacity={activeOpacity}
                onPress={() => {
                    navigation.goBack()
                    navigation.navigate(ScreenNames.Locations.routeName)
                }}
            >
                <Text style={[TextStyles.capsExtraSmall, { color: theme.textHint, textAlign: "center", marginBottom: 6 }]}>
                    {i18n.t("notification.currentlyViewingTitle")}
                </Text>
                <Text style={[TextStyles.body3Medium, { color: theme.textSecondary, textAlign: "center" }]}>{displayName}</Text>
            </TouchableOpacity>
        )
    }

    const _handleAppStateChange = (nextAppState) => {
        if (appState.current.match(/inactive|background/) && nextAppState === "active") {
            console.log("Notifications screen has come to the foreground, refreshing data!")
            // Get data when we re-appear
            dispatch(fetchNotifications(1, pageSize))

            // Scroll to top
            scrollRef?.current?.scrollToOffset({ offset: 0, animated: true })
        }

        appState.current = nextAppState
    }

    return (
        <FlatList
            ref={scrollRef}
            data={visibleNotifications}
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background, paddingTop: 16 }]}
            contentContainerStyle={[CommonStyles.mainScreenContent, { paddingHorizontal: 20 }]}
            ListHeaderComponent={locationChooser}
            renderItem={({ item }) => {
                return <NotificationRow notificationData={item} currentUtilityAccount={currentUtilityAccount} />
            }}
            keyExtractor={(item, index) => prop("id", item) || index.toString()}
        />
    )
}
