import { ActionSheetProvider } from "@expo/react-native-action-sheet"
import * as SplashScreen from "expo-splash-screen"
import React, { useEffect } from "react"
import { Platform, View } from "react-native"
import { AppearanceProvider } from "react-native-appearance"
import { GestureHandlerRootView } from "react-native-gesture-handler"
// Required for the root-level toast
import { RootSiblingParent } from "react-native-root-siblings"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { enableScreens } from "react-native-screens"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import * as Sentry from "sentry-expo"
import MainApp from "./components/MainApp"
import useCachedResources from "./hooks/useCachedResources"
import configureStore from "./model/configureStore"
import AppConstants from "./shared/AppConstants"
import { ThemeContextProvider } from "./shared/ThemeContext"
// ReactotronConfig.js:
import { Tron } from "./tron"
import { Audio } from "expo-av"

const { store, persistor } = configureStore()

if (Platform.OS !== "web") {
    LogBox = require("react-native").LogBox
    Tron
        // Your real ip address 👇
        .configure({ host: "192.168.1.7" })
        .useReactNative()
        .connect()
} else {
    // initialize Firebase via a different mechanism on the web.
    const firebase = require("firebase/compat/app").default
    firebase.initializeApp(AppConstants.firebaseConfig)
}

SplashScreen.preventAutoHideAsync()

// Use native screen rendering whenever possible.
enableScreens()

if (Platform.OS !== "web") {
    LogBox.ignoreLogs([
        "Firebase Analytics is not available in the Expo client",
        "YellowBox has been replaced with LogBox",
        "No native splash screen",
        "Native splash screen is already hidden",
        "remote notifications are not supported in the simulator",
        "Sentry Logger [Error]: SentryError: SDK not enabled, will not send event.",
        "Constants.manifest is null because",
        "AsyncStorage has been extracted", // This is an internal Firebase issue and should be resolved before it's a real problem.
    ])
}

export default function App(props) {
    const isLoadingComplete = useCachedResources()

    Sentry.init({
        dsn: "https://42befa5e608b4dd9bed2ead0fa948f99@o293168.ingest.sentry.io/5415202",
        enableInExpoDevelopment: false,
        debug: false, // This makes a ton of unhelpful noise on dev builds.
    })

    useEffect(() => {
        // Make sure our audio plays regardless of silent mode on iOS.
        Audio.setAudioModeAsync({ playsInSilentModeIOS: true })
    }, [])

    if (!isLoadingComplete) {
        return <View style={{}} />
    } else {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <RootSiblingParent>
                        <AppearanceProvider>
                            <ThemeContextProvider>
                                <SafeAreaProvider>
                                    <ActionSheetProvider>
                                        <GestureHandlerRootView style={{ flex: 1 }}>
                                            <MainApp />
                                        </GestureHandlerRootView>
                                    </ActionSheetProvider>
                                </SafeAreaProvider>
                            </ThemeContextProvider>
                        </AppearanceProvider>
                    </RootSiblingParent>
                </PersistGate>
            </Provider>
        )
    }
}
