import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, View } from "react-native"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import CardView from "./CardView"

export default function InitialDataFetchCard(props) {
    const { theme } = React.useContext(ThemeContext)

    return (
        <CardView includesBorder={true} includesPadding={true} {...props}>
            <View style={{ flexDirection: "row" }}>
                <Image source={theme.icons.refresh} style={[{ width: 32, height: 32, marginRight: 16, tintColor: theme.textSecondary }]} />
                <Text style={[TextStyles.body2, { flexShrink: 1, color: theme.textSecondary }]}>{i18n.t("initialFetchDescription")}</Text>
            </View>
        </CardView>
    )
}
