import { useNavigation } from "@react-navigation/native"
import { StatusBar } from "expo-status-bar"
import { prop } from "ramda"
import React, { useState } from "react"
import { Image, ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useSelector } from "react-redux"
import Colors from "../shared/Colors"
import { TextStyles } from "../shared/TextStyles"
import BackButton from "./BackButton"
const axios = require("axios").default
import i18n from "../shared/i18n"

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        backgroundColor: Colors.legacy,
    },

    // headerCurve: {
    //     position: "absolute",
    //     bottom: 0,
    //     left: 0,
    //     right: 0,
    //     tintColor: Colors.legacy,
    // },

    textRow: {
        marginHorizontal: 26,
        marginBottom: 24,
        flexDirection: "row",
    },
    textInnerContainer: {
        flex: 1,
        marginTop: 6,
    },
    bulletNumber: {
        fontFamily: "lato-black",
        fontSize: 40,
        color: Colors.darkPurple100,
        opacity: 0.2,
        marginRight: 12,
    },
})

export default function MapInfoDetail(props) {
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()

    const { isExpanded = false, cardMode = false } = props

    const mainData = useSelector((state) => state.primaryData.mainData)
    const { gridRewards = {} } = prop("nextSteps", mainData) || {}
    let gridRewardsSetupComplete = gridRewards.status == true
    const [spinnerVisible, setSpinnerVisible] = useState(false)

    const link = prop("link", gridRewards)

    const innerContent = () => {
        return (
            <View>
                {/* Numbers */}
                <View style={[styles.textRow, { marginTop: cardMode ? 30 : insets.top + 64 }]}>
                    <View style={styles.textInnerContainer}>
                        <Text style={TextStyles.h2}>{i18n.t("mapInfoTitle1")}</Text>
                        <Text style={[TextStyles.content3, { marginTop: 4 }]}>{i18n.t("mapInfoBody1")}</Text>
                    </View>
                </View>
                <View style={styles.textRow}>
                    <View style={styles.textInnerContainer}>
                        <Text style={TextStyles.content2Strong}>{i18n.t("mapInfoTitle2")}</Text>
                        <Text style={[TextStyles.content3, { marginTop: 4 }]}>{i18n.t("mapInfoBody2")}</Text>
                    </View>
                </View>
                <View style={styles.textRow}>
                    <View style={styles.textInnerContainer}>
                        <Text style={TextStyles.content2Strong}>{i18n.t("mapInfoTitle3")}</Text>
                        <Text style={[TextStyles.content3, { marginTop: 4 }]}> {i18n.t("mapInfoBody3")}</Text>
                    </View>
                </View>

                {/* Two people image */}
                <Image
                    source={require("../assets/images/onboardingTwoPeopleSmall.png")}
                    style={{ alignSelf: "flex-start", width: 338, height: 180, marginTop: 46 }}
                />
            </View>
        )
    }

    return (
        <View style={{ flexGrow: 1 }}>
            {cardMode ? (
                innerContent()
            ) : (
                <ScrollView style={styles.container} scrollEnabled={!cardMode} showsVerticalScrollIndicator={false}>
                    {innerContent()}
                </ScrollView>
            )}
            {!cardMode && (
                <BackButton
                    onPress={() => {
                        navigation.goBack()
                    }}
                    containerStyle={{ position: "absolute", top: insets.top, left: 12 }}
                />
            )}
            <StatusBar style="dark" />
        </View>
    )
}
