import { useNavigation } from "@react-navigation/native"
import React, { useContext, useEffect } from "react"
import { ScrollView } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import EnvironmentalImpactCard from "../components/cards/EnvironmentalImpactCard"
import LessonCard from "../components/cards/LessonCard"
import NeighborhoodCard from "../components/cards/NeighborhoodCard"
import { DataSource, InsightTypes, ScreenNames } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { ThemeContext } from "../shared/ThemeContext"
import InsightCard from "../components/cards/InsightCard"
import { prop } from "ramda"
import { fetchNeighborhoodPerformance } from "../model/primaryDataActions"

export default function NeighborhoodScreen(props) {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const { theme } = useContext(ThemeContext)
    const neighborhoodData = useSelector((state) => state.primaryData.neighborhoodData) || []
    const activeNeighborhoodData = neighborhoodData.length > 0 ? neighborhoodData[0] : {}

    useEffect(() => {
        if (activeNeighborhoodData == null || activeNeighborhoodData == {}) {
            // Nothing to do
            return
        }

        const { id, name } = activeNeighborhoodData
        // Update the screen title with the neighborhood name.
        if (name != null && name.length > 0) {
            navigation.setOptions({ title: name })
        }

        // Fetch neighborhood performance (this will be used by the environmental impact card, etc.)
        dispatch(fetchNeighborhoodPerformance(id))
    }, [activeNeighborhoodData])


    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background, paddingTop: 24 }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
        >
            {/* <ScrollView
                style={{ flex: 1 }}
                horizontal={true}
                alwaysBounceHorizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingRight: 20 }}
            >
                <InsightCard
                    type={InsightTypes.neighborhoodRankOverall}
                    source={DataSource.electricity}
                    infoButtonAction={() => {
                        console.log("Overall rank info pressed")
                    }}
                />
                <InsightCard
                    type={InsightTypes.neighborhoodRankPerCapita}
                    source={DataSource.electricity}
                    infoButtonAction={() => {
                        console.log("Per capita rank info pressed")
                    }}
                />
            </ScrollView> */}
            <EnvironmentalImpactCard />
            <LessonCard
                title={i18n.t("lesson.referral.title")}
                description={i18n.t("lesson.referral.description")}
                onPress={() => {
                    navigation.navigate(ScreenNames.ReferralDetail.routeName)
                }}
            />
            <NeighborhoodCard buttonVisible={false} />
        </ScrollView>
    )
}
