import { useHeaderHeight } from "@react-navigation/elements"
import { prop } from "ramda"
import React, { useEffect, useState } from "react"
import { Dimensions, ScrollView, Text, View } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import Button from "../components/Button.js"
import { CommonStyles } from "../shared/CommonStyles"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext.js"
import { openSetup } from "../shared/Utils"

export default function AccountConnectionPromptScreen(props) {
    const { theme } = React.useContext(ThemeContext)
    const headerHeight = useHeaderHeight()
    const dispatch = useDispatch()

    const mainData = useSelector((state) => state.primaryData.mainData)
    const { ami = {} } = prop("nextSteps", mainData) || {}

    const [initialLoginStateResolved, setInitialLoginStateResolved] = useState(false)
    const [spinnerVisible, setSpinnerVisible] = useState(false)

    useEffect(() => {
        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged((user) => {
            // Once something loads (with a user or without), we know we can proceed.
            if (!initialLoginStateResolved) {
                setInitialLoginStateResolved(true)
            }
        })
        return () => {
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    const spinnerCallback = (visible) => {
        setSpinnerVisible(visible)
    }

    if (!initialLoginStateResolved) {
        // console.log("Login not resolved yet")
        // If we don't know whether we've logged in or not yet, just show a simple-to-render white screen.
        return null
    }

    let screenHeight = Dimensions.get("window").height - headerHeight

    return (
        <ScrollView
            style={[{ height: screenHeight, backgroundColor: theme.background }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
            showsVerticalScrollIndicator={false}
            alwaysBounceVertical={true}
            extraScrollHeight={50}
            keyboardOpeningTime={1}
            keyboardShouldPersistTaps={"handled"}
        >
            <View style={{ flexShrink: 1, marginHorizontal: 32, paddingTop: 24 }}>
                <Text style={[TextStyles.h2, { color: theme.textPrimary, marginBottom: 16 }]}>{i18n.t("connectAccount.title")}</Text>
                <Text style={[TextStyles.body2, { color: theme.textSecondary, marginBottom: 24 }]}>{i18n.t("connectAccount.description")}</Text>
                <Text style={[TextStyles.capsExtraSmall, { color: theme.textSecondary, marginBottom: 8 }]}>
                    {i18n.t("connectAccount.noOnlineAccountTitle")}
                </Text>
                <View style={{ padding: 16, borderRadius: 8, borderColor: theme.border, borderWidth: 1, marginBottom: 32 }}>
                    <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>{i18n.t("connectAccount.noOnlineAccountDescription")}</Text>
                </View>

                <Button
                    type="fill"
                    buttonTintColor={theme.purple}
                    contentTintColor={theme.textLight}
                    spinnerTintColor={theme.textLight}
                    onPress={() => openSetup(prop("link", ami), spinnerCallback)}
                    title={i18n.t("connectAccount.cta")}
                    showSpinner={spinnerVisible}
                    containerStyle={{ marginBottom: 16, opacity: spinnerVisible ? 0.6 : 1.0 }}
                />
            </View>
        </ScrollView>
    )
}
