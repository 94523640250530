import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
import { prop, path } from "ramda"
import React, { useEffect, useRef, useState } from "react"
import { Image, Modal, Text, TextInput, View, TouchableOpacity } from "react-native"
import { FlatList } from "react-native-gesture-handler"
import { useDispatch, useSelector, useStore } from "react-redux"
import { fetchDataForRoute, loadAsyncStorage, switchUtilityAccount } from "../model/primaryDataActions"
import AppConstants, { PropertyType, ScreenNames } from "../shared/AppConstants"
import i18n from "../shared/i18n"
import { activeOpacity } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import { openSetup, setDefaultTimeZone, titleCase } from "../shared/Utils"
import { useActionSheet } from "@expo/react-native-action-sheet"
import moment from "moment-timezone"

export default function LocationsScreen(props) {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const { theme } = React.useContext(ThemeContext)
    const store = useStore()
    const { showActionSheetWithOptions } = useActionSheet()

    const mainData = useSelector((state) => state.primaryData.mainData)
    const [currentAccountId, setCurrentAccountId] = useState(null)

    const accountsList = useSelector((state) => state.primaryData.accountsData) || []
    const [visibleData, setVisibleData] = useState(accountsList)
    const [searchTerm, setSearchTerm] = useState("")

    const [nicknameDialogVisible, setNicknameDialogVisible] = useState(false)
    const utilityAccountNicknames = useSelector((state) => state.primaryData.utilityAccountNicknames) || {}
    const [dialogCurrentId, setDialogCurrentId] = useState(null)
    const [dialogCurrentNickname, setDialogCurrentNickname] = useState(null)

    // Initial setup
    useEffect(() => {
        navigation.setOptions({
            headerRight: (props) => (
                <TouchableOpacity style={{ marginRight: 20 }} onPress={addLocationButtonPressed} {...props}>
                    <Text style={[TextStyles.buttonText, { color: theme.textPrimary }]}>{i18n.t("addButtonTitle")}</Text>
                </TouchableOpacity>
            ),
        })
    }, [])

    useEffect(() => {
        // Get the stored account.
        AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((savedAccountId) => {
            // console.log("Accounts list is " + JSON.stringify(accountsList) + ", saved ID is " + savedAccountId)
            setCurrentAccountId(savedAccountId)

            // Created the processed data array
            setVisibleData(accountsList)
        })
    }, [accountsList])

    useEffect(() => {
        if (searchTerm == null || searchTerm.length < 2) {
            setVisibleData(accountsList)
        } else {
            setVisibleData(accountsList.filter((item) => prop("serviceAddress", item).toLowerCase().includes(searchTerm.toLowerCase())))
        }
    }, [searchTerm])

    function addLocationButtonPressed() {
        const { ami = {} } = prop("nextSteps", mainData) || {}
        openSetup(prop("link", ami))
    }

    async function changeLocation(accountData) {
        const accountId = prop("accountId", accountData)

        setCurrentAccountId(accountId)

        // // Clear the timezone suppression preference.
        // await AsyncStorage.multiRemove([AppConstants.suppressTimeZoneDisplay, AppConstants.suppressEducationSplashCard])

        // // Update the app-wide display timezone.
        // const newTimeZone = path(["building", "timezone"], accountData)
        // setDefaultTimeZone(newTimeZone, store)

        // // Change accounts
        // await AsyncStorage.setItem(AppConstants.currentUtilityAccount, accountId)
        // console.log("Fetching updated data for new account ID") //" + accountId)
        // dispatch(fetchDataForRoute(ScreenNames.Locations.routeName))
        // // Reload data into async storage
        // loadAsyncStorage(store)

        dispatch(switchUtilityAccount(accountData, store))

        // Wait a short while, then dismiss.
        setTimeout(() => {
            navigation.goBack()
        }, 250)
    }

    // Utility-account related
    async function setAccountNickname(nickname, utilityAccountId) {
        if (utilityAccountId == null || utilityAccountId.length == 0) {
            // Nothing to do.
            return
        }

        const accountNicknamesJSON = await AsyncStorage.getItem(AppConstants.utilityAccountNicknames)
        let accountNicknames = accountNicknamesJSON != null ? JSON.parse(accountNicknamesJSON) : {}
        accountNicknames[utilityAccountId] = nickname
        await AsyncStorage.setItem(AppConstants.utilityAccountNicknames, JSON.stringify(accountNicknames))

        // Reload this into Redux
        loadAsyncStorage(store)
    }

    // Property-related
    function iconForPropertyType(type, selected = false) {
        switch (type) {
            case PropertyType.apartment:
            case PropertyType.smallCommercial:
                return selected ? rawThemes.dark.icons.building : theme.icons.buildingBlue
            default:
                return selected ? rawThemes.dark.icons.home : theme.icons.homeBlue
        }
    }

    return (
        <View style={{ flex: 1, paddingTop: 32, backgroundColor: theme.background }}>
            {accountsList.length > 2 && (
                <TextInput
                    key={"searchField"}
                    textContentType="none"
                    placeholder={i18n.t("search")}
                    placeholderTextColor={theme.textHint}
                    underlineColorAndroid={"transparent"}
                    style={[
                        TextStyles.body2,
                        {
                            marginHorizontal: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 12,
                            backgroundColor: theme.surface1,
                            color: theme.textPrimary,
                            marginBottom: 32,
                            borderRadius: 1000,
                        },
                    ]}
                    value={searchTerm}
                    onChangeText={async (text) => setSearchTerm(text)}
                />
            )}
            <FlatList
                // NOTE: the overflow buttons have 10 points of padding on the far right to allow for a slightly larger tap target.
                // We're shrinking the right-side padding so they appear to be along a consistent 20-point edge from the right side.
                style={[{ flex: 1, paddingLeft: 20, paddingRight: 10 }]}
                data={visibleData}
                keyExtractor={(item, index) => item + index}
                ItemSeparatorComponent={() => <View style={{ height: 16 }} />}
                renderItem={({ item }) => {
                    const accountId = prop("accountId", item)
                    let displayName = utilityAccountNicknames[accountId]
                    if (displayName == null || displayName.length == 0) {
                        displayName = titleCase(prop("serviceAddress", item) || prop("utilityAccountId", item))
                    }
                    const propertyType = path(["building", "type"], item)
                    return (
                        <TouchableOpacity
                            style={{ flexShrink: 1, flexDirection: "row", alignItems: "center", marginBottom: 8 }}
                            activeOpacity={activeOpacity}
                            onPress={() => changeLocation(item)}
                        >
                            <View
                                style={{
                                    height: 40,
                                    width: 40,
                                    borderRadius: 12,
                                    marginRight: 16,
                                    backgroundColor: accountId == currentAccountId ? theme.blue : theme.surface1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Image source={iconForPropertyType(propertyType, accountId == currentAccountId)} style={{ width: 20, height: 20 }} />
                            </View>
                            <Text style={[TextStyles.body2Medium, { color: theme.textSecondary, flex: 1, marginRight: 24 }]}>{displayName}</Text>
                            <TouchableOpacity
                                style={{ width: 44, height: 40, alignItems: "center", justifyContent: "center" }}
                                onPress={() => {
                                    showActionSheetWithOptions({ options: [i18n.t("setNickname"), i18n.t("cancel")], cancelButtonIndex: 1 }, (buttonIndex) => {
                                        switch (buttonIndex) {
                                            case 0:
                                                // Nickname
                                                setDialogCurrentId(accountId)
                                                setDialogCurrentNickname(utilityAccountNicknames[accountId])
                                                setNicknameDialogVisible(true)
                                                break
                                            case 1:
                                                // Cancel
                                                console.log("Cancel")
                                                break
                                            default:
                                                break
                                        }
                                    })
                                }}
                            >
                                <Image source={theme.icons.ellipsis24} style={{ width: 24, height: 24, opacity: 0.5 }} />
                            </TouchableOpacity>
                        </TouchableOpacity>
                    )
                }}
            />
            <Modal
                visible={nicknameDialogVisible}
                animationType="slide"
                transparent
                presentationStyle="overFullScreen"
                // onDismiss={() => {
                //     setNicknameDialogVisible(false)
                // }}
            >
                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                    <View
                        style={{
                            backgroundColor: theme.background,
                            borderRadius: 16,
                            alignItems: "center",
                            width: 300,
                            paddingTop: 20,
                            overflow: "hidden",
                        }}
                    >
                        <View style={{ paddingHorizontal: 20, alignItems: "center", width: "100%" }}>
                            <Text style={[TextStyles.h4, { color: theme.textPrimary, marginBottom: 8 }]}>{i18n.t("setNickname")}</Text>
                            <Text style={[TextStyles.body3, { color: theme.textSecondary, marginBottom: 16 }]}>{i18n.t("setNicknameDescription")}</Text>
                            <TextInput
                                underlineColorAndroid={"transparent"}
                                returnKeyType={"done"}
                                style={[
                                    TextStyles.body2,
                                    {
                                        color: theme.textPrimary,
                                        borderRadius: 8,
                                        paddingHorizontal: 16,
                                        paddingVertical: 8,
                                        borderWidth: 1,
                                        borderColor: theme.border,
                                        width: "100%",
                                    },
                                ]}
                                defaultValue={dialogCurrentNickname}
                                onChangeText={(text) => setDialogCurrentNickname(text)}
                            />
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                width: "100%",
                                height: 44,
                                alignItems: "stretch",
                                marginTop: 24,
                                borderTopColor: theme.divider,
                                borderTopWidth: 1,
                            }}
                        >
                            <TouchableOpacity
                                style={{ flex: 1, justifyContent: "center", alignItems: "center", borderRightColor: theme.divider, borderRightWidth: 1 }}
                                onPress={() => {
                                    setNicknameDialogVisible(false)
                                }}
                            >
                                <Text style={[TextStyles.buttonText, { flexShrink: 1, color: theme.textSecondary }]}>{i18n.t("cancel")}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
                                onPress={async () => {
                                    await setAccountNickname(dialogCurrentNickname, dialogCurrentId)
                                    setNicknameDialogVisible(false)
                                }}
                            >
                                <Text style={[TextStyles.buttonText, { flexShrink: 1, color: theme.textPrimary }]}>{i18n.t("ok")}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </View>
    )
}
