import { useActionSheet } from "@expo/react-native-action-sheet"
import { useHeaderHeight } from "@react-navigation/elements"
import { useNavigation } from "@react-navigation/native"
import * as ImagePicker from "expo-image-picker"
import _ from "lodash"
import { path, prop } from "ramda"
import React, { useEffect, useState } from "react"
import {
    ActivityIndicator,
    Alert,
    Dimensions,
    Image,
    KeyboardAvoidingView,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { useDispatch, useSelector } from "react-redux"
import Divider from "../components/Divider"
import ErrorMessageInline from "../components/ErrorMessageInline"
import { deleteAvatar, fetchProfileData, updateAvatar, updateProfileData } from "../model/primaryDataActions"
import Colors from "../shared/Colors"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { activeOpacity } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

if (Platform.OS !== "web") {
    Adjust = require("react-native-adjust").Adjust
    AdjustConfig = require("react-native-adjust").AdjustConfig
    AdjustEvent = require("react-native-adjust").AdjustEvent
}

export default function EditProfileScreen(props) {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const { theme } = React.useContext(ThemeContext)
    const headerHeight = useHeaderHeight()
    const { showActionSheetWithOptions } = useActionSheet()

    const profileData = useSelector((state) => state.primaryData.profileData)
    const { first_name = "", last_name = "", zip_code = "", email, phone_number, payment_preference = "", avatar } = profileData || {}
    const [avatarSource, setAvatarSource] = useState(avatar)
    const avatarUpdateLoading = useSelector((state) => state.primaryData.avatarUpdateLoading)
    const [avatarSpinnerVisible, setAvatarSpinnerVisible] = useState(avatarUpdateLoading == true)

    const profileDataError = useSelector((state) => state.primaryData.profileDataError)
    const profileDataLoading = useSelector((state) => state.primaryData.profileDataLoading)

    const { modal = false } = path(["route", "params"], props) || {}

    const [errorMessage, setErrorMessage] = useState(null)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState(null)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [emailLocal, setEmailLocal] = useState("")

    const [phoneNumber, setPhoneNumber] = useState(null)

    const [focusedField, setFocusedField] = useState(null)

    useEffect(() => {
        dispatch(fetchProfileData())
    }, [])

    useEffect(() => {
        setFirstName(first_name)
        setLastName(last_name)
        setEmailLocal(email)
        setPhoneNumber(phone_number)
    }, [profileData])

    useEffect(() => {
        let responseData = path(["response", "data"], profileDataError) || {}
        let phoneError = prop("phone_number", responseData)
        if (phoneError != null) {
            if (phoneError.length > 0) {
                setPhoneErrorMessage(phoneError[0])
            } else {
                setPhoneErrorMessage(i18n.t("phoneNumberGenericError"))
            }
        } else {
            setPhoneErrorMessage(null)
        }
    }, [profileDataError])

    useEffect(() => {
        if ((firstName == null || firstName.length == 0) && (lastName == null || lastName.length == 0)) {
            // Don't bother trying to send this, we know it will fail, and is almost certainly a setup artifact.
            return
        }

        if (firstName != first_name || lastName != last_name || phoneNumber != phone_number) {
            console.log(
                "About to call changeProfile data: |" +
                    firstName +
                    "| " +
                    first_name +
                    " |" +
                    lastName +
                    "| " +
                    last_name +
                    " |" +
                    phoneNumber +
                    "| " +
                    phone_number
            )
            _.debounce(changeProfileData, 1000)()
        }
    }, [firstName, lastName, phoneNumber])

    useEffect(() => {
        // Whenever we have a valid profile, use the avatar URI as our display image.
        // This should also clear the image if the avatar field comes back null as part of a valid profile.
        if (profileData != null && profileData != {}) {
            setAvatarSource(avatar)
        }
    }, [avatar])

    useEffect(() => {
        setAvatarSpinnerVisible(avatarUpdateLoading == true)
    }, [avatarUpdateLoading])

    const changeProfileData = () => {
        // Top section
        if (firstName == null || firstName.length == 0) {
            setErrorMessage(i18n.t("profileFirstNameMissing"))
            return
        } else if (lastName == null || lastName.length == 0) {
            setErrorMessage(i18n.t("profileLastNameMissing"))
            return
        } else {
            setErrorMessage(null)
        }

        if (phoneNumber != null && phoneNumber.length > 0 && phoneNumber.length <= 7) {
            setPhoneErrorMessage(i18n.t("phoneNumberGenericError"))
            return
        }

        // At this point, we have what looks like a valid attempt to update profile info.
        setErrorMessage(null)
        setPhoneErrorMessage(null)

        let newProfileInfo = {
            first_name: firstName,
            last_name: lastName,
        }

        // See if we need to modify the phone record at all
        if (phoneNumber != null && phoneNumber.length > 0 && phoneNumber.length > 7) {
            // If the user put in what might be a valid number, try to set it.
            newProfileInfo["phone_number"] = phoneNumber
        } else if (prop("phone_number", profileData) != null && (phoneNumber == null || phoneNumber.length == 0)) {
            // If we started with a number in the user's profile, and the user deleted it, clear the number stored on the server.
            newProfileInfo["phone_number"] = ""
        }

        console.log("Trying to set new profile data to " + JSON.stringify(newProfileInfo))

        dispatch(updateProfileData(newProfileInfo))
    }

    const onChangeFirstName = (text) => {
        let processedText = text ? text.trim() : null

        if (processedText == null || processedText.length == 0) {
            setErrorMessage(i18n.t("profileFirstNameMissing"))
            return
        }

        setFirstName(processedText)
    }

    const onChangeLastName = (text) => {
        let processedText = text ? text.trim() : null

        if (processedText == null || processedText.length == 0) {
            setErrorMessage(i18n.t("profileLastNameMissing"))
            return
        }

        setLastName(processedText)
    }

    const onChangePhoneNumber = (text) => {
        let processedText = text ? text.trim() : null

        setPhoneNumber(processedText)
    }

    const onProfilePhotoTapped = async () => {
        showActionSheetWithOptions(
            {
                options: [i18n.t("photoTake"), i18n.t("photoLibrary"), i18n.t("photoDelete"), i18n.t("cancel")],
                destructiveButtonIndex: 2,
                cancelButtonIndex: 3,
            },
            async (buttonIndex) => {
                switch (buttonIndex) {
                    case 0:
                        // Take a photo
                        try {
                            const permission = await ImagePicker.requestCameraPermissionsAsync()
                            if (permission.granted === false) {
                                Alert.alert(i18n.t("photoPermissionMissingTitle"), i18n.t("photoPermissionMissing"), [
                                    {
                                        text: i18n.t("ok"),
                                        style: "cancel",
                                    },
                                ])
                            }
                            // At this point, we have permission.
                            // If the user cancelled the action, the method returns { cancelled: true }.
                            // Otherwise, this method returns information about the selected media item.
                            // When the chosen item is an image, this method returns { cancelled: false, type: 'image', uri, width, height, exif, base64 };
                            // when the item is a video, this method returns { cancelled: false, type: 'video', uri, width, height, duration }.
                            const { cancelled, type, uri, width, height, exif, base64 } = await ImagePicker.launchCameraAsync({
                                allowsEditing: true,
                                allowsMultipleSelection: false,
                                aspect: [1, 1],
                                quality: 0.7,
                            })
                            if (!cancelled) {
                                console.log("Chosen image URI is " + uri + " of type " + type)
                                setAvatarSource(uri)
                                // Push this change to the API
                                dispatch(updateAvatar(uri))
                            }
                        } catch (error) {
                            console.log("Error trying to take a photo: " + JSON.stringify(error))
                        }
                        break
                    case 1:
                        // Choose from library
                        try {
                            const permission = await ImagePicker.requestMediaLibraryPermissionsAsync()
                            if (permission.granted === false) {
                                Alert.alert(i18n.t("photoPermissionMissingTitle"), i18n.t("photoPermissionMissing"), [
                                    {
                                        text: i18n.t("ok"),
                                        style: "cancel",
                                    },
                                ])
                            }
                            // At this point, we have permission.
                            // If the user cancelled the action, the method returns { cancelled: true }.
                            // Otherwise, this method returns information about the selected media item.
                            // When the chosen item is an image, this method returns { cancelled: false, type: 'image', uri, width, height, exif, base64 };
                            // when the item is a video, this method returns { cancelled: false, type: 'video', uri, width, height, duration }.
                            const { cancelled, type, uri, width, height, exif, base64 } = await ImagePicker.launchImageLibraryAsync({
                                allowsEditing: true,
                                allowsMultipleSelection: false,
                                aspect: [1, 1],
                                quality: 0.7,
                            })
                            if (!cancelled) {
                                console.log("Chosen image URI is " + uri + " of type " + type)
                                setAvatarSource(uri)
                                // Push this change to the API
                                dispatch(updateAvatar(uri))
                            }
                        } catch (error) {
                            console.log("Error trying to choose a photo: " + JSON.stringify(error))
                        }
                        break
                    case 2:
                        // Delete photo
                        dispatch(deleteAvatar())
                        break
                    case 3:
                        // Cancel
                        console.log("Cancel")
                        break
                    default:
                        break
                }
            }
        )
    }

    let screenHeight = Dimensions.get("window").height

    const displayName = firstName.length > 0 && lastName.length > 0 ? firstName + " " + lastName : i18n.t("defaultDisplayName")

    return (
        <View style={{ backgroundColor: theme.background }}>
            <KeyboardAwareScrollView
                style={[{ height: screenHeight, backgroundColor: theme.background }]}
                contentContainerStyle={CommonStyles.mainScreenContent}
                showsVerticalScrollIndicator={false}
                alwaysBounceVertical={true}
                extraScrollHeight={50}
                keyboardOpeningTime={1}
                keyboardShouldPersistTaps="always"
            >
                <View style={{ marginHorizontal: 32, marginTop: 24 }}>
                    <View style={{ flexShrink: 1, alignItems: "center", alignSelf: "center", marginHorizontal: 20, marginBottom: 24 }}>
                        {/* Avatar */}
                        <View style={{ marginBottom: 24 }}>
                            <TouchableOpacity
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 1000,
                                    width: 80,
                                    height: 80,
                                    backgroundColor: theme.surface1,
                                    marginBottom: 16,
                                    overflow: "hidden",
                                    borderColor: theme.border,
                                    borderWidth: avatarSource ? 0 : 1,
                                }}
                                onPress={onProfilePhotoTapped}
                                activeOpacity={activeOpacity}
                            >
                                <Image
                                    source={avatarSource ? { uri: avatarSource } : theme.icons.plus32}
                                    style={{ width: 80, height: 80 }}
                                    resizeMode={avatarSource ? "cover" : "center"}
                                />
                                {avatarSpinnerVisible && (
                                    <ActivityIndicator size={"small"} animating={true} color={theme.white} style={{ position: "absolute" }} />
                                )}
                            </TouchableOpacity>
                            <TouchableOpacity onPress={onProfilePhotoTapped} activeOpacity={activeOpacity}>
                                <Text style={[TextStyles.body2Medium, { color: theme.textHint, textAlign: "center" }]}>
                                    {i18n.t(avatarSource ? "editPhoto" : "addPhoto")}
                                </Text>
                            </TouchableOpacity>
                        </View>
                        {/* Name & email */}
                        <Text style={[TextStyles.h1, { color: theme.textPrimary, marginBottom: 8, textAlign: "center" }]}>{displayName}</Text>
                        <Text style={[TextStyles.body2Medium, { color: theme.textHint, textAlign: "center" }]}>{emailLocal}</Text>
                    </View>

                    {errorMessage != null && <ErrorMessageInline text={errorMessage} />}

                    <View style={[styles.inputFieldContainer, { marginBottom: 16 }]}>
                        <TextInput
                            placeholder={i18n.t("firstName")}
                            placeholderTextColor={theme.textHint}
                            underlineColorAndroid={"transparent"}
                            returnKeyType={"next"}
                            autoCorrect={false}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.inputField,
                                { color: theme.textPrimary, borderColor: focusedField == "firstName" ? theme.textSecondary : theme.border },
                            ]}
                            onChangeText={_.debounce(onChangeFirstName, 1000)}
                            autoComplete="name-given"
                            textContentType="givenName"
                            onFocus={() => setFocusedField("firstName")}
                            defaultValue={firstName}
                        />
                    </View>
                    <View style={[styles.inputFieldContainer, {}]}>
                        <TextInput
                            placeholder={i18n.t("lastName")}
                            placeholderTextColor={theme.textHint}
                            underlineColorAndroid={"transparent"}
                            returnKeyType={"next"}
                            autoCorrect={false}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.inputField,
                                { color: theme.textPrimary, borderColor: focusedField == "lastName" ? theme.textSecondary : theme.border },
                            ]}
                            onChangeText={_.debounce(onChangeLastName, 1000)}
                            autoComplete="name-family"
                            textContentType="familyName"
                            onFocus={() => setFocusedField("lastName")}
                            defaultValue={lastName}
                        />
                    </View>

                    <Divider style={{ marginVertical: 32 }} />
                    {phoneErrorMessage != null && <ErrorMessageInline text={phoneErrorMessage} />}
                    <View style={{ justifyContent: "center" }}>
                        <View style={[styles.inputFieldContainer, { marginBottom: 16 }]}>
                            <TextInput
                                textContentType="telephoneNumber"
                                autoComplete="tel-national"
                                placeholder={i18n.t("phoneNumberPlaceholder")}
                                placeholderTextColor={theme.textHint}
                                underlineColorAndroid={"transparent"}
                                style={[
                                    TextStyles.body2,
                                    styles.inputField,
                                    { color: theme.textPrimary, borderColor: focusedField == "phone" ? theme.textSecondary : theme.border },
                                ]}
                                defaultValue={phoneNumber}
                                onChangeText={_.debounce(onChangePhoneNumber, 1000)}
                                onFocus={() => setFocusedField("phone")}
                            />
                        </View>
                    </View>
                    <Text style={[TextStyles.body4, { color: theme.textHint, textAlign: "center" }]}>{i18n.t("profilePhoneNumberDescription")}</Text>
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    inputFieldContainer: {
        height: 50,
    },

    inputField: {
        flex: 1,
        width: "100%",
        borderRadius: 8,
        paddingHorizontal: 16,
        justifyContent: "center",
        borderWidth: 1,
    },

    loginButtonContainer: {
        height: 50,
        marginTop: 24,
        marginBottom: 16,
    },

    loginHelpButton: {
        height: 50,
        marginBottom: 50,
        justifyContent: "center",
        alignItems: "center",
    },

    loginErrorContainer: {
        width: "100%",
        height: 70,
        backgroundColor: Colors.white,
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 8,
    },

    loginErrorText: {
        textAlign: "center",
        color: Colors.red,
    },

    loginErrorButton: {
        justifyContent: "center",
        alignItems: "center",
    },
})
