import React, { useEffect, useState } from "react"
import { Platform } from "react-native"
import { ButtonGroup } from "react-native-elements"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function SegmentedControl(props) {
    const { labels = [], selectedIndex = 0 } = props
    const { theme } = React.useContext(ThemeContext)

    // Start with the requested segment selected
    const [internalSelectedIndex, setInternalSelectedIndex] = useState(selectedIndex)

    useEffect(() => {
        setInternalSelectedIndex(selectedIndex)
    }, [selectedIndex])

    return (
        <ButtonGroup
            containerStyle={[
                { height: 36, backgroundColor: theme.surface1, borderRadius: 10, borderColor: "transparent", marginHorizontal: 0, padding: 1 },
                props.containerStyle,
            ]}
            onPress={(selectedIndex) => {
                setInternalSelectedIndex(selectedIndex)
                props.onPress(selectedIndex)
            }}
            selectedIndex={internalSelectedIndex}
            buttons={labels}
            innerBorderStyle={{ color: "transparent" }}
            textStyle={[TextStyles.caption, { color: theme.textHint }]}
            buttonStyle={{
                paddingHorizontal: 4,
                ...Platform.select({
                    web: {
                        paddingHorizontal: 20,
                        paddingVertical: 8,
                    },
                }),
            }}
            selectedButtonStyle={{ backgroundColor: theme.background, borderColor: theme.border, borderWidth: 1, borderRadius: 8 }}
            selectedTextStyle={[TextStyles.caption, { color: theme.textPrimary }]}
        />
    )
}
