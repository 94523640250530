import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
import * as Linking from "expo-linking"
import { path, prop } from "ramda"
import * as React from "react"
import { useEffect, useState } from "react"
import { Text, View } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import AppConstants from "../shared/AppConstants"
import { titleCase } from "../shared/Utils"

export default function PaymentDetailScreen(props) {
    const navigation = useNavigation()
    const dispatch = useDispatch()

    const { payment } = path(["route", "params"], props) || {}
    console.log("Payment is " + JSON.stringify(payment))
    const { state, pickupURL } = payment || {}

    // Get the stored account.
    const accountsList = useSelector((state) => state.primaryData.accountsData)

    const [serviceAddress, setServiceAddress] = useState(null)
    useEffect(() => {
        // Get the stored account.
        AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((currentAccountId) => {
            let matchingAccount = accountsList.find((possibleAccount) => {
                return prop("accountId", possibleAccount) == currentAccountId
            })
            if (matchingAccount != null) {
                setServiceAddress(prop("serviceAddress", matchingAccount))
            }
        })
    }, [accountsList])

    const sendEmail = () => {
        Linking.openURL("mailto:gridrewards-payments@logicalbuildings.com?subject=GridRewards%20payment%20question")
    }

    const serviceAddressProcessed = titleCase(serviceAddress)

    return (
        <View>
            <Text>PAYMENT DETAIL TEST</Text>
        </View>
    )
    // return (
    //     <View style={CommonStyles.container}>
    //         {/* Note that we're including a tiny amount of additional padding at the top of the 
    //         scroll view so that we don't clip the shadow at the top of the card, 
    //         which will always be the first thing in the scroll view */}
    //         <ScrollView
    //             style={CommonStyles.container}
    //             contentContainerStyle={[CommonStyles.scrollContentContainer, { paddingTop: 8, alignItems: "center" }]}
    //             showsVerticalScrollIndicator={false}
    //         >
    //             <View style={{ width: maxContentWidth(), marginBottom: 50, overflow: "visible" }}>
    //                 <CardPayment payment={payment} isLocked={true} />
    //                 {state == "digital" ? (
    //                     <View>
    //                         <Divider style={{ marginTop: 40 }} />
    //                         <Text style={[TextStyles.content3, { color: Colors.darkPurple80, marginBottom: 16 }]}>
    //                             {i18n.t("digitalPaymentTimeoutInstructions")}
    //                         </Text>
    //                     </View>
    //                 ) : (
    //                     <View style={{ height: 16 }} />
    //                 )}
    //                 {state != "donate" ? (
    //                     <TouchableOpacity
    //                         style={{ backgroundColor: Colors.gray11, borderRadius: 12, padding: 16 }}
    //                         activeOpacity={serviceAddressProcessed != null ? 1.0 : 0.7}
    //                         onPress={serviceAddressProcessed != null ? null : sendEmail}
    //                     >
    //                         <Text
    //                             style={[
    //                                 TextStyles.content3Strong,
    //                                 { color: serviceAddressProcessed != null ? Colors.darkPurple100 : Colors.darkRed, marginBottom: 16 },
    //                             ]}
    //                         >
    //                             {i18n.t("yourAddress")}
    //                         </Text>
    //                         <Text style={[TextStyles.content3, { color: serviceAddressProcessed != null ? Colors.darkPurple80 : Colors.darkRed }]}>
    //                             {serviceAddressProcessed || i18n.t("serviceAddressMissing")}
    //                         </Text>
    //                         <Divider style={{ marginVertical: 16, opacity: 0.25 }} fullWidth={true} />
    //                         <Text style={[TextStyles.button2, { color: Colors.darkPurple80 }]}>{i18n.t("incorrectAddressOrPaymentLate")}</Text>
    //                         <TouchableOpacity onPress={sendEmail}>
    //                             <Text style={[TextStyles.button2, { color: Colors.purple }]}>{i18n.t("getInTouch")}</Text>
    //                         </TouchableOpacity>
    //                     </TouchableOpacity>
    //                 ) : (
    //                     <View style={{ width: maxContentWidth(), marginBottom: 50 }}>
    //                         <Text style={[TextStyles.h2, { marginTop: 8, marginBottom: 16 }]}>{i18n.t("donationsDetailTitle")}</Text>
    //                         <Text style={[TextStyles.content3, { color: Colors.darkPurple80, marginBottom: 24 }]}>{i18n.t("donationsDetailSubtitle")}</Text>
    //                         {DonationOrganizations.map((orgInfo, index) => {
    //                             return <CardDonation key={index.toString()} orgInfo={orgInfo} />
    //                         })}
    //                     </View>
    //                 )}
    //             </View>
    //         </ScrollView>
    //         <StatusBar style="dark" />
    //     </View>
    // )
}
