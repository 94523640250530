import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Platform, TouchableOpacity } from "react-native"
import { activeOpacity } from "../shared/Layout"
import { ThemeContext } from "../shared/ThemeContext"

export default function BackButton(props) {
    const navigation = useNavigation()
    const onPressDefault = () => {
        if (Platform.OS === "web") {
            window.history.back()
        } else {
            navigation.goBack()
        }
    }
    const { theme } = React.useContext(ThemeContext)

    return (
        <TouchableOpacity
            onPress={props.onPress ?? onPressDefault}
            activeOpacity={activeOpacity}
            style={[{ width: 44, height: 44, justifyContent: "center", alignItems: "center" }, props.containerStyle]}
        >
            <Image source={theme.icons.backCaret} style={[{ width: 24, height: 24 }, props.imageStyle]} />
        </TouchableOpacity>
    )
}
