import { Dimensions, Platform, useWindowDimensions } from "react-native"

// const width = Dimensions.get("window").width
// const height = Dimensions.get("window").height

// export const cardMarginHorizontal = 12

export const activeOpacity = 0.6
export const activeCardOpacity = 0.8

// export function maxWidth() {
//   const window = useWindowDimensions()
//   return Math.min(window.width - (2 * cardMarginHorizontal), 768)
// }

// export function maxContentWidth() {
//   const window = useWindowDimensions()
//   return Math.min(window.width - (2 * cardMarginHorizontal) - 40, 768)
// }

export const maxContentWidth = Math.min(Dimensions.get("window").width, 768)