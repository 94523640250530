import AsyncStorage from "@react-native-async-storage/async-storage"
import { DefaultTheme, NavigationContainer } from "@react-navigation/native"
import { createStackNavigator, TransitionPresets } from "@react-navigation/stack"
import * as Analytics from "expo-firebase-analytics"
import * as ScreenOrientation from "expo-screen-orientation"
import { checkForUpdateAsync, fetchUpdateAsync, reloadAsync } from "expo-updates"
import moment from "moment-timezone"
import { path, prop } from "ramda"
import React, { useContext, useEffect, useRef, useState } from "react"
import { AppState, Platform, View } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import MapInfoDetail from "../components/MapInfoDetail"
import configureStore from "../model/configureStore"
import { fetchDataForRoute, loadAsyncStorage } from "../model/primaryDataActions"
import LinkingConfiguration from "../navigation/LinkingConfiguration"
import MainNavigator from "../navigation/MainNavigation"
import AccountConnectionPromptScreen from "../screens/AccountConnectionPromptScreen"
import AchievementDetailScreen from "../screens/AchievementDetailScreen"
import ActionsInfoScreen from "../screens/ActionsInfoScreen"
import AddressVerificationScreen from "../screens/AddressVerificationScreen"
import DeleteAccountScreen from "../screens/DeleteAccountScreen"
import DonationsDetailScreen from "../screens/DonationsDetailScreen.js"
import EditProfileScreen from "../screens/EditProfileScreen"
import EnrollmentCompleteScreen from "../screens/EnrollmentCompleteScreen"
import LicensesScreen from "../screens/LicensesScreen.js"
import LocationsScreen from "../screens/LocationsScreen"
import MapScreen from "../screens/MapScreen"
import NotificationsScreen from "../screens/NotificationsScreen"
import PaymentPrefsDetailScreen from "../screens/PaymentPrefsDetailScreen"
import ProfileScreen from "../screens/ProfileScreen"
import ReferralDetailScreen from "../screens/ReferralDetailScreen"
import UniversityCourseScreen from "../screens/UniversityCourseScreen"
import UniversityScreen from "../screens/UniversityScreen"
import AppConstants, { ScreenNames } from "../shared/AppConstants"
import { baseHeaderStyle, detailOptions } from "../shared/CommonStyles.js"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import { setDefaultTimeZone } from "../shared/Utils"
import CloseButton from "./CloseButton"

if (Platform.OS !== "web") {
    Adjust = require("react-native-adjust").Adjust
    AdjustConfig = require("react-native-adjust").AdjustConfig
    AdjustEvent = require("react-native-adjust").AdjustEvent
}

const { store, persistor } = configureStore()

const navTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: "transparent",
    },
}

const Stack = createStackNavigator()

const checkOTA = async () => {
    if (Platform.OS == "web") {
        // Skip this check.
        console.log("Skipping update check because we're on web")
        return
    }

    console.log("Checking for OTA updates")
    try {
        const { isAvailable } = await checkForUpdateAsync()
        if (isAvailable) {
            console.log("Updates available")
            const { isNew, manifest } = await fetchUpdateAsync()
            if (isNew) {
                reloadAsync()
            }
        } else {
            console.log("No updates available, no error")
        }
    } catch (error) {
        // Do nothing. When this fails, it does not impact use.
        console.log("Error while updating: " + error)
    }
}

const MapStack = createStackNavigator()
function MapStackScreen() {
    return (
        <MapStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <MapStack.Screen name={ScreenNames.MapScreen.routeName} component={MapScreen} />
            <MapStack.Screen name={ScreenNames.MapInfoDetail.routeName} component={MapInfoDetail} />
        </MapStack.Navigator>
    )
}

const ProfileStack = createStackNavigator()
function ProfileStackScreen() {
    const { theme } = useContext(ThemeContext)

    return (
        <ProfileStack.Navigator
            screenOptions={{
                // headerShown: false,
                headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
            }}
        >
            <ProfileStack.Screen
                name={ScreenNames.Profile.routeName}
                component={ProfileScreen}
                options={{
                    ...detailOptions,
                    title: null,
                    headerLeft: (props) => <CloseButton {...props} />,
                }}
            />
            <ProfileStack.Screen
                name={ScreenNames.EditProfile.routeName}
                component={EditProfileScreen}
                options={{ ...detailOptions, title: ScreenNames.EditProfile.title }}
            />
            <ProfileStack.Screen
                name={ScreenNames.PaymentPrefsDetail.routeName}
                component={PaymentPrefsDetailScreen}
                options={{ ...detailOptions, title: ScreenNames.PaymentPrefsDetail.title }}
            />
            <ProfileStack.Screen
                name={ScreenNames.DonationsDetail.routeName}
                component={DonationsDetailScreen}
                options={{ ...detailOptions, title: ScreenNames.DonationsDetail.title }}
            />
            <ProfileStack.Screen
                name={ScreenNames.Licenses.routeName}
                component={LicensesScreen}
                options={{ ...detailOptions, title: ScreenNames.Licenses.title }}
            />
            <ProfileStack.Screen name={ScreenNames.DeleteAccount.routeName} component={DeleteAccountScreen} options={{ ...detailOptions, title: "" }} />
        </ProfileStack.Navigator>
    )
}

export default function MainApp(props) {
    const appState = useRef(AppState.currentState)
    const [appStateVisible, setAppStateVisible] = useState(appState.current)
    const confettiView = useRef()
    const routeNameRef = useRef()
    const navigationRef = useRef()
    const dispatch = useDispatch()
    const { theme } = useContext(ThemeContext)
    const accountsList = useSelector((state) => state.primaryData.accountsData) || []

    let initialUserAuthComplete = false
    let lastDepartureFromHomeStack = null
    let lastDataFetch = {}

    useEffect(() => {
        if (Platform.OS !== "web") {
            // Lock the screen to portrait.
            ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
        }

        // Check for app updates
        if (__DEV__ || Platform.OS == "web") {
            console.log("Skipping OTA checks because we're on a development or web build")
        } else {
            checkOTA()
        }

        // Load async state into Redux
        loadAsyncStorage(store)

        // Start listening for authentication to complete so we know what user's data we're fetching.
        const authListenerUnsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user != null) {
                // We only want to mark this once.
                if (!initialUserAuthComplete) {
                    console.log("MainApp's initial auth state changed in a way we care about")
                    initialUserAuthComplete = true
                    // We've just changed our auth state for the first time. Fetch data.
                    if (routeNameRef.current != null) {
                        console.log("Just changed MainApp's auth complete state for the first time, about to fetch")
                        dispatch(fetchDataForRoute(routeNameRef.current))
                        lastDataFetch[routeNameRef.current] = moment()
                    } else {
                        console.log("Main app's auth is complete, but nav isn't ready yet. Skipping data fetch")
                    }
                }
            }
        })

        if (Platform.OS !== "web") {
            // Set up Adjust
            const adjustConfig = new AdjustConfig(AppConstants.analytics.adjustToken, AdjustConfig.EnvironmentProduction)
            // TEST ONLY: Lots of logging
            // adjustConfig.setLogLevel(AdjustConfig.LogLevelVerbose);   // enable all logging

            // Start the Adjust instance
            Adjust.create(adjustConfig)

            // We need to manually request tracking authorization on iOS.
            Adjust.requestTrackingAuthorizationWithCompletionHandler(function (status) {
                switch (status) {
                    case 0:
                        // ATTrackingManagerAuthorizationStatusNotDetermined case
                        Adjust.trackEvent(new AdjustEvent(AppConstants.analytics.attNotDetermined))
                        console.log("ATT: not determined")
                        break
                    case 1:
                        // ATTrackingManagerAuthorizationStatusRestricted case
                        Adjust.trackEvent(new AdjustEvent(AppConstants.analytics.attRestricted))
                        console.log("ATT: restricted")
                        break
                    case 2:
                        // ATTrackingManagerAuthorizationStatusDenied case
                        Adjust.trackEvent(new AdjustEvent(AppConstants.analytics.attDenied))
                        console.log("ATT: denied")
                        break
                    case 3:
                        // ATTrackingManagerAuthorizationStatusAuthorized case
                        Adjust.trackEvent(new AdjustEvent(AppConstants.analytics.attAuthorized))
                        console.log("ATT: authorized")
                        break
                }
            })
        }

        // Set our default display timezone based on what we have stored in AsyncStorage
        AsyncStorage.getItem(AppConstants.currentPropertyTimeZone).then((timeZone) => {
            // If we've stored a time zone, try to load it into Redux
            if (timeZone != null) {
                console.log("Setting time zone from stored value")
                setDefaultTimeZone(timeZone, store)
            } else {
                // // We haven't set a time zone yet. Try to get one from our current account info
                AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((savedAccountId) => {
                    let matchingAccount = accountsList.find((possibleItem) => {
                        const { accountId = "" } = possibleItem
                        return accountId == savedAccountId
                    })
                    if (matchingAccount != null) {
                        // Update the app-wide display timezone.
                        const newTimeZone = path(["building", "timezone"], matchingAccount)
                        if (newTimeZone != null) {
                            console.log("Didn't have a stored timezone but did have one in the stored data")
                            setDefaultTimeZone(newTimeZone, store)
                        }
                    }
                })
            }
        })

        const appStateChangeListener = AppState.addEventListener("change", handleAppStateChange)

        // Wait long enough to figure out what screen we're on, then send the initial screen to analytics.
        // (React Navigation doesn't consider the initial render to be a state change, so we have to send this one manually.)
        setTimeout(() => {
            navigationStateDidChange(true)
        }, 1000)

        return () => {
            if (Platform.OS !== "web") {
                Adjust.componentWillUnmount()
            }
            if (appStateChangeListener != null) {
                appStateChangeListener.remove()
            }
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    const modalOptions = {
        headerStyle: {
            elevation: 0, // remove shadow on Android
            shadowOpacity: 0, // remove shadow on iOS
            borderWidth: 0,
            backgroundColor: theme.background,
        },
        headerTitleStyle: TextStyles.h3,
        headerTitleAlign: "center",
        headerBackTitleVisible: false,
        headerLeft: (props) => <CloseButton {...props} />,
        headerTintColor: theme.textPrimary,
        presentation: "modal",
    }

    const handleAppStateChange = (nextAppState) => {
        if (appState.current.match(/inactive|background/) && nextAppState === "active") {
            console.log("App has come to the foreground!")
            // Check for app updates
            checkOTA()

            updateDataForCurrentRoute()
        }

        appState.current = nextAppState
        setAppStateVisible(appState.current)
        // console.log("AppState:", appState.current)
    }

    const updateDataForCurrentRoute = () => {
        if (routeNameRef.current == null) {
            // Nothing to do
            console.log("Asked to update data for current route, but there isn't one. Skipping.")
            return
        }

        let currentRouteName = routeNameRef.current
        // Fetch any data we need to for the new route.
        if (auth.currentUser != null) {
            console.log("MainApp about to request a data fetch for " + currentRouteName)
            if (lastDataFetch[currentRouteName] == null || lastDataFetch[currentRouteName].isBefore(moment().subtract(1, "minutes"))) {
                dispatch(fetchDataForRoute(currentRouteName))
                lastDataFetch[currentRouteName] = moment()
            } else {
                console.log("Last data fetch for " + currentRouteName + " was too recent, skipping")
            }
        } else {
            console.log("MainApp asked to update data for current route, but user auth isn't complete yet. Skipping")
        }
    }

    const navigationStateDidChange = (forceSend = false) => {
        if (navigationRef.current == null) {
            console.log("Asked to change nav state, but no stored ref available. Skipping.")
            return
        }

        const previousRouteName = routeNameRef.current
        const currentRouteName = navigationRef.current.getCurrentRoute().name

        console.log("Nav state changed to " + currentRouteName)

        if (forceSend || previousRouteName !== currentRouteName) {
            // console.log("Screen changed to "+currentRouteName)
            Analytics.logEvent("screen_view", { screen_name: currentRouteName })
        }

        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName

        updateDataForCurrentRoute()

        let homeStackRoutes = ["Home", "CarbonDetail", "InfoDetailHomeStack", "History"]
        if (homeStackRoutes.includes(previousRouteName) && !homeStackRoutes.includes(currentRouteName)) {
            // If we're leaving any part of the Home stack, mark our time of departure.
            lastDepartureFromHomeStack = moment()
        } else if (!homeStackRoutes.includes(previousRouteName) && homeStackRoutes.includes(currentRouteName)) {
            // Otherwise, if we're returning FROM outside the home stack, see if we need to go back to the top.
            if (lastDepartureFromHomeStack != null && lastDepartureFromHomeStack.isBefore(moment().subtract(1, "minutes"))) {
                navigationRef.current?.navigate(ScreenNames.HomeTab.routeName, { screen: ScreenNames.Home.routeName })
            }
        }
    }

    return (
        <View style={[{ flex: 1, backgroundColor: theme.background }]}>
            {/* {Platform.OS === "ios" && <StatusBar barStyle="dark-content" />} */}
            <NavigationContainer
                ref={navigationRef}
                onReady={() => {
                    console.log("Ready")
                    routeNameRef.current = navigationRef.current.getCurrentRoute().name
                    // Only do this if we already have a user. Otherwise, do this fetch once the user auth completes.
                    if (auth.currentUser != null) {
                        dispatch(fetchDataForRoute(routeNameRef.current))
                        lastDataFetch[routeNameRef.current] = moment()
                    }
                }}
                onStateChange={navigationStateDidChange}
                linking={LinkingConfiguration}
                theme={navTheme}
                documentTitle={{
                    formatter: (options, route) => {
                        const routeName = route?.name
                        let displayName = routeName
                        if (options?.title != null && options?.title.length > 0) {
                            displayName = options.title
                        } else {
                            const derivedTitle = prop("title", ScreenNames[routeName])
                            if (derivedTitle != null && derivedTitle.length > 0) {
                                displayName = derivedTitle
                            }
                        }
                        return `${displayName} - ${i18n.t("gridRewards")}`
                    },
                }}
            >
                {/* Full screen modals */}
                <Stack.Navigator screenOptions={modalOptions}>
                    <Stack.Screen name={ScreenNames.Root.routeName} component={MainNavigator} options={{ headerShown: false }} />

                    <Stack.Screen name={ScreenNames.Map.routeName} component={MapStackScreen} />
                    <Stack.Screen
                        name={ScreenNames.ReferralDetail.routeName}
                        component={ReferralDetailScreen}
                        options={{ title: ScreenNames.ReferralDetail.title }}
                    />
                    <Stack.Screen name={ScreenNames.EditProfileModal.routeName} component={EditProfileScreen} />
                    <Stack.Screen
                        name={ScreenNames.Notifications.routeName}
                        component={NotificationsScreen}
                        options={{ title: ScreenNames.Notifications.title }}
                    />
                    <Stack.Screen name={ScreenNames.Locations.routeName} component={LocationsScreen} options={{ title: ScreenNames.Locations.title }} />

                    <Stack.Screen
                        name={ScreenNames.ProfileStack.routeName}
                        component={ProfileStackScreen}
                        options={{
                            headerShown: false,
                        }}
                    />

                    <Stack.Screen name={ScreenNames.ActionsInfo.routeName} component={ActionsInfoScreen} options={{ title: ScreenNames.ActionsInfo.title }} />

                    <Stack.Screen
                        name={ScreenNames.AchievementDetail.routeName}
                        component={AchievementDetailScreen}
                        options={{
                            title: null,
                            headerTransparent: true,
                            headerLeft: (props) => <CloseButton iconSource={rawThemes.dark.icons.close} {...props} />,
                            presentation: "transparentModal",
                        }}
                    />

                    <Stack.Screen
                        name={ScreenNames.EnrollmentComplete.routeName}
                        component={EnrollmentCompleteScreen}
                        options={{
                            presentation: "transparentModal",
                            gestureEnabled: true,
                            headerShown: false,
                            ...TransitionPresets.ModalPresentationIOS,
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.University.routeName}
                        component={UniversityScreen}
                        options={{
                            title: ScreenNames.University.title,
                            presentation: "modal",
                            // Make this full-screen.
                            ...TransitionPresets.ModalSlideFromBottomIOS,
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.UniversityCourse.routeName}
                        component={UniversityCourseScreen}
                        options={{
                            presentation: "modal",
                            headerShown: false,
                            ...TransitionPresets.ModalPresentationIOS,
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.AccountConnectionPrompt.routeName}
                        component={AccountConnectionPromptScreen}
                        options={{
                            title: ScreenNames.AccountConnectionPrompt.title,
                            presentation: "modal",
                            ...TransitionPresets.ModalPresentationIOS,
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.AddressVerification.routeName}
                        component={AddressVerificationScreen}
                        options={{
                            presentation: "transparentModal",
                            gestureEnabled: true,
                            headerShown: false,
                            ...TransitionPresets.ModalPresentationIOS,
                        }}
                    />
                </Stack.Navigator>
            </NavigationContainer>
        </View>
    )
}
