import { useNavigation } from "@react-navigation/native"
import { prop } from "ramda"
import * as React from "react"
import { Text, View } from "react-native"
import { ScrollView } from "react-native-gesture-handler"
import Button from "../components/Button"
import UniversityCoursePreviewCard from "../components/cards/UniversityCoursePreviewCard"
import { ScreenNames } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { UniversityContent } from "../model/UniversityContent"

export default function UniversityScreen(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    return (
        <ScrollView style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]} contentContainerStyle={CommonStyles.mainScreenContent}>
            {UniversityContent.map((item, index) => {
                return <UniversityCoursePreviewCard key={prop("id", item) + index.toString()} course={item} />
            })}
        </ScrollView>
    )
}
