import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Platform, TouchableOpacity, View } from "react-native"
import { activeOpacity } from "../shared/Layout"
import { ThemeContext } from "../shared/ThemeContext"

export default function CloseButton(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    const { iconSource, showBackgroundCircle = false } = props

    const onPressDefault = () => {
        if (Platform.OS === "web") {
            window.history.back()
        } else {
            navigation.goBack()
        }
    }

    return (
        <TouchableOpacity
            onPress={props.onPress ?? onPressDefault}
            activeOpacity={activeOpacity}
            style={[{ paddingLeft: 20, paddingRight: 8, justifyContent: "center", alignItems: "center" }, props.containerStyle]}
        >
            {showBackgroundCircle ? (
                <View style={{ justifyContent: "center", alignItems: "center", width: 22, height: 22, backgroundColor: theme.surface1, borderRadius: 1000 }}>
                    <Image source={iconSource ?? theme.icons.close} style={[{ width: 16, height: 16, opacity: 0.5 }, props.imageStyle]} />
                </View>
            ) : (
                <Image source={iconSource ?? theme.icons.close} style={[{ width: 24, height: 24 }, props.imageStyle]} />
            )}
        </TouchableOpacity>
    )
}
