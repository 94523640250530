import React, { useEffect, useState, useCallback } from "react"
import { View, Animated } from "react-native"
import { ThemeContext } from "../shared/ThemeContext"

export default function ProgressBar(props) {
    const { theme } = React.useContext(ThemeContext)
    const [width] = useState(new Animated.Value(0))

    const {
        progress,
        animated,
        height = 4,
        trackColor = theme.tealTranslucent,
        barColor = theme.teal,
        progressDuration = 500,
        onProgressChangeCompletion,
    } = props

    const startAnimation = useCallback(() => {
        Animated.timing(width, {
            duration: animated ? progressDuration : 0,
            toValue: progress,
            useNativeDriver: false,
        }).start(() => {
            if (onProgressChangeCompletion != null) {
                onProgressChangeCompletion()
            }
        })
    }, [animated, onProgressChangeCompletion, progress, progressDuration, width])

    const stopAnimation = useCallback(() => {
        Animated.timing(width, {
            duration: 200,
            toValue: 0,
            useNativeDriver: true,
            isInteraction: false,
        }).start()
    }, [width])

    useEffect(() => {
        if (typeof progress === "number") {
            startAnimation()
        } else {
            stopAnimation()
        }
    }, [progress, startAnimation, stopAnimation])

    return (
        <Animated.View style={[{ width: "100%", height: height, backgroundColor: trackColor }, props.containerStyle]}>
            <Animated.View
                style={[
                    { height: "100%", backgroundColor: barColor, borderTopRightRadius: 1000, borderBottomRightRadius: 1000 },
                    props.barStyle,
                    {
                        width: width.interpolate({
                            inputRange: [0, 100],
                            outputRange: ["0%", "100%"],
                            extrapolate: "clamp",
                        }),
                    },
                ]}
            />
        </Animated.View>
    )
}
