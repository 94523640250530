import { useIsFocused, useNavigation } from "@react-navigation/native"
import * as WebBrowser from "expo-web-browser"
import _ from "lodash"
import { path, prop } from "ramda"
import React, { useEffect, useState } from "react"
import { ActivityIndicator, Image, Platform, ScrollView, StyleSheet, Text, View, TouchableOpacity } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useDispatch, useSelector } from "react-redux"
import CloseButton from "../components/CloseButton"
import MapLegend from "../components/MapLegend"
import { fetchMapData } from "../model/primaryDataActions"
import { CarbonComparison, CustomMapStyle, ScreenNames } from "../shared/AppConstants"
import Colors from "../shared/Colors"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
// import "intl-pluralrules"

if (Platform.OS === "android") {
    // only android needs polyfill
    require("intl") // import intl object
    require("intl/locale-data/jsonp/en-US") // load the required locale details
}

export default function MapScreen(props) {
    return null

    // const navigation = useNavigation()
    // const dispatch = useDispatch()
    // const insets = useSafeAreaInsets()
    // const isFocused = useIsFocused()
    // // TODO: Eventually we're going to want to be able to fetch a default region based on zip code (if it's the NYC area, we get this one, but
    // // if it's in Boston, we show Boston, etc). For now, hardcode this to the NYC area.
    // // const [region, setRegion] = useState({
    // //     latitude: 40.745893,
    // //     longitude: -73.991627,
    // //     latitudeDelta: 0.11,
    // //     longitudeDelta: 0.06,
    // // })

    // let region = {
    //     latitude: 40.745893,
    //     longitude: -73.991627,
    //     latitudeDelta: 0.11,
    //     longitudeDelta: 0.06,
    // }
    // const [spinnerVisible, setSpinnerVisible] = useState(false)

    // useEffect(() => {
    //     navigation.setOptions({
    //         headerTitle: null,
    //         headerTransparent: true,
    //     })

    //     dispatch(fetchMapData(region.latitude, region.longitude, region.latitudeDelta, region.longitudeDelta))
    //     setSpinnerVisible(true)
    // }, [])

    // const mapData = useSelector((state) => state.primaryData.mapData)
    // const [locationsSorted, setLocationsSorted] = useState([])

    // const aggregateRaw = (path(["metadata", "icapAggregate"], mapData) || 0) * 0.3

    // useEffect(() => {
    //     if (mapData != null) {
    //         setSpinnerVisible(false)
    //         const { locations = [] } = mapData || {}
    //         setLocationsSorted(locations.slice().sort((a, b) => (path(["coordinate", "longitude"], a) > path(["coordinate", "longitude"], b) ? 1 : -1)))
    //     } else {
    //         console.log("Got null map data in the map, not hiding the spinner.")
    //     }
    // }, [mapData])

    // const icapThreshold1 = 1
    // const icapThreshold2 = 5
    // const icapThreshold3 = 25

    // // Should we displaying this in megawatts instead of kilowatts?
    // const megawattThreshold = 10000
    // var aggregateAdjusted = aggregateRaw
    // var shouldDisplayMW = false
    // if (aggregateRaw > megawattThreshold) {
    //     aggregateAdjusted = aggregateRaw / 1000
    //     shouldDisplayMW = true
    // }
    // const numberFormatter = new Intl.NumberFormat("en-US", {
    //     maximumFractionDigits: shouldDisplayMW ? 1 : 0,
    // })

    // const multiplierFormatter = new Intl.NumberFormat("en-US", {
    //     maximumSignificantDigits: 3,
    // })

    // var calloutNumberFormatter = new Intl.NumberFormat("en-US", {
    //     maximumSignificantDigits: 3,
    // })

    // const updateData = (newRegion) => {
    //     const { latitude = 0, longitude = 0, latitudeDelta = 1, longitudeDelta = 1 } = newRegion || {}
    //     if (
    //         Math.abs(region.latitude - newRegion.latitude) > newRegion.latitudeDelta * 0.25 ||
    //         Math.abs(region.longitude - newRegion.longitude) > newRegion.longitudeDelta * 0.25 ||
    //         Math.round(region.latitudeDelta * 100000) != Math.round(newRegion.latitudeDelta * 100000) ||
    //         Math.round(region.longitudeDelta * 100000) != Math.round(newRegion.longitudeDelta * 100000)
    //     ) {
    //         // console.log("Map region changed, updating map data with new region: " + JSON.stringify(newRegion))
    //         if (spinnerVisible == false) {
    //             setSpinnerVisible(true)
    //         }
    //         dispatch(fetchMapData(latitude, longitude, latitudeDelta, longitudeDelta))
    //         region = newRegion
    //     } else {
    //         // console.log("Asked to update map, but region hadn't changed enough")
    //     }
    // }

    // const updateDataLimited = _.throttle(updateData, 500, { trailing: false })

    // const clusterSize = 47

    // if (Platform.OS == "web") {
    //     // Not supported on web currently.
    //     return null
    // }

    // if (Platform.OS !== "web") {
    //     Marker = require("react-native-maps").Marker
    //     Callout = require("react-native-maps").Callout
    //     MapView = require("react-native-map-clustering").default
    // }

    // // console.log("Should be rendering map now.")
    // return (
    //     <View style={[CommonStyles.container, { backgroundColor: Colors.black }]}>
    //         {/* renderCluster example: https://github.com/venits/react-native-map-clustering/issues/128#issuecomment-575728976 */}
    //         <MapView
    //             style={styles.map}
    //             customMapStyle={Platform.OS == "android" ? CustomMapStyle : null} // This is ignored on iOS
    //             mapType={Platform.OS == "android" ? "standard" : "mutedStandard"} // Android doesn't have a "mutedStandard" style
    //             showsPointsOfInterest={false}
    //             showsBuildings={false}
    //             zoomTapEnabled={false}
    //             rotateEnabled={false}
    //             moveOnMarkerPress={false} //Android only
    //             initialRegion={region}
    //             onRegionChangeComplete={updateData}
    //             // spiralEnabled={false}
    //             // maxZoom={13}
    //             // maxZoomLevel={15.7}
    //             minPoints={4}
    //             radius={27}
    //             spiderLineColor={"#fff5"}
    //             renderCluster={(cluster) => {
    //                 const { id, geometry, onPress, properties } = cluster
    //                 const points = properties.point_count

    //                 return (
    //                     <Marker
    //                         key={`cluster-${id}`}
    //                         coordinate={{
    //                             longitude: geometry.coordinates[0],
    //                             latitude: geometry.coordinates[1],
    //                         }}
    //                         onPress={onPress}
    //                         tracksViewChanges={false}
    //                     >
    //                         <View style={{ width: clusterSize, height: clusterSize, justifyContent: "center", alignItems: "center" }}>
    //                             <Image
    //                                 source={require("../assets/images/mapNodeCluster.png")}
    //                                 style={{ position: "absolute", width: "100%", height: "100%" }}
    //                             />
    //                             <Text style={[TextStyles.content3Strong, { color: Colors.powerPlantText }]}>{points}</Text>
    //                         </View>
    //                     </Marker>
    //                 )
    //             }}
    //         >
    //             {isFocused &&
    //                 locationsSorted.map((marker, index) => {
    //                     let icap = prop("icap", marker) || 0
    //                     let coordinate = {
    //                         latitude: marker.coordinate.latitude,
    //                         longitude: marker.coordinate.longitude,
    //                     }

    //                     // What graphic do we use?
    //                     let imageSource = require("../assets/images/mapNode1.png")
    //                     // How big should it be?
    //                     let iconSize = 25
    //                     if (icap > icapThreshold3) {
    //                         imageSource = require("../assets/images/mapNode4.png")
    //                         iconSize = 40
    //                     } else if (icap > icapThreshold2) {
    //                         imageSource = require("../assets/images/mapNode3.png")
    //                         iconSize = 34
    //                     } else if (icap > icapThreshold1) {
    //                         imageSource = require("../assets/images/mapNode2.png")
    //                         iconSize = 31
    //                     }

    //                     // What color text should we use?
    //                     let textColor = Colors.darkPurple100

    //                     let propertyType = prop("propertyType", marker)
    //                     let propertyTypeString = null
    //                     switch (propertyType) {
    //                         case "smallResidential":
    //                             propertyTypeString = i18n.t("mapSmallResidential")
    //                             break
    //                         case "largeResidential":
    //                             propertyTypeString = i18n.t("mapLargeResidential")
    //                             break
    //                         case "smallCommercial":
    //                             propertyTypeString = i18n.t("mapSmallCommercial")
    //                             break
    //                         case "largeCommercial":
    //                             propertyTypeString = i18n.t("mapLargeCommercial")
    //                             break
    //                         default:
    //                             break
    //                     }

    //                     // Draw code
    //                     return (
    //                         <Marker
    //                             key={icap.toString() + index.toString()}
    //                             coordinate={coordinate}
    //                             stopPropagation={true}
    //                             tracksViewChanges={Platform.OS == "android" ? !spinnerVisible : false}
    //                         >
    //                             <View style={{ width: iconSize, height: iconSize }}>
    //                                 <Image source={imageSource} style={{ width: "100%", height: "100%" }} resizeMode={"stretch"} />
    //                             </View>
    //                             <Callout>
    //                                 {/* This has a default callout wrapper with about 12pts of padding all the way around */}
    //                                 <View style={{ minWidth: 300, padding: Platform.OS == "android" ? 12 : 0 }}>
    //                                     <Text
    //                                         style={[
    //                                             TextStyles.content4,
    //                                             { color: Colors.darkPurple100, marginHorizontal: Platform.OS == "android" ? 0 : 8, marginBottom: 8 },
    //                                         ]}
    //                                     >
    //                                         {i18n.t("mapCalloutHeader")}
    //                                     </Text>
    //                                     <View style={{ flexDirection: "row" }}>
    //                                         {/* There's a remarkable bug here that prevents Android from displaying images in callouts: https://github.com/react-native-maps/react-native-maps/issues/2633 */}
    //                                         {Platform.OS != "android" && (
    //                                             <View style={{ width: 63, height: 63, justifyContent: "center", alignItems: "center", marginRight: 10 }}>
    //                                                 <Image source={imageSource} style={{ position: "absolute" }} />
    //                                             </View>
    //                                         )}
    //                                         <View style={{ justifyContent: "center" }}>
    //                                             <Text style={[TextStyles.content1Strong, { color: textColor }]}>
    //                                                 {calloutNumberFormatter.format((icap * 0.3).toString()) + " kW"}
    //                                             </Text>
    //                                             {propertyTypeString != null && (
    //                                                 <Text style={[TextStyles.content4, { color: textColor, opacity: 0.6 }]}>{propertyTypeString}</Text>
    //                                             )}
    //                                             <Text style={[TextStyles.content4, { color: textColor, opacity: 0.6 }]}>
    //                                                 {i18n.t("mapApproximateLocation")}
    //                                             </Text>
    //                                         </View>
    //                                     </View>
    //                                 </View>
    //                             </Callout>
    //                         </Marker>
    //                     )
    //                 })}
    //         </MapView>
    //         <View style={[styles.header]}>
    //             <View style={[styles.headerUpperContent, { paddingTop: insets.top + 8, alignItems: "center" }]}>
    //                 <Text style={[TextStyles.h4, { color: Colors.powerPlantPurple, marginTop: 2 }]}>{i18n.t("virtualPowerPlant")}</Text>
    //                 <Text style={[TextStyles.content3, { color: Colors.powerPlantText, textAlign: "center", marginTop: 10, marginHorizontal: 60 }]}>
    //                     {i18n.t("mapHeaderSubtitle")}
    //                 </Text>
    //                 <Text style={styles.headerTextLarge}>{numberFormatter.format(aggregateAdjusted)}</Text>
    //                 <Text style={styles.headerTextUnits}>{shouldDisplayMW ? i18n.t("megawatts") : i18n.t("kilowatts")}</Text>
    //             </View>
    //             <Image style={{ width: "100%", opacity: 0.8 }} source={require("../assets/images/headerFooterMask.png")} />
    //             {spinnerVisible && (
    //                 <View style={{ alignSelf: "center" }}>
    //                     <ActivityIndicator size={"large"} animating={true} color={Colors.white} />
    //                 </View>
    //             )}
    //         </View>
    //         <CloseButton usePurpleTint={true} containerStyle={{ position: "absolute", marginTop: insets.top, marginLeft: 8 }} />
    //         <TouchableOpacity
    //             style={{ position: "absolute", marginTop: insets.top, right: 10, height: 37, width: 44, justifyContent: "center", alignItems: "center" }}
    //             onPress={() => {
    //                 navigation.navigate(ScreenNames.MapInfoDetail.routeName)
    //             }}
    //         >
    //             <Image source={require("../assets/images/infoButton.png")} style={{ tintColor: Colors.powerPlantPurple, width: 21, height: 21 }} />
    //         </TouchableOpacity>
    //         <View style={[styles.footer, { bottom: insets.bottom }]} pointerEvents={"box-none"}>
    //             <MapLegend />
    //             <Text style={[TextStyles.content3Strong, styles.footerTitle]}>{i18n.t("mapDetailHeader")}</Text>
    //             <ScrollView
    //                 style={[styles.horizontalScroll, {}]}
    //                 horizontal={true}
    //                 alwaysBounceHorizontal={true}
    //                 showsHorizontalScrollIndicator={false}
    //                 contentContainerStyle={{ paddingRight: 12, overflow: "visible", paddingLeft: 20, alignItems: "flex-start", marginTop: 10 }}
    //             >
    //                 {CarbonComparison.map((comparison, index) => {
    //                     let value = aggregateRaw * comparison.multiplier
    //                     return (
    //                         <TouchableOpacity
    //                             key={comparison.after + index.toString()}
    //                             style={{
    //                                 flexDirection: "row",
    //                                 width: 265,
    //                                 minHeight: 80,
    //                                 alignItems: "center",
    //                                 borderRadius: 8,
    //                                 overflow: "hidden",
    //                                 marginRight: 12,
    //                             }}
    //                             activeOpacity={0.8}
    //                             onPress={() => {
    //                                 WebBrowser.openBrowserAsync(comparison.link)
    //                             }}
    //                         >
    //                             <View style={{ ...StyleSheet.absoluteFillObject, backgroundColor: Colors.black, opacity: 0.8 }} />
    //                             <Image
    //                                 source={comparison.icon}
    //                                 style={{ marginLeft: 12, marginRight: 12, tintColor: Colors.powerPlantText, width: 48, height: 48 }}
    //                             />
    //                             <Text style={[TextStyles.content3, { color: Colors.powerPlantText, flex: 1, marginRight: 12, marginVertical: 12 }]}>
    //                                 {comparison.before + " " + multiplierFormatter.format(value) + " " + comparison.after}
    //                             </Text>
    //                         </TouchableOpacity>
    //                     )
    //                 })}
    //             </ScrollView>
    //         </View>
    //     </View>
    // )
}

const styles = StyleSheet.create({
    map: {
        ...StyleSheet.absoluteFillObject,
    },
    header: {
        position: "absolute",
        alignSelf: "center",
        overflow: "hidden",
        top: 0,
        left: 0,
        right: 0,
    },
    headerUpperContent: {
        padding: 20,
        backgroundColor: "#000000CD",
    },
    headerFooterContent: {
        backgroundColor: "#000000CD",
        height: 50,
    },
    headerTextLarge: {
        fontFamily: "lato-black",
        fontSize: 45,
        color: Colors.powerPlantText,
        marginTop: 12,
        ...Platform.select({
            ios: {
                shadowColor: Colors.blue,
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 1.0,
                shadowRadius: 10,
            },
        }),
    },
    headerTextUnits: {
        fontFamily: "lato-black",
        fontSize: 20,
        color: Colors.powerPlantText,
        ...Platform.select({
            ios: {
                shadowColor: Colors.blue,
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 1.0,
                shadowRadius: 10,
            },
        }),
    },

    footer: {
        position: "absolute",
        alignSelf: "center",
        overflow: "hidden",
        left: 0,
        right: 0,
    },

    footerTitle: {
        color: Colors.powerPlantText,
        marginHorizontal: 20,
        ...Platform.select({
            ios: {
                shadowColor: Colors.black,
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 1.0,
                shadowRadius: 5,
            },
            android: {
                elevation: 3,
            },
        }),
    },
    horizontalScroll: {
        width: "100%",
        overflow: "visible",
        paddingTop: 0,
    },
})
