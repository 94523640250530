import AsyncStorage from "@react-native-async-storage/async-storage"
import { useHeaderHeight } from "@react-navigation/elements"
import { useNavigation } from "@react-navigation/native"
import { createUserWithEmailAndPassword } from "firebase/auth"
import _ from "lodash"
import { path } from "ramda"
import React, { useEffect, useState } from "react"
import { Dimensions, Platform, StyleSheet, Text, TextInput, useWindowDimensions, View } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { ActivityIndicator } from "react-native-paper"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useDispatch, useSelector, useStore } from "react-redux"
import Button from "../components/Button"
import Divider from "../components/Divider"
import ErrorMessageInline from "../components/ErrorMessageInline"
import { checkLocation, setNewAccountCreationPending } from "../model/primaryDataActions"
import AppConstants, { ScreenNames } from "../shared/AppConstants"
import Colors from "../shared/Colors"
import { baseHeaderStyle, CommonStyles, detailOptions } from "../shared/CommonStyles"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { sendBranchEvent } from "../shared/Utils"

if (Platform.OS !== "web") {
    Adjust = require("react-native-adjust").Adjust
    AdjustConfig = require("react-native-adjust").AdjustConfig
    AdjustEvent = require("react-native-adjust").AdjustEvent
}

export default function CreateAccountScreen(props) {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const insets = useSafeAreaInsets()
    const store = useStore()
    const window = useWindowDimensions()
    const { theme } = React.useContext(ThemeContext)
    const headerHeight = useHeaderHeight()

    const availableServices = useSelector((state) => state.primaryData.availableServices)
    const pendingIncomingReferralCode = useSelector((state) => state.primaryData.pendingIncomingReferralCode)

    const { initialEmail, initialPassword } = path(["route", "params"], props) || {}
    const { onboardingComplete } = props

    const [initialLoginStateResolved, setInitialLoginStateResolved] = useState(false)

    const [errorMessage, setErrorMessage] = useState(null)
    const [zipErrorMessage, setZipErrorMessage] = useState(null)
    const [inviteErrorMessage, setInviteErrorMessage] = useState(null)

    const [createAccountPending, setCreateAccountPending] = useState(false)

    const [email, setEmail] = useState(initialEmail)
    const [password, setPassword] = useState(initialPassword)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [zipCode, setZipCode] = useState(null)
    const [inviteCode, setInviteCode] = useState(null)

    const [zipCheckPending, setZipCheckPending] = useState(false)

    const [focusedField, setFocusedField] = useState(null)

    useEffect(() => {
        // FIXME: For some reason, we can't use useContext in the LoginStack containing this component, so we can't set the
        // screen options that require theme context out there. All other stacks work fine... ???
        // For now, set them inside this component.
        navigation.setOptions({ ...detailOptions, headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background }, headerTintColor: theme.textPrimary })

        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged((user) => {
            // Once something loads (with a user or without), we know we can proceed.
            if (!initialLoginStateResolved) {
                setInitialLoginStateResolved(true)
            }
        })
        return () => {
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    // Kick off the create account process
    useEffect(() => {
        if (createAccountPending) {
            // If we're logging in as part of a new account creation, we need to set a Redux flag to tell the
            // system that we should wait a brief while (~1.5s max) to allow Firebase to propagate the new account info to our servers.
            dispatch(setNewAccountCreationPending(true))

            // Start the create account process
            createUserWithEmailAndPassword(auth, email, password)
                .then(function (firebaseUser) {
                    // Log a custom event
                    if (Platform.OS !== "web") {
                        // Tell Adjust that a user logged in.
                        console.log("Created account successfully, telling Adjust")
                        Adjust.trackEvent(new AdjustEvent(AppConstants.analytics.logicalBuildingsAccountCreate))
                    }

                    // Tell Branch
                    const customData = {
                        email: email,
                    }
                    sendBranchEvent(AppConstants.analytics.branchEvents.userLogin, null, customData)
                })
                .catch(function (error) {
                    let errorCode = error.code
                    let errorMessage = error.message
                    console.log("Create account error: " + errorCode + " " + errorMessage)
                    setErrorMessage(errorMessage)
                    setCreateAccountPending(false)
                })
        }
    }, [createAccountPending])

    // Zip code server response changed
    useEffect(() => {
        if (!zipCheckPending) {
            // This isn't a real zip code check, just the array being initialized from memory.
            return
        }

        if (availableServices != null && availableServices.length > 0) {
            // console.log("We've got something available in this zipcode, moving to Login.")
            // Keyboard.dismiss()
            setZipCheckPending(false)
            setZipErrorMessage(null)
        } else {
            setZipCheckPending(false)
            setZipErrorMessage(i18n.t("onboardingLocationError"))
        }
    }, [availableServices])

    const onChangeZipText = (text) => {
        setZipErrorMessage(null)
        let zipCodeTemp = text.trim()
        console.log("About to submit |" + zipCodeTemp + "|")
        setZipCheckPending(true)
        // Store this zipcode in case we need it later (for GridRewards program signup, etc.)
        AsyncStorage.setItem(AppConstants.currentZipCode, zipCodeTemp)
        if (zipCodeTemp != null) {
            if (zipCodeTemp.length < 5) {
                setZipCheckPending(false)
                setZipErrorMessage(i18n.t("zipCodeInvalid"))
            } else {
                // Make sure this is a valid zipcode.
                dispatch(checkLocation(zipCodeTemp))
                // Store it in state for the UI (this happens async, so we can't read this value here unless we want to wait)
                setZipCode(zipCodeTemp)
            }
        }
    }

    const createAccountButtonPressed = () => {
        let validInput = true
        // Top section
        if (email == null || password == null) {
            // console.log("Null email or password, skipping request...")
            setErrorMessage(i18n.t("createAccountErrorMissingFields"))
            validInput = false
        } else if (firstName == null || firstName.length == 0) {
            setErrorMessage(i18n.t("profileFirstNameMissing"))
            validInput = false
        } else if (lastName == null || lastName.length == 0) {
            setErrorMessage(i18n.t("profileLastNameMissing"))
            validInput = false
        } else {
            setErrorMessage(null)
        }

        // Zip code section
        if (zipCode == null || zipCode.length < 5 || availableServices == null || availableServices.length == 0) {
            console.log("Not a valid zip code, skipping request...")
            setZipErrorMessage(i18n.t("zipCodeInvalid"))
            // Make sure we don't accidentally try to create an account with an invalid zip.
            validInput = false
        }

        if (validInput != true) {
            // Something went wrong.
            setCreateAccountPending(false)
            return
        }

        // At this point, we have what looks like a valid attempt to create a new account.

        // Store the profile data we'll need to upload later, and mark this user as needing a profile update.
        AsyncStorage.setItem(AppConstants.profileFirstName, firstName)
        AsyncStorage.setItem(AppConstants.profileLastName, lastName)
        if (inviteCode != null) {
            AsyncStorage.setItem(AppConstants.profileIncomingReferralCode, inviteCode)
        }
        AsyncStorage.setItem(AppConstants.profileUpdateComplete, "false")

        // Try to create a new user account.
        console.log("Trying to create a user")
        setErrorMessage(null)
        setZipErrorMessage(null)
        setCreateAccountPending(true)
    }

    if (!initialLoginStateResolved) {
        // console.log("Login not resolved yet")
        // If we don't know whether we've logged in or not yet, just show a simple-to-render screen.
        return <View style={{ backgroundColor: Colors.darkPurple100 }} />
    }

    let screenHeight = Dimensions.get("window").height - headerHeight

    return (
        <View style={{ backgroundColor: theme.background }}>
            <KeyboardAwareScrollView
                style={[{ height: screenHeight, backgroundColor: theme.background }]}
                contentContainerStyle={CommonStyles.mainScreenContent}
                showsVerticalScrollIndicator={false}
                alwaysBounceVertical={true}
                extraScrollHeight={50}
                keyboardOpeningTime={1}
                keyboardShouldPersistTaps={"handled"}
            >
                <View style={{ marginHorizontal: 32, paddingTop: 24 }}>
                    <View>
                        <Text style={[TextStyles.h2, { marginBottom: 32, color: theme.textPrimary }]}>{i18n.t("createGridRewardsAccount")}</Text>
                    </View>

                    {errorMessage != null && <ErrorMessageInline text={errorMessage} />}

                    <View style={[styles.inputFieldContainer, { marginBottom: 16 }]}>
                        <TextInput
                            placeholder={i18n.t("email")}
                            placeholderTextColor={theme.textHint}
                            underlineColorAndroid={"transparent"}
                            returnKeyType={"next"}
                            autoCorrect={false}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.inputField,
                                { color: theme.textPrimary, borderColor: focusedField == "email" ? theme.textSecondary : theme.border },
                            ]}
                            defaultValue={initialEmail}
                            onChangeText={(text) => setEmail(text ? text.trim() : null)}
                            autoComplete="username-new"
                            textContentType="username"
                            onFocus={() => setFocusedField("email")}
                        />
                    </View>
                    <View style={[styles.inputFieldContainer, { marginBottom: 16 }]}>
                        <TextInput
                            placeholder={i18n.t("password")}
                            placeholderTextColor={theme.textHint}
                            secureTextEntry={true}
                            underlineColorAndroid={"transparent"}
                            returnKeyType={"next"}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.inputField,
                                { color: theme.textPrimary, borderColor: focusedField == "password" ? theme.textSecondary : theme.border },
                            ]}
                            defaultValue={initialPassword}
                            onChangeText={(text) => setPassword(text ? text.trim() : null)}
                            autoComplete="password-new"
                            textContentType="newPassword"
                            onFocus={() => setFocusedField("password")}
                        />
                    </View>
                    <View style={[styles.inputFieldContainer, { marginBottom: 16 }]}>
                        <TextInput
                            placeholder={i18n.t("firstName")}
                            placeholderTextColor={theme.textHint}
                            underlineColorAndroid={"transparent"}
                            returnKeyType={"next"}
                            autoCorrect={false}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.inputField,
                                { color: theme.textPrimary, borderColor: focusedField == "firstName" ? theme.textSecondary : theme.border },
                            ]}
                            onChangeText={(text) => setFirstName(text ? text.trim() : null)}
                            autoComplete="name-given"
                            textContentType="givenName"
                            onFocus={() => setFocusedField("firstName")}
                        />
                    </View>
                    <View style={[styles.inputFieldContainer, {}]}>
                        <TextInput
                            placeholder={i18n.t("lastName")}
                            placeholderTextColor={theme.textHint}
                            underlineColorAndroid={"transparent"}
                            returnKeyType={"next"}
                            autoCorrect={false}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.inputField,
                                { color: theme.textPrimary, borderColor: focusedField == "lastName" ? theme.textSecondary : theme.border },
                            ]}
                            onChangeText={(text) => setLastName(text ? text.trim() : null)}
                            autoComplete="name-family"
                            textContentType="familyName"
                            onFocus={() => setFocusedField("lastName")}
                        />
                    </View>

                    {/* Zip code */}
                    <Divider style={{ marginVertical: 32 }} />
                    <Text style={[TextStyles.body2, { color: theme.textSecondary, marginBottom: 16 }]}>{i18n.t("zipCodeExplanation")}</Text>
                    {zipErrorMessage != null && <ErrorMessageInline text={zipErrorMessage} />}
                    <View style={{ justifyContent: "center" }}>
                        <View style={[styles.inputFieldContainer, {}]}>
                            <TextInput
                                // textAlign="center"
                                keyboardType={"numbers-and-punctuation"}
                                returnKeyType={"next"}
                                placeholder={i18n.t("onboardingLocationPlaceholder")}
                                placeholderTextColor={theme.textHint}
                                underlineColorAndroid={"transparent"}
                                style={[
                                    TextStyles.body2,
                                    styles.inputField,
                                    { color: theme.textPrimary, borderColor: focusedField == "zipCode" ? theme.textSecondary : theme.border },
                                ]}
                                onChangeText={_.debounce(onChangeZipText, 1000)}
                                autoComplete="postal-code"
                                textContentType="postalCode"
                                onFocus={() => setFocusedField("zipCode")}
                            />
                        </View>
                        {zipCheckPending && (
                            <ActivityIndicator size={"small"} animating={true} color={theme.textSecondary} style={{ position: "absolute", right: 16 }} />
                        )}
                    </View>

                    {/* Invite */}
                    <Divider style={{ marginVertical: 32 }} />
                    <Text style={[TextStyles.body2, { color: theme.textSecondary, marginBottom: 8 }]}>{i18n.t("inviteExplanation")}</Text>
                    <Text style={[TextStyles.caption, { color: theme.textHint, marginBottom: 16 }]}>{i18n.t("inviteExplanationSubtitle")}</Text>

                    {inviteErrorMessage != null && <ErrorMessageInline text={inviteErrorMessage} />}
                    <View style={{ justifyContent: "center" }}>
                        <View style={[styles.inputFieldContainer, { marginBottom: 32 }]}>
                            <TextInput
                                textContentType="none"
                                placeholder={i18n.t("invitePlaceholder")}
                                placeholderTextColor={theme.textHint}
                                underlineColorAndroid={"transparent"}
                                defaultValue={pendingIncomingReferralCode}
                                style={[
                                    TextStyles.body2,
                                    styles.inputField,
                                    { color: theme.textPrimary, borderColor: focusedField == "referral" ? theme.textSecondary : theme.border },
                                ]}
                                onChangeText={(text) => setInviteCode(text ? text.trim() : null)}
                                onFocus={() => setFocusedField("referral")}
                            />
                        </View>
                    </View>

                    {/* Buttons */}
                    {/* Create account */}
                    <Button
                        title={i18n.t("createAccount")}
                        onPress={createAccountButtonPressed}
                        buttonTintColor={theme.purple}
                        contentTintColor={theme.textLight}
                        spinnerTintColor={theme.textLight}
                        key={"createAccountButton"}
                        containerStyle={{ marginBottom: 16, opacity: createAccountPending ? 0.6 : 1.0 }}
                        showSpinner={createAccountPending}
                    />
                    {/* Back to login */}
                    <Button
                        type="ghost"
                        contentTintColor={theme.textSecondary}
                        onPress={() => {
                            navigation.navigate(ScreenNames.Login.routeName)
                        }}
                        title={i18n.t("alreadyHaveAnAccountPrompt")}
                        key={"backToLoginButton"}
                    />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    inputFieldContainer: {
        height: 50,
    },

    inputField: {
        flex: 1,
        width: "100%",
        borderRadius: 8,
        paddingHorizontal: 16,
        justifyContent: "center",
        borderWidth: 1,
    },

    loginButtonContainer: {
        height: 50,
        marginTop: 24,
        marginBottom: 16,
    },

    loginHelpButton: {
        height: 50,
        marginBottom: 50,
        justifyContent: "center",
        alignItems: "center",
    },

    loginErrorContainer: {
        width: "100%",
        height: 70,
        backgroundColor: Colors.white,
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 8,
    },

    loginErrorText: {
        textAlign: "center",
        color: Colors.red,
    },

    loginErrorButton: {
        justifyContent: "center",
        alignItems: "center",
    },
})
