import * as Localization from "expo-localization"
// import i18n from "i18n-js"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { translationStrings as translationEnglish } from "../translations/translation-en"
import { translationStrings as translationRussian } from "../translations/translation-ru"
import { translationStrings as translationSpanish } from "../translations/translation-es"
import { translationStrings as translationChinese } from "../translations/translation-zh"
import moment from "moment-timezone"

// Set up localization
// Set the key-value pairs for the different languages you want to support.
const resources = {
    en: translationEnglish,
    ru: translationRussian,
    es: translationSpanish,
    zh: translationChinese,
}

i18n.use(initReactI18next).init({ compatibilityJSON: "v3", resources, fallbackLng: "en", returnObjects: true })

// Set up localization
// Set the locale once at the beginning of your app.
// i18n.locale = Localization.locale
// console.log("Locale is " + i18n.locale)

// Tell Moment about our locale.
var languageCode = Localization.locale.slice(0, 2)
if (languageCode.length > 0) {
    if (languageCode == "zh") {
        // Convert this to something Moment understands
        languageCode = "zh-cn"
    }
    console.log("Setting i18next and moment locales to " + languageCode)
    moment.locale(languageCode)
    i18n.changeLanguage(languageCode, (err, t) => {
        if (err) return console.log("Couldn't change language: ", err)
        else {
            console.log("Changed language to " + languageCode)
        }
    })
}

// When a value is missing from a language it'll fallback to another language with the key present.
// i18n.fallbacks = true

export default i18n
