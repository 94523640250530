import { useNavigation } from "@react-navigation/native"
import * as Analytics from "expo-firebase-analytics"
import React, { useContext, useEffect } from "react"
import { Text, View, Platform, Share } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { addAchievement, fetchProfileData } from "../../model/primaryDataActions"
import AppConstants from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { rawThemes, ThemeContext } from "../../shared/ThemeContext"
import { copyToClipboard, sendBranchEvent, showToast } from "../../shared/Utils"
import Button from "../Button"
import CardView from "./CardView"

if (Platform.OS !== "web") {
    branch = require("react-native-branch").default
}
export default function ReferralShareCard(props) {
    const navigation = useNavigation()
    const { theme } = useContext(ThemeContext)
    const dispatch = useDispatch()

    const {
        first_name: firstName,
        last_name: lastName,
        zip_code: zipCode,
        phone_number: phoneNumber,
        incoming_referral_code: incomingReferralCode,
        outgoing_referral_code: outgoingReferralCode,
    } = useSelector((state) => state.primaryData.profileData) || {}

    useEffect(() => {
        dispatch(fetchProfileData())
    }, [])

    const onShare = async () => {
        if (Platform.OS != "web") {
            // only canonicalIdentifier is required
            let branchUniversalObject = await branch.createBranchUniversalObject("referralShareCard", {})
            let shareOptions = { messageBody: i18n.t("referral.shareMessage", { code: outgoingReferralCode }), emailSubject: i18n.t("referral.emailSubject") }
            let linkProperties = { feature: "referral" }
            let controlParams = { $desktop_url: AppConstants.urls.mainWebsite, referral_code: outgoingReferralCode }
            let { channel, completed, error } = await branchUniversalObject.showShareSheet(shareOptions, linkProperties, controlParams)

            console.log("Share channel was " + channel + ", completed: " + completed + ", error was " + JSON.stringify(error))

            if (completed) {
                // Tell Branch
                console.log("Should be telling branch that there was a referral")
                sendBranchEvent(AppConstants.analytics.branchEvents.referralSent, null, null)

                // Add a new achievement
                dispatch(addAchievement("referralSent"))
            }
        } else {
            console.log("Should be hitting branch now")
            // Branch's RN SDK doesn't support web, so we need to use manual link creation + regular RN share sheet on web.
            const linkData = {
                // campaign: "content 123",
                // channel: "facebook",
                feature: "referral",
                // stage: "new user",
                // tags: ["tag1", "tag2", "tag3"],
                // alias: "",
                data: {
                    // custom_bool: true,
                    // custom_int: Date.now(),
                    // custom_string: "hello",
                    // $og_title: "Title",
                    // $og_description: "Description",
                    // $og_image_url: "http://lorempixel.com/400/400",
                    $desktop_url: AppConstants.urls.mainWebsite,
                    referral_code: outgoingReferralCode,
                },
            }
            branch.link(linkData, async function (err, link) {
                console.log("web referral link is " + link + " and error is " + err)
                if (err != null) {
                    console.log("Error creating Branch link from web: " + error)
                    showToast(i18n.t("referral.errorCreatingLink"))
                    return
                } else {
                    // We have a valid link, share it.
                    const content = {
                        // Using this single item rather than a separate URL because URL is ignored on Android.
                        message: i18n.t("referral.shareMessage", { code: outgoingReferralCode }) + "\n" + link,
                    }
                    const options = {
                        subject: i18n.t("referral.emailSubject"), // iOS only
                    }
                    // RN/Web does't produce a result object from this sharer, so we can't check it to see if the share was successful. We'll treat it like it was.
                    try {
                        await Share.share(content, options)
                        dispatch(addAchievement("referralSent"))
                    } catch (error) {
                        console.log("Cancelled share on web")
                    }
                }
            })
        }
    }

    return (
        <CardView
            includesPadding={false}
            backgroundImage={require("../../assets/images/gradient06.jpg")}
            // We do want the border logic (rounding, external margins, etc.), but no visible border in this case.
            includesBorder={true}
            containerStyle={{ borderWidth: 0, minHeight: 405 }}
            contentContainerStyle={[{ paddingHorizontal: 24, paddingTop: 48, paddingBottom: 16 }]}
            {...props}
        >
            <Text style={[TextStyles.h1kobe, { color: theme.textLight, marginBottom: 16 }]}>{i18n.t("referral.title")}</Text>
            <Text style={[TextStyles.body2, { color: theme.textLight, marginBottom: 24 }]}>{i18n.t("referral.description")}</Text>
            <View style={{ flex: 1 }} />
            <Button
                title={i18n.t("referral.shareYourCode")}
                icon={theme.icons.share}
                buttonTintColor={theme.textLight}
                contentTintColor={theme.textDark}
                containerStyle={{ marginBottom: 8 }}
                onPress={() => {
                    // Log this tap regardless of whether the network call completes successfully.
                    Analytics.logEvent("referralShareButtonTapped")
                    copyToClipboard(outgoingReferralCode)
                    onShare()
                }}
            />
            <Button
                title={i18n.t("referral.copyYourCode") + ": " + outgoingReferralCode}
                icon={rawThemes.dark.icons.copy}
                type={"ghost"}
                contentTintColor={theme.textLight}
                onPress={() => {
                    copyToClipboard(outgoingReferralCode)
                    showToast(i18n.t("referral.codeCopied"), rawThemes.light.icons.checkmark32)
                }}
            />
        </CardView>
    )
}
