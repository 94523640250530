import {
    FETCH_ACCOUNTS_BEGIN,
    FETCH_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_ERROR,
    FETCH_PROFILE_BEGIN,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_ERROR,
    UPDATE_PROFILE_BEGIN,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    UPDATE_AVATAR_BEGIN,
    UPDATE_AVATAR_SUCCESS,
    UPDATE_AVATAR_ERROR,
    DELETE_AVATAR_BEGIN,
    DELETE_AVATAR_SUCCESS,
    DELETE_AVATAR_ERROR,
    FETCH_PAYMENT_ADDRESS_BEGIN,
    FETCH_PAYMENT_ADDRESS_SUCCESS,
    FETCH_PAYMENT_ADDRESS_ERROR,
    UPDATE_PAYMENT_ADDRESS_BEGIN,
    UPDATE_PAYMENT_ADDRESS_SUCCESS,
    UPDATE_PAYMENT_ADDRESS_ERROR,
    FETCH_MAINDATA_BEGIN,
    FETCH_MAINDATA_SUCCESS,
    FETCH_MAINDATA_ERROR,
    FETCH_ACHIEVEMENTS_BEGIN,
    FETCH_ACHIEVEMENTS_SUCCESS,
    FETCH_ACHIEVEMENTS_ERROR,
    ADD_ACHIEVEMENT_BEGIN,
    ADD_ACHIEVEMENT_SUCCESS,
    ADD_ACHIEVEMENT_ERROR,
    FETCH_NOTIFICATIONS_BEGIN,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_ERROR,
    FETCH_NEIGHBORHOOD_BEGIN,
    FETCH_NEIGHBORHOOD_SUCCESS,
    FETCH_NEIGHBORHOOD_ERROR,
    FETCH_NEIGHBORHOOD_PERFORMANCE_BEGIN,
    FETCH_NEIGHBORHOOD_PERFORMANCE_SUCCESS,
    FETCH_NEIGHBORHOOD_PERFORMANCE_ERROR,
    FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_BEGIN,
    FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_SUCCESS,
    FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_ERROR,
    FETCH_NEIGHBORHOOD_MESSAGES_BEGIN,
    FETCH_NEIGHBORHOOD_MESSAGES_SUCCESS,
    FETCH_NEIGHBORHOOD_MESSAGES_ERROR,
    FETCH_PARTICIPANTS_BEGIN,
    FETCH_PARTICIPANTS_SUCCESS,
    FETCH_PARTICIPANTS_ERROR,
    ADD_PARTICIPANT_BEGIN,
    ADD_PARTICIPANT_SUCCESS,
    ADD_PARTICIPANT_ERROR,
    DELETE_PARTICIPANT_BEGIN,
    DELETE_PARTICIPANT_SUCCESS,
    DELETE_PARTICIPANT_ERROR,
    FETCH_SCORECARDS_BEGIN,
    FETCH_SCORECARDS_SUCCESS,
    FETCH_SCORECARDS_ERROR,
    FETCH_GRIDREWARDS_HISTORY_BEGIN,
    FETCH_GRIDREWARDS_HISTORY_SUCCESS,
    FETCH_GRIDREWARDS_HISTORY_ERROR,
    FETCH_ELECTRICITY_HISTORY_BEGIN,
    FETCH_ELECTRICITY_HISTORY_SUCCESS,
    FETCH_ELECTRICITY_HISTORY_ERROR,
    FETCH_SHORT_ELECTRICITY_HISTORY_BEGIN,
    FETCH_SHORT_ELECTRICITY_HISTORY_SUCCESS,
    FETCH_SHORT_ELECTRICITY_HISTORY_ERROR,
    FETCH_GAS_HISTORY_BEGIN,
    FETCH_GAS_HISTORY_SUCCESS,
    FETCH_GAS_HISTORY_ERROR,
    FETCH_PAYMENTS_BEGIN,
    FETCH_PAYMENTS_SUCCESS,
    FETCH_PAYMENTS_ERROR,
    FETCH_MAP_BEGIN,
    FETCH_MAP_SUCCESS,
    FETCH_MAP_ERROR,
    FETCH_MAP_PREVIEW_BEGIN,
    FETCH_MAP_PREVIEW_SUCCESS,
    FETCH_MAP_PREVIEW_ERROR,
    FETCH_ACTIONS_BEGIN,
    FETCH_ACTIONS_SUCCESS,
    FETCH_ACTIONS_ERROR,
    FETCH_CARBON_INTENSITY_DETAIL_BEGIN,
    FETCH_CARBON_INTENSITY_DETAIL_SUCCESS,
    FETCH_CARBON_INTENSITY_DETAIL_ERROR,
    FETCH_WEATHER_BEGIN,
    FETCH_WEATHER_SUCCESS,
    FETCH_WEATHER_ERROR,
    FETCH_EVENT_LIKELIHOOD_BEGIN,
    FETCH_EVENT_LIKELIHOOD_SUCCESS,
    FETCH_EVENT_LIKELIHOOD_ERROR,
    FETCH_THERMOSTATS_BEGIN,
    FETCH_THERMOSTATS_SUCCESS,
    FETCH_THERMOSTATS_ERROR,
    UPDATE_THERMOSTAT_BEGIN,
    UPDATE_THERMOSTAT_SUCCESS,
    UPDATE_THERMOSTAT_ERROR,
    SEND_EXPO_TOKEN_SUCCESS,
    SEND_EXPO_TOKEN_ERROR,
    CHECK_LOCATION_BEGIN,
    CHECK_LOCATION_SUCCESS,
    CHECK_LOCATION_ERROR,
    SIGN_IN,
    SIGN_OUT_BEGIN,
    SIGN_OUT_SUCCESS,
    SIGN_OUT_ERROR,
    CONFETTI_BEGIN,
    CONFETTI_END,
    LOAD_ASYNC_STORAGE,
    SET_NOTIFICATION_TARGET,
    CLEAR_NOTIFICATION_TARGET,
    NEW_ACCOUNT_CREATION_PENDING,
    SWITCH_UTILITY_BEGIN,
    SWITCH_UTILITY_SUCCESS,
} from "./primaryDataActions"

import _ from "lodash"

const initialState = {
    currentUserLoading: true,
    currentUser: null,

    accountsData: null,
    accountsDataLoading: true,
    accountsDataError: null,

    profileData: null,
    profileDataLoading: true,
    profileDataError: null,

    mainData: null,
    mainDataLoading: true,
    mainDataError: null,

    achievementsData: null,
    achievementsDataLoading: true,
    achievementsDataError: null,

    scorecardsData: null,
    scorecardsDataLoading: true,
    scorecardsDataError: null,

    gridRewardsHistoryData: null,
    gridRewardsHistoryDataLoading: true,
    gridRewardsHistoryDataError: null,

    paymentsData: null,
    paymentsDataLoading: true,
    paymentsDataError: null,

    gradeData: null,
    gradeDataLoading: true,
    gradeDataError: null,

    mapData: null,
    mapDataLoading: true,
    mapDataError: null,

    actionsData: null,
    carbonIntensityData: null,

    thermostatsData: null,
    thermostatsLastUpdate: null,
    thermostatsError: null,
    updateThermostatError: null,

    availableServices: null,
    checkLocationError: null,
    onboardingComplete: false,
    nextStepsHidden: false,
    takeActionError: null,
    sendExpoTokenError: null,
    confettiRunning: false,
    notificationTarget: null,
}

import moment from "moment-timezone"
import { path } from "ramda"

const primaryData = (state = initialState, action) => {
    switch (action.type) {
        // Accounts list
        case FETCH_ACCOUNTS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                accountsDataLoading: true,
                accountsDataError: null,
            }

        case FETCH_ACCOUNTS_SUCCESS:
            // All done: set loading "false".
            // Also, replace data with the data from the server
            return {
                ...state,
                accountsDataLoading: false,
                accountsData: action.accountsData,
            }

        case FETCH_ACCOUNTS_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                accountsDataLoading: false,
                accountsDataError: action.error,
            }

        // User profile
        case FETCH_PROFILE_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                profileDataLoading: true,
                profileDataError: null,
            }

        case FETCH_PROFILE_SUCCESS:
            // All done: set loading "false".
            // Also, replace data with the data from the server
            return {
                ...state,
                profileDataLoading: false,
                profileData: action.profileData,
            }

        case FETCH_PROFILE_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                profileDataLoading: false,
                profileDataError: action.error,
            }

        case UPDATE_PROFILE_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                profileDataLoading: true,
                profileDataError: null,
            }

        case UPDATE_PROFILE_SUCCESS:
            // All done: set loading "false".
            // Also, replace data with the data from the server
            return {
                ...state,
                profileDataLoading: false,
                profileData: action.profileData,
                profileDataError: null,
            }

        case UPDATE_PROFILE_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                profileDataLoading: false,
                profileDataError: action.error,
            }

        // Avatar
        case UPDATE_AVATAR_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                avatarUpdateLoading: true,
                avatarUpdateError: null,
            }

        case UPDATE_AVATAR_SUCCESS:
            // All done: set loading "false".
            // Also, replace data with the data from the server
            return {
                ...state,
                avatarUpdateLoading: false,
                avatarUpdateError: null,
            }

        case UPDATE_AVATAR_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                avatarUpdateLoading: false,
                avatarUpdateError: action.error,
            }

        case DELETE_AVATAR_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                deleteAvatarLoading: true,
                deleteAvatarError: null,
            }

        case DELETE_AVATAR_SUCCESS:
            // All done: set loading "false".
            // Also, replace data with the data from the server
            return {
                ...state,
                deleteAvatarLoading: false,
                deleteAvatarError: null,
            }

        case DELETE_AVATAR_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                deleteAvatarLoading: false,
                deleteAvatarError: action.error,
            }

        // Payment mailing address
        case FETCH_PAYMENT_ADDRESS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                paymentAddressDataLoading: true,
                paymentAddressDataError: null,
            }

        case FETCH_PAYMENT_ADDRESS_SUCCESS:
            // All done: set loading "false".
            // Also, replace data with the data from the server
            return {
                ...state,
                paymentAddressDataLoading: false,
                paymentAddressData: action.paymentAddressData,
            }

        case FETCH_PAYMENT_ADDRESS_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                paymentAddressDataLoading: false,
                paymentAddressDataError: action.error,
            }

        case UPDATE_PAYMENT_ADDRESS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                updatePaymentAddressDataLoading: true,
            }

        case UPDATE_PAYMENT_ADDRESS_SUCCESS:
            // All done: set loading "false".
            // Also, replace data with the data from the server
            return {
                ...state,
                updatePaymentAddressDataLoading: false,
                updatePaymentAddressDataError: null,
            }

        case UPDATE_PAYMENT_ADDRESS_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                updatePaymentAddressDataLoading: false,
                updatePaymentAddressDataError: action.error,
            }

        // Main data
        case FETCH_MAINDATA_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                mainDataLoading: true,
                mainDataError: null,
            }

        case FETCH_MAINDATA_SUCCESS:
            // All done: set loading "false".
            // Also, replace data with the data from the server
            return {
                ...state,
                mainDataLoading: false,
                mainData: action.mainData,
            }

        case FETCH_MAINDATA_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                mainDataLoading: false,
                mainDataError: action.error,
            }

        // Notifications
        case FETCH_NOTIFICATIONS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                notificationsLoading: true,
                notificationsError: null,
            }

        case FETCH_NOTIFICATIONS_SUCCESS:
            // All done: set loading "false".

            // Merge this page of data with whatever else we've got
            // Overwrite anything with an id that matches incoming data, and maintain the order the server gave us.
            const mergedNotificationData = _.unionBy(action.notificationsData, state.notificationsData, "id")

            // Limit this to the most recent 500 notifications as a failsafe.
            if (mergedNotificationData.length > 500) {
                mergedNotificationData.splice(500)
            }

            return {
                ...state,
                notificationsLoading: false,
                notificationsData: mergedNotificationData,
            }

        case FETCH_NOTIFICATIONS_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                notificationsLoading: false,
                notificationsError: action.error,
            }

        // Achievements
        case FETCH_ACHIEVEMENTS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                achievementsDataLoading: true,
                achievementsDataError: null,
            }

        case FETCH_ACHIEVEMENTS_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                achievementsDataLoading: false,
                achievementsData: action.achievementsData,
            }

        case FETCH_ACHIEVEMENTS_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                achievementsDataLoading: false,
                achievementsDataError: action.error,
            }

        case ADD_ACHIEVEMENT_BEGIN:
            return {
                ...state,
                addAchievementError: null,
            }

        case ADD_ACHIEVEMENT_SUCCESS:
            return {
                ...state,
                addAchievementError: null,
            }
        case ADD_ACHIEVEMENT_ERROR:
            return {
                ...state,
                addAchievementError: action.error,
            }

        // Neighborhoods
        case FETCH_NEIGHBORHOOD_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                neighborhoodLoading: true,
                neighborhoodError: null,
            }

        case FETCH_NEIGHBORHOOD_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                neighborhoodLoading: false,
                neighborhoodData: action.neighborhoodData,
            }

        case FETCH_NEIGHBORHOOD_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                neighborhoodLoading: false,
                neighborhoodError: action.error,
            }

        // Neighborhood overall and event performance
        case FETCH_NEIGHBORHOOD_PERFORMANCE_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                neighborhoodPerformanceLoading: true,
                neighborhoodPerformanceError: null,
            }

        case FETCH_NEIGHBORHOOD_PERFORMANCE_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                neighborhoodPerformanceLoading: false,
                neighborhoodPerformanceData: action.neighborhoodPerformanceData,
            }

        case FETCH_NEIGHBORHOOD_PERFORMANCE_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                neighborhoodPerformanceLoading: false,
                neighborhoodPerformanceError: action.error,
            }

        case FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                neighborhoodEventPerformanceLoading: true,
                neighborhoodEventPerformanceError: null,
            }

        case FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                neighborhoodEventPerformanceLoading: false,
                neighborhoodEventPerformanceData: action.neighborhoodEventPerformanceData,
            }

        case FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                neighborhoodEventPerformanceLoading: false,
                neighborhoodEventPerformanceError: action.error,
            }

        // Neighborhood messages
        case FETCH_NEIGHBORHOOD_MESSAGES_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                neighborhoodMessagesLoading: true,
                neighborhoodMessagesError: null,
            }

        case FETCH_NEIGHBORHOOD_MESSAGES_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                neighborhoodMessagesLoading: false,
                neighborhoodMessagesData: action.neighborhoodMessagesData,
            }

        case FETCH_NEIGHBORHOOD_MESSAGES_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                neighborhoodMessagesLoading: false,
                neighborhoodMessagesError: action.error,
            }

        // Event participants
        case FETCH_PARTICIPANTS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.

            return {
                ...state,
                participantsLoading: true,
                participantsError: null,
            }

        case FETCH_PARTICIPANTS_SUCCESS:
            // All done: set loading "false".

            return {
                ...state,
                participantsLoading: false,
                participantsError: null,
                eventParticipantData: action.eventParticipantData,
            }

        case FETCH_PARTICIPANTS_ERROR:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.

            return {
                ...state,
                participantsLoading: false,
                participantsError: action.error,
            }

        case ADD_PARTICIPANT_BEGIN:
            return {
                ...state,
                addParticipantError: null,
            }

        case ADD_PARTICIPANT_SUCCESS:
            return {
                ...state,
                addParticipantError: null,
            }
        case ADD_PARTICIPANT_ERROR:
            return {
                ...state,
                addParticipantError: action.error,
            }

        case DELETE_PARTICIPANT_BEGIN:
            return {
                ...state,
                deleteParticipantError: null,
            }

        case DELETE_PARTICIPANT_SUCCESS:
            return {
                ...state,
                deleteParticipantError: null,
            }
        case DELETE_PARTICIPANT_ERROR:
            return {
                ...state,
                deleteParticipantError: action.error,
            }

        // Scorecards
        case FETCH_SCORECARDS_BEGIN:
            return {
                ...state,
                scorecardsDataLoading: true,
                scorecardsDataError: null,
            }

        case FETCH_SCORECARDS_SUCCESS:
            return {
                ...state,
                scorecardsDataLoading: false,
                scorecardsData: action.scorecardsData,
            }

        case FETCH_SCORECARDS_ERROR:
            return {
                ...state,
                scorecardsDataLoading: false,
                scorecardsDataError: action.error,
            }

        // GridRewards event history
        case FETCH_GRIDREWARDS_HISTORY_BEGIN:
            return {
                ...state,
                gridRewardsHistoryDataLoading: true,
                gridRewardsHistoryDataError: null,
            }

        case FETCH_GRIDREWARDS_HISTORY_SUCCESS:
            return {
                ...state,
                gridRewardsHistoryDataLoading: false,
                gridRewardsHistoryData: action.gridRewardsHistoryData,
            }

        case FETCH_GRIDREWARDS_HISTORY_ERROR:
            return {
                ...state,
                gridRewardsHistoryDataLoading: false,
                gridRewardsHistoryDataError: action.error,
            }

        // History
        case FETCH_ELECTRICITY_HISTORY_BEGIN:
            return {
                ...state,
                electricityHistoryDataLoading: true,
                electricityHistoryDataError: null,
            }

        case FETCH_ELECTRICITY_HISTORY_SUCCESS:
            return {
                ...state,
                electricityHistoryDataLoading: false,
                electricityHistoryData: action.historyData,
            }

        case FETCH_ELECTRICITY_HISTORY_ERROR:
            return {
                ...state,
                electricityHistoryDataLoading: false,
                electricityHistoryDataError: action.error,
            }

        case FETCH_SHORT_ELECTRICITY_HISTORY_BEGIN:
            return {
                ...state,
                electricityShortHistoryDataLoading: true,
                electricityShortHistoryDataError: null,
            }

        case FETCH_SHORT_ELECTRICITY_HISTORY_SUCCESS:
            return {
                ...state,
                electricityShortHistoryDataLoading: false,
                electricityShortHistoryData: action.historyData,
            }

        case FETCH_SHORT_ELECTRICITY_HISTORY_ERROR:
            return {
                ...state,
                electricityShortHistoryDataLoading: false,
                electricityShortHistoryDataError: action.error,
            }

        case FETCH_GAS_HISTORY_BEGIN:
            return {
                ...state,
                gasHistoryDataLoading: true,
                gasHistoryDataError: null,
            }

        case FETCH_GAS_HISTORY_SUCCESS:
            return {
                ...state,
                gasHistoryDataLoading: false,
                gasHistoryData: action.historyData,
            }

        case FETCH_GAS_HISTORY_ERROR:
            return {
                ...state,
                gasHistoryDataLoading: false,
                gasHistoryDataError: action.error,
            }

        // GridRewards payments
        case FETCH_PAYMENTS_BEGIN:
            return {
                ...state,
                paymentsDataLoading: true,
                paymentsDataError: null,
            }

        case FETCH_PAYMENTS_SUCCESS:
            return {
                ...state,
                paymentsDataLoading: false,
                paymentsData: action.paymentsData,
            }

        case FETCH_PAYMENTS_ERROR:
            return {
                ...state,
                paymentsDataLoading: false,
                paymentsDataError: action.error,
            }

        // Map data
        case FETCH_MAP_BEGIN:
            return {
                ...state,
                mapDataLoading: true,
                mapDataError: null,
            }

        case FETCH_MAP_SUCCESS:
            return {
                ...state,
                mapDataLoading: false,
                mapData: action.mapData,
            }

        case FETCH_MAP_ERROR:
            return {
                ...state,
                mapDataLoading: false,
                mapDataError: action.error,
            }

        // Map preview data
        case FETCH_MAP_PREVIEW_BEGIN:
            return {
                ...state,
                mapPreviewDataLoading: true,
                mapPreviewDataError: null,
            }

        case FETCH_MAP_PREVIEW_SUCCESS:
            return {
                ...state,
                mapPreviewDataLoading: false,
                mapPreviewData: action.mapPreviewData,
            }

        case FETCH_MAP_PREVIEW_ERROR:
            return {
                ...state,
                mapPreviewDataLoading: false,
                mapPreviewDataError: action.error,
            }

        // Actions
        case FETCH_ACTIONS_BEGIN:
            return {
                ...state,
            }

        case FETCH_ACTIONS_SUCCESS:
            return {
                ...state,
                actionsData: action.actionsData,
            }

        case FETCH_ACTIONS_ERROR:
            return {
                ...state,
                actionsError: action.error,
            }

        case FETCH_CARBON_INTENSITY_DETAIL_BEGIN:
            return {
                ...state,
            }

        case FETCH_CARBON_INTENSITY_DETAIL_SUCCESS:
            return {
                ...state,
                carbonIntensityData: action.carbonIntensityData,
            }

        case FETCH_CARBON_INTENSITY_DETAIL_ERROR:
            return {
                ...state,
                carbonIntensityError: action.error,
            }

        case FETCH_WEATHER_BEGIN:
            return {
                ...state,
            }

        case FETCH_WEATHER_SUCCESS:
            return {
                ...state,
                weatherData: action.weatherData,
            }

        case FETCH_WEATHER_ERROR:
            return {
                ...state,
                weatherDataError: action.error,
            }

        case FETCH_EVENT_LIKELIHOOD_BEGIN:
            return {
                ...state,
            }

        case FETCH_EVENT_LIKELIHOOD_SUCCESS:
            return {
                ...state,
                eventLikelihoodData: action.eventLikelihoodData,
            }

        case FETCH_EVENT_LIKELIHOOD_ERROR:
            return {
                ...state,
                eventLikelihoodError: action.error,
            }

        case FETCH_THERMOSTATS_BEGIN:
            return {
                ...state,
            }

        case FETCH_THERMOSTATS_SUCCESS:
            return {
                ...state,
                thermostatsData: action.thermostatsData,
                thermostatsLastUpdate: moment(), // We're changing properties inside the thermostatsData object, so use this to force a re-render and get around the equality check being wrong.
            }

        case FETCH_THERMOSTATS_ERROR:
            return {
                ...state,
                thermostatsError: action.error,
            }

        case UPDATE_THERMOSTAT_BEGIN:
            return {
                ...state,
            }
        case UPDATE_THERMOSTAT_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_THERMOSTAT_ERROR:
            return {
                ...state,
                updateThermostatError: action.error,
            }

        case SEND_EXPO_TOKEN_SUCCESS:
            return {
                ...state,
            }
        case SEND_EXPO_TOKEN_ERROR:
            return {
                ...state,
                sendExpoTokenError: action.error,
            }

        case CHECK_LOCATION_BEGIN:
            return {
                ...state,
            }

        case CHECK_LOCATION_SUCCESS:
            return {
                ...state,
                availableServices: action.availableServices,
            }

        case CHECK_LOCATION_ERROR:
            return {
                ...state,
                checkLocationError: action.error,
            }

        case SIGN_IN:
            return {
                ...state,
                currentUser: action.currentUser,
                currentUserLoading: false,
            }

        case SIGN_OUT_BEGIN:
            return {
                ...state,
            }
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                accountsData: null,
                actionsData: null,
                amiComplete: null,
                availableServices: null,
                carbonIntensityData: null,
                currentUser: null,
                electricityHistoryData: null,
                electricityShortHistoryData: null,
                eventLikelihoodData: null,
                gasHistoryData: null,
                gridRewardsHistoryData: null,
                mainData: null,
                mapData: null,
                mapPreviewData: null,
                neighborhoodData: null,
                neighborhoodMessagesData: null,
                neighborhoodPerformanceData: null,
                neighborhoodEventPerformanceData: null,
                newAccountCreationPending: false,
                notificationsData: null,
                pendingIncomingReferralCode: null,
                profileData: null,
                scorecardsData: null,
                suppressTimeZone: null,
                suppressEducationSplashCard: null,
                thermostatsData: null,
                timeZone: null,
                weatherData: null,
            }
        case SIGN_OUT_ERROR:
            return {
                ...state,
                signOutError: action.error,
            }

        case SWITCH_UTILITY_BEGIN:
            return {
                ...state,
            }
        case SWITCH_UTILITY_SUCCESS:
            return {
                ...state,
                // Clear everything that's utility-account-level so that we can refill it during the switch process.
                actionsData: null,
                amiComplete: null,
                carbonIntensityData: null,
                electricityHistoryData: null,
                electricityShortHistoryData: null,
                eventLikelihoodData: null,
                gasHistoryData: null,
                gridRewardsHistoryData: null,
                mainData: null,
                mapData: null,
                mapPreviewData: null,
                neighborhoodData: null,
                neighborhoodMessagesData: null,
                neighborhoodPerformanceData: null,
                neighborhoodEventPerformanceData: null,
                scorecardsData: null,
                suppressTimeZone: null,
                suppressEducationSplashCard: null,
                thermostatsData: null,
                timeZone: null,
                weatherData: null,
            }

        case CONFETTI_BEGIN:
            return {
                ...state,
                confettiRunning: action.confettiRunning,
            }
        case CONFETTI_END:
            return {
                ...state,
                confettiRunning: action.confettiRunning,
            }

        case LOAD_ASYNC_STORAGE:
            return {
                ...state,
                onboardingComplete: action.onboardingComplete,
                nextStepsHidden: action.nextStepsHidden,
                amiComplete: action.amiComplete,
                enrollmentComplete: action.enrollmentComplete,
                utilityAccountNicknames: action.utilityAccountNicknames,
                timeZone: action.timeZone,
                suppressTimeZone: action.suppressTimeZone,
                suppressEducationSplashCard: action.suppressEducationSplashCard,
                pendingIncomingReferralCode: action.pendingIncomingReferralCode,
            }

        case SET_NOTIFICATION_TARGET:
            return {
                ...state,
                notificationTarget: action.notificationTarget,
            }
        case CLEAR_NOTIFICATION_TARGET:
            return {
                ...state,
                notificationTarget: null,
            }

        case NEW_ACCOUNT_CREATION_PENDING:
            return {
                ...state,
                newAccountCreationPending: action.pending,
            }

        default:
            // ALWAYS have a default case in a reducer
            return state
    }
}

export default primaryData
