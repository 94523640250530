import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Text, TouchableOpacity, View } from "react-native"
import { activeOpacity } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function SummaryRow(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    // When descriptionView is set, it supersedes the description text.
    const {
        title = "",
        titleTintColor = theme.textSecondary,
        titleUseHeavyText = true,
        descriptionHeader,
        description = "",
        descriptionTintColor = theme.textPrimary,
        indented = false,
        onPress,
        containerStyle,
    } = props

    return (
        <TouchableOpacity
            style={[
                { flex: 1, flexDirection: "row", paddingHorizontal: 8, paddingVertical: 12, justifyContent: "space-between", alignItems: "center" },
                containerStyle,
            ]}
            activeOpacity={activeOpacity}
            onPress={onPress}
            {...props}
        >
            <View style={{ marginLeft: indented ? 24 : 0 }}>
                {descriptionHeader != null && (
                    <Text style={[TextStyles.capsExtraSmall, { flexShrink: 1, marginBottom: 4, color: theme.textHint }]}>{descriptionHeader}</Text>
                )}
                <Text style={[indented || !titleUseHeavyText ? TextStyles.body2 : TextStyles.body2Medium, { flexShrink: 1, color: titleTintColor }]}>
                    {title}
                </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
                {description != null && description.length > 0 && (
                    <Text style={[TextStyles.body2Semi, { flexShrink: 1, color: descriptionTintColor }]}>{description}</Text>
                )}
                {props.children}
            </View>
        </TouchableOpacity>
    )
}
