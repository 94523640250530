import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation, useRoute, useScrollToTop } from "@react-navigation/native"
import { prop } from "ramda"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Platform, RefreshControl, ScrollView, StyleSheet, Text } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useDispatch, useSelector } from "react-redux"
import Button from "../components/Button"
import CarbonIntensityGraphCard from "../components/cards/CarbonIntensityGraphCard"
import CardCarbonIntensityNextGoodCard from "../components/cards/CarbonIntensityNextGoodCard"
import CriticalWarningCard from "../components/cards/CriticalWarningCard"
import GridFuelMixCard from "../components/cards/GridFuelMixCard"
import InsightCard from "../components/cards/InsightCard"
import OnboardingCard from "../components/cards/OnboardingCard"
import { fetchDataForRoute } from "../model/primaryDataActions"
import AppConstants, { CarbonStatus, DataSource, InsightTypes, ScreenNames } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import { openSetup } from "../shared/Utils"

export default function CarbonScreen() {
    const dispatch = useDispatch()
    const route = useRoute()
    const { theme } = useContext(ThemeContext)

    // Allow scrolling to top when tapping our tab bar icon
    const scrollRef = React.useRef(null)
    useScrollToTop(scrollRef)

    const mainData = useSelector((state) => state.primaryData.mainData)
    const amiComplete = useSelector((state) => state.primaryData.amiComplete)
    const enrollmentComplete = useSelector((state) => state.primaryData.enrollmentComplete)
    const [initialDataRequested, setInitialDataRequested] = useState(false)

    const carbonData = prop("carbonData", mainData)
    const carbonStatus = prop("carbonStatus", carbonData)

    const [refreshing, setRefreshing] = useState(false)

    let shortDescription = ""
    switch (carbonStatus) {
        case CarbonStatus.okay:
            shortDescription = "okay"
            break
        case CarbonStatus.bad:
            shortDescription = "bad"
            break
        case CarbonStatus.veryBad:
            shortDescription = "very bad"
        default:
            break
    }

    // Initial setup
    useEffect(() => {
        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user != null) {
                // We only want to do this fetch once.
                if (!initialDataRequested) {
                    // console.log("CARBON Auth state changed in a way we care about")
                    setInitialDataRequested(true)
                }
            }
        })

        return () => {
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        // console.log("Carbon initial data request changed to " + initialDataRequested)
        if (initialDataRequested == true) {
            // Do our initial data fetch.

            // In this case, we're loading an existing account. If we don't have any utility accounts yet, try to fetch them first.
            AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((currentAccount) => {
                if (currentAccount != null) {
                    // We've got a connected account, just fetch.
                    // console.log("Fetching normal cold-start data with no delay.")
                    // dispatch(fetchDataForRoute(ScreenNames.CarbonScreen.routeName))
                }
            })
        }
    }, [initialDataRequested])

    useEffect(() => {
        setRefreshing(false)
    }, [mainData])

    const onRefresh = useCallback(() => {
        setRefreshing(true)
        dispatch(fetchDataForRoute(ScreenNames.CarbonScreen.routeName))
    }, [])

    if (!amiComplete) {
        // If we're not connected to AMI data yet, just show the correct onboarding card.
        return (
            <ScrollView
                style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
                contentContainerStyle={CommonStyles.mainScreenContent}
                ref={scrollRef}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={theme.textHint} colors={[theme.textHint]} />}
            >
                <OnboardingCard onboardingRoute={route.name} />
            </ScrollView>
        )
    }

    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
            refreshControl={
                Platform.OS != "web" && <RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={theme.textHint} colors={[theme.textHint]} />
            }
            ref={scrollRef}
        >
            {amiComplete && !enrollmentComplete && (
                <CriticalWarningCard title={i18n.t("amiOnlyWarning")}>
                    <Button
                        title={i18n.t("completeAccountSetup")}
                        buttonTintColor={rawThemes.dark.surface1}
                        contentTintColor={rawThemes.dark.textPrimary}
                        onPress={() => openSetup(prop("link", gridRewards))}
                    />
                </CriticalWarningCard>
            )}
            <CardCarbonIntensityNextGoodCard />
            <CarbonIntensityGraphCard />
            <GridFuelMixCard />
            <Text style={[TextStyles.body2Semi, { flexShrink: 1, color: theme.textSecondary, marginHorizontal: 16, marginBottom: 16 }]}>
                {i18n.t("yourCarbonFootprint")}
            </Text>
            <ScrollView
                style={{ flex: 1 }}
                horizontal={true}
                alwaysBounceHorizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingRight: 20 }}
            >
                <InsightCard type={InsightTypes.carbonVsProjected} source={DataSource.electricity} />
                <InsightCard type={InsightTypes.carbonVsYearAgo} source={DataSource.electricity} />
                <InsightCard type={InsightTypes.carbonVsSimilar} source={DataSource.electricity} />
            </ScrollView>
        </ScrollView>
    )
}
