import * as React from "react"
import { ActivityIndicator, Image, StyleSheet, Text, useWindowDimensions, View, TouchableOpacity } from "react-native"
import { TextStyles } from "../shared/TextStyles"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import i18n from "../shared/i18n"
import { useDispatch, useSelector, useStore } from "react-redux"
import AsyncStorage from "@react-native-async-storage/async-storage"
import AppConstants, { ScreenNames } from "../shared/AppConstants"
import { useEffect, useState } from "react"
import { isEmpty, path, prop } from "ramda"
import { titleCase, hasUnreadNotifications } from "../shared/Utils"
import { useFocusEffect, useNavigation, useScrollToTop } from "@react-navigation/native"
import { ThemeContext } from "../shared/ThemeContext"
import { activeOpacity } from "../shared/Layout"

export default function HomeScreenHeader(props) {
    const { spinnerVisible = false, containerStyle } = props
    const window = useWindowDimensions()
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()

    const { theme } = React.useContext(ThemeContext)

    const utilityAccountNicknames = useSelector((state) => state.primaryData.utilityAccountNicknames) || {}
    const [currentAccountName, setCurrentAccountName] = useState(null)

    const accountsList = useSelector((state) => state.primaryData.accountsData)
    const enrollmentComplete = useSelector((state) => state.primaryData.enrollmentComplete)

    const notificationsData = useSelector((state) => state.primaryData.notificationsData)
    const [unreadNotifications, setUnreadNotifications] = useState(false)

    useEffect(() => {
        if (accountsList == null || accountsList.length == 0) {
            // Nothing to do
            return
        }

        // Get the stored account.
        AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((savedAccountId) => {
            // console.log("Accounts list is "+JSON.stringify(accountsList) +", saved ID is "+savedAccountId)
            let matchingAccount = accountsList.find((possibleItem) => {
                const { accountId = "" } = possibleItem
                return accountId == savedAccountId
            })
            // console.log("Matching account is "+JSON.stringify(matchingAccount))
            let displayName = utilityAccountNicknames[savedAccountId]
            if (displayName == null || displayName.length == 0) {
                displayName = titleCase(prop("serviceAddress", matchingAccount) || prop("utilityAccountId", matchingAccount))
            }
            setCurrentAccountName(displayName)
        })
    }, [accountsList, utilityAccountNicknames])

    useEffect(async () => {
        setUnreadNotifications(await hasUnreadNotifications(notificationsData))
    }, [notificationsData])

    useFocusEffect(() => {
        hasUnreadNotifications(notificationsData).then((shouldShowUnread) => {
            setUnreadNotifications(shouldShowUnread)
        })
    })

    return (
        <View
            style={[
                {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingHorizontal: 20,
                    paddingTop: insets.top + 8,
                    paddingBottom: 8,
                    backgroundColor: theme.background,
                },
                containerStyle,
            ]}
        >
            <TouchableOpacity
                style={{ flexShrink: 1 }}
                activeOpacity={enrollmentComplete ? activeOpacity : 1.0}
                onPress={() => {
                    if (enrollmentComplete) {
                        navigation.navigate(ScreenNames.Locations.routeName)
                    }
                }}
            >
                <Text style={[TextStyles.h1kobe, { color: theme.textPrimary, marginBottom: 0 }]}>{i18n.t("gridRewards")}</Text>
                {currentAccountName != null && (
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={[TextStyles.body3Medium, { maxWidth: 154, color: theme.textHint, marginRight: 8 }]} numberOfLines={1}>
                            {titleCase(currentAccountName)}
                        </Text>
                        <Image source={theme.icons.caretDown} style={[{ width: 16, height: 16, opacity: 0.5 }]} />
                    </View>
                )}
            </TouchableOpacity>
            <View style={{ flexDirection: "row" }}>
                {enrollmentComplete && (
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate(ScreenNames.ReferralDetail.routeName)
                        }}
                    >
                        <Image source={theme.icons.giftLavender} style={[{ width: 36, height: 36, marginLeft: 4 }]} />
                    </TouchableOpacity>
                )}

                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate(ScreenNames.Notifications.routeName)
                    }}
                >
                    <Image source={unreadNotifications ? theme.icons.bellOn : theme.icons.bell} style={[{ width: 36, height: 36, marginLeft: 4 }]} />
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate(ScreenNames.ProfileStack.routeName)
                    }}
                >
                    <Image source={theme.icons.user36} style={[{ width: 36, height: 36, marginLeft: 4 }]} />
                </TouchableOpacity>
            </View>
        </View>
    )
}
