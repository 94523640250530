import { useNavigation } from "@react-navigation/native"
import React, { useEffect, useState } from "react"
import { Image, Platform, ScrollView, Text, View } from "react-native"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { maxContentWidth } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import Button from "./Button"

export default function UniversityLesson(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    const { lesson, containerStyle } = props
    const { id, icon, title = "", video, leadingImage, body, buttonTitle, buttonRoute, buttonParams, trailingImage, tips = [], links } = lesson || {}

    // We have a lot of padding :-)
    const maxActiveContentWidth = maxContentWidth - 2 * 48

    // We want the leading and trailing images to fit width, but keep their native aspect ratio,
    // so we need to calculate those ratios.
    const [leadingImageRatio, setLeadingImageRatio] = useState(3 / 2)
    const [trailingImageRatio, setTrailingImageRatio] = useState(3 / 2)

    useEffect(() => {
        if (leadingImage != null) {
            // resolveAssetSource doesn't exist on RN/Web (https://github.com/necolas/react-native-web/issues/1666)
            if (Platform.OS != "web") {
                const { width, height } = Image.resolveAssetSource(leadingImage)
                setLeadingImageRatio(width / height)
            } else {
                Image.getSize(leadingImage, (width, height) => {
                    setLeadingImageRatio(width / height)
                })
            }
        }
    }, [leadingImage])

    useEffect(() => {
        if (trailingImage != null) {
            // resolveAssetSource doesn't exist on RN/Web (https://github.com/necolas/react-native-web/issues/1666)
            if (Platform.OS != "web") {
                const { width, height } = Image.resolveAssetSource(trailingImage)
                setTrailingImageRatio(width / height)
            } else {
                Image.getSize(trailingImage, (width, height) => {
                    setTrailingImageRatio(width / height)
                })
            }
        }
    }, [trailingImage])

    return (
        <ScrollView
            style={[{ flex: 1, width: "100%", paddingTop: 80 }, containerStyle]}
            contentContainerStyle={[CommonStyles.mainScreenContent, { paddingHorizontal: 48 }]}
            {...props}
        >
            {icon != null && <Image source={theme.icons.university[icon]} style={{ width: 48, height: 48, marginBottom: 24 }} />}
            {leadingImage != null && (
                <Image
                    source={leadingImage}
                    style={{
                        width: maxActiveContentWidth,
                        height: maxActiveContentWidth / leadingImageRatio,
                        borderRadius: 8,
                        overflow: "hidden",
                        marginBottom: 24,
                    }}
                />
            )}

            <Text style={[TextStyles.h3, { color: theme.textPrimary, marginBottom: 24 }]}>{title}</Text>
            {body != null && body.length > 0 && <Text style={[TextStyles.body1, { color: theme.textSecondary, marginBottom: 24 }]}>{body}</Text>}
            {buttonTitle != null && buttonTitle.length > 0 && (
                <Button
                    containerStyle={{ marginTop: 16, marginBottom: 24 }}
                    type={"outline"}
                    buttonTintColor={theme.border}
                    title={buttonTitle}
                    onPress={() => {
                        navigation.navigate(buttonRoute, buttonParams)
                    }}
                />
            )}
            {trailingImage != null && (
                <Image
                    source={trailingImage}
                    style={{
                        width: maxActiveContentWidth,
                        height: maxActiveContentWidth / trailingImageRatio,
                        borderRadius: 8,
                        overflow: "hidden",
                        marginBottom: 24,
                    }}
                />
            )}
            {tips.map((tip, index) => {
                if (tip == null || tip.length == 0) {
                    return null
                }
                return (
                    <View
                        key={index.toString()}
                        style={{ alignItems: "flex-start", padding: 16, borderColor: theme.border, borderWidth: 1, borderRadius: 8, marginBottom: 24 }}
                    >
                        <Text
                            style={[
                                TextStyles.capsSmall,
                                {
                                    color: theme.textPrimary,
                                    backgroundColor: theme.yellow,
                                    paddingHorizontal: 8,
                                    paddingVertical: 4,
                                    marginBottom: 8,
                                    borderRadius: 4,
                                    overflow: "hidden",
                                },
                            ]}
                        >
                            {i18n.t("tip")}
                        </Text>
                        <Text style={[TextStyles.body1, { flexShrink: 1, color: theme.textSecondary }]}>{tip}</Text>
                    </View>
                )
            })}
        </ScrollView>
    )
}
