import { applyMiddleware, createStore } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./rootReducer"
import { persistStore, persistReducer } from "redux-persist"
import AsyncStorage from '@react-native-async-storage/async-storage';

const persistConfig = {
    key: "root",
    storage: AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

var persistor = null

// Create a store with our reducer
export default () => {
    let store = createStore(persistedReducer, applyMiddleware(thunk))
    persistor = persistStore(store)
    return { store, persistor }
}
