import * as Haptics from "expo-haptics"
import moment from "moment-timezone"
import { prop } from "ramda"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Image, Platform, Text, View, FlatList, TouchableOpacity } from "react-native"
import { useSelector } from "react-redux"
import { CarbonThresholdManual } from "../shared/AppConstants"
import i18n from "../shared/i18n"
import { activeOpacity, maxContentWidth } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { electricityQualityForValue } from "../shared/Utils"
import * as Localization from "expo-localization"

export function CarbonIntensityGraph(props) {
    const mainData = useSelector((state) => state.primaryData.mainData)
    const carbonData = prop("carbonData", mainData)
    const carbonIntensityData = useSelector((state) => state.primaryData.carbonIntensityData)
    const historySlices = prop("actual", carbonIntensityData) || []
    const forecastSlices = prop("forecast", carbonIntensityData) || []
    const thresholds = prop("thresholds", carbonIntensityData) || []

    const { theme } = useContext(ThemeContext)
    const [processedData, setProcessedData] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(null)

    const [isGoodOrOkay, setIsGoodOrOkay] = useState(true)
    const [selectedTime, setSelectedTime] = useState("")
    const [selectedTimeQuality, setSelectedTimeQuality] = useState("")
    const [analysisSentence, setAnalysisSentence] = useState("")

    const [initialScrollIndex, setInitialScrollIndex] = useState(null)
    const chartRef = useRef()

    const graphLeftPadding = 40
    const barColumnWidth = (maxContentWidth - 32) / 24
    const barSeparatorWidth = 3
    const maxRelativeHeight = 0.85

    const threshold = thresholds.length >= 3 ? thresholds[1] : CarbonThresholdManual.okay

    useEffect(() => {
        // Build an array of objects that we'll pass to the FlatList
        const processedHistory = historySlices.map((element) => {
            const carbonIntensity = prop("carbonIntensity", element)
            const isGoodOrOkay = carbonIntensity < threshold
            return {
                value: carbonIntensity,
                timestamp: prop("timestamp", element),
                color: isGoodOrOkay ? theme.blue : theme.red,
                isForecast: false,
            }
        })
        const processedForecast = forecastSlices.map((element) => {
            const carbonIntensity = prop("carbonIntensity", element)
            const isGoodOrOkay = carbonIntensity < threshold
            return {
                value: carbonIntensity,
                timestamp: prop("timestamp", element),
                color: isGoodOrOkay ? theme.blueTranslucent : theme.redTranslucent,
                strokeColor: isGoodOrOkay ? theme.blue : theme.red,
                isForecast: true,
            }
        })

        const processedDataLocal = [...processedHistory, ...processedForecast]

        // setInitialScrollOffset(graphLeftPadding + (processedHistory.length * barColumnWidth * 1.0) / 3.0)
        setProcessedData(processedDataLocal)
        setSelectedIndex(processedHistory.length > 0 ? processedHistory.length - 1 : null)
    }, [carbonIntensityData])

    useEffect(() => {
        if (selectedIndex == null) {
            // Nothing to change
            return
        }

        const selectedSlice = processedData[selectedIndex]
        const carbonIntensity = prop("value", selectedSlice)
        setIsGoodOrOkay(carbonIntensity < threshold)
        setSelectedTime(selectedSlice != null ? moment(prop("timestamp", selectedSlice)).format("h:mmA") : "")
        setSelectedTimeQuality(electricityQualityForValue(carbonIntensity, thresholds))
        const isForecast = prop("isForecast", selectedSlice) ?? false

        if (Localization.locale.startsWith("en")) {
            setAnalysisSentence(
                (prop("marketName", carbonData) ?? i18n.t("carbonGraphRegionPlaceholder")) +
                    "'s power grid" +
                    " " +
                    (isForecast ? i18n.t("carbonGraphAnalysisForecast") : i18n.t("carbonGraphAnalysisPast")) +
                    " " +
                    carbonIntensity +
                    i18n.t("carbonGraphAnalysisUnits")
            )
        } else {
            setAnalysisSentence(
                (prop("marketName", carbonData) ?? i18n.t("carbonGraphRegionPlaceholder")) +
                    ": " +
                    i18n.t("powerGrid") +
                    " " +
                    (isForecast ? i18n.t("carbonGraphAnalysisForecast") : i18n.t("carbonGraphAnalysisPast")) +
                    " " +
                    carbonIntensity +
                    i18n.t("carbonGraphAnalysisUnits")
            )
        }

        if (initialScrollIndex == null && selectedIndex > 0 && processedData.length > 0 && chartRef.current != null) {
            setInitialScrollIndex(selectedIndex)
        }
    }, [selectedIndex, processedData])

    useEffect(() => {
        if (initialScrollIndex != null && chartRef.current != null) {
            // console.log("Trying to scroll")
            // We need to delay this initial scroll, because it fails if we fire it too early, even if the data's in place.
            // I think it's that the list hasn't actually built fully yet.
            setTimeout(() => {
                chartRef.current.scrollToIndex({
                    animated: true,
                    index: initialScrollIndex,
                    viewOffset: maxContentWidth / 3.0,
                })
            }, 500)
        }
    }, [initialScrollIndex])

    const barTapped = (index) => {
        if (Platform.OS != "web") {
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
        }
        setSelectedIndex(index)
    }

    // Update our min and max values for bar scaling.
    const maxBarValue = Math.max(...processedData.map((item) => item.value || 1000))
    const minBarValueRaw = Math.min(...processedData.map((item) => item.value || 0))
    const minBarValue = minBarValueRaw - (maxBarValue - minBarValueRaw) * 0.5

    if (carbonIntensityData == null || (historySlices.length == 0 && forecastSlices.length == 0)) {
        return null
    }

    return (
        <View style={{ flex: 1 }}>
            {/* Horizontal container for the smokestack and text */}
            <View style={{ flexDirection: "row", marginHorizontal: 16, marginBottom: 4 }}>
                {/* Vertical text stack */}
                <View style={{ flex: 1 }}>
                    <Text style={[TextStyles.h3, { color: theme.textPrimary }]}>
                        {selectedTime + " "}
                        <Text style={[TextStyles.h3, { color: isGoodOrOkay ? theme.blue : theme.red }]}>{selectedTimeQuality}</Text>
                    </Text>
                    <Text style={[TextStyles.caption, { minHeight: 36, color: theme.textHint, marginTop: 4 }]}>{analysisSentence}</Text>
                </View>
            </View>
            {/* Graph */}
            <FlatList
                ref={chartRef}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingLeft: graphLeftPadding }}
                data={processedData}
                onScrollToIndexFailed={() => {
                    console.log("Scroll to index failed")
                }}
                ListEmptyComponent={() => {
                    // console.log("Rendering empty carbon forecast list")
                    return null
                }}
                initialNumToRender={48}
                renderItem={({ item, index }) => {
                    // if (minBarValue == null || maxBarValue == null) {
                    //     return null
                    // }
                    let barValue = (item.value - minBarValue) / (maxBarValue - minBarValue)
                    let barRelativeHeight = barValue * maxRelativeHeight
                    // console.log("Returning a bar, barColumnWidth should be " + barColumnWidth)
                    return (
                        <TouchableOpacity
                            style={{ width: barColumnWidth, height: 234, paddingHorizontal: barSeparatorWidth / 2.0 }}
                            activeOpacity={activeOpacity}
                            onPress={() => barTapped(index)}
                        >
                            <View
                                style={{
                                    width: barColumnWidth,
                                    height: 234,
                                    paddingHorizontal: barSeparatorWidth / 2.0,
                                    paddingBottom: 34,
                                }}
                            >
                                {selectedIndex == index && (
                                    <Image
                                        source={require("../assets/images/carbonGraphDashedLine.png")}
                                        style={{ width: 1.3, height: 200, position: "absolute", left: (barColumnWidth - barSeparatorWidth) / 2.0 + 0.5 }}
                                    />
                                )}
                                <View style={{ flex: 1.0 - barRelativeHeight, width: "100%" }} />
                                <View
                                    style={{
                                        flex: barRelativeHeight,
                                        backgroundColor: item.color,
                                        width: "100%",
                                        borderTopLeftRadius: 20,
                                        borderTopRightRadius: 20,
                                        overflow: "hidden",
                                        borderWidth: item.strokeColor != null ? 1 : 0,
                                        borderColor: item.strokeColor,
                                    }}
                                />
                            </View>
                            {index % 3 == 0 && (
                                <Text
                                    style={[
                                        TextStyles.capsSmall,
                                        {
                                            width: 100,
                                            overflow: "visible",
                                            position: "absolute",
                                            bottom: 12,
                                            color: item.isForecast == true ? theme.textHint : theme.textSecondary,
                                        },
                                    ]}
                                    adjustsFontSizeToFit={true}
                                >
                                    {moment(item.timestamp).format("ha").replace("am", "a").replace("pm", "p")}
                                </Text>
                            )}
                        </TouchableOpacity>
                    )
                }}
                keyExtractor={(item, index) => item.timestamp + index.toString()}
            />
        </View>
    )
}
