import { useNavigation } from "@react-navigation/native"
import { prop } from "ramda"
import React, { useEffect, useState } from "react"
import { Image, Text, View } from "react-native"
import { useSelector } from "react-redux"
import { DataMeasurement, DataSource, ScreenNames } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import { formatWithUnits } from "../../shared/Utils"
import Button from "../Button"
import Divider from "../Divider"
import CardView from "./CardView"

export default function SeasonEarningsCard(props) {
    var { showPrefsButton = false } = props
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    const paymentsData = useSelector((state) => state.primaryData.paymentsData) || {}

    const [pendingPerformance, setPendingPerformance] = useState(0)
    const [pendingBonus, setPendingBonus] = useState(0)
    const [previousPayments, setPreviousPayments] = useState(0)

    useEffect(() => {
        // Logic:
        // The total amount finalized already goes into the previous payments bucket.
        // Everything that's pending and has category "Demand Response" gets aggregated into pending performance payments.
        // Everything else that's pending goes into pendingBonus
        setPreviousPayments(prop("totalFinalized", paymentsData) || 0)

        const pending = prop("pendingItems", paymentsData) || []
        const performance = pending.filter((item) => {
            return prop("category", item) == "Demand Response"
        })
        setPendingPerformance(performance.reduce((previousValue, currentItem) => previousValue + (prop("dollarAmount", currentItem) || 0), 0))

        const bonus = pending.filter((item) => {
            return prop("category", item) != "Demand Response"
        })
        setPendingBonus(bonus.reduce((previousValue, currentItem) => previousValue + (prop("dollarAmount", currentItem) || 0), 0))
    }, [paymentsData])

    return (
        <CardView includesBorder={true} includesPadding={true} containerStyle={{ paddingTop: 24, paddingBottom: 32 }} {...props}>
            <Text style={[TextStyles.h2kobe, { color: theme.textPrimary, marginBottom: 10 }]}>{i18n.t("payments.title")}</Text>
            <View style={{ flexDirection: "row", alignItems: "center", marginVertical: 8 }}>
                <Image style={{ width: 24, height: 24, marginRight: 8 }} source={theme.icons.bolt} />
                <Text style={[TextStyles.body2Medium, { flex: 1, color: theme.textPrimary }]}>{i18n.t("payments.rowTitles.performance")}</Text>
                <Text style={[TextStyles.body2, { color: theme.textSecondary }]}>
                    {pendingPerformance > 0 ? formatWithUnits(pendingPerformance, DataSource.electricity, DataMeasurement.money) : i18n.t("tbd")}
                </Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", marginTop: 8, marginBottom: 4 }}>
                <Image style={{ width: 24, height: 24, marginRight: 8 }} source={theme.icons.partyHorn} />
                <Text style={[TextStyles.body2Medium, { flex: 1, color: theme.textPrimary }]}>{i18n.t("payments.rowTitles.bonus")}</Text>
                <Text style={[TextStyles.body2, { color: theme.textSecondary }]}>
                    {pendingBonus > 0 ? formatWithUnits(pendingBonus, DataSource.electricity, DataMeasurement.money) : i18n.t("tbd")}
                </Text>
            </View>
            <Text style={[TextStyles.caption, { marginLeft: 32, color: theme.textSecondary }]}>{i18n.t("payments.rowTitles.bonusDescription")}</Text>

            <Divider style={{ marginVertical: 16 }} />
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: 16,
                    paddingVertical: 12,
                    backgroundColor: theme.greenHint,
                    borderRadius: 4,
                    overflow: "hidden",
                }}
            >
                <Text style={[TextStyles.body2Semi, { flex: 1, color: theme.greenDark }]}>{i18n.t("payments.rowTitles.estimated")}</Text>
                <Text style={[TextStyles.body2Semi, { color: theme.greenDark }]}>
                    {pendingPerformance + pendingBonus > 0
                        ? formatWithUnits(pendingPerformance + pendingBonus, DataSource.electricity, DataMeasurement.money)
                        : "--"}
                </Text>
            </View>
            {previousPayments > 0 && (
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        paddingHorizontal: 16,
                        paddingVertical: 12,
                        marginTop: 10,
                        backgroundColor: theme.surface1,
                        borderRadius: 4,
                        overflow: "hidden",
                    }}
                >
                    <Image style={{ width: 24, height: 24, marginRight: 8 }} source={theme.icons.dollarSign} />
                    <Text style={[TextStyles.body2Medium, { flex: 1, color: theme.textPrimary }]}>{i18n.t("payments.rowTitles.previous")}</Text>
                    <Text style={[TextStyles.body2Medium, { color: theme.textPrimary }]}>
                        {formatWithUnits(previousPayments, DataSource.electricity, DataMeasurement.money)}
                    </Text>
                </View>
            )}

            <View style={{ marginTop: 24, paddingHorizontal: 16 }}>
                <Text style={[TextStyles.caption, { flexShrink: 1, color: theme.textSecondary, marginBottom: 10 }]}>{i18n.t("paymentGeneralTimeframe")}</Text>
                {showPrefsButton && (
                    <Button
                        containerStyle={{}}
                        type="outline"
                        title={i18n.t("seePaymentPreferences")}
                        arrow={true}
                        buttonTintColor={theme.border}
                        onPress={() => navigation.navigate(ScreenNames.PaymentPrefsDetail.routeName)}
                    />
                )}
            </View>
        </CardView>
    )
}
