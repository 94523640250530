import React, { useContext } from "react"
import { Dimensions, Text } from "react-native"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import CardView from "./CardView"

export default function WelcomeCard(props) {
    const { theme } = useContext(ThemeContext)

    return (
        <CardView
            includesPadding={false}
            backgroundImage={require("../../assets/images/welcomeCardBackground.jpg")}
            // We do want the border logic (rounding, external margins, etc.), but no visible border in this case.
            includesBorder={true}
            containerStyle={{ borderWidth: 0, height: 400, marginTop: 24 }}
            contentContainerStyle={[{ paddingHorizontal: 32, paddingTop: 100, alignItems: Dimensions.get("window").width > 600 ? "center" : "stretch" }]}
            {...props}
        >
            <Text style={[TextStyles.headerExtraLargeKobe, { color: theme.textLight }]}>{i18n.t("welcomeToGridRewards")}</Text>
        </CardView>
    )
}
