import AsyncStorage from "@react-native-async-storage/async-storage"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { createStackNavigator } from "@react-navigation/stack"
import { path, prop } from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { Image, Platform } from "react-native"
import { SafeAreaInsetsContext } from "react-native-safe-area-context"
import { useDispatch, useSelector, useStore } from "react-redux"
import HomeScreenHeader from "../components/HomeScreenHeader.js"
import { loadAsyncStorage, setNotificationTarget, signIn } from "../model/primaryDataActions"
import AchievementsScreen from "../screens/AchievementsScreen.js"
import CarbonScreen from "../screens/CarbonScreen"
import CreateAccountScreen from "../screens/CreateAccountScreen"
import DonationsDetailScreen from "../screens/DonationsDetailScreen.js"
import EnergyScreen from "../screens/EnergyScreen.js"
import EventsScreen from "../screens/EventsScreen"
import GridRewardsEventDetailScreen from "../screens/GridRewardsEventDetailScreen"
import HomeScreen from "../screens/HomeScreen"
import LoginScreen from "../screens/LoginScreen"
import NeighborhoodScreen from "../screens/NeighborhoodScreen.js"
import PaymentDetailScreen from "../screens/PaymentDetailScreen.js"
import PaymentPrefsDetailScreen from "../screens/PaymentPrefsDetailScreen"
import WelcomeScreen from "../screens/WelcomeScreen"
import AppConstants, { HomeScreenModals, ScreenNames } from "../shared/AppConstants"
import { baseHeaderStyle, detailOptions } from "../shared/CommonStyles.js"
import { auth } from "../shared/firebase.js"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext.js"
import * as Linking from "expo-linking"

if (Platform.OS !== "web") {
    branch = require("react-native-branch").default
}

const LoginStack = createStackNavigator()
function LoginStackScreen() {
    // let currentUser = auth.currentUser
    // console.log("Login stack thinks current user is " + currentUser)
    return (
        <LoginStack.Navigator
            initialRouteName={ScreenNames.WelcomeScreen.routeName}
            screenOptions={
                {
                    // headerShown: false,
                }
            }
        >
            <LoginStack.Screen
                name={ScreenNames.WelcomeScreen.routeName}
                component={WelcomeScreen}
                options={{
                    title: ScreenNames.WelcomeScreen.title,
                    animationEnabled: false,
                    headerShown: false,
                }}
            />
            <LoginStack.Screen name={ScreenNames.Login.routeName} component={LoginScreen} options={{ ...detailOptions, title: "" }} />
            <LoginStack.Screen name={ScreenNames.CreateAccount.routeName} component={CreateAccountScreen} options={{ ...detailOptions, title: "" }} />
        </LoginStack.Navigator>
    )
}

const InitialRouteName = ScreenNames.Home.routeName
const BottomTab = createBottomTabNavigator()

const HomeStack = createStackNavigator()

function HomeStackScreen() {
    const { theme } = useContext(ThemeContext)

    return (
        <HomeStack.Navigator
            screenOptions={{
                // animationEnabled: false,
                headerTintColor: theme.textPrimary,
            }}
        >
            <HomeStack.Screen
                name={ScreenNames.Home.routeName}
                component={HomeScreen}
                options={{
                    title: ScreenNames.Home.title,
                    headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
                    header: (props) => <HomeScreenHeader {...props} />,
                }}
            />

            <HomeStack.Screen
                name={ScreenNames.GridRewardsEventDetailHomeStack.routeName}
                component={GridRewardsEventDetailScreen}
                options={{ ...detailOptions, title: ScreenNames.GridRewardsEventDetailHomeStack.title }}
            />
            <HomeStack.Screen
                name={ScreenNames.PaymentPrefsDetail.routeName}
                component={PaymentPrefsDetailScreen}
                options={{
                    ...detailOptions,
                    title: ScreenNames.PaymentPrefsDetail.title,
                    headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
                }}
            />
            <HomeStack.Screen
                name={ScreenNames.PaymentDetail.routeName}
                component={PaymentDetailScreen}
                options={{ ...detailOptions, title: ScreenNames.PaymentDetail.title, headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background } }}
            />
            <HomeStack.Screen
                name={ScreenNames.DonationsDetail.routeName}
                component={DonationsDetailScreen}
                options={{ ...detailOptions, title: ScreenNames.DonationsDetail.title, headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background } }}
            />
            <HomeStack.Screen
                name={ScreenNames.Achievements.routeName}
                component={AchievementsScreen}
                options={{ ...detailOptions, title: ScreenNames.Achievements.title, headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background } }}
            />
        </HomeStack.Navigator>
    )
}

const EventsStack = createStackNavigator()
function EventsStackScreen() {
    const { theme } = useContext(ThemeContext)

    return (
        <EventsStack.Navigator
            screenOptions={{
                // headerShown: false,
                headerTintColor: theme.textPrimary,
            }}
        >
            <EventsStack.Screen
                name={ScreenNames.EventsScreen.routeName}
                component={EventsScreen}
                options={{
                    title: ScreenNames.EventsScreen.title,
                    headerTitleStyle: [TextStyles.h2, { marginLeft: 4 }],
                    headerTitleAlign: "left",
                    headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
                    headerTintColor: theme.textPrimary,
                }}
            />
            <EventsStack.Screen
                name={ScreenNames.GridRewardsEventDetail.routeName}
                component={GridRewardsEventDetailScreen}
                options={{
                    ...detailOptions,
                    title: ScreenNames.GridRewardsEventDetail.title,
                    headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
                }}
            />
            <EventsStack.Screen
                name={ScreenNames.PaymentPrefsDetail.routeName}
                component={PaymentPrefsDetailScreen}
                options={{
                    ...detailOptions,
                    title: ScreenNames.PaymentPrefsDetail.title,
                    headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
                }}
            />
            <EventsStack.Screen
                name={ScreenNames.Neighborhood.routeName}
                component={NeighborhoodScreen}
                options={{
                    ...detailOptions,
                    title: ScreenNames.Neighborhood.title,
                    headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
                }}
            />
            <EventsStack.Screen
                name={ScreenNames.PaymentDetail.routeName}
                component={PaymentDetailScreen}
                options={{ ...detailOptions, title: ScreenNames.PaymentDetail.title, headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background } }}
            />
            <EventsStack.Screen
                name={ScreenNames.DonationsDetail.routeName}
                component={DonationsDetailScreen}
                options={{ ...detailOptions, title: ScreenNames.DonationsDetail.title, headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background } }}
            />
        </EventsStack.Navigator>
    )
}

const EnergyStack = createStackNavigator()
function EnergyStackScreen() {
    const { theme } = useContext(ThemeContext)
    const enrollmentComplete = useSelector((state) => state.primaryData.enrollmentComplete)

    return (
        <EnergyStack.Navigator
            screenOptions={{
                // headerShown: false,
                headerTintColor: theme.textPrimary,
            }}
        >
            <EnergyStack.Screen
                name={ScreenNames.EnergyScreen.routeName}
                component={EnergyScreen}
                options={{
                    title: ScreenNames.EnergyScreen.title,
                    headerTitleStyle: [TextStyles.h2, { marginLeft: 4 }],
                    headerTitleAlign: "left",
                    headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
                    headerTintColor: theme.textPrimary,
                    // headerShown: !enrollmentComplete,
                }}
            />
        </EnergyStack.Navigator>
    )
}

const CarbonStack = createStackNavigator()
function CarbonStackScreen() {
    const { theme } = useContext(ThemeContext)

    return (
        <CarbonStack.Navigator
            screenOptions={{
                // headerShown: false,
                headerTintColor: theme.textPrimary,
            }}
        >
            <CarbonStack.Screen
                name={ScreenNames.CarbonScreen.routeName}
                component={CarbonScreen}
                options={{
                    title: ScreenNames.CarbonScreen.title,
                    headerTitleStyle: [TextStyles.h2, { marginLeft: 4 }],
                    headerTitleAlign: "left",
                    headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background },
                    headerTintColor: theme.textPrimary,
                }}
            />
        </CarbonStack.Navigator>
    )
}

export default function MainNavigator({ navigation, route }) {
    const currentUser = useSelector((state) => state.primaryData.currentUser)

    const dispatch = useDispatch()
    const store = useStore()

    const { theme } = useContext(ThemeContext)
    const [firebaseUser, setFirebaseUser] = useState(null)

    useEffect(() => {
        // Set up Branch
        if (Platform.OS !== "web") {
            branch.subscribe(({ error, params, uri }) => {
                if (error) {
                    console.error("Error from Branch: " + error)
                    return
                }
                if (uri != null) {
                    console.log("Got a Branch subscription event: " + uri + " and params are: " + JSON.stringify(params))
                } else {
                    console.log("Got a Branch NULL URI event with params: " + JSON.stringify(params))
                }
                // params will never be null if error is null
                const referralCode = prop("referral_code", params)
                if (referralCode != null) {
                    console.log("Setting pending referral code to " + referralCode)
                    AsyncStorage.setItem(AppConstants.pendingIncomingReferralCode, referralCode).then(() => {
                        // Reload this data into Redux
                        loadAsyncStorage(store)
                    })
                }
            })
            branch.getLatestReferringParams().then((lastParams) => {
                console.log("Last params are " + JSON.stringify(lastParams))
            }) // params from last open
            branch.getFirstReferringParams().then((originalParams) => {
                console.log("Original install params are " + JSON.stringify(originalParams))
            }) // params from original install
        }

        // // Try to set up a Linking listener to catch incoming links.
        // Linking.addEventListener("url", (linkingObject) => {
        //     const url = prop("url", linkingObject)
        //     console.log("Got a linking event: " + JSON.stringify(linkingObject))
        //     const parsedLink = Linking.parse(url)
        //     console.log("Parsed referral code is " + JSON.stringify(prop("referral_code", parsedLink.queryParams)))
        //     const referralCode = prop("referral_code", parsedLink.queryParams)
        //     if (referralCode != null) {
        //         console.log("Setting pending referral code to " + referralCode)
        //         AsyncStorage.setItem(AppConstants.pendingIncomingReferralCode, referralCode).then(() => {
        //             // Reload this data into Redux
        //             loadAsyncStorage(store)
        //         })
        //     }
        // })

        // Linking.parseInitialURLAsync().then((parsedLink) => {
        //     console.log("Parsed initial link is " + JSON.stringify(parsedLink))
        //     const referralCode = prop("referral_code", parsedLink.queryParams)
        //     if (referralCode != null) {
        //         console.log("Setting pending referral code to " + referralCode)
        //         AsyncStorage.setItem(AppConstants.pendingIncomingReferralCode, referralCode).then(() => {
        //             // Reload this data into Redux
        //             loadAsyncStorage(store)
        //         })
        //     }
        // })
    }, [])

    useEffect(() => {
        if (firebaseUser != null) {
            console.log("MAIN NAV: We are authenticated as " + JSON.stringify(firebaseUser))

            dispatch(signIn(firebaseUser))
        }
    }, [firebaseUser])

    // Initial setup
    useEffect(() => {
        // Configure our push behavior.
        // NOTE: There's a bug in Expo's notification setup currently that blows up the dev app on reload.
        // https://github.com/expo/expo/issues/15788

        const IOS_NOTIFICATION_ISSUE = Platform.OS === "ios" && __DEV__

        if (!IOS_NOTIFICATION_ISSUE) {
            const Notifications = require("expo-notifications")

            Notifications.setNotificationHandler({
                handleNotification: async () => ({
                    shouldShowAlert: true,
                    shouldPlaySound: false,
                    shouldSetBadge: false,
                }),
            })

            Notifications.addNotificationResponseReceivedListener((response) => {
                let keyPath = ["notification", "request", "content", "data", "targetScreen"]
                let targetScreen = path(keyPath, response)
                console.log("Notification tapped! Response is: " + JSON.stringify(response) + ", targetScreen is " + targetScreen)
                if (targetScreen != null) {
                    if (AppConstants.pushNotificationTargetScreens.includes(targetScreen)) {
                        let translatedTarget = ScreenNames.Home.routeName
                        switch (targetScreen) {
                            case "Home":
                                translatedTarget = ScreenNames.Home.routeName
                                break
                            case "CarbonDetail":
                                translatedTarget = ScreenNames.CarbonScreen.routeName
                                break
                            case "Actions":
                                translatedTarget = ScreenNames.Actions.routeName
                                break
                            case "GridRewards":
                                translatedTarget = ScreenNames.EventsScreen.routeName
                                break
                            case "Profile":
                                translatedTarget = ScreenNames.Profile.routeName
                                break
                            case "History":
                                translatedTarget = ScreenNames.History.routeName
                                break
                            default:
                                break
                        }
                        navigation.navigate(translatedTarget)
                    } else if (Object.values(HomeScreenModals).includes(targetScreen)) {
                        // These will all move to the home tab, but open a modal shortly thereafter.
                        // Wait a bit so we're sure to be on the main stack.
                        console.log("Got a homescreen modal request! " + targetScreen)
                        setTimeout(() => {
                            if (currentUser != null) {
                                navigation.navigate(ScreenNames.Home.routeName)
                                dispatch(setNotificationTarget(targetScreen))
                            }
                        }, 500)
                    }
                }
            })
        }

        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged((user) => {
            // console.log("Main nav's auth listener picked up user " + JSON.stringify(user))
            if (user != null) {
                setFirebaseUser(user)
            } else if (user == null && firebaseUser != null) {
                setFirebaseUser(null)
            }
        })

        return () => {
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    // Don't show the loading screen unless we're sure we need it.
    if (currentUser == null) {
        // console.log("Main nav wants to show login")
        // if(false) {
        return <SafeAreaInsetsContext.Consumer>{LoginStackScreen}</SafeAreaInsetsContext.Consumer>
    } else {
        // console.log("Main nav wants to show home")

        return (
            <SafeAreaInsetsContext.Consumer>
                {(insets) => (
                    <BottomTab.Navigator
                        initialRouteName={InitialRouteName}
                        screenOptions={{
                            headerShown: false,
                            tabBarActiveTintColor: theme.textPrimary,
                            tabBarInactiveTintColor: theme.textHint,
                            lazy: true,
                            tabBarLabelStyle: [TextStyles.body4],
                            tabBarStyle: [
                                baseHeaderStyle,
                                {
                                    borderTopColor: theme.border,
                                    backgroundColor: theme.background,
                                    height: 60 + insets.bottom,
                                    paddingTop: Platform.isPad ? 0 : 10,
                                    ...Platform.select({
                                        web: {
                                            height: 60,
                                            position: "absolute",
                                            top: 0,
                                        },
                                    }),
                                },
                            ],
                        }}
                        sceneContainerStyle={{
                            ...Platform.select({
                                web: {
                                    marginTop: 60,
                                    borderTopWidth: 1,
                                    borderTopColor: theme.divider,
                                },
                            }),
                        }}
                    >
                        <BottomTab.Screen
                            name={ScreenNames.HomeTab.routeName}
                            component={HomeStackScreen}
                            options={{
                                title: ScreenNames.HomeTab.title,
                                tabBarIcon: ({ focused }) => (
                                    <Image
                                        source={focused ? theme.icons.tabHomeActive : theme.icons.tabHome}
                                        style={{ width: 26, height: 26, opacity: focused ? 1.0 : 0.5 }}
                                    />
                                ),
                            }}
                        />
                        <BottomTab.Screen
                            name={ScreenNames.EventsTab.routeName}
                            component={EventsStackScreen}
                            options={{
                                title: ScreenNames.EventsTab.title,
                                tabBarIcon: ({ focused }) => (
                                    <Image
                                        source={focused ? theme.icons.tabEventsActive : theme.icons.tabEvents}
                                        style={{ width: 26, height: 26, opacity: focused ? 1.0 : 0.5 }}
                                    />
                                ),
                            }}
                        />
                        <BottomTab.Screen
                            name={ScreenNames.EnergyTab.routeName}
                            component={EnergyStackScreen}
                            options={{
                                title: ScreenNames.EnergyTab.title,
                                tabBarIcon: ({ focused }) => (
                                    <Image
                                        source={focused ? theme.icons.tabEnergyActive : theme.icons.tabEnergy}
                                        style={{ width: 26, height: 26, opacity: focused ? 1.0 : 0.5 }}
                                    />
                                ),
                            }}
                        />
                        <BottomTab.Screen
                            name={ScreenNames.CarbonTab.routeName}
                            component={CarbonStackScreen}
                            options={{
                                title: ScreenNames.CarbonTab.title,
                                tabBarIcon: ({ focused }) => (
                                    <Image
                                        source={focused ? theme.icons.tabCarbonActive : theme.icons.tabCarbon}
                                        style={{ width: 26, height: 26, opacity: focused ? 1.0 : 0.5 }}
                                    />
                                ),
                            }}
                        />
                    </BottomTab.Navigator>
                )}
            </SafeAreaInsetsContext.Consumer>
        )
    }
}
