import { useNavigation } from "@react-navigation/native"
import * as Linking from "expo-linking"
import * as WebBrowser from "expo-web-browser"
import * as React from "react"
import { Text, View } from "react-native"
import AppConstants from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import Button from "../Button"
import Divider from "../Divider"
import CardView from "./CardView"

export default function ContactUsCard(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    return (
        <CardView includesBorder={true} includesPadding={true} {...props}>
            <Text style={[TextStyles.h4, { color: theme.textPrimary, marginBottom: 24 }]}>{i18n.t("haveQuestions")}</Text>
            <Button
                type="outline"
                buttonTintColor={theme.teal}
                contentTintColor={theme.textPrimary}
                title={i18n.t("faqs")}
                arrow={true}
                onPress={() => {
                    WebBrowser.openBrowserAsync(AppConstants.urls.faq)
                }}
            />
            <Divider style={{ marginVertical: 16 }} />
            <View style={{ flexDirection: "row" }}>
                <Button
                    containerStyle={{ marginRight: 4, flex: 1 }}
                    type="fill"
                    contentTintColor={theme.textPrimary}
                    title={i18n.t("callUs")}
                    icon={theme.icons.phone}
                    onPress={() => {
                        Linking.openURL("tel:" + AppConstants.logicalBuildingsSupportPhone)
                    }}
                />
                <Button
                    containerStyle={{ marginLeft: 4, flex: 1 }}
                    type="fill"
                    contentTintColor={theme.textPrimary}
                    title={i18n.t("emailUs")}
                    icon={theme.icons.email}
                    onPress={() => {
                        Linking.openURL("mailto:" + AppConstants.logicalBuildingsSupportEmail + "?subject=GridRewards%20app%20feedback")
                    }}
                />
            </View>
        </CardView>
    )
}
