import { useHeaderHeight } from "@react-navigation/elements"
import { deleteUser, EmailAuthProvider, reauthenticateWithCredential, signOut } from "firebase/auth"
import { prop } from "ramda"
import React, { useEffect, useState } from "react"
import { Dimensions, Linking, StyleSheet, Text, TextInput, View } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { useDispatch } from "react-redux"
import Button from "../components/Button.js"
import ErrorMessageInline from "../components/ErrorMessageInline"
import { performSignOut } from "../model/primaryDataActions"
import AppConstants from "../shared/AppConstants"
import Colors from "../shared/Colors"
import { CommonStyles } from "../shared/CommonStyles"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext.js"

export default function DeleteAccountScreen(props) {
    const { theme } = React.useContext(ThemeContext)
    const headerHeight = useHeaderHeight()
    const dispatch = useDispatch()

    const [initialLoginStateResolved, setInitialLoginStateResolved] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [deletePending, setDeletePending] = useState(false)
    const [password, setPassword] = useState(null)

    const [passwordFocused, setPasswordFocused] = useState(false)

    useEffect(() => {
        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged((user) => {
            // Once something loads (with a user or without), we know we can proceed.
            if (!initialLoginStateResolved) {
                setInitialLoginStateResolved(true)
            }
        })
        return () => {
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        if (deletePending) {
            let currentUser = auth.currentUser

            // Make sure this isn't one of our non-deletable accounts.
            if (AppConstants.undeleteableAccounts.includes(prop("email", currentUser))) {
                console.log("Trying to delete account " + prop("email", currentUser) + ", which isn't allowed. Skipping.")
                setErrorMessage("Trying to delete account " + prop("email", currentUser) + ", which isn't allowed. Skipping.")
                setDeletePending(false)
                return
            }

            // First, we have to re-auth using our password and the user's credential email.
            const credential = EmailAuthProvider.credential(currentUser.email, password)
            reauthenticateWithCredential(currentUser, credential)
                .then((result) => {
                    console.log("We're about to delete user: " + JSON.stringify(prop("user", result)))
                    deleteUser(prop("user", result))
                        .then(() => {
                            // User deleted
                            setDeletePending(false)
                            dispatch(performSignOut())
                        })
                        .catch(function (error) {
                            let errorCode = error.code
                            let errorMessage = error.message
                            console.log("Account delete error: " + errorCode + " " + errorMessage)
                            setErrorMessage(errorMessage)
                            setDeletePending(false)
                        })
                })
                .catch(function (error) {
                    let errorCode = error.code
                    let errorMessage = error.message
                    console.log("Reauthentication-before-delete error: " + errorCode + " " + errorMessage)
                    setErrorMessage(errorMessage)
                    setDeletePending(false)
                })
        }
    }, [deletePending])

    const deleteButtonPressed = () => {
        if (password == null) {
            console.log("Null email or password, skipping request...")
            setErrorMessage(i18n.t("deleteAccount.passwordMissing"))
            setDeletePending(false)
            return
        }

        // Try to log in.
        console.log("Trying to delete an account")
        setErrorMessage(null)
        setDeletePending(true)
    }

    if (!initialLoginStateResolved) {
        // console.log("Login not resolved yet")
        // If we don't know whether we've logged in or not yet, just show a simple-to-render white screen.
        return null
    }

    let screenHeight = Dimensions.get("window").height - headerHeight

    return (
        <View style={{ backgroundColor: theme.background }}>
            <KeyboardAwareScrollView
                style={[{ height: screenHeight, backgroundColor: theme.background }]}
                contentContainerStyle={CommonStyles.mainScreenContent}
                showsVerticalScrollIndicator={false}
                alwaysBounceVertical={true}
                extraScrollHeight={50}
                keyboardOpeningTime={1}
                keyboardShouldPersistTaps={"handled"}
            >
                <View style={{ marginHorizontal: 32, paddingTop: 24 }}>
                    <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 16 }}>
                        <Text style={[TextStyles.h2, { color: theme.textPrimary }]}>{i18n.t("deleteAccount.title")}</Text>
                    </View>
                    <View style={{ marginBottom: 32 }}>
                        <Text style={[TextStyles.body2, { color: theme.textSecondary }]}>
                            {i18n.t("deleteAccount.descriptionBeforeDisconnect") + " "}
                            <Text style={{ textDecorationLine: "underline" }} onPress={() => Linking.openURL(AppConstants.urls.disconnectAccount)}>
                                {i18n.t("deleteAccount.descriptionDisconnectLink")}
                            </Text>
                            {i18n.t("deleteAccount.descriptionAfterDisconnect")}
                        </Text>
                    </View>

                    {errorMessage != null && <ErrorMessageInline text={errorMessage} />}

                    <View style={[{ height: 50, marginBottom: 32 }]}>
                        <TextInput
                            placeholder={i18n.t("password")}
                            placeholderTextColor={theme.textHint}
                            secureTextEntry={true}
                            underlineColorAndroid={"transparent"}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.loginInputField,
                                { color: theme.textPrimary, borderColor: passwordFocused ? theme.textSecondary : theme.border },
                            ]}
                            onChangeText={(text) => setPassword(text ? text.trim() : null)}
                            onFocus={() => setPasswordFocused(true)}
                            onBlur={() => setPasswordFocused(false)}
                            autoCompleteType="password"
                            textContentType="password"
                        />
                    </View>

                    <Button
                        type="fill"
                        buttonTintColor={theme.red}
                        contentTintColor={theme.textLight}
                        spinnerTintColor={theme.textLight}
                        onPress={deleteButtonPressed}
                        title={i18n.t("deleteAccount.button")}
                        key={"loginButton"}
                        showSpinner={deletePending}
                        containerStyle={{ marginTop: 0, marginBottom: 16, opacity: deletePending ? 0.6 : 1.0 }}
                    />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    loginInputField: {
        flex: 1,
        width: "100%",
        borderRadius: 8,
        paddingHorizontal: 16,
        justifyContent: "center",
        borderWidth: 1,
    },
})
