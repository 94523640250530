import * as React from "react"
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { useDispatch } from "react-redux"
import { updateThermostat } from "../model/primaryDataActions"
import { activeOpacity } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { checkNetwork } from "../shared/Utils"
import i18n from "../shared/i18n"
import { ThemeContext } from "../shared/ThemeContext"

export default function ThermostatControlRow(props) {
    const { thermostatData, thermostatId, isHeatingSetpoint = false, temperature = 0, isOff = false } = props
    const { theme } = React.useContext(ThemeContext)

    const dispatch = useDispatch()

    return (
        <View style={[styles.horizontalContainer, props.containerStyle]}>
            {/* Temperature down */}
            <TouchableOpacity
                onPress={async () => {
                    // Make sure we can get to the network (this will notify the user otherwise).
                    let networkAvailable = await checkNetwork()
                    if (networkAvailable != true) {
                        // Nothing to do
                        return
                    }

                    dispatch(
                        updateThermostat(
                            thermostatId,
                            isHeatingSetpoint
                                ? {
                                      heating_setpoint: temperature - 1,
                                  }
                                : {
                                      cooling_setpoint: temperature - 1,
                                  }
                        )
                    )
                }}
                activeOpacity={activeOpacity}
                disabled={isOff}
            >
                <Image source={require("../assets/images/thermostatArrowDown.png")} style={styles.icon} />
            </TouchableOpacity>
            {/* Add padding on the left to offset for the size of the degree symbol */}
            <Text
                style={[TextStyles.h1, { color: isOff ? theme.textDisabled : theme.textPrimary, width: 92, textAlign: "center", opacity: isOff ? 0.4 : 1.0 }]}
            >
                {isOff ? i18n.t("off") : temperature.toFixed(0) + "º"}
            </Text>
            {/* Temperature up */}
            <TouchableOpacity
                onPress={async () => {
                    // Make sure we can get to the network (this will notify the user otherwise).
                    let networkAvailable = await checkNetwork()
                    if (networkAvailable != true) {
                        // Nothing to do
                        return
                    }

                    dispatch(
                        updateThermostat(
                            thermostatId,
                            isHeatingSetpoint
                                ? {
                                      heating_setpoint: temperature + 1,
                                  }
                                : {
                                      cooling_setpoint: temperature + 1,
                                  }
                        )
                    )
                }}
                activeOpacity={activeOpacity}
                disabled={isOff}
            >
                <Image source={require("../assets/images/thermostatArrowUp.png")} style={styles.icon} />
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    horizontalContainer: {
        flexDirection: "row",
        alignItems: "center",
    },

    textContainer: {
        flex: 1,
        marginLeft: 15,
        justifyContent: "center",
        alignItems: "flex-start",
    },

    icon: {
        width: 96,
        height: 96,
    },
})
