import * as React from "react"
import { StyleSheet, View } from "react-native"
import { ThemeContext } from "../shared/ThemeContext"

export default function RoundedBar(props) {
    const { theme } = React.useContext(ThemeContext)

    var { normalizedValue = 0, minNormalizedValue = 0.05, barTintColor = theme.textPrimary, containerStyle, barStyle } = props

    if(isNaN(normalizedValue)) {
        normalizedValue = 0
    }

    // Prevent this from going below 5% or above 100%
    let clampedValue = Math.min(Math.max(minNormalizedValue, normalizedValue), 1.0)

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={[styles.bar, barStyle, { backgroundColor: barTintColor, flex: clampedValue }]} />
            <View style={[styles.spacer, { flex: 1.0 - clampedValue }]} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        width: "100%",
        height: 2,
    },
    bar: {
        flex: 1,
        overflow: "hidden",
        height: "100%",
        borderRadius: 1000,
    },
    spacer: {
        flex: 0,
        height: "100%",
    },
})
