import { useHeaderHeight } from "@react-navigation/elements"
import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { useEffect, useState } from "react"
import { Dimensions, Image, ScrollView, Text, View } from "react-native"
import { useDispatch, useSelector, useStore } from "react-redux"
import Button from "../components/Button.js"
import WelcomeCard from "../components/cards/WelcomeCard.js"
import { signIn } from "../model/primaryDataActions"
import { ScreenNames } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext.js"

export default function WelcomeScreen(props) {
    const [initialLoginStateResolved, setInitialLoginStateResolved] = useState(false)
    const store = useStore()
    const headerHeight = useHeaderHeight()

    const pendingIncomingReferralCode = useSelector((state) => state.primaryData.pendingIncomingReferralCode)
    // Make sure we don't bounce back and forth forever if the user actively returns to this screen.
    const [movedToCreateAccount, setMovedToCreateAccount] = useState(false)

    const { theme } = React.useContext(ThemeContext)

    const navigation = useNavigation()
    const dispatch = useDispatch()

    useEffect(() => {
        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged((user) => {
            // Once something loads (with a user or without), we know we can proceed.
            if (!initialLoginStateResolved) {
                setInitialLoginStateResolved(true)
            }
        })
        return () => {
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        console.log("Welcome screen got a changed pending referral code: " + pendingIncomingReferralCode)
        // If we have a pending referral code and haven't already done so this run, go right to the create account screen.
        if (!movedToCreateAccount && pendingIncomingReferralCode != null && pendingIncomingReferralCode.length > 0) {
            console.log("Should be moving to create account screen automatically")
            setMovedToCreateAccount(true)
            navigation.navigate(ScreenNames.CreateAccount.routeName)
        }
    }, [pendingIncomingReferralCode])

    let screenHeight = Dimensions.get("window").height - headerHeight
    if (!initialLoginStateResolved) {
        // console.log("Login not resolved yet")
        // If we don't know whether we've logged in or not yet, just show a simple-to-render white screen.
        return null
    } else {
        return (
            <ScrollView
                style={[CommonStyles.mainScreenScroll, { maxHeight: screenHeight, backgroundColor: theme.background, paddingTop: 32 }]}
                contentContainerStyle={CommonStyles.mainScreenContent}
            >
                <WelcomeCard />

                <View style={{ flex: 1, paddingHorizontal: 32 }}>
                    <Text style={[TextStyles.body1, { color: theme.textSecondary, marginBottom: 40 }]}>{i18n.t("onboardingWelcomeSubtitle")}</Text>
                    <Button
                        type="fill"
                        title={i18n.t("loginButtonCreate")}
                        buttonTintColor={theme.purple}
                        contentTintColor={theme.textLight}
                        containerStyle={{ marginBottom: 16 }}
                        onPress={() => navigation.navigate(ScreenNames.CreateAccount.routeName)}
                    />
                    <Button
                        type="outline"
                        title={i18n.t("signIn")}
                        buttonTintColor={theme.purple}
                        onPress={() => navigation.navigate(ScreenNames.Login.routeName)}
                    />
                </View>
            </ScrollView>
        )
    }
}
