import { useActionSheet } from "@expo/react-native-action-sheet"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
import { Video } from "expo-av"
import * as ImagePicker from "expo-image-picker"
import { prop } from "ramda"
import React, { useEffect, useState } from "react"
import { Alert, Image, Keyboard, Platform, Text, TextInput, TouchableOpacity, View } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { getMuxUploadInfo, uploadVideoToMux } from "../model/directAPICalls"
import { fetchNeighborhoodMessages, updateNeighborhoodMessage } from "../model/primaryDataActions"
import AppConstants from "../shared/AppConstants"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import { eventTimeFormatted, fetchEndpointNoRedux, isCaptain, isEmpty } from "../shared/Utils"
import Button from "./Button"

/* Expected props:
    event: the event to which this message applies
    containerStyle: applied to the outer view
*/
export default function MessageContainer(props) {
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const { showActionSheetWithOptions } = useActionSheet()

    const { theme } = React.useContext(ThemeContext)

    const { event, containerStyle, incomingMessage } = props
    const { id } = useSelector((state) => state.primaryData.profileData) || {}
    const amiComplete = useSelector((state) => state.primaryData.amiComplete)
    const neighborhoodData = useSelector((state) => state.primaryData.neighborhoodData) || []
    const activeNeighborhoodData = neighborhoodData.length > 0 ? neighborhoodData[0] : {}
    const captain = isCaptain(id, activeNeighborhoodData)

    // console.log("Incoming captain message is " + JSON.stringify(incomingMessage))

    // Include the first message matching this event. Messages are sorted newest-to-oldest.
    const { user } = incomingMessage || {}

    const [userPublicProfile, setUserPublicProfile] = useState(null)
    const [captainMessageText, setCaptainMessageText] = useState(prop("text", incomingMessage))

    // Has our message changed since it came in?
    const [messageChanged, setMessageChanged] = useState(false)

    // Video setup
    const video = React.useRef(null)
    const [videoStatus, setVideoStatus] = useState(null)
    const [videoSourceUrl, setVideoSourceUrl] = useState(null)
    const [videoPendingUploadUri, setVideoPendingUploadUri] = useState(null)
    // While we're uploading and the video is processing, we want to show our local copy of the video if possible.
    const [localVideoUri, setLocalVideoUri] = useState(null)
    // Is there a video error we need to resolve?
    const [videoErrorVisible, setVideoErrorVisible] = useState(false)

    // Initial setup
    useEffect(() => {
        const focusUnsub = navigation.addListener("focus", async () => {
            // Get the pending upload uri if we don't have one.
            if (videoPendingUploadUri == null) {
                const storedUriJSON = await AsyncStorage.getItem(AppConstants.videoPendingUploadUri)
                const storedUriInfo = JSON.parse(storedUriJSON) || {}
                const eventId = prop("eventId", event)
                const matchingUri = prop(eventId, storedUriInfo)
                if (matchingUri != null) {
                    // If we didn't have anything in our local pending state, but AsyncStorage has something that hasn't been uploaded, there was a problem during upload.
                    setVideoPendingUploadUri(matchingUri)
                    setVideoErrorVisible(true)
                }
            }

            // Try to play the video when we're focused.
            console.log("Message container is focused")
            video.current?.setIsMutedAsync(true)
            video.current?.setIsLoopingAsync(true)
            video.current?.playAsync()
        })

        const blurUnsub = navigation.addListener("blur", () => {
            // Try to play the video when we're focused.
            console.log("Message container is blurred")
            video.current?.pauseAsync()
        })

        return () => {
            focusUnsub()
            blurUnsub()
        }
    }, [])

    // Whenever we get a change the message, update the sender.
    useEffect(() => {
        if (amiComplete == "true" && auth.currentUser != null && user != null) {
            // console.log("Trying to fetch public profile for user " + user)
            fetchEndpointNoRedux(
                `${AppConstants.apiBase}/gridrewards/users/publicProfiles/${user}`,
                null,
                (responseData) => {
                    setUserPublicProfile(responseData)
                },
                null,
                ["data"],
                false
            )
        }
    }, [incomingMessage, amiComplete, user])

    useEffect(() => {
        if (incomingMessage != null) {
            if (prop("text", incomingMessage) != captainMessageText) {
                // console.log("Event card's incoming message changed to " + prop("text", incomingMessage))
                setCaptainMessageText(prop("text", incomingMessage))
            }
            const videoData = prop("video", incomingMessage) || {}
            const videoAsset = prop("asset", videoData)
            if (!isEmpty(videoAsset)) {
                // If we have an asset, see if the source has changed.
                const newSourceUrl = prop("url", videoAsset)
                const newStatus = prop("status", videoAsset)

                // NOTE: The URL's token param changes every time. We need to compare the URL itself, minus the query params.
                if ((newSourceUrl ?? "").split("?")[0] != (videoSourceUrl ?? "").split("?")[0]) {
                    console.log("Setting video source url to " + newSourceUrl + ", old url was " + videoSourceUrl)
                    setVideoSourceUrl(newSourceUrl)
                    // If we're still displaying the local file, and we get a new remote file, clear our local file and display that instead.
                    setLocalVideoUri(null)
                }
                if (newStatus != videoStatus) {
                    setVideoStatus(newStatus)
                }
            } else {
                if (videoStatus != null) {
                    // If we already had a real status value, and now we have a null message, clear the status.
                    setVideoStatus(null)
                }
            }
            setMessageChanged(prop("text", incomingMessage) != captainMessageText)
        }
    }, [incomingMessage])

    useEffect(() => {
        setMessageChanged(prop("text", incomingMessage) != captainMessageText)
    }, [captainMessageText])

    useEffect(() => {
        console.log("Video status changed to " + JSON.stringify(videoStatus))
    }, [videoStatus])

    const uploadVideo = (uri) => {
        setVideoErrorVisible(false)
        console.log("Chosen video URI is " + uri)
        const eventId = prop("eventId", event)

        setVideoPendingUploadUri(uri)
        setLocalVideoUri(uri)
        // Put this in AsyncStorage also, in case we get killed for some reason.
        var uploadInfo = {}
        uploadInfo[eventId] = uri
        AsyncStorage.setItem(AppConstants.videoPendingUploadUri, JSON.stringify(uploadInfo))

        // Push this change to the API.
        // 1. Get the Mux upload URL
        // 2. Upload the video to Mux
        // 3. Update our message to reflect that this video ID is now available.
        getMuxUploadInfo(
            async (uploadInfo) => {
                console.log("Got Mux upload info: " + JSON.stringify(uploadInfo))
                const { videoPassthroughId, url } = uploadInfo || {}
                await uploadVideoToMux(
                    uri,
                    url,
                    (progress) => {
                        console.log("Upload progress: " + progress)
                    },
                    () => {
                        console.log("Video uploaded successfully!")
                        // Clear our pending upload URI
                        setVideoPendingUploadUri(null)
                        // Clear AsyncStorage also
                        AsyncStorage.removeItem(AppConstants.videoPendingUploadUri)

                        // Try to update the neighborhood message.
                        const existingMessageId = prop("id", incomingMessage)
                        const payload = {
                            neighborhood: prop("id", activeNeighborhoodData),
                            event: eventId,
                            videoPassthroughId: videoPassthroughId,
                        }
                        dispatch(updateNeighborhoodMessage(payload, existingMessageId))

                        // Wait a few seconds, then refresh neighborhood messages, since processing is probably complete.
                        setTimeout(() => {
                            const neighborhoodId = prop("id", activeNeighborhoodData)
                            if (neighborhoodId != null) {
                                console.log("Doing a timed refresh of neighborhood messages after a video upload")
                                dispatch(fetchNeighborhoodMessages(neighborhoodId))
                            }
                        }, 5000)
                    },
                    (error) => {
                        console.log("Error while uploading to Mux: " + JSON.stringify(error.message))
                        setVideoErrorVisible(true)
                    }
                )
            },
            (error) => {
                console.log("Error getting mux upload info: " + JSON.stringify(error))
                setVideoErrorVisible(true)
            }
        )
    }

    const videoUploadTapped = async () => {
        // Take a video
        try {
            const permission = await ImagePicker.requestCameraPermissionsAsync()
            if (permission.granted === false) {
                Alert.alert(i18n.t("photoPermissionMissingTitle"), i18n.t("photoPermissionMissing"), [
                    {
                        text: i18n.t("ok"),
                        style: "cancel",
                    },
                ])
            }
            // At this point, we have permission.
            // If the user cancelled the action, the method returns { cancelled: true }.
            // Otherwise, this method returns information about the selected media item.
            // When the chosen item is an image, this method returns { cancelled: false, type: 'image', uri, width, height, exif, base64 };
            // when the item is a video, this method returns { cancelled: false, type: 'video', uri, width, height, duration }.
            const { cancelled, type, uri, width, height, exif, base64 } = await ImagePicker.launchCameraAsync({
                allowsEditing: false,
                allowsMultipleSelection: false,
                mediaTypes: ImagePicker.MediaTypeOptions.Videos,
                videoMaxDuration: 30,
            })
            if (!cancelled) {
                uploadVideo(uri)
            }
        } catch (error) {
            console.log("Error trying to record a video: " + JSON.stringify(error))
        }
    }

    const videoPlayTapped = async () => {
        // video.current?.pauseAsync()
        // video.current?.setIsLoopingAsync(false)
        video.current?.presentFullscreenPlayer()
    }

    const removeVideoTapped = async () => {
        showActionSheetWithOptions(
            {
                options: [i18n.t("neighborhood.message.videoUpload.remove"), i18n.t("cancel")],
                destructiveButtonIndex: 0,
                cancelButtonIndex: 1,
            },
            async (buttonIndex) => {
                switch (buttonIndex) {
                    case 0:
                        // Remove the photo.

                        // Clear any existing upload and remove the error panel.
                        setVideoPendingUploadUri(null)
                        // Clear AsyncStorage also
                        AsyncStorage.removeItem(AppConstants.videoPendingUploadUri)
                        setVideoErrorVisible(false)

                        // Update our message to remove the video
                        const existingMessageId = prop("id", incomingMessage)
                        const payload = {
                            neighborhood: prop("id", activeNeighborhoodData),
                            event: prop("eventId", event),
                            videoPassthroughId: null,
                        }
                        dispatch(updateNeighborhoodMessage(payload, existingMessageId))
                        break
                    case 1:
                        // Cancel
                        console.log("Cancel")
                        break
                    default:
                        break
                }
            }
        )
    }

    if (!captain && (captainMessageText == null || captainMessageText.length == 0) && isEmpty(prop("video", incomingMessage))) {
        return null
    }

    // NOTE: There's a bug in ExpoAV that means that showing this video on a LOCAL web build (via expo web -w) crashes the app in lots of cases. It doesn't
    // occur on real web builds.
    const showVideoSection =
        /*Platform.OS != "web" &&*/ videoSourceUrl != null && (videoStatus == "ready" || videoPendingUploadUri != null || localVideoUri != null)

    // console.log("Remote video URL is " + videoSourceUrl)
    return (
        <View style={[{ backgroundColor: theme.greenDark, borderRadius: 8, overflow: "hidden", paddingHorizontal: 16, paddingVertical: 24 }, containerStyle]}>
            {captain && (
                <View style={{ marginBottom: 16 }}>
                    <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 8 }}>
                        <Image source={theme.icons.commentsDarkGreen} style={{ width: 32, height: 32, marginRight: 16 }} />
                        <Text style={[TextStyles.capsSmall, { flexShrink: 1, color: rawThemes.dark.textPrimary }]}>
                            {i18n.t("neighborhood.message.captainTitle")}
                        </Text>
                    </View>
                    <Text style={[TextStyles.body3Medium, { flexShrink: 1, color: rawThemes.dark.textPrimary }]}>
                        {i18n.t("neighborhood.message.captainDescription")}
                    </Text>
                </View>
            )}
            {showVideoSection && (
                <View style={{ marginBottom: 8, borderRadius: 8, overflow: "hidden" }}>
                    <Video
                        ref={video}
                        source={{
                            // In order, show: our local temp video, anything we've stored in the pending state, and the actual video.
                            uri: localVideoUri ?? videoPendingUploadUri ?? videoSourceUrl, //"https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4",
                            overrideFileExtensionAndroid: "m3u8",
                        }}
                        // useNativeControls
                        resizeMode="cover"
                        isMuted
                        isLooping
                        onLoad={() => {
                            // console.log("Video loaded, uri was " + localVideoUri ?? videoPendingUploadUri ?? videoSourceUrl)
                            video.current.playAsync()
                        }}
                        onError={(error) => console.log("Video error: " + error + ", uri was " + (localVideoUri ?? videoPendingUploadUri ?? videoSourceUrl))}
                        onFullscreenUpdate={(event) => {
                            switch (prop("fullscreenUpdate", event)) {
                                case 0:
                                    console.log("Will present")
                                    video.current?.setPositionAsync(0)
                                    video.current?.setIsMutedAsync(false)
                                    break
                                case 1:
                                    console.log("Did present")
                                    break
                                case 2:
                                    console.log("Will dismiss")
                                    video.current?.setIsMutedAsync(true)
                                    break
                                case 3:
                                    console.log("Did dismiss")
                                    video.current?.playAsync()
                                    break
                            }
                            // console.log(event)
                        }}
                        style={{ aspectRatio: 0.75, width: "100%" }}
                    />
                    <TouchableOpacity style={{ position: "absolute", width: "100%", height: "100%" }} onPress={videoPlayTapped}>
                        <Image style={{ position: "absolute", bottom: 16, right: 16, width: 48, height: 48 }} source={theme.icons.videoPlay} />
                    </TouchableOpacity>
                    {videoErrorVisible && (
                        <View
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.6)",
                                paddingHorizontal: 24,
                                paddingVertical: 32,
                            }}
                        >
                            <Text style={[TextStyles.body2Semi, { color: theme.white, textAlign: "center", marginBottom: 24 }]}>
                                {i18n.t("neighborhood.message.videoUpload.error")}
                            </Text>
                            <Button
                                type="outline"
                                containerStyle={{ marginBottom: 16 }}
                                contentTintColor={theme.white}
                                buttonTintColor={theme.white}
                                title={i18n.t("neighborhood.message.videoUpload.retry")}
                                onPress={() => {
                                    // Retry the upload
                                    uploadVideo(videoPendingUploadUri)
                                }}
                            />
                            <Button
                                type="outline"
                                containerStyle={{ marginBottom: 16 }}
                                contentTintColor={theme.white}
                                buttonTintColor={theme.white}
                                title={i18n.t("neighborhood.message.videoUpload.recordNew")}
                                onPress={() => {
                                    // Clear any existing upload
                                    setVideoPendingUploadUri(null)
                                    // Clear AsyncStorage also
                                    AsyncStorage.removeItem(AppConstants.videoPendingUploadUri)
                                    // Start the record and upload process again.
                                    videoUploadTapped()
                                }}
                            />
                            <Button
                                type="ghost"
                                contentTintColor={theme.white}
                                buttonTintColor={theme.white}
                                title={i18n.t("neighborhood.message.videoUpload.clear")}
                                onPress={() => {
                                    // Clear any existing upload and remove the panel.
                                    setVideoPendingUploadUri(null)
                                    // Clear AsyncStorage also
                                    AsyncStorage.removeItem(AppConstants.videoPendingUploadUri)
                                    setVideoErrorVisible(false)
                                }}
                            />
                        </View>
                    )}
                </View>
            )}
            {captain && showVideoSection && (
                <TouchableOpacity style={{ marginBottom: 16 }} onPress={removeVideoTapped}>
                    <Text style={[TextStyles.capsExtraSmall, { color: theme.white }]}>{i18n.t("neighborhood.message.videoUpload.remove")}</Text>
                </TouchableOpacity>
            )}
            <View style={{ padding: captain ? 12 : 8, marginBottom: 16, borderWidth: captain ? 1 : 0, borderRadius: 4, borderColor: theme.white }}>
                <TextInput
                    style={[
                        captain ? TextStyles.body2 : TextStyles.body1,
                        {
                            paddingTop: 0,
                            flexShrink: 1,
                            color: rawThemes.dark.textPrimary,
                            // Min height is calculated incorrectly on Android, so the placeholder text gets cut off. Manually increase it.
                            minHeight: Platform.OS == "android" ? 132 : 120,
                        },
                    ]}
                    editable={captain}
                    multiline={true}
                    textAlignVertical={"top"}
                    maxLength={400}
                    placeholder={i18n.t("neighborhood.message.captainPlaceholder", {
                        neighborhoodName: prop("name", activeNeighborhoodData),
                        eventTime: eventTimeFormatted(event),
                    })}
                    placeholderTextColor={rawThemes.dark.textSecondary}
                    onChangeText={(text) => {
                        setCaptainMessageText(text)
                    }}
                    blurOnSubmit={true}
                    returnKeyType={"done"}
                    value={captainMessageText}
                />
            </View>

            {captain ? (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {/* Video button */}
                    <TouchableOpacity
                        style={{
                            borderRadius: 1000,
                            backgroundColor: videoErrorVisible ? rawThemes.dark.surface1 : theme.white,
                            justifyContent: "center",
                            alignItems: "center",
                            width: 72,
                            height: 48,
                            marginRight: 6,
                        }}
                        onPress={videoUploadTapped}
                    >
                        <Image
                            source={videoErrorVisible ? rawThemes.dark.icons.video : theme.icons.videoGreen}
                            style={{ width: 24, height: 24, opacity: videoErrorVisible ? 0.7 : 1.0 }}
                        />
                    </TouchableOpacity>
                    {/* Update message button */}
                    <Button
                        type="fill"
                        containerStyle={{ flex: 1 }}
                        buttonTintColor={messageChanged ? theme.white : rawThemes.dark.surface1}
                        contentTintColor={messageChanged ? theme.greenDark : rawThemes.dark.textSecondary}
                        title={i18n.t("neighborhood.message.captainButtonTitle")}
                        onPress={() => {
                            if (messageChanged) {
                                // Try to update the neighborhood message.
                                const existingMessageId = prop("id", incomingMessage)
                                const payload = {
                                    neighborhood: prop("id", activeNeighborhoodData),
                                    event: prop("eventId", event),
                                    text: captainMessageText,
                                }
                                dispatch(updateNeighborhoodMessage(payload, existingMessageId))
                                Keyboard.dismiss()
                            }
                        }}
                    />
                </View>
            ) : (
                // Attribution
                <View style={{ flexDirection: "row", alignItems: "center", paddingHorizontal: 8, marginBottom: 8 }}>
                    <Image source={theme.icons.commentsDarkGreen} style={{ width: 40, height: 40, marginRight: 10 }} />
                    <View>
                        {userPublicProfile != null && userPublicProfile != {} && (
                            <Text style={[TextStyles.capsSmall, { flexShrink: 1, color: rawThemes.dark.textPrimary }]}>
                                {prop("firstName", userPublicProfile) + " " + prop("lastName", userPublicProfile) + "."}
                            </Text>
                        )}
                        <Text style={[TextStyles.caption, { flexShrink: 1, color: rawThemes.dark.textSecondary }]}>
                            {i18n.t("neighborhood.message.attribution")}
                        </Text>
                    </View>
                </View>
            )}
        </View>
    )
}
