import * as React from "react"
import { View, Text } from "react-native"
import Colors from "../shared/Colors"
import { isEmpty, path, prop } from "ramda"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function ErrorMessageInline(props) {
    const { theme } = React.useContext(ThemeContext)

    return (
        <View
            {...props}
            style={[
                { paddingHorizontal: 16, paddingVertical: 8, marginBottom: 16, borderRadius: 8, backgroundColor: theme.redTranslucent },
                props.containerStyle,
            ]}
        >
            <Text style={[TextStyles.body3Medium, { color: theme.textSecondary }]}>{prop("text", props) || "Encountered an error"}</Text>
        </View>
    )
}
