import AsyncStorage from "@react-native-async-storage/async-storage"
import * as Analytics from "expo-firebase-analytics"
import { auth } from "../shared/firebase.js"
import { signOut } from "firebase/auth"

import { prop, path } from "ramda"
import { Platform } from "react-native"
import * as Sentry from "sentry-expo"
import AppConstants, { DataSource, DataTimeframe, ScreenNames } from "../shared/AppConstants"
import moment from "moment-timezone"
import { currentAndFutureEventIds, isEmpty, setDefaultTimeZone, showToast } from "../shared/Utils.js"
import i18n from "../shared/i18n.js"
import Toast from "react-native-root-toast"
const axios = require("axios").default
import _ from "lodash"
import { useDispatch, useSelector, useStore } from "react-redux"
import { rawThemes } from "../shared/ThemeContext.js"
import { async } from "@firebase/util"

if (Platform.OS !== "web") {
    Adjust = require("react-native-adjust").Adjust
    AdjustConfig = require("react-native-adjust").AdjustConfig
    AdjustEvent = require("react-native-adjust").AdjustEvent
}

// To allow us to cancel Axios calls later
let axiosCancelToken = axios.CancelToken

// Global timeout for all of our calls.
const timeoutLength = 30 * 1000

const noUtilityError = Error("Tried to fetch utility-specific info without a utility account selected")

export function fetchEndpoint(url, beginFetchCallback, successCallback, errorCallback, responseDataPath = ["data"], showDebug = false) {
    return (dispatch) => {
        dispatch(beginFetchCallback())

        let currentUser = auth.currentUser
        if (currentUser != null) {
            // First, get the user's token
            currentUser
                .getIdToken()
                .then((token) => {
                    axios
                        .get(url, {
                            params: {},
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                            timeout: timeoutLength,
                        })
                        .then(function (response) {
                            // If we actually get notifications data, return it!!
                            if (showDebug) {
                                console.log("Got data from " + url + "\n" + JSON.stringify(path(responseDataPath, response)))
                            }
                            dispatch(successCallback(path(responseDataPath, response)))
                        })
                        .catch(function (error) {
                            // If we failed to get notifications data, throw an error
                            console.log("Failed to get data from " + url + ": " + JSON.stringify(error.message))
                            if (Platform.OS == "web") {
                                Sentry.Browser.captureException(error)
                            } else {
                                Sentry.Native.captureException(error)
                            }
                            dispatch(errorCallback(error))
                        })
                })
                .catch(function (error) {
                    console.log("getIdToken failed, logging the user out. Error was: " + error)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("getIdToken failed: " + error)
                    } else {
                        Sentry.Native.captureException("getIdToken failed: " + error)
                    }
                    dispatch(performSignOut())
                })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

function utilityAccountMissingError(error, callback) {
    console.log("Utility account missing: " + JSON.stringify(error.message)) //+ " with callback " + callback)
    // If we failed to get an account ID, throw an error
    if (Platform.OS == "web") {
        Sentry.Browser.captureException(error)
    } else {
        Sentry.Native.captureException(error)
    }
    callback(error)
}

/* Accounts data */
export const FETCH_ACCOUNTS_BEGIN = "FETCH_ACCOUNTS_BEGIN"
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS"
export const FETCH_ACCOUNTS_ERROR = "FETCH_ACCOUNTS_ERROR"

export const fetchAccountsBegin = () => ({
    type: FETCH_ACCOUNTS_BEGIN,
})

export const fetchAccountsSuccess = (accountsData) => ({
    type: FETCH_ACCOUNTS_SUCCESS,
    accountsData,
})

export const fetchAccountsError = (error) => ({
    type: FETCH_ACCOUNTS_ERROR,
    error,
})

export function fetchAccountsData() {
    return (dispatch) => {
        dispatch(fetchAccountsBegin())

        let url = `${AppConstants.apiBase}/user/gridrewards/accounts`
        let currentUser = auth.currentUser
        if (currentUser != null) {
            currentUser
                .getIdToken()
                .then((token) => {
                    // Store the token.
                    AsyncStorage.setItem(AppConstants.currentFirebaseToken, token)
                    // Once we have the correct token, figure out what utility accounts this user has.
                    axios
                        .get(url, {
                            params: {},
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                            timeout: timeoutLength,
                        })
                        .then(function (response) {
                            let accountsResponse = response.data
                            // console.log("Got accounts data from " + url + "\n" + JSON.stringify(accountsResponse))
                            let accountIds = (accountsResponse || []).map((item) => prop("accountId", item))
                            if (accountIds.length == 0) {
                                // No connected accounts.
                                if (Platform.OS == "web") {
                                    Sentry.Browser.captureMessage("Got zero valid accounts while trying to fetch primary data")
                                } else {
                                    Sentry.Native.captureMessage("Got zero valid accounts while trying to fetch primary data")
                                }
                                dispatch(fetchAccountsSuccess(accountsResponse))
                            } else {
                                // At this point, we have accounts data.
                                // Check against our stored account: if the stored account is in the list, we're done.
                                // Otherwise, pick the first thing from the accounts list and save it as our stored account.
                                AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((currentAccount) => {
                                    if (accountIds.includes(currentAccount) == false) {
                                        AsyncStorage.setItem(AppConstants.currentUtilityAccount, accountIds[0])
                                    }
                                    dispatch(fetchAccountsSuccess(accountsResponse))
                                })
                            }
                        })
                })
                .catch(function (error) {
                    console.log("getIdToken failed, logging the user out. Error was: " + error)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("getIdToken failed: " + error)
                    } else {
                        Sentry.Native.captureException("getIdToken failed: " + error)
                    }
                    dispatch(performSignOut())
                })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

/* User profile data */
export const FETCH_PROFILE_BEGIN = "FETCH_PROFILE_BEGIN"
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS"
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR"

export const fetchProfileDataBegin = () => ({
    type: FETCH_PROFILE_BEGIN,
})

export const fetchProfileDataSuccess = (profileData) => ({
    type: FETCH_PROFILE_SUCCESS,
    profileData,
})

export const fetchProfileDataError = (error) => ({
    type: FETCH_PROFILE_ERROR,
    error,
})

export function fetchProfileData() {
    return (dispatch) => {
        dispatch(fetchEndpoint(`${AppConstants.apiBase}/users/profile`, fetchProfileDataBegin, fetchProfileDataSuccess, fetchProfileDataError, ["data"], false))
    }
}

/* Updating the user profile */
export const UPDATE_PROFILE_BEGIN = "UPDATE_PROFILE_BEGIN"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR"

export const updateProfileDataBegin = () => ({
    type: UPDATE_PROFILE_BEGIN,
})

export const updateProfileDataSuccess = (profileData) => ({
    type: UPDATE_PROFILE_SUCCESS,
    profileData,
})

export const updateProfileDataError = (error) => ({
    type: UPDATE_PROFILE_ERROR,
    error,
})

export function updateProfileData(partialProfile, fetchOnComplete = false) {
    return (dispatch) => {
        // console.log("Trying to update profile data to " + JSON.stringify(partialProfile))
        dispatch(updateProfileDataBegin())
        let currentUser = auth.currentUser
        if (currentUser != null) {
            let url = `${AppConstants.apiBase}/users/profile`
            currentUser
                .getIdToken()
                .then((token) => {
                    axios
                        .patch(url, partialProfile, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                        })
                        .then(function (response) {
                            // console.log("Updated profile data successfully, response was " + JSON.stringify(response.data))
                            console.log("Marking profile as updated")
                            AsyncStorage.setItem(AppConstants.profileUpdateComplete, "true")
                            dispatch(updateProfileDataSuccess(response.data))
                            if (fetchOnComplete == true) {
                                // Update our profile from the API
                                dispatch(fetchProfileData())
                            }
                        })
                        .catch(function (error) {
                            // If we failed to update profile data, throw an error
                            console.log("Failed to update profile data: " + JSON.stringify(error.response))
                            if (Platform.OS == "web") {
                                Sentry.Browser.captureException(error)
                            } else {
                                Sentry.Native.captureException(error)
                            }
                            showToast(i18n.t("profileUpdateGenericError"), rawThemes.light.icons.warningSquareRed, Toast.durations.SHORT)
                            dispatch(updateProfileDataError(error))
                        })
                })
                .catch(function (error) {
                    console.log("getIdToken failed, logging the user out. Error was: " + error)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("getIdToken failed: " + error)
                    } else {
                        Sentry.Native.captureException("getIdToken failed: " + error)
                    }
                    dispatch(performSignOut())
                })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

/* Updating & deleting the user's avatar */
export const UPDATE_AVATAR_BEGIN = "UPDATE_AVATAR_BEGIN"
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS"
export const UPDATE_AVATAR_ERROR = "UPDATE_AVATAR_ERROR"

export const updateAvatarBegin = () => ({
    type: UPDATE_AVATAR_BEGIN,
})

export const updateAvatarSuccess = () => ({
    type: UPDATE_AVATAR_SUCCESS,
})

export const updateAvatarError = (error) => ({
    type: UPDATE_AVATAR_ERROR,
    error,
})

export function updateAvatar(imageUri) {
    return (dispatch) => {
        // console.log("Trying to update profile data to " + JSON.stringify(partialProfile))
        dispatch(updateAvatarBegin())

        // Create a form data object, use Axios to put it in place.
        const avatarForm = new FormData()

        // Extract the end of the path to use as the filename
        let filename = imageUri.split("/").pop()

        // Infer the type of the image
        let match = /\.(\w+)$/.exec(filename)
        let type = match ? `image/${match[1]}` : `image`

        avatarForm.append("avatar", {
            uri: imageUri,
            name: filename,
            type: type,
        })

        let currentUser = auth.currentUser
        if (currentUser != null) {
            let url = `${AppConstants.apiBase}/gridrewards/users/avatars`
            currentUser
                .getIdToken()
                .then((token) => {
                    axios
                        .put(url, avatarForm, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                            timeout: 2 * 60 * 1000, //Longer timeout since this could be a larger file.
                        })
                        .then(function (response) {
                            // console.log("Updated avatar, response was " + JSON.stringify(response.data))
                            dispatch(updateAvatarSuccess(response.data))

                            // Update our profile from the API
                            dispatch(fetchProfileData())
                        })
                        .catch(function (error) {
                            // If we failed to update avatar data, throw an error
                            console.log("Failed to update avatar: " + JSON.stringify(error.response))
                            if (Platform.OS == "web") {
                                Sentry.Browser.captureException(error)
                            } else {
                                Sentry.Native.captureException(error)
                            }
                            dispatch(updateAvatarError(error))
                            showToast(i18n.t("neighborhood.message.avatarUpdateFailed"), rawThemes.light.icons.warning)
                        })
                })
                .catch(function (error) {
                    console.log("getIdToken failed, logging the user out. Error was: " + error)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("getIdToken failed: " + error)
                    } else {
                        Sentry.Native.captureException("getIdToken failed: " + error)
                    }
                    dispatch(performSignOut())
                })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

export const DELETE_AVATAR_BEGIN = "DELETE_AVATAR_BEGIN"
export const DELETE_AVATAR_SUCCESS = "DELETE_AVATAR_SUCCESS"
export const DELETE_AVATAR_ERROR = "DELETE_AVATAR_ERROR"

export const deleteAvatarBegin = () => ({
    type: DELETE_AVATAR_BEGIN,
})

export const deleteAvatarSuccess = () => ({
    type: DELETE_AVATAR_SUCCESS,
})

export const deleteAvatarError = (error) => ({
    type: DELETE_AVATAR_ERROR,
    error,
})

export function deleteAvatar() {
    return (dispatch) => {
        dispatch(deleteAvatarBegin())

        let url = `${AppConstants.apiBase}/gridrewards/users/avatars`

        let currentUser = auth.currentUser
        if (currentUser != null) {
            currentUser.getIdToken().then((token) => {
                axios
                    .delete(url, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        timeout: timeoutLength,
                    })
                    .then(function (response) {
                        // console.log("Deleted the avatar for this user: " + JSON.stringify(response.data))
                        dispatch(deleteAvatarSuccess(response.data))

                        // Re-fetch profile.
                        dispatch(fetchProfileData())
                    })
                    .catch(function (error) {
                        // If we failed to get primary data, throw an error
                        console.log("Failed to delete avatar:" + JSON.stringify(error.response))
                        if (Platform.OS == "web") {
                            Sentry.Browser.captureException(error)
                        } else {
                            Sentry.Native.captureException(error)
                        }
                        dispatch(deleteAvatarError(error))
                    })
            })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

/* Payment mailing address */
export const FETCH_PAYMENT_ADDRESS_BEGIN = "FETCH_PAYMENT_ADDRESS_BEGIN"
export const FETCH_PAYMENT_ADDRESS_SUCCESS = "FETCH_PAYMENT_ADDRESS_SUCCESS"
export const FETCH_PAYMENT_ADDRESS_ERROR = "FETCH_PAYMENT_ADDRESS_ERROR"

export const fetchPaymentAddressBegin = () => ({
    type: FETCH_PAYMENT_ADDRESS_BEGIN,
})

export const fetchPaymentAddressSuccess = (paymentAddressData) => ({
    type: FETCH_PAYMENT_ADDRESS_SUCCESS,
    paymentAddressData,
})

export const fetchPaymentAddressError = (error) => ({
    type: FETCH_PAYMENT_ADDRESS_ERROR,
    error,
})

export function fetchPaymentAddress() {
    return (dispatch) => {
        dispatch(fetchPaymentAddressBegin())

        dispatch(
            fetchEndpoint(
                `${AppConstants.apiBase}/gridrewards/payments/address`,
                fetchPaymentAddressBegin,
                fetchPaymentAddressSuccess,
                fetchPaymentAddressError,
                ["data", "results", 0],
                true
            )
        )

        // TEST ONLY: pull this from local storage.
        // AsyncStorage.getItem(AppConstants.mailingAddressLocal).then((address) => {
        //     try {
        //         const parsedAddress = JSON.parse(address)
        //         dispatch(fetchPaymentAddressSuccess(parsedAddress))
        //     } catch (error) {
        //         console.log("Error fetching payment address: " + JSON.stringify(error))
        //         dispatch(fetchPaymentAddressError(error))
        //     }
        // })
    }
}

/* Updating the user's payment address */
export const UPDATE_PAYMENT_ADDRESS_BEGIN = "UPDATE_PAYMENT_ADDRESS_BEGIN"
export const UPDATE_PAYMENT_ADDRESS_SUCCESS = "UPDATE_PAYMENT_ADDRESS_SUCCESS"
export const UPDATE_PAYMENT_ADDRESS_ERROR = "UPDATE_PAYMENT_ADDRESS_ERROR"

export const updatePaymentAddressBegin = () => ({
    type: UPDATE_PAYMENT_ADDRESS_BEGIN,
})

export const updatePaymentAddressSuccess = () => ({
    type: UPDATE_PAYMENT_ADDRESS_SUCCESS,
})

export const updatePaymentAddressError = (error) => ({
    type: UPDATE_PAYMENT_ADDRESS_ERROR,
    error,
})

export function updatePaymentAddress(newAddressJSON, existingAddressId = null, isValidated = true, validationService = "PostGrid") {
    return (dispatch) => {
        console.log("Trying to update address data data to " + JSON.stringify(newAddressJSON) + ", existing ID is " + existingAddressId)
        dispatch(updatePaymentAddressBegin())

        let currentUser = auth.currentUser
        if (currentUser != null) {
            const url = `${AppConstants.apiBase}/gridrewards/payments/address${existingAddressId ? "/" + existingAddressId : ""}`
            const payload = {
                isValidated: isValidated,
                validation_service: validationService,
                address: newAddressJSON,
            }

            currentUser
                .getIdToken()
                .then((token) => {
                    axios
                        .request({
                            url: url,
                            method: existingAddressId != null ? "patch" : "post",
                            data: payload,
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                        })
                        .then(function (response) {
                            console.log("Updated payment address, response was " + JSON.stringify(response.data))
                            dispatch(updatePaymentAddressSuccess(response.data))
                            showToast(i18n.t("mailingAddress.prefs.updateSuccessful"), rawThemes.light.icons.checkmark32)

                            // Update our payment address from the API
                            dispatch(fetchPaymentAddress())
                        })
                        .catch(function (error) {
                            // If we failed to update profile data, throw an error
                            console.log("Failed to update payment address: " + JSON.stringify(error.response))
                            if (Platform.OS == "web") {
                                Sentry.Browser.captureException(error)
                            } else {
                                Sentry.Native.captureException(error)
                            }
                            dispatch(updatePaymentAddressError(error))
                            showToast(i18n.t("mailingAddress.prefs.updateError"), rawThemes.light.icons.warning)
                        })
                })
                .catch(function (error) {
                    console.log("getIdToken failed, logging the user out. Error was: " + error)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("getIdToken failed: " + error)
                    } else {
                        Sentry.Native.captureException("getIdToken failed: " + error)
                    }
                    dispatch(performSignOut())
                })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }

        // TEST ONLY: For now, store this locally.
        // try {
        //     const newAddress = JSON.stringify(newAddressJSON)
        //     AsyncStorage.setItem(AppConstants.mailingAddressLocal, newAddress).then(() => {
        //         // console.log("Theoretically just stored new payment address in Redux: " + JSON.stringify(newAddress))
        //         dispatch(updatePaymentAddressSuccess())
        //         // Re-fetch the stored value
        //         dispatch(fetchPaymentAddress())
        //     })
        // } catch (error) {
        //     dispatch(updatePaymentAddressError(error))
        // }
    }
}

/* Main data */
export const FETCH_MAINDATA_BEGIN = "FETCH_MAINDATA_BEGIN"
export const FETCH_MAINDATA_SUCCESS = "FETCH_MAINDATA_SUCCESS"
export const FETCH_MAINDATA_ERROR = "FETCH_MAINDATA_ERROR"

export const fetchMainDataBegin = () => ({
    type: FETCH_MAINDATA_BEGIN,
})

export const fetchMainDataSuccess = (mainData) => ({
    type: FETCH_MAINDATA_SUCCESS,
    mainData,
})

export const fetchMainDataError = (error) => ({
    type: FETCH_MAINDATA_ERROR,
    error,
})

export function fetchMainData() {
    return (dispatch) => {
        dispatch(fetchMainDataBegin())

        let currentUser = auth.currentUser
        if (currentUser != null) {
            // First, get the user's token
            currentUser
                .getIdToken()
                .then((token) => {
                    // Once we have the correct token, figure out utility account to use.
                    AsyncStorage.getItem(AppConstants.currentUtilityAccount)
                        .then((currentAccount) => {
                            // TODO: Eventually, we want to use an all-zeros UUID instead of 'null' here for unconnected accounts,
                            // but the API needs to support it first.

                            let url = `${AppConstants.apiBase}/user/gridrewards/${currentAccount}/top`
                            axios
                                .get(url, {
                                    params: {},
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + token,
                                    },
                                    timeout: timeoutLength,
                                })
                                .then(function (response) {
                                    // If we actually get primary data, return it!!
                                    console.log("Got main data from " + url) // + "\n" + JSON.stringify(response.data))

                                    updateNextStepsStatus(response.data).then(dispatch(fetchMainDataSuccess(response.data)))
                                })
                                .catch(function (error) {
                                    // If we failed to get primary data, throw an error
                                    console.log("Failed to get main data: " + JSON.stringify(error.response))
                                    if (Platform.OS == "web") {
                                        Sentry.Browser.captureException(error)
                                    } else {
                                        Sentry.Native.captureException(error)
                                    }
                                    dispatch(fetchMainDataError(error))
                                })
                        })
                        .catch(function (error) {
                            // If we failed to get an account ID, throw an error
                            console.log("Failed to get current account ID: " + JSON.stringify(error.response))
                            if (Platform.OS == "web") {
                                Sentry.Browser.captureException(error)
                            } else {
                                Sentry.Native.captureException(error)
                            }
                            dispatch(fetchMainDataError(error))
                        })
                })
                .catch(function (error) {
                    console.log("getIdToken failed, logging the user out. Error was: " + error)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("getIdToken failed: " + error)
                    } else {
                        Sentry.Native.captureException("getIdToken failed: " + error)
                    }
                    dispatch(performSignOut())
                })
        } else {
            console.log("No user, logging out while getting main data")
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

/* In-app notifications */
export const FETCH_NOTIFICATIONS_BEGIN = "FETCH_NOTIFICATIONS_BEGIN"
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS"
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR"

export const fetchNotificationsBegin = () => ({
    type: FETCH_NOTIFICATIONS_BEGIN,
})

export const fetchNotificationsSuccess = (notificationsData) => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    notificationsData,
})

export const fetchNotificationsError = (error) => ({
    type: FETCH_NOTIFICATIONS_ERROR,
    error,
})

export function fetchNotifications(page, pageSize) {
    return (dispatch) => {
        dispatch(
            fetchEndpoint(
                `${AppConstants.apiBase}/gridrewards/notifications?page=${page}&page_size=${pageSize}`,
                fetchNotificationsBegin,
                fetchNotificationsSuccess,
                fetchNotificationsError,
                ["data", "results"]
            )
        )
    }
}

/* User achievements */
export const FETCH_ACHIEVEMENTS_BEGIN = "FETCH_ACHIEVEMENTS_BEGIN"
export const FETCH_ACHIEVEMENTS_SUCCESS = "FETCH_ACHIEVEMENTS_SUCCESS"
export const FETCH_ACHIEVEMENTS_ERROR = "FETCH_ACHIEVEMENTS_ERROR"

export const fetchAchievementsDataBegin = () => ({
    type: FETCH_ACHIEVEMENTS_BEGIN,
})

export const fetchAchievementsDataSuccess = (achievementsData) => ({
    type: FETCH_ACHIEVEMENTS_SUCCESS,
    achievementsData,
})

export const fetchAchievementsDataError = (error) => ({
    type: FETCH_ACHIEVEMENTS_ERROR,
    error,
})

export function fetchAchievementsData() {
    return (dispatch) => {
        dispatch(
            fetchEndpoint(
                `${AppConstants.apiBase}/gridrewards/userAchievements`,
                fetchAchievementsDataBegin,
                fetchAchievementsDataSuccess,
                fetchAchievementsDataError,
                ["data", "results"],
                false
            )
        )
    }
}

export const ADD_ACHIEVEMENT_BEGIN = "ADD_ACHIEVEMENT_BEGIN"
export const ADD_ACHIEVEMENT_SUCCESS = "ADD_ACHIEVEMENT_SUCCESS"
export const ADD_ACHIEVEMENT_ERROR = "ADD_ACHIEVEMENT_ERROR"

export const addAchievementBegin = () => ({
    type: ADD_ACHIEVEMENT_BEGIN,
})

export const addAchievementSuccess = () => ({
    type: ADD_ACHIEVEMENT_SUCCESS,
})

export const addAchievementError = (error) => ({
    type: ADD_ACHIEVEMENT_ERROR,
    error,
})

export function addAchievement(achievementType) {
    return async (dispatch) => {
        if (achievementType == null) {
            console.log("Asked to add an achievement without a type, skipping.")
            return
        }
        dispatch(addAchievementBegin())
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(noUtilityError, addAchievementError)
                    return
                }
                console.log("About to return dispatch")
                let url = `${AppConstants.apiBase}/gridrewards/userAchievements`
                let currentUser = auth.currentUser
                if (currentUser != null) {
                    currentUser.getIdToken().then((token) => {
                        axios
                            .post(
                                url,
                                {
                                    achievement: {
                                        type: achievementType,
                                    },
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + token,
                                    },
                                    timeout: timeoutLength,
                                }
                            )
                            .then(function (response) {
                                console.log("Added an achievement of type " + achievementType + ", response: " + JSON.stringify(response.data))
                                dispatch(addAchievementSuccess(response.data))
                                dispatch(fetchAchievementsData())
                            })
                            .catch(function (error) {
                                // If we failed to get primary data, throw an error
                                console.log("Failed to add achievement of type " + achievementType + ": " + JSON.stringify(error.response) + ", url was " + url)
                                if (Platform.OS == "web") {
                                    Sentry.Browser.captureException(error)
                                } else {
                                    Sentry.Native.captureException(error)
                                }
                                dispatch(addAchievementError(error))
                            })
                    })
                } else {
                    console.log("No user, logging out. Request was " + url)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("User invalid, logging out")
                    } else {
                        Sentry.Native.captureException("User invalid, logging out")
                    }
                    dispatch(performSignOut())
                }
            })
            .catch((error) => utilityAccountMissingError(error, addAchievementError))
    }
}

/* Scorecards data */
export const FETCH_SCORECARDS_BEGIN = "FETCH_SCORECARDS_BEGIN"
export const FETCH_SCORECARDS_SUCCESS = "FETCH_SCORECARDS_SUCCESS"
export const FETCH_SCORECARDS_ERROR = "FETCH_SCORECARDS_ERROR"

export const fetchScorecardsDataBegin = () => ({
    type: FETCH_SCORECARDS_BEGIN,
})

export const fetchScorecardsDataSuccess = (scorecardsData) => ({
    type: FETCH_SCORECARDS_SUCCESS,
    scorecardsData,
})

export const fetchScorecardsDataError = (error) => ({
    type: FETCH_SCORECARDS_ERROR,
    error,
})

export function fetchScorecardsData() {
    return (dispatch) => {
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(noUtilityError, fetchScorecardsDataError)
                    return
                }
                dispatch(
                    fetchEndpoint(
                        `${AppConstants.apiBase}/user/gridrewards/${currentAccount}/scorecards`,
                        fetchScorecardsDataBegin,
                        fetchScorecardsDataSuccess,
                        fetchScorecardsDataError,
                        ["data"],
                        false
                    )
                )
            })
            .catch((error) => utilityAccountMissingError(error, fetchScorecardsDataError))
    }
}

/* Neighborhood data */
export const FETCH_NEIGHBORHOOD_BEGIN = "FETCH_NEIGHBORHOOD_BEGIN"
export const FETCH_NEIGHBORHOOD_SUCCESS = "FETCH_NEIGHBORHOOD_SUCCESS"
export const FETCH_NEIGHBORHOOD_ERROR = "FETCH_NEIGHBORHOOD_ERROR"

export const fetchNeighborhoodBegin = () => {
    // console.log("Neighborhood begin called")
    return {
        type: FETCH_NEIGHBORHOOD_BEGIN,
    }
}

export const fetchNeighborhoodSuccess = (neighborhoodData) => {
    // console.log("Neighborhood success called with data " + JSON.stringify(neighborhoodData))
    return {
        type: FETCH_NEIGHBORHOOD_SUCCESS,
        neighborhoodData,
    }
}

export const fetchNeighborhoodError = (error) => ({
    type: FETCH_NEIGHBORHOOD_ERROR,
    error,
})

export function fetchNeighborhood() {
    return (dispatch) => {
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(noUtilityError, fetchNeighborhoodError)
                    return
                }
                dispatch(
                    fetchEndpoint(
                        `${AppConstants.apiBase}/gridrewards/neighborhoods?electric_utility_account=${currentAccount}&page=${1}&page_size=${100}`,
                        fetchNeighborhoodBegin,
                        fetchNeighborhoodSuccess,
                        fetchNeighborhoodError,
                        ["data", "results"],
                        false
                    )
                )
            })
            .catch((error) => utilityAccountMissingError(error, fetchNeighborhoodError))
    }
}

/* Neighborhood performance data (overall, as well as event-by-event) */
export const FETCH_NEIGHBORHOOD_PERFORMANCE_BEGIN = "FETCH_NEIGHBORHOOD_PERFORMANCE_BEGIN"
export const FETCH_NEIGHBORHOOD_PERFORMANCE_SUCCESS = "FETCH_NEIGHBORHOOD_PERFORMANCE_SUCCESS"
export const FETCH_NEIGHBORHOOD_PERFORMANCE_ERROR = "FETCH_NEIGHBORHOOD_PERFORMANCE_ERROR"

export const fetchNeighborhoodPerformanceBegin = () => {
    // console.log("Neighborhood begin called")
    return {
        type: FETCH_NEIGHBORHOOD_PERFORMANCE_BEGIN,
    }
}

export const fetchNeighborhoodPerformanceSuccess = (neighborhoodPerformanceData) => {
    // Take the first item in the results array, since this is already filtered by neighborhood.
    var firstItem = null
    if (neighborhoodPerformanceData != null && neighborhoodPerformanceData.length > 0) {
        firstItem = neighborhoodPerformanceData[0]
    }
    return {
        type: FETCH_NEIGHBORHOOD_PERFORMANCE_SUCCESS,
        neighborhoodPerformanceData: firstItem,
    }
}

export const fetchNeighborhoodPerformanceError = (error) => ({
    type: FETCH_NEIGHBORHOOD_PERFORMANCE_ERROR,
    error,
})

export function fetchNeighborhoodPerformance(neighborhoodId) {
    return (dispatch) => {
        if (neighborhoodId == null) {
            return
        }

        dispatch(
            fetchEndpoint(
                `${AppConstants.apiBase}/gridrewards/performance/neighborhoods?neighborhood=${neighborhoodId}`,
                fetchNeighborhoodPerformanceBegin,
                fetchNeighborhoodPerformanceSuccess,
                fetchNeighborhoodPerformanceError,
                ["data", "results"]
            )
        )
    }
}

export const FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_BEGIN = "FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_BEGIN"
export const FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_SUCCESS = "FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_SUCCESS"
export const FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_ERROR = "FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_ERROR"

export const fetchNeighborhoodEventPerformanceBegin = () => {
    return {
        type: FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_BEGIN,
    }
}

export const fetchNeighborhoodEventPerformanceSuccess = (neighborhoodEventPerformanceData) => {
    return {
        type: FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_SUCCESS,
        neighborhoodEventPerformanceData: neighborhoodEventPerformanceData,
    }
}

export const fetchNeighborhoodEventPerformanceError = (error) => ({
    type: FETCH_NEIGHBORHOOD_EVENT_PERFORMANCE_ERROR,
    error,
})

export function fetchNeighborhoodEventPerformance(neighborhoodId) {
    return (dispatch) => {
        if (neighborhoodId == null) {
            return
        }

        dispatch(
            fetchEndpoint(
                `${AppConstants.apiBase}/gridrewards/performance/neighborhoodEvents?neighborhood=${neighborhoodId}`,
                fetchNeighborhoodEventPerformanceBegin,
                fetchNeighborhoodEventPerformanceSuccess,
                fetchNeighborhoodEventPerformanceError,
                ["data", "results"]
            )
        )
    }
}

/* Neighborhood message data */
export const FETCH_NEIGHBORHOOD_MESSAGES_BEGIN = "FETCH_NEIGHBORHOOD_MESSAGES_BEGIN"
export const FETCH_NEIGHBORHOOD_MESSAGES_SUCCESS = "FETCH_NEIGHBORHOOD_MESSAGES_SUCCESS"
export const FETCH_NEIGHBORHOOD_MESSAGES_ERROR = "FETCH_NEIGHBORHOOD_MESSAGES_ERROR"

export const fetchNeighborhoodMessagesBegin = () => {
    return {
        type: FETCH_NEIGHBORHOOD_MESSAGES_BEGIN,
    }
}

export const fetchNeighborhoodMessagesSuccess = (neighborhoodMessagesData) => {
    return {
        type: FETCH_NEIGHBORHOOD_MESSAGES_SUCCESS,
        neighborhoodMessagesData,
    }
}

export const fetchNeighborhoodMessagesError = (error) => ({
    type: FETCH_NEIGHBORHOOD_MESSAGES_ERROR,
    error,
})

export function fetchNeighborhoodMessages(neighborhoodId) {
    return (dispatch) => {
        dispatch(
            fetchEndpoint(
                `${AppConstants.apiBase}/gridrewards/neighborhoods/${neighborhoodId}/messages/`,
                fetchNeighborhoodMessagesBegin,
                fetchNeighborhoodMessagesSuccess,
                fetchNeighborhoodMessagesError,
                ["data", "results"]
            )
        )
    }
}

/* Updating the user profile */
export const UPDATE_NEIGHBORHOOD_MESSAGE_BEGIN = "UPDATE_NEIGHBORHOOD_MESSAGE_BEGIN"
export const UPDATE_NEIGHBORHOOD_MESSAGE_SUCCESS = "UPDATE_NEIGHBORHOOD_MESSAGE_SUCCESS"
export const UPDATE_NEIGHBORHOOD_MESSAGE_ERROR = "UPDATE_NEIGHBORHOOD_MESSAGE_ERROR"

export const updateNeighborhoodMessageBegin = () => ({
    type: UPDATE_NEIGHBORHOOD_MESSAGE_BEGIN,
})

export const updateNeighborhoodMessageSuccess = () => ({
    type: UPDATE_NEIGHBORHOOD_MESSAGE_SUCCESS,
})

export const updateNeighborhoodMessageError = (error) => ({
    type: UPDATE_NEIGHBORHOOD_MESSAGE_ERROR,
    error,
})

export function updateNeighborhoodMessage(payload, existingMessageId) {
    if (isEmpty(payload)) {
        // This isn't a valid message change
        console.log("Tried to update a neighborhood message with an empty payload, skipping...")
        return
    }

    return (dispatch) => {
        // console.log("Trying to update profile data to " + JSON.stringify(partialProfile))
        dispatch(updateNeighborhoodMessageBegin())

        let currentUser = auth.currentUser
        if (currentUser != null) {
            const neighborhoodId = prop("neighborhood", payload)
            let url = `${AppConstants.apiBase}/gridrewards/neighborhoods/${neighborhoodId}/messages/${existingMessageId ?? ""}`
            currentUser
                .getIdToken()
                .then((token) => {
                    axios
                        .request({
                            url: url,
                            method: existingMessageId != null ? "patch" : "post",
                            data: payload,
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                        })
                        .then(function (response) {
                            console.log("Updated neighborhood message, response was " + JSON.stringify(response.data))
                            dispatch(updateNeighborhoodMessageSuccess(response.data))
                            showToast(i18n.t("neighborhood.message.updateSuccess"), rawThemes.light.icons.checkmark32)

                            // Update our messages list from the API
                            dispatch(fetchNeighborhoodMessages(neighborhoodId))
                        })
                        .catch(function (error) {
                            // If we failed to update profile data, throw an error
                            console.log("Failed to update network message: " + JSON.stringify(error.response))
                            if (Platform.OS == "web") {
                                Sentry.Browser.captureException(error)
                            } else {
                                Sentry.Native.captureException(error)
                            }
                            dispatch(updateNeighborhoodMessageError(error))
                            showToast(i18n.t("neighborhood.message.updateFailed"), rawThemes.light.icons.warning)
                        })
                })
                .catch(function (error) {
                    console.log("getIdToken failed, logging the user out. Error was: " + error)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("getIdToken failed: " + error)
                    } else {
                        Sentry.Native.captureException("getIdToken failed: " + error)
                    }
                    dispatch(performSignOut())
                })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

/* GridRewards event participants */
export const FETCH_PARTICIPANTS_BEGIN = "FETCH_PARTICIPANTS_BEGIN"
export const FETCH_PARTICIPANTS_SUCCESS = "FETCH_PARTICIPANTS_SUCCESS"
export const FETCH_PARTICIPANTS_ERROR = "FETCH_PARTICIPANTS_ERROR"

export const fetchParticipantsBegin = () => ({
    type: FETCH_PARTICIPANTS_BEGIN,
})

export const fetchParticipantsSuccess = (eventParticipantData) => ({
    type: FETCH_PARTICIPANTS_SUCCESS,
    eventParticipantData,
})

export const fetchParticipantsError = (error) => ({
    type: FETCH_PARTICIPANTS_ERROR,
    error,
})

// Get *all* participants for the specified events.
export function fetchParticpants(eventIds) {
    return (dispatch) => {
        if (eventIds == null || eventIds.length == 0) {
            // We don't want to accidentally fetch a huge list of events, so skip this.
            console.log("Asked to fetch participants, but no event list given. Skipping...")
            return
        }

        let eventFilter = (eventIds || []).length > 0 ? `?event=${eventIds.join()}&page=${1}&page_size=${500}` : ``
        // FIXME/NOTE: The API currently doesn't route this endpoint correctly unless we include a trailing slash, even if we have query params. Le sigh.
        // This will be merged with our existing data.
        dispatch(
            fetchEndpoint(
                `${AppConstants.apiBase}/gridrewards/events/participants/${eventFilter}`,
                fetchParticipantsBegin,
                fetchParticipantsSuccess,
                fetchParticipantsError,
                ["data", "results"],
                true
            )
        )
    }
}

export const ADD_PARTICIPANT_BEGIN = "ADD_PARTICIPANT_BEGIN"
export const ADD_PARTICIPANT_SUCCESS = "ADD_PARTICIPANT_SUCCESS"
export const ADD_PARTICIPANT_ERROR = "ADD_PARTICIPANT_ERROR"

export const addParticipantBegin = () => ({
    type: ADD_PARTICIPANT_BEGIN,
})

export const addParticipantSuccess = () => ({
    type: ADD_PARTICIPANT_SUCCESS,
})

export const addParticipantError = (error) => ({
    type: ADD_PARTICIPANT_ERROR,
    error,
})

export function addParticipant(eventId) {
    return async (dispatch, getState) => {
        if (eventId == null) {
            console.log("Asked to add an event participant without an eventId, skipping.")
            return
        }
        dispatch(addParticipantBegin())
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(noUtilityError, addParticipantError)
                    return
                }
                console.log("About to return dispatch")
                // FIXME/NOTE: The API currently doesn't route this endpoint correctly unless we include a trailing slash, even if we have query params. Le sigh.
                let url = `${AppConstants.apiBase}/gridrewards/eventParticipants/`
                let currentUser = auth.currentUser
                if (currentUser != null) {
                    currentUser.getIdToken().then((token) => {
                        console.log("About to post, eventId is " + eventId + " and account is " + currentAccount)

                        axios
                            .post(
                                url,
                                {
                                    event: eventId,
                                    electric_utility_account: currentAccount,
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + token,
                                    },
                                    timeout: timeoutLength,
                                }
                            )
                            .then(function (response) {
                                console.log("Added this user as a participant for event " + eventId + ", response: " + JSON.stringify(response.data))
                                dispatch(addParticipantSuccess(response.data))
                                // Re-fetch data.
                                const mainData = getState().primaryData.mainData
                                const events = prop("gridRewardsEvents", mainData) || []
                                dispatch(fetchParticpants(currentAndFutureEventIds(events)))
                            })
                            .catch(function (error) {
                                // If we failed to get primary data, throw an error
                                console.log("Failed to add participants for event " + eventId + ": " + JSON.stringify(error.response.data) + ", url was " + url)
                                if (Platform.OS == "web") {
                                    Sentry.Browser.captureException(error)
                                } else {
                                    Sentry.Native.captureException(error)
                                }
                                dispatch(addParticipantError(error))
                            })
                    })
                } else {
                    console.log("No user, logging out. Request was " + url)
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException("User invalid, logging out")
                    } else {
                        Sentry.Native.captureException("User invalid, logging out")
                    }
                    dispatch(performSignOut())
                }
            })
            .catch((error) => utilityAccountMissingError(error, addParticipantError))
    }
}

export const DELETE_PARTICIPANT_BEGIN = "DELETE_PARTICIPANT_BEGIN"
export const DELETE_PARTICIPANT_SUCCESS = "DELETE_PARTICIPANT_SUCCESS"
export const DELETE_PARTICIPANT_ERROR = "DELETE_PARTICIPANT_ERROR"

export const deleteParticipantBegin = () => ({
    type: DELETE_PARTICIPANT_BEGIN,
})

export const deleteParticipantSuccess = () => ({
    type: DELETE_PARTICIPANT_SUCCESS,
})

export const deleteParticipantError = (error) => ({
    type: DELETE_PARTICIPANT_ERROR,
    error,
})

// Note that this takes the ID of the *participant object*, not the utility account or event IDs.
export function deleteParticipant(participantObjectId) {
    if (participantObjectId == null) {
        console.log("Asked to delete an event participant without a participantObjectId, skipping.")
    }

    return (dispatch) => {
        dispatch(deleteParticipantBegin())

        // FIXME/NOTE: The API currently doesn't route this endpoint correctly unless we include a trailing slash, even if we have query params. Le sigh.
        let url = `${AppConstants.apiBase}/gridrewards/eventParticipants/${participantObjectId}`

        let currentUser = auth.currentUser
        if (currentUser != null) {
            currentUser.getIdToken().then((token) => {
                axios
                    .delete(url, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        timeout: timeoutLength,
                    })
                    .then(function (response) {
                        console.log("Removed this user as a participant for event " + eventId + ", response: " + JSON.stringify(response.data))
                        dispatch(deleteParticipantSuccess(response.data))

                        // Re-fetch data.
                        const mainData = useSelector((state) => state.primaryData.mainData)
                        const events = prop("gridRewardsEvents", mainData) || []
                        dispatch(fetchParticpants(currentAndFutureEventIds(events)))
                    })
                    .catch(function (error) {
                        // If we failed to get primary data, throw an error
                        console.log("Failed to delete participants for event " + eventId + ": " + error + ", url was " + url)
                        if (Platform.OS == "web") {
                            Sentry.Browser.captureException(error)
                        } else {
                            Sentry.Native.captureException(error)
                        }
                        dispatch(deleteParticipantError(error))
                    })
            })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

/* GridRewards history data */
export const FETCH_GRIDREWARDS_HISTORY_BEGIN = "FETCH_GRIDREWARDS_HISTORY_BEGIN"
export const FETCH_GRIDREWARDS_HISTORY_SUCCESS = "FETCH_GRIDREWARDS_HISTORY_SUCCESS"
export const FETCH_GRIDREWARDS_HISTORY_ERROR = "FETCH_GRIDREWARDS_HISTORY_ERROR"

export const fetchGridRewardsHistoryDataBegin = () => ({
    type: FETCH_GRIDREWARDS_HISTORY_BEGIN,
})

export const fetchGridRewardsHistoryDataSuccess = (gridRewardsHistoryData) => ({
    type: FETCH_GRIDREWARDS_HISTORY_SUCCESS,
    gridRewardsHistoryData,
})

export const fetchGridRewardsHistoryDataError = (error) => ({
    type: FETCH_GRIDREWARDS_HISTORY_ERROR,
    error,
})

export function fetchGridRewardsHistoryData() {
    return (dispatch) => {
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(noUtilityError, fetchGridRewardsHistoryDataError)
                    return
                }
                dispatch(
                    fetchEndpoint(
                        `${AppConstants.apiBase}/user/gridrewards/${currentAccount}/gridrewards-event-history`,
                        fetchGridRewardsHistoryDataBegin,
                        fetchGridRewardsHistoryDataSuccess,
                        fetchGridRewardsHistoryDataError,
                        ["data"]
                    )
                )
            })
            .catch((error) => utilityAccountMissingError(error, fetchGridRewardsHistoryDataError))
    }
}

/* History data */
export const FETCH_ELECTRICITY_HISTORY_BEGIN = "FETCH_ELECTRICITY_HISTORY_BEGIN"
export const FETCH_ELECTRICITY_HISTORY_SUCCESS = "FETCH_ELECTRICITY_HISTORY_SUCCESS"
export const FETCH_ELECTRICITY_HISTORY_ERROR = "FETCH_ELECTRICITY_HISTORY_ERROR"

export const FETCH_SHORT_ELECTRICITY_HISTORY_BEGIN = "FETCH_SHORT_ELECTRICITY_HISTORY_BEGIN"
export const FETCH_SHORT_ELECTRICITY_HISTORY_SUCCESS = "FETCH_SHORT_ELECTRICITY_HISTORY_SUCCESS"
export const FETCH_SHORT_ELECTRICITY_HISTORY_ERROR = "FETCH_SHORT_ELECTRICITY_HISTORY_ERROR"

export const FETCH_GAS_HISTORY_BEGIN = "FETCH_GAS_HISTORY_BEGIN"
export const FETCH_GAS_HISTORY_SUCCESS = "FETCH_GAS_HISTORY_SUCCESS"
export const FETCH_GAS_HISTORY_ERROR = "FETCH_GAS_HISTORY_ERROR"

export const fetchElectricityHistoryBegin = () => ({
    type: FETCH_ELECTRICITY_HISTORY_BEGIN,
})

export const fetchElectricityHistorySuccess = (historyData) => ({
    type: FETCH_ELECTRICITY_HISTORY_SUCCESS,
    historyData,
})

export const fetchElectricityHistoryError = (error) => ({
    type: FETCH_ELECTRICITY_HISTORY_ERROR,
    error,
})

export const fetchShortElectricityHistoryBegin = () => ({
    type: FETCH_SHORT_ELECTRICITY_HISTORY_BEGIN,
})

export const fetchShortElectricityHistorySuccess = (historyData) => ({
    type: FETCH_SHORT_ELECTRICITY_HISTORY_SUCCESS,
    historyData,
})

export const fetchShortElectricityHistoryError = (error) => ({
    type: FETCH_SHORT_ELECTRICITY_HISTORY_ERROR,
    error,
})

export const fetchGasHistoryBegin = () => ({
    type: FETCH_GAS_HISTORY_BEGIN,
})

export const fetchGasHistorySuccess = (historyData) => ({
    type: FETCH_GAS_HISTORY_SUCCESS,
    historyData,
})

export const fetchGasHistoryError = (error) => ({
    type: FETCH_GAS_HISTORY_ERROR,
    error,
})

export function fetchShortElectricityHistory() {
    return (dispatch) => {
        let endDate = moment()
        endDate.subtract(1, "days")

        let startDate = endDate.clone()
        startDate.subtract(7, "days")

        let granularity = DataTimeframe.hour

        dispatch(fetchHistory(startDate, endDate, DataSource.electricity, granularity, true))
    }
}

// Expects Moment data for start and end dates
// `shortData` indicates that we're going to use this data for a summary or sparkline-type graph.
export function fetchHistory(startDate, endDate, dataSource = DataSource.electricity, granularity = DataTimeframe.day, shortData = false) {
    let commodityString = ""
    switch (dataSource) {
        case DataSource.electricity:
            commodityString = "electric"
            break
        case DataSource.gas:
            commodityString = "gas"
            break
        default:
            break
    }

    let levelString = ""
    switch (granularity) {
        case DataTimeframe.hour:
            levelString = "hourly"
            break
        case DataTimeframe.day:
            levelString = "daily"
            break
        case DataTimeframe.billingCycle:
            levelString = "bp"
            break
        default:
            break
    }

    return (dispatch) => {
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(
                        noUtilityError,
                        dataSource == DataSource.electricity
                            ? shortData
                                ? fetchShortElectricityHistoryError
                                : fetchElectricityHistoryError
                            : fetchGasHistoryError
                    )
                    return
                }
                dispatch(
                    fetchEndpoint(
                        `${AppConstants.apiBase}/user/history/${currentAccount}?start=${startDate.toISOString(true)}&end=${endDate.toISOString(
                            true
                        )}&commodity=${commodityString}&level=${levelString}`,
                        dataSource == DataSource.electricity
                            ? shortData
                                ? fetchShortElectricityHistoryBegin
                                : fetchElectricityHistoryBegin
                            : fetchGasHistoryBegin,
                        dataSource == DataSource.electricity
                            ? shortData
                                ? fetchShortElectricityHistorySuccess
                                : fetchElectricityHistorySuccess
                            : fetchGasHistorySuccess,
                        dataSource == DataSource.electricity
                            ? shortData
                                ? fetchShortElectricityHistoryError
                                : fetchElectricityHistoryError
                            : fetchGasHistoryError,
                        ["data"],
                        false
                    )
                )
            })
            .catch((error) =>
                utilityAccountMissingError(
                    error,
                    dataSource == DataSource.electricity ? (shortData ? fetchShortElectricityHistoryError : fetchElectricityHistoryError) : fetchGasHistoryError
                )
            )
    }
}

/* GridRewards payments data */
export const FETCH_PAYMENTS_BEGIN = "FETCH_PAYMENTS_BEGIN"
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS"
export const FETCH_PAYMENTS_ERROR = "FETCH_PAYMENTS_ERROR"

export const fetchPaymentsBegin = () => ({
    type: FETCH_PAYMENTS_BEGIN,
})

export const fetchPaymentsSuccess = (paymentsData) => ({
    type: FETCH_PAYMENTS_SUCCESS,
    paymentsData,
})

export const fetchPaymentsError = (error) => ({
    type: FETCH_PAYMENTS_ERROR,
    error,
})

export function fetchPayments() {
    return (dispatch) => {
        dispatch(fetchEndpoint(`${AppConstants.apiBase}/gridrewards/payments/summary`, fetchPaymentsBegin, fetchPaymentsSuccess, fetchPaymentsError))
    }
}

/* Map data */
export const FETCH_MAP_BEGIN = "FETCH_MAP_BEGIN"
export const FETCH_MAP_SUCCESS = "FETCH_MAP_SUCCESS"
export const FETCH_MAP_ERROR = "FETCH_MAP_ERROR"

export const fetchMapDataBegin = () => ({
    type: FETCH_MAP_BEGIN,
})

export const fetchMapDataSuccess = (mapData) => ({
    type: FETCH_MAP_SUCCESS,
    mapData,
})

export const fetchMapDataError = (error) => ({
    type: FETCH_MAP_ERROR,
    error,
})

var mapCancelToken
export function fetchMapData(latitude, longitude, latitudeDelta, longitudeDelta) {
    // const cancelTokenSource = axios.CancelToken.source()

    return (dispatch) => {
        dispatch(fetchMapDataBegin())

        let currentUser = auth.currentUser
        if (currentUser != null) {
            // Get the current token and figure out utility account to use.
            AsyncStorage.multiGet([AppConstants.currentFirebaseToken, AppConstants.currentUtilityAccount])
                .then((ids) => {
                    const token = ids.find((possibleItem) => possibleItem[0] == AppConstants.currentFirebaseToken)[1]
                    // This isn't used currently, but might be for positioning a "current property" indicator later
                    const currentAccount = ids.find((possibleItem) => possibleItem[0] == AppConstants.currentUtilityAccount)[1]

                    //Check if there are any previous pending requests
                    if (typeof mapCancelToken != typeof undefined) {
                        // console.log("Cancelling a map request because we have a live cancel token")
                        mapCancelToken.cancel("Map call canceled due to new request.")
                    }

                    //Save the cancel token for the current request
                    mapCancelToken = axiosCancelToken.source()

                    // Once we have the correct token, go get the map data.
                    let url = `${AppConstants.apiBase}/gridrewards/geo/list?latitude=${latitude}&longitude=${longitude}&latitudeDelta=${latitudeDelta}&longitudeDelta=${longitudeDelta}`
                    axios
                        .get(url, {
                            params: {},
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                            timeout: timeoutLength,
                            cancelToken: mapCancelToken.token, //Pass the cancel token to the current request
                        })
                        .then(function (response) {
                            // If we actually get map data, return it!!
                            console.log("Got map data") //from " + url) // + "\n" + JSON.stringify(response.data))
                            dispatch(fetchMapDataSuccess(response.data))
                        })
                        .catch(function (error) {
                            if (axios.isCancel(error)) {
                                // console.log("CANCELLED MAP REQUEST: " + JSON.stringify(error))
                            } else {
                                // If we failed to get primary data, throw an error
                                // console.log("Failed to get map data: " + JSON.stringify(error.response))
                                if (Platform.OS == "web") {
                                    Sentry.Browser.captureException(error)
                                } else {
                                    Sentry.Native.captureException(error)
                                }
                                dispatch(fetchMapDataError(error))
                            }
                        })
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        console.log("CANCELLED MAP USER ID REQUEST: " + JSON.stringify(error))
                    } else {
                        // If we failed to get an account ID, throw an error
                        console.log("Map failed to load current account ID or auth token: " + JSON.stringify(error))
                        if (Platform.OS == "web") {
                            Sentry.Browser.captureException(error)
                        } else {
                            Sentry.Native.captureException(error)
                        }
                        dispatch(fetchMapDataError(error))
                    }
                })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

/* Map preview data */
export const FETCH_MAP_PREVIEW_BEGIN = "FETCH_MAP_PREVIEW_BEGIN"
export const FETCH_MAP_PREVIEW_SUCCESS = "FETCH_MAP_PREVIEW_SUCCESS"
export const FETCH_MAP_PREVIEW_ERROR = "FETCH_MAP_PREVIEW_ERROR"

export const fetchMapPreviewDataBegin = () => ({
    type: FETCH_MAP_PREVIEW_BEGIN,
})

export const fetchMapPreviewDataSuccess = (mapPreviewData) => ({
    type: FETCH_MAP_PREVIEW_SUCCESS,
    mapPreviewData,
})

export const fetchMapPreviewDataError = (error) => ({
    type: FETCH_MAP_PREVIEW_ERROR,
    error,
})

export function fetchMapPreviewData(latitude, longitude, latitudeDelta, longitudeDelta) {
    // const cancelTokenSource = axios.CancelToken.source()

    return (dispatch) => {
        dispatch(fetchMapPreviewDataBegin())

        let currentUser = auth.currentUser
        if (currentUser != null) {
            // Get the current token and figure out utility account to use.
            AsyncStorage.multiGet([AppConstants.currentFirebaseToken, AppConstants.currentUtilityAccount])
                .then((ids) => {
                    const token = ids.find((possibleItem) => possibleItem[0] == AppConstants.currentFirebaseToken)[1]
                    // This isn't used currently, but might be for positioning a "current property" indicator later
                    const currentAccount = ids.find((possibleItem) => possibleItem[0] == AppConstants.currentUtilityAccount)[1]

                    // Once we have the correct token, go get the map data.
                    let url = `${AppConstants.apiBase}/gridrewards/geo/list/preview?latitude=${latitude}&longitude=${longitude}&latitudeDelta=${latitudeDelta}&longitudeDelta=${longitudeDelta}&everyK=30`
                    axios
                        .get(url, {
                            params: {},
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                            timeout: timeoutLength,
                        })
                        .then(function (response) {
                            // If we actually get primary data, return it!!
                            console.log("Got map preview data") //from " + url) // + "\n" + JSON.stringify(response.data))
                            dispatch(fetchMapPreviewDataSuccess(response.data))
                        })
                        .catch(function (error) {
                            // If we failed to get primary data, throw an error
                            // console.log("Failed to get map preview data: " + JSON.stringify(error.response))
                            if (Platform.OS == "web") {
                                Sentry.Browser.captureException(error)
                            } else {
                                Sentry.Native.captureException(error)
                            }
                            dispatch(fetchMapPreviewDataError(error))
                        })
                })
                .catch(function (error) {
                    // If we failed to get an account ID, throw an error
                    console.log("Map failed to load current account ID or auth token: " + JSON.stringify(error))
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException(error)
                    } else {
                        Sentry.Native.captureException(error)
                    }
                    dispatch(fetchMapPreviewDataError(error))
                })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

// Update our local storage for next steps completion, and potentially log some events to analytics.
const updateNextStepsStatus = async (mainData) => {
    const { gridRewards = {}, ami = {}, tstat = {}, survey = {} } = prop("nextSteps", mainData) || {}

    let gridRewardsOld = await AsyncStorage.getItem(AppConstants.gridRewardsNextStepComplete)
    let amiOld = await AsyncStorage.getItem(AppConstants.amiNextStepComplete)
    let surveyOld = await AsyncStorage.getItem(AppConstants.surveyNextStepComplete)
    let thermostatOld = await AsyncStorage.getItem(AppConstants.thermostatNextStepComplete)

    let showDataProcessingPrompt = false

    if (gridRewardsOld != "true" && gridRewards.status == true) {
        // Completed full GridRewards auth for the first time
        Analytics.logEvent("gridRewardsNextStepCompleted")
        console.log("Completed GridRewards auth successfully, telling Adjust")
        if (Platform.OS !== "web") {
            Adjust.trackEvent(new AdjustEvent(AppConstants.analytics.gridRewardsSetupComplete))
        }
        AsyncStorage.setItem(AppConstants.gridRewardsNextStepComplete, "true")
        showDataProcessingPrompt = true
    }

    if (amiOld != "true" && ami.status == true) {
        // Completed AMI setup for the first time.
        Analytics.logEvent("amiNextStepCompleted")
        console.log("Completed AMI auth successfully, telling Adjust")
        if (Platform.OS !== "web") {
            Adjust.trackEvent(new AdjustEvent(AppConstants.analytics.amiSetupComplete))
        }
        AsyncStorage.setItem(AppConstants.amiNextStepComplete, "true")
        showDataProcessingPrompt = true
    }

    if (surveyOld != "true" && survey.status == true) {
        Analytics.logEvent("surveyNextStepCompleted")
        AsyncStorage.setItem(AppConstants.surveyNextStepComplete, "true")
    }

    if (thermostatOld != "true" && tstat.status == true) {
        Analytics.logEvent("thermostatNextStepCompleted")
        AsyncStorage.setItem(AppConstants.thermostatNextStepComplete, "true")
    }

    await AsyncStorage.setItem(AppConstants.showDataProcessingPrompt, showDataProcessingPrompt ? "true" : "false")
}

/* Actions */
export const FETCH_ACTIONS_BEGIN = "FETCH_ACTIONS_BEGIN"
export const FETCH_ACTIONS_SUCCESS = "FETCH_ACTIONS_SUCCESS"
export const FETCH_ACTIONS_ERROR = "FETCH_ACTIONS_ERROR"

export const fetchActionsBegin = () => ({
    type: FETCH_ACTIONS_BEGIN,
})

export const fetchActionsSuccess = (actionsData) => ({
    type: FETCH_ACTIONS_SUCCESS,
    actionsData,
})

export const fetchActionsError = (error) => ({
    type: FETCH_ACTIONS_ERROR,
    error,
})

export function fetchActions() {
    return (dispatch) => {
        dispatch(fetchEndpoint(`${AppConstants.apiBase}/user/actions`, fetchActionsBegin, fetchActionsSuccess, fetchActionsError))
    }
}

/* Carbon intensity detail */
export const FETCH_CARBON_INTENSITY_DETAIL_BEGIN = "FETCH_CARBON_INTENSITY_DETAIL_BEGIN"
export const FETCH_CARBON_INTENSITY_DETAIL_SUCCESS = "FETCH_CARBON_INTENSITY_DETAIL_SUCCESS"
export const FETCH_CARBON_INTENSITY_DETAIL_ERROR = "FETCH_CARBON_INTENSITY_DETAIL_ERROR"

export const fetchCarbonIntensityDetailBegin = () => ({
    type: FETCH_CARBON_INTENSITY_DETAIL_BEGIN,
})

export const fetchCarbonIntensityDetailSuccess = (carbonIntensityData) => ({
    type: FETCH_CARBON_INTENSITY_DETAIL_SUCCESS,
    carbonIntensityData,
})

export const fetchCarbonIntensityDetailError = (error) => ({
    type: FETCH_CARBON_INTENSITY_DETAIL_ERROR,
    error,
})

export function fetchCarbonIntensityDetail() {
    return (dispatch) => {
        dispatch(
            fetchEndpoint(
                `${AppConstants.apiBase}/isodata/1/carbon-mixed-view`,
                fetchCarbonIntensityDetailBegin,
                fetchCarbonIntensityDetailSuccess,
                fetchCarbonIntensityDetailError
            )
        )
    }
}

/* Weather */
export const FETCH_WEATHER_BEGIN = "FETCH_WEATHER_BEGIN"
export const FETCH_WEATHER_SUCCESS = "FETCH_WEATHER_SUCCESS"
export const FETCH_WEATHER_ERROR = "FETCH_WEATHER_ERROR"

export const fetchWeatherBegin = () => ({
    type: FETCH_WEATHER_BEGIN,
})

export const fetchWeatherSuccess = (weatherData) => ({
    type: FETCH_WEATHER_SUCCESS,
    weatherData,
})

export const fetchWeatherError = (error) => ({
    type: FETCH_WEATHER_ERROR,
    error,
})

// Expects Moment data for start and end dates
export function fetchWeather(startDate, endDate, granularity = DataTimeframe.day) {
    return (dispatch) => {
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(noUtilityError, fetchWeatherError)
                    return
                }
                dispatch(
                    fetchEndpoint(
                        `${AppConstants.apiBase}/gridrewards/weather/${
                            granularity == DataTimeframe.hour ? "hourly" : "daily"
                        }/${currentAccount}?page=${1}&page_size=${1000}&start=${startDate.toISOString(true)}&end=${endDate.toISOString(true)}`,
                        fetchWeatherBegin,
                        fetchWeatherSuccess,
                        fetchWeatherError,
                        ["data", "results"]
                    )
                )
            })
            .catch((error) => utilityAccountMissingError(error, fetchWeatherError))
    }
}

/* Event likelihood */
export const FETCH_EVENT_LIKELIHOOD_BEGIN = "FETCH_EVENT_LIKELIHOOD_BEGIN"
export const FETCH_EVENT_LIKELIHOOD_SUCCESS = "FETCH_EVENT_LIKELIHOOD_SUCCESS"
export const FETCH_EVENT_LIKELIHOOD_ERROR = "FETCH_EVENT_LIKELIHOOD_ERROR"

export const fetchEventLikelihoodBegin = () => ({
    type: FETCH_EVENT_LIKELIHOOD_BEGIN,
})

export const fetchEventLikelihoodSuccess = (eventLikelihoodData) => ({
    type: FETCH_EVENT_LIKELIHOOD_SUCCESS,
    eventLikelihoodData,
})

export const fetchEventLikelihoodError = (error) => ({
    type: FETCH_EVENT_LIKELIHOOD_ERROR,
    error,
})

// Expects Moment data for start and end dates
export function fetchEventLikelihood() {
    // Get 7 days of data.
    const startDate = moment()
    const endDate = moment().add(7, "days")

    return (dispatch) => {
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(noUtilityError, fetchEventLikelihoodError)
                    return
                }
                dispatch(
                    fetchEndpoint(
                        `${AppConstants.apiBase}/gridrewards/events/likelihood/${currentAccount}?page=${1}&page_size=${1000}&start=${startDate.toISOString(
                            true
                        )}&end=${endDate.toISOString(true)}`,
                        fetchEventLikelihoodBegin,
                        fetchEventLikelihoodSuccess,
                        fetchEventLikelihoodError,
                        ["data", "results"],
                        true
                    )
                )
            })
            .catch((error) => utilityAccountMissingError(error, fetchEventLikelihoodError))
    }
}

/* Getting thermostats */
export const FETCH_THERMOSTATS_BEGIN = "FETCH_THERMOSTATS_BEGIN"
export const FETCH_THERMOSTATS_SUCCESS = "FETCH_THERMOSTATS_SUCCESS"
export const FETCH_THERMOSTATS_ERROR = "FETCH_THERMOSTATS_ERROR"

export const fetchThermostatsBegin = () => ({
    type: FETCH_THERMOSTATS_BEGIN,
})

export const fetchThermostatsSuccess = (thermostatsData) => ({
    type: FETCH_THERMOSTATS_SUCCESS,
    thermostatsData,
})

export const fetchThermostatsError = (error) => ({
    type: FETCH_THERMOSTATS_ERROR,
    error,
})

export function fetchThermostats() {
    return (dispatch) => {
        AsyncStorage.getItem(AppConstants.currentUtilityAccount)
            .then((currentAccount) => {
                if (currentAccount == null || currentAccount.length == 0) {
                    utilityAccountMissingError(noUtilityError, fetchThermostatsError)
                    return
                }
                dispatch(
                    fetchEndpoint(
                        `${AppConstants.apiBase}/thermostats/account/${currentAccount}/list`,
                        fetchThermostatsBegin,
                        fetchThermostatsSuccess,
                        fetchThermostatsError,
                        ["data", "thermostats"],
                        true
                    )
                )
            })
            .catch((error) => utilityAccountMissingError(error, fetchThermostatsError))
    }
}

/* Updating a thermostat */
export const UPDATE_THERMOSTAT_BEGIN = "UPDATE_THERMOSTAT_BEGIN"
export const UPDATE_THERMOSTAT_SUCCESS = "UPDATE_THERMOSTAT_SUCCESS"
export const UPDATE_THERMOSTAT_ERROR = "UPDATE_THERMOSTAT_ERROR"

export const updateThermostatBegin = () => ({
    type: UPDATE_THERMOSTAT_BEGIN,
})

export const updateThermostatSuccess = () => ({
    type: UPDATE_THERMOSTAT_SUCCESS,
})

export const updateThermostatError = (error) => ({
    type: UPDATE_THERMOSTAT_ERROR,
    error,
})

export function updateThermostat(thermostatId, data) {
    return (dispatch) => {
        dispatch(updateThermostatBegin())

        let url = `${AppConstants.apiBase}/thermostats/${thermostatId}`

        let currentUser = auth.currentUser
        if (currentUser != null) {
            currentUser.getIdToken().then((token) => {
                axios
                    .patch(url, data, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        timeout: timeoutLength,
                    })
                    .then(function (response) {
                        console.log(
                            "Updated thermostat " + thermostatId + " with data " + JSON.stringify(data) + ", response: " + JSON.stringify(response.data)
                        )
                        dispatch(updateThermostatSuccess(response.data))

                        // Re-fetch data.
                        dispatch(fetchThermostats())
                    })
                    .catch(function (error) {
                        // If we failed to get primary data, throw an error
                        console.log("Failed to update thermostat " + thermostatId + ": " + JSON.stringify(error.response))
                        if (Platform.OS == "web") {
                            Sentry.Browser.captureException(error)
                        } else {
                            Sentry.Native.captureException(error)
                        }
                        dispatch(updateThermostatError(error))
                    })
            })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

export function clearThermostatHold(thermostatId) {
    return (dispatch) => {
        dispatch(updateThermostatBegin())

        let url = `${AppConstants.apiBase}/thermostats/${thermostatId}`

        let currentUser = auth.currentUser
        if (currentUser != null) {
            currentUser.getIdToken().then((token) => {
                axios
                    .delete(url, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        timeout: timeoutLength,
                    })
                    .then(function (response) {
                        console.log("Deleted the hold for this thermostat: " + JSON.stringify(response.data))
                        dispatch(updateThermostatSuccess(response.data))

                        // Re-fetch profile.
                        dispatch(fetchThermostats())
                    })
                    .catch(function (error) {
                        // If we failed to get primary data, throw an error
                        console.log("Failed to delete thermostat hold:" + JSON.stringify(error.response))
                        if (Platform.OS == "web") {
                            Sentry.Browser.captureException(error)
                        } else {
                            Sentry.Native.captureException(error)
                        }
                        dispatch(updateThermostatError(error))
                    })
            })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

// Combined fetch calls for particular screens
const fetchHome = _.throttle((dispatch) => {
    console.log("Firing throttled fetchHome call")
    dispatch(fetchAccountsData())
    dispatch(fetchProfileData())
    dispatch(fetchMainData())
    dispatch(fetchAchievementsData())
    dispatch(fetchCarbonIntensityDetail())
    dispatch(fetchScorecardsData())
    dispatch(fetchGridRewardsHistoryData())
    dispatch(fetchPaymentAddress())
    dispatch(fetchPayments())
    // Get the most recent notifications (enough to fill the first couple of pages; we'll replace these with the full set when we open notifications)
    dispatch(fetchNotifications(1, 30))
    dispatch(fetchShortElectricityHistory())
    dispatch(fetchNeighborhood(1, 10)) // we should only ever get one result here anyway.
}, 500)

const fetchCarbon = _.throttle((dispatch) => {
    console.log("Firing throttled fetchCarbon call")
    dispatch(fetchMainData())
    dispatch(fetchCarbonIntensityDetail())
    dispatch(fetchScorecardsData())
}, 500)

const fetchLocations = _.throttle((dispatch) => {
    console.log("Firing throttled fetchLocations call")
    // When we change locations (which isn't a common action), we'll need to re-fetch everything.
    // We're being extra-aggressive here so that sub-screens have data when needed.
    dispatch(fetchAccountsData())
    dispatch(fetchProfileData())
    dispatch(fetchMainData())
    dispatch(fetchCarbonIntensityDetail())
    dispatch(fetchScorecardsData())
    dispatch(fetchThermostats())
    dispatch(fetchGridRewardsHistoryData())
    dispatch(fetchPayments())
    dispatch(fetchShortElectricityHistory())
    dispatch(fetchNeighborhood(1, 10)) // we should only ever get one result here anyway.
}, 500)

const fetchEvents = _.throttle((dispatch) => {
    console.log("Firing throttled fetchEvents call")
    dispatch(fetchMainData())
    dispatch(fetchEventLikelihood())
    dispatch(fetchNeighborhood(1, 10)) // we should only ever get one result here anyway.
    dispatch(fetchGridRewardsHistoryData())
    dispatch(fetchPayments())
}, 500)

const fetchEnergy = _.throttle((dispatch) => {
    console.log("Firing throttled fetchEnergy call")
    // The history graphs take care of their own data fetches.
    dispatch(fetchThermostats())
}, 500)

export function fetchDataForRoute(routeName) {
    return (dispatch) => {
        console.log("Asked to fetch data for route: " + routeName)
        switch (routeName) {
            case ScreenNames.Home.routeName:
                fetchHome(dispatch)
                break
            case ScreenNames.CarbonScreen.routeName:
                fetchCarbon(dispatch)
                break
            case ScreenNames.Locations.routeName:
                fetchLocations(dispatch)
                break
            case ScreenNames.EventsScreen.routeName:
                fetchEvents(dispatch)
                break
            case ScreenNames.EnergyScreen.routeName:
                fetchEnergy(dispatch)
                break
            default:
                // console.log("No matching data fetch, skipping")
                break
        }
    }
}

/* Sending the Expo token */
export const SEND_EXPO_TOKEN_SUCCESS = "SEND_EXPO_TOKEN_SUCCESS"
export const SEND_EXPO_TOKEN_ERROR = "SEND_EXPO_TOKEN_ERROR"

export const sendExpoTokenSuccess = () => ({
    type: SEND_EXPO_TOKEN_SUCCESS,
})

export const sendExpoTokenError = (error) => ({
    type: SEND_EXPO_TOKEN_ERROR,
    error,
})

export function sendExpoToken(token) {
    return (dispatch) => {
        let url = `${AppConstants.apiBase}/notification/expo`

        const data = {
            token: token,
        }

        let currentUser = auth.currentUser
        if (currentUser != null) {
            currentUser.getIdToken().then((token) => {
                axios
                    .post(url, data, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        timeout: timeoutLength,
                    })
                    .then(function (response) {
                        // console.log("Sent expo token to server successfully, response was " + JSON.stringify(response.data))
                        dispatch(sendExpoTokenSuccess(response.data))
                    })
                    .catch(function (error) {
                        // If we failed to get primary data, throw an error
                        console.log("Failed to send expo token: " + JSON.stringify(error.response))
                        if (Platform.OS == "web") {
                            Sentry.Browser.captureException(error)
                        } else {
                            Sentry.Native.captureException(error)
                        }
                        dispatch(sendExpoTokenError(error))
                    })
            })
        } else {
            console.log("No user, logging out. Request was " + url)
            if (Platform.OS == "web") {
                Sentry.Browser.captureException("User invalid, logging out")
            } else {
                Sentry.Native.captureException("User invalid, logging out")
            }
            dispatch(performSignOut())
        }
    }
}

/* Clearing the Expo token */
export const DELETE_EXPO_TOKEN_SUCCESS = "DELETE_EXPO_TOKEN_SUCCESS"
export const DELETE_EXPO_TOKEN_ERROR = "DELETE_EXPO_TOKEN_ERROR"

export const deleteExpoTokenSuccess = () => ({
    type: DELETE_EXPO_TOKEN_SUCCESS,
})

export const deleteExpoTokenError = (error) => ({
    type: DELETE_EXPO_TOKEN_ERROR,
    error,
})

export function deleteExpoToken(expoToken, authToken) {
    return (dispatch) => {
        let url = `${AppConstants.apiBase}/notification/expo`

        if (expoToken != null && authToken != null) {
            console.log("Trying to delete token " + expoToken)
            const data = {
                token: expoToken,
            }

            axios
                .delete(url, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authToken,
                    },
                    data,
                })
                .then(function (response) {
                    console.log("Deleted expo token from server successfully, response was " + JSON.stringify(response.data))
                    dispatch(deleteExpoTokenSuccess(response.data))
                })
                .catch(function (error) {
                    // If we failed to get primary data, throw an error
                    console.log("Failed to delete expo token: " + JSON.stringify(error.response))
                    if (Platform.OS == "web") {
                        Sentry.Browser.captureException(error)
                    } else {
                        Sentry.Native.captureException(error)
                    }
                    dispatch(deleteExpoTokenError(error))
                })
        }
    }
}

/* Getting location data */
export const CHECK_LOCATION_BEGIN = "CHECK_LOCATION_BEGIN"
export const CHECK_LOCATION_SUCCESS = "CHECK_LOCATION_SUCCESS"
export const CHECK_LOCATION_ERROR = "CHECK_LOCATION_ERROR"

export const checkLocationBegin = () => ({
    type: CHECK_LOCATION_BEGIN,
})

export const checkLocationSuccess = (availableServices) => ({
    type: CHECK_LOCATION_SUCCESS,
    availableServices: availableServices,
})

export const checkLocationError = (error) => ({
    type: CHECK_LOCATION_ERROR,
    error,
})

export function checkLocation(postalCode) {
    return (dispatch) => {
        console.log("Checking location...")
        dispatch(checkLocationBegin())

        let url = `${AppConstants.apiBase}/user/checklocation?zipcode=` + postalCode

        // This doesn't get auth because it's happening before we've signed in.
        axios
            .get(url, {
                params: {},
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                timeout: timeoutLength,
            })
            .then(function (response) {
                // If we actually get primary data, return it!!
                console.log("Got location-check data! " + JSON.stringify(response.data))
                dispatch(checkLocationSuccess(response.data))
            })
            .catch(function (error) {
                // If we failed to get primary data, throw an error
                console.log("Failed to get location-check data: " + error)
                if (Platform.OS == "web") {
                    Sentry.Browser.captureException(error)
                } else {
                    Sentry.Native.captureException(error)
                }
                dispatch(checkLocationError(error))
            })
    }
}

/* Sign in/out */
export const SIGN_IN = "SIGN_IN"
export const SIGN_OUT_BEGIN = "SIGN_OUT_BEGIN"
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS"
export const SIGN_OUT_ERROR = "SIGN_OUT_ERROR"

export function signIn(user) {
    // console.log("Signing in user with email " + user.email)
    return {
        type: "SIGN_IN",
        currentUser: user,
    }
}

export const signOutBegin = () => ({
    type: SIGN_OUT_BEGIN,
})

export const signOutSuccess = () => ({
    type: SIGN_OUT_SUCCESS,
    currentUser: null,
})

export const signOutError = (error) => ({
    type: SIGN_OUT_ERROR,
    error,
})

export function performSignOut() {
    return async (dispatch) => {
        dispatch(signOutBegin())

        let oldExpoToken = await AsyncStorage.getItem(AppConstants.expoPushToken)
        let oldUser = auth.currentUser

        signOut(auth)
            .then(function () {
                // Sign-out successful.
                console.log("Signed out ok")

                // Clear our push token
                if (oldUser != null) {
                    oldUser.getIdToken().then((userToken) => {
                        dispatch(deleteExpoToken(oldExpoToken, userToken))
                    })
                }

                // Clear our async storage
                resetAsyncStorage()

                // Reload anything from async storage into Redux
                loadAsyncStorage(null, dispatch)

                // Purge any state we don't want to keep.
                dispatch(signOutSuccess())
            })
            .catch(function (error) {
                // A sign out error happened.
                console.log("Sign-out failure: " + error)
                if (Platform.OS == "web") {
                    Sentry.Browser.captureException(error)
                } else {
                    Sentry.Native.captureException(error)
                }
                dispatch(signOutError(error))
            })
    }
}

/* Switch utility accounts for a logged-in user */
export const SWITCH_UTILITY_BEGIN = "SWITCH_UTILITY_BEGIN"
export const SWITCH_UTILITY_SUCCESS = "SWITCH_UTILITY_SUCCESS"

export const switchUtilityBegin = () => ({
    type: SWITCH_UTILITY_BEGIN,
})

export const switchUtilitySuccess = () => ({
    type: SWITCH_UTILITY_SUCCESS,
})

export function switchUtilityAccount(accountData, store) {
    return async (dispatch) => {
        dispatch(switchUtilityBegin())

        const accountId = prop("accountId", accountData)

        // Clear the timezone suppression preference.
        await AsyncStorage.multiRemove([AppConstants.suppressTimeZoneDisplay, AppConstants.suppressEducationSplashCard])

        // Update the app-wide display timezone.
        const newTimeZone = path(["building", "timezone"], accountData)
        setDefaultTimeZone(newTimeZone, store)

        // Change accounts
        await AsyncStorage.setItem(AppConstants.currentUtilityAccount, accountId)
        console.log("Fetching updated data for new account ID") //" + accountId)

        // Reload anything from async storage into Redux
        loadAsyncStorage(null, dispatch)

        // All done switching.
        dispatch(switchUtilitySuccess())

        // Update any data that's dependent on a specific location
        fetchLocations(dispatch)
    }
}

/* Confetti */
export const CONFETTI_BEGIN = "CONFETTI_BEGIN"
export const CONFETTI_END = "CONFETTI_END"

export function confettiBegin() {
    // console.log("Starting confetti")
    return {
        type: CONFETTI_BEGIN,
        confettiRunning: true,
    }
}

export function confettiEnd() {
    // console.log("Stopping confetti")
    return {
        type: CONFETTI_END,
        confettiRunning: false,
    }
}

export function showConfetti() {
    return (dispatch) => {
        dispatch(confettiBegin())

        setTimeout(() => {
            dispatch(confettiEnd())
        }, 300)
    }
}

/* Anything we need to load from AsyncStorage into Redux */
export const LOAD_ASYNC_STORAGE = "LOAD_ASYNC_STORAGE"

const importAsyncStorage = (result) => {
    return {
        type: "LOAD_ASYNC_STORAGE",
        onboardingComplete: prop(AppConstants.onboardingComplete, result),
        nextStepsHidden: prop(AppConstants.nextStepsHidden, result),
        amiComplete: prop(AppConstants.amiNextStepComplete, result),
        enrollmentComplete: prop(AppConstants.enrollmentComplete, result),
        utilityAccountNicknames: prop(AppConstants.utilityAccountNicknames, result),
        timeZone: prop(AppConstants.currentPropertyTimeZone, result),
        suppressTimeZone: prop(AppConstants.suppressTimeZoneDisplay, result),
        suppressEducationSplashCard: prop(AppConstants.suppressEducationSplashCard, result),
        pendingIncomingReferralCode: prop(AppConstants.pendingIncomingReferralCode, result),
    }
}

/* Notifications might ask us to do something */
export const SET_NOTIFICATION_TARGET = "SET_NOTIFICATION_TARGET"
export const CLEAR_NOTIFICATION_TARGET = "CLEAR_NOTIFICATION_TARGET"

export function setNotificationTarget(newTarget) {
    return {
        type: SET_NOTIFICATION_TARGET,
        notificationTarget: newTarget,
    }
}

export function clearNotificationTarget() {
    console.log("clearing notification target")
    return {
        type: CLEAR_NOTIFICATION_TARGET,
    }
}

/* Identify cases where we've JUST created an account with Firebase, and want to delay some things, etc.*/
export const NEW_ACCOUNT_CREATION_PENDING = "NEW_ACCOUNT_CREATION_PENDING"

export const setNewAccountCreationPending = (pending) => ({
    type: NEW_ACCOUNT_CREATION_PENDING,
    pending: pending,
})

// We need direct access to the store for this one async function, but don't want to create a circular dependency, so pass it in here.
export const loadAsyncStorage = async (store, dispatch) => {
    let result = {}
    let onboardingComplete = await AsyncStorage.getItem(AppConstants.onboardingComplete)
    // console.log("loadAsyncStorage thinks onboardingComplete is "+onboardingComplete)
    result[AppConstants.onboardingComplete] = onboardingComplete

    let nextStepsHidden = await AsyncStorage.getItem(AppConstants.nextStepsHidden)
    result[AppConstants.nextStepsHidden] = nextStepsHidden

    let amiComplete = await AsyncStorage.getItem(AppConstants.amiNextStepComplete)
    result[AppConstants.amiNextStepComplete] = amiComplete

    let enrollmentComplete = await AsyncStorage.getItem(AppConstants.enrollmentComplete)
    result[AppConstants.enrollmentComplete] = enrollmentComplete

    let utilityAccountNicknamesJSON = await AsyncStorage.getItem(AppConstants.utilityAccountNicknames)
    result[AppConstants.utilityAccountNicknames] = utilityAccountNicknamesJSON != null ? JSON.parse(utilityAccountNicknamesJSON) : null

    let timeZone = await AsyncStorage.getItem(AppConstants.currentPropertyTimeZone)
    result[AppConstants.currentPropertyTimeZone] = timeZone

    let suppressTimeZone = await AsyncStorage.getItem(AppConstants.suppressTimeZoneDisplay)
    result[AppConstants.suppressTimeZoneDisplay] = suppressTimeZone

    let suppressEducationSplashCard = await AsyncStorage.getItem(AppConstants.suppressEducationSplashCard)
    result[AppConstants.suppressEducationSplashCard] = suppressEducationSplashCard

    let pendingIncomingReferralCode = await AsyncStorage.getItem(AppConstants.pendingIncomingReferralCode)
    result[AppConstants.pendingIncomingReferralCode] = pendingIncomingReferralCode

    if (store != null) {
        store.dispatch(importAsyncStorage(result))
    } else {
        dispatch(importAsyncStorage(result))
    }
}

const resetAsyncStorage = async () => {
    AsyncStorage.multiRemove([
        AppConstants.amiNextStepComplete,
        AppConstants.currentPropertyTimeZone,
        AppConstants.currentUtilityAccount,
        AppConstants.currentZipCode,
        AppConstants.enrollmentComplete,
        AppConstants.firstRunDataFetchCompleted,
        AppConstants.foregroundCount,
        AppConstants.foregroundCountAfterGridRewardsAuth,
        AppConstants.gridRewardsNextStepComplete,
        AppConstants.notificationLastDateSeen,
        AppConstants.pendingIncomingReferralCode,
        AppConstants.profileFirstName,
        AppConstants.profileIncomingReferralCode,
        AppConstants.profileLastName,
        AppConstants.profileUpdateComplete,
        AppConstants.suppressTimeZoneDisplay,
        AppConstants.suppressEducationSplashCard,
        AppConstants.surveyNextStepComplete,
        AppConstants.thermostatNextStepComplete,
        AppConstants.utilityAccountNicknames,
        AppConstants.valuePropositionNextStepComplete,
        AppConstants.valuePropositionPromptComplete,
        AppConstants.yearRoundNextStepComplete,
    ])
}
