import * as React from "react"
import { Image, Platform, Text, View } from "react-native"
import { maxContentWidth } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

// This is the custom component we'll pass to (currently) react-native-root-toast
export default function CustomToast(props) {
    const { text = "", imageSource } = props
    const { theme } = React.useContext(ThemeContext)

    return (
        <View
            style={[
                {
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    marginHorizontal: 40,
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                    backgroundColor: theme.background,
                    borderWidth: 1,
                    borderColor: theme.border,
                    borderRadius: 9,
                    maxWidth: maxContentWidth,
                    minHeight: 65,
                    ...Platform.select({
                        ios: {
                            shadowColor: "#07001b",
                            shadowOffset: { width: 0, height: 6 },
                            shadowOpacity: 0.1,
                            shadowRadius: 36,
                        },
                        android: {
                            elevation: 15,
                        },
                    }),
                },
                props.containerStyle,
            ]}
        >
            {imageSource && <Image source={imageSource} style={{ width: 32, height: 32, marginRight: 16 }} />}
            <Text
                style={[
                    TextStyles.body3,
                    {
                        flexShrink: 1,
                        color: theme.textPrimary,
                    },
                ]}
            >
                {text}
            </Text>
        </View>
    )
}
