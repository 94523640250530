import * as React from "react"
import { Dimensions, Image, ScrollView, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import CloseButton from "../components/CloseButton"
import i18n from "../shared/i18n"
import { maxContentWidth } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function EnrollmentCompleteScreen(props) {
    const insets = useSafeAreaInsets()
    const { theme } = React.useContext(ThemeContext)

    const contentWidth = maxContentWidth - 40
    const contentHeight = Math.min(768, Dimensions.get("window").height - insets.top - insets.bottom - 180)
    return (
        <View
            style={{
                flex: 1,
                width: contentWidth,
                marginTop: insets.top + 24,
                marginBottom: insets.bottom + 72,
                alignSelf: "center",
                alignItems: "center",
            }}
        >
            <ScrollView
                style={{
                    flex: 1,
                    padding: 24,
                    paddingTop: 64,
                    borderRadius: 24,
                    backgroundColor: theme.background,

                    width: contentWidth,
                    height: contentHeight,
                    overflow: "hidden",
                }}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingBottom: 150 }}
            >
                <Text style={[TextStyles.h2, { color: theme.textPrimary, width: "100%", textAlign: "center", marginBottom: 24 }]}>
                    {i18n.t("enrollmentComplete.title")}
                </Text>

                <View style={{ flexDirection: "row", marginBottom: 16 }}>
                    <Image source={theme.icons.ringingBell} style={{ width: 40, height: 40, marginRight: 16 }} />
                    <View style={{ flexShrink: 1 }}>
                        <Text style={[TextStyles.body2Medium, { color: theme.textPrimary, marginBottom: 8 }]}>{i18n.t("enrollmentComplete.events.title")}</Text>
                        <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>{i18n.t("enrollmentComplete.events.description")}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 16 }}>
                    <Image source={theme.icons.comments} style={{ width: 40, height: 40, marginRight: 16 }} />
                    <View style={{ flexShrink: 1 }}>
                        <Text style={[TextStyles.body2Medium, { color: theme.textPrimary, marginBottom: 8 }]}>
                            {i18n.t("enrollmentComplete.community.title")}
                        </Text>
                        <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>{i18n.t("enrollmentComplete.community.description")}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                    <Image source={theme.icons.earth} style={{ width: 40, height: 40, marginRight: 16 }} />
                    <View style={{ flexShrink: 1 }}>
                        <Text style={[TextStyles.body2Medium, { color: theme.textPrimary, marginBottom: 8 }]}>{i18n.t("enrollmentComplete.carbon.title")}</Text>
                        <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>{i18n.t("enrollmentComplete.carbon.description")}</Text>
                    </View>
                </View>
            </ScrollView>

            <CloseButton
                showBackgroundCircle={true}
                containerStyle={{ position: "absolute", right: 20, top: 20, paddingLeft: 0, paddingRight: 0 }}
                imageStyle={{ tintColor: theme.textSecondary }}
            />
        </View>
    )
}
