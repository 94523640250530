import { useIsFocused, useNavigation, useRoute, useScrollToTop } from "@react-navigation/native"
import { prop } from "ramda"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { RefreshControl, ScrollView, View, Platform } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { useDispatch, useSelector } from "react-redux"
import Button from "../components/Button"
import CriticalWarningCard from "../components/cards/CriticalWarningCard"
import EventBannerCard from "../components/cards/EventBannerCard"
import EventHistoryCard from "../components/cards/EventHistoryCard"
import EventLikelihoodCard from "../components/cards/EventLikelihoodCard"
import LessonCard from "../components/cards/LessonCard"
import NeighborhoodCard from "../components/cards/NeighborhoodCard"
import OnboardingCard from "../components/cards/OnboardingCard"
import SeasonEarningsCard from "../components/cards/SeasonEarningsCard"
import { fetchDataForRoute, fetchNeighborhoodEventPerformance, fetchNeighborhoodMessages, fetchParticpants } from "../model/primaryDataActions"
import { ScreenNames } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import { auth } from "../shared/firebase"
import i18n from "../shared/i18n"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import { currentAndFutureEventIds, eventsHappeningNow, openSetup, upcomingEvents } from "../shared/Utils"

export default function EventsScreen() {
    const dispatch = useDispatch()
    const route = useRoute()
    const isFocused = useIsFocused()
    const { theme } = React.useContext(ThemeContext)
    // Allow scrolling to top when tapping our tab bar icon
    const scrollRef = React.useRef(null)
    useScrollToTop(scrollRef)

    const navigation = useNavigation()

    const mainData = useSelector((state) => state.primaryData.mainData)
    const { gridRewards = {} } = prop("nextSteps", mainData) || {}
    const events = prop("gridRewardsEvents", mainData) || []
    const eventParticipants = useSelector((state) => state.primaryData.eventParticipantData)
    const [visibleEvents, setVisibleEvents] = useState([])
    const neighborhoodData = useSelector((state) => state.primaryData.neighborhoodData) || []
    const activeNeighborhoodData = neighborhoodData.length > 0 ? neighborhoodData[0] : {}
    const neighborhoodMessagesData = useSelector((state) => state.primaryData.neighborhoodMessagesData) || []

    const paymentsData = useSelector((state) => state.primaryData.paymentsData)
    const estimatedPayments = prop("estimated", paymentsData) || []

    const amiComplete = useSelector((state) => state.primaryData.amiComplete)
    const enrollmentComplete = useSelector((state) => state.primaryData.enrollmentComplete)

    const [refreshing, setRefreshing] = useState(false)

    useEffect(() => {
        if (isFocused && auth.currentUser != null && amiComplete == "true" && events != null && events.length > 0) {
            const happeningNowData = eventsHappeningNow(events)
            const upcomingEventsData = upcomingEvents(events)
            setVisibleEvents([...happeningNowData, ...upcomingEventsData])

            console.log("Events list changed, getting participants")
            dispatch(fetchParticpants(currentAndFutureEventIds(events)))
        }
    }, [events])

    // Whenever we get new neighborhood data, we need to refresh our list of neighborhood messages
    useEffect(() => {
        if (amiComplete == "true" && auth.currentUser != null && mainData != null) {
            const neighborhoodId = prop("id", activeNeighborhoodData)
            if (neighborhoodId != null) {
                console.log("Neighborhood data changed, getting neighborhood messages and event performance")
                dispatch(fetchNeighborhoodMessages(neighborhoodId))
                dispatch(fetchNeighborhoodEventPerformance(neighborhoodId))
            }
        }
    }, [activeNeighborhoodData])

    useEffect(() => {
        setRefreshing(false)
    }, [mainData])

    const onRefresh = useCallback(() => {
        setRefreshing(true)
        console.log("Refreshing home screen from refresh callback")
        dispatch(fetchDataForRoute(ScreenNames.EventsScreen.routeName))
    }, [])

    if (amiComplete != "true") {
        // If we're not connected to AMI data yet, just show the correct onboarding card.
        return (
            <ScrollView
                style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
                contentContainerStyle={CommonStyles.mainScreenContent}
                ref={scrollRef}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={theme.textHint} colors={[theme.textHint]} />}
            >
                <OnboardingCard onboardingRoute={route.name} />
            </ScrollView>
        )
    }

    return (
        <KeyboardAwareScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
            ref={scrollRef}
            extraScrollHeight={150}
            keyboardShouldPersistTaps={"handled"}
            refreshControl={
                Platform.OS != "web" && <RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={theme.textHint} colors={[theme.textHint]} />
            }
        >
            {amiComplete && !enrollmentComplete && (
                <CriticalWarningCard title={i18n.t("amiOnlyWarning")}>
                    <Button
                        title={i18n.t("completeAccountSetup")}
                        buttonTintColor={rawThemes.dark.surface1}
                        contentTintColor={rawThemes.dark.textPrimary}
                        onPress={() => openSetup(prop("link", gridRewards))}
                    />
                </CriticalWarningCard>
            )}

            {visibleEvents.map((event) => {
                const participantData = (eventParticipants || []).find((possibleItem) => prop("id", possibleItem) == prop("eventId", event)) || {}
                // Include the first message matching this event. Messages are sorted newest-to-oldest.
                const incomingCaptainMessage = neighborhoodMessagesData.find((possibleMessage) => prop("event", possibleMessage) == prop("eventId", event))
                return (
                    <View key={prop("startTime", event)}>
                        <EventBannerCard event={event} participants={prop("participants", participantData)} incomingMessage={incomingCaptainMessage} />
                    </View>
                )
            })}

            {visibleEvents.length == 0 && <EventLikelihoodCard />}

            <LessonCard
                icon={theme.icons.coin}
                title={i18n.t("lesson.actions.title")}
                description={i18n.t("lesson.actions.description")}
                onPress={() => navigation.navigate(ScreenNames.ActionsInfo.routeName)}
            />

            <SeasonEarningsCard showPrefsButton={true} />
            <NeighborhoodCard />
            <EventHistoryCard />
            <Button
                type="fill"
                containerStyle={{ marginHorizontal: 20, marginTop: 8 }}
                title={i18n.t("universityTitle")}
                arrow={true}
                onPress={() => navigation.navigate(ScreenNames.University.routeName)}
            />
        </KeyboardAwareScrollView>
    )
}
