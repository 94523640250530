import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, View, TouchableOpacity } from "react-native"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import CardView from "./CardView"

export default function LessonCard(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)
    const { icon = theme.icons.book, title, description, onPress } = props

    return (
        <CardView includesBorder={true} includesPadding={false} {...props}>
            <TouchableOpacity style={{ flexDirection: "row", margin: 20 }} onPress={onPress}>
                <Image source={icon} style={[{ width: 24, height: 24, marginRight: 16, opacity: 0.7 }]} />
                <View style={{ flexShrink: 1 }}>
                    {title != null && <Text style={[TextStyles.h4, { color: theme.textPrimary, marginBottom: 6 }]}>{title}</Text>}
                    {description != null && <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>{description}</Text>}
                </View>
            </TouchableOpacity>
        </CardView>
    )
}
