import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, View } from "react-native"
import { ScreenNames } from "../../shared/AppConstants"
import { maxContentWidth } from "../../shared/Layout"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import CardView from "./CardView"

export default function UniversityCoursePreviewCard(props) {
    const navigation = useNavigation()
    const { course } = props
    const { id, title = "", image } = course || {}
    const { theme } = React.useContext(ThemeContext)

    return (
        <CardView
            includesBorder={true}
            includesPadding={false}
            onCardPress={() => {
                navigation.navigate(ScreenNames.UniversityCourse.routeName, { course: course })
            }}
            {...props}
        >
            <View style={{ borderBottomColor: theme.border, height: 220 }}>
                <Image source={image} style={{ width: maxContentWidth, height: 220 }} resizeMode={"cover"} />
            </View>
            <View style={{ flexDirection: "row", marginHorizontal: 20, marginVertical: 24, justifyContent: "space-between", alignItems: "center" }}>
                <Text style={[TextStyles.h4, { flexShrink: 1, color: theme.textPrimary, marginRight: 16 }]}>{title}</Text>
                <Image source={theme.icons.arrowRight} style={{ width: 16, height: 16 }} />
            </View>
        </CardView>
    )
}
