import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, View, TouchableOpacity } from "react-native"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import Button from "../Button"
import CardView from "./CardView"

export default function ScreenSummaryCard(props) {
    const { title = "", buttonTitle, hideGroupLine = false, onHeaderPress, onButtonPress } = props
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    return (
        <CardView includesBorder={false} includesPadding={false} {...props}>
            <TouchableOpacity style={{ flexDirection: "row", paddingBottom: 16, justifyContent: "flex-start", alignItems: "center" }} onPress={onHeaderPress}>
                <Text style={[TextStyles.body2Semi, { flexShrink: 1, color: theme.textSecondary }]}>{title}</Text>
                {/* <Image source={theme.icons.infoCircle16} style={[{ width: 16, height: 16, marginLeft: 8, opacity: 0.7 }]} /> */}
            </TouchableOpacity>
            <View style={{ flexDirection: "row", marginBottom: 16 }}>
                {!hideGroupLine && <View style={{ width: 1, marginLeft: 4, marginRight: 8, backgroundColor: theme.border }} />}
                <View style={{ flex: 1 }}>{props.children}</View>
            </View>
            {buttonTitle != null && <Button type="fill" title={buttonTitle} arrow={true} onPress={onButtonPress} />}
        </CardView>
    )
}
