export const Colors = {
    // FIXME: This has all moved to ThemeContext.js
    legacy: "#f00",
    legacyText: "#00f",
}

export function colorForPerformanceFactor(performance = 0) {
    const accentColors = require("./ThemeContext").accentColors
    if (performance == null) {
        return accentColors.blue
    }

    let color = accentColors.blue
    if (performance < 0.25) {
        color = accentColors.red
    } else if (performance < 0.5) {
        color = accentColors.orange
    } else if (performance >= 1.0) {
        color = accentColors.green
    }

    return color
}

export function colorForEfficiencyGrade(grade = 0) {
    if (grade <= 49) return "#CE0967"
    else if (grade <= 59) return "#8F2496"
    else if (grade <= 69) return "#5040C5"
    else if (grade <= 79) return "#3277DA"
    else return "#13AEF0"
}

export function colorForTemperature(temperature = 0) {
    if (temperature <= 59) return "#013B66"
    else if (temperature <= 64) return "#065770"
    else if (temperature <= 66) return "#016E88"
    else if (temperature <= 68) return "#079CCB"
    else if (temperature <= 70) return "#11BBC5"
    else if (temperature <= 72) return "#0DBAA2"
    else if (temperature <= 74) return "#13A04C"
    else if (temperature <= 76) return "#6A9B2C"
    else if (temperature <= 78) return "#BA930B"
    else return "#AF8109"
}

export default Colors
