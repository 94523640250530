import moment from "moment-timezone"
import * as React from "react"
import { View, Text, TouchableOpacity, Image } from "react-native"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { useDispatch, useSelector, useStore } from "react-redux"
import AsyncStorage from "@react-native-async-storage/async-storage"
import AppConstants from "../shared/AppConstants"
import { loadAsyncStorage } from "../model/primaryDataActions"

export default function TimeZonePill(props) {
    const { theme } = React.useContext(ThemeContext)
    const store = useStore()

    // If this is null, we treat it as local time.
    const timeZoneId = useSelector((state) => state.primaryData.timeZone) || moment.tz.guess()
    const suppressTimeZone = useSelector((state) => state.primaryData.suppressTimeZone)

    if (timeZoneId == moment.tz.guess() || suppressTimeZone == "true") {
        // Only show this if it doesn't match our device time zone and we haven't actively hidden it.
        return null
    }

    return (
        <View
            style={[
                {
                    borderRadius: 1000,
                    borderColor: theme.border,
                    borderWidth: 1,
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                    marginHorizontal: 16,
                    marginBottom: 24,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                },
                props.style,
            ]}
        >
            <Text style={[TextStyles.body3, { flexShrink: 1, color: theme.textSecondary }]}>
                {i18n.t("timeZoneInfo", { name: moment.tz(timeZoneId).zoneAbbr() })}
            </Text>
            <TouchableOpacity
                onPress={async () => {
                    // Hide this until the next time the user switches properties
                    await AsyncStorage.setItem(AppConstants.suppressTimeZoneDisplay, "true")
                    loadAsyncStorage(store)
                }}
            >
                <Image source={theme.icons.close} style={{ width: 16, height: 16, opacity: 0.5 }} />
            </TouchableOpacity>
        </View>
    )
}
