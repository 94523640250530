import React, { useState, useEffect } from "react"
import { Animated } from "react-native"
import { StyleSheet, View, Text, TouchableNativeFeedback, Image } from "react-native"
import { FlingGestureHandler, Directions, State } from "react-native-gesture-handler"
import Colors from "../shared/Colors"
import { TextStyles } from "../shared/TextStyles"
import i18n from "../shared/i18n"

const contentWidth = 286

export default function MapLegend(props) {
    const [expanded, setExpanded] = useState(false)
    const [xTrans, setXTrans] = useState(new Animated.Value(-contentWidth))

    useEffect(() => {
        console.log("Animating to expanded = " + expanded)
        Animated.timing(xTrans, {
            toValue: expanded ? 0 : -contentWidth,
            duration: 250,
            useNativeDriver: true,
        }).start()
    }, [expanded])

    return (
        <FlingGestureHandler
            direction={expanded ? Directions.LEFT : Directions.RIGHT}
            onHandlerStateChange={({ nativeEvent }) => {
                if (nativeEvent.state === State.ACTIVE) {
                    setExpanded(!expanded)
                }
            }}
        >
            <Animated.View style={[styles.container, { transform: [{ translateX: xTrans }] }]} pointerEvents={"box-none"}>
                <View style={styles.contentContainer}>
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                            marginTop: 35,
                            marginHorizontal: 40,
                            marginBottom: 8,
                        }}
                    >
                        <Image source={require("../assets/images/mapNode1.png")} />
                        <Image source={require("../assets/images/mapNode2.png")} />
                        <Image source={require("../assets/images/mapNode3.png")} />
                        <Image source={require("../assets/images/mapNode4.png")} />
                    </View>
                    <Text style={[TextStyles.content3Strong, { marginHorizontal: 40, textAlign: "center", color: Colors.powerPlantPurple }]}>
                        {i18n.t("mapLegendTitle")}
                    </Text>
                    <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 8 }}>
                        <Text style={[TextStyles.content4, { textAlign: "right", color: Colors.powerPlantPurple, marginRight: 18 }]}>{i18n.t("less")}</Text>
                        <Image source={require("../assets/images/mapLegendArrows.png")} style={{ width: 114, height: 16 }} />
                        <Text style={[TextStyles.content4, { textAlign: "left", color: Colors.powerPlantPurple, marginLeft: 12 }]}>{i18n.t("more")}</Text>
                    </View>
                    <View style={{ width: 47, height: 47, justifyContent: "center", alignItems: "center", alignSelf: "center", marginTop: 30 }}>
                        <Image source={require("../assets/images/mapNodeCluster.png")} style={{ position: "absolute", width: "100%", height: "100%" }} />
                        <Text style={[TextStyles.content3Strong, { color: Colors.powerPlantText }]}>12</Text>
                    </View>
                    <Text
                        style={[
                            TextStyles.content3Strong,
                            { marginHorizontal: 40, textAlign: "center", color: Colors.powerPlantPurple, marginTop: 2, marginBottom: 30 },
                        ]}
                    >
                        {i18n.t("mapLegendGroupTitle")}
                    </Text>
                </View>
                <TouchableNativeFeedback
                    onPress={() => {
                        setExpanded(!expanded)
                    }}
                >
                    <View style={styles.tab}>
                        <Image source={require("../assets/images/legendTabHandle.png")} style={styles.tabHandle} />
                        <Text style={[styles.tabTitle, TextStyles.content3, { color: Colors.white }]}>{i18n.t("mapLegendButtonTitle")}</Text>
                    </View>
                </TouchableNativeFeedback>
            </Animated.View>
        </FlingGestureHandler>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        alignItems: "flex-end",
        width: 320,
        minHeight: 284,
    },
    contentContainer: {
        width: contentWidth,
        height: "100%",
        backgroundColor: Colors.black,
        opacity: 0.8,
        overflow: "hidden",
        borderTopRightRadius: 8,
    },
    tab: {
        width: 87,
        height: 34,
        backgroundColor: Colors.black,
        opacity: 0.8,
        overflow: "hidden",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        transform: [{ rotate: "90deg" }, { translateX: -26.5 }, { translateY: 27 }],
    },
    tabTitle: {
        width: "100%",
        height: "100%",
        textAlign: "center",
        marginTop: 8,
    },
    tabHandle: {
        // TODO: Figure out why this translation makes sense. Need to understand anchoring behavior waaaay better.
        position: "absolute",
        transform: [{ rotate: "90deg" }, { translateX: -12 }, { translateY: -40 }],
    },
})
