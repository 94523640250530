import AsyncStorage from "@react-native-async-storage/async-storage"
import { useRoute, useScrollToTop } from "@react-navigation/native"
import { prop } from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { ScrollView } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import Button from "../components/Button"
import CriticalWarningCard from "../components/cards/CriticalWarningCard"
import HistoryCard from "../components/cards/HistoryCard"
import InsightCard from "../components/cards/InsightCard"
import OnboardingCard from "../components/cards/OnboardingCard"
import ThermostatCard from "../components/cards/ThermostatCard"
import ThermostatSetupCard from "../components/cards/ThermostatSetupCard"
import Divider from "../components/Divider"
import { addAchievement } from "../model/primaryDataActions"
import AppConstants, { DataSource, InsightTypes } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import { isEmpty, openSetup } from "../shared/Utils"

export default function EnergyScreen() {
    const { theme } = useContext(ThemeContext)
    const route = useRoute()
    const dispatch = useDispatch()

    // Allow scrolling to top when tapping our tab bar icon
    const scrollRef = React.useRef(null)
    useScrollToTop(scrollRef)

    const thermostats = useSelector((state) => state.primaryData.thermostatsData) || []
    const amiComplete = useSelector((state) => state.primaryData.amiComplete)
    const enrollmentComplete = useSelector((state) => state.primaryData.enrollmentComplete)

    // const electricityHistoryData =
    //     prop(
    //         "data",
    //         useSelector((state) => state.primaryData.electricityHistoryData)
    //     ) || []
    // console.log("Electricity history data is " + JSON.stringify(electricityHistoryData))
    const electricityScorecardsData = prop("electricityScorecardData", useSelector((state) => state.primaryData.scorecardsData) || {})
    // const gasHistoryData =
    //     prop(
    //         "data",
    //         useSelector((state) => state.primaryData.gasHistoryData)
    //     ) || []
    const gasScorecardsData = prop("gasScorecardData", useSelector((state) => state.primaryData.scorecardsData) || {})

    // Initial setup
    useEffect(() => {
        AsyncStorage.getItem(AppConstants.energyGraphFirstViewComplete).then((graphViewed) => {
            if (graphViewed != "true") {
                // Create this user achievement, then mark the graph locally as viewed.
                console.log("Marking energy graph as viewed")
                dispatch(addAchievement("graphViewed"))
                AsyncStorage.setItem(AppConstants.energyGraphFirstViewComplete, "true")
            }
        })
    }, [])

    if (!amiComplete) {
        // If we're not enrolled yet, just show the correct onboarding card.
        return (
            <ScrollView
                style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
                contentContainerStyle={CommonStyles.mainScreenContent}
                ref={scrollRef}
            >
                <OnboardingCard onboardingRoute={route.name} />
            </ScrollView>
        )
    }

    // console.log("Energy screen showing electricity graph? " + (electricityHistoryData != null && electricityHistoryData.length > 0).toString())
    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
            ref={scrollRef}
        >
            {amiComplete && !enrollmentComplete && (
                <CriticalWarningCard title={i18n.t("amiOnlyWarning")}>
                    <Button
                        title={i18n.t("completeAccountSetup")}
                        buttonTintColor={rawThemes.dark.surface1}
                        contentTintColor={rawThemes.dark.textPrimary}
                        onPress={() => openSetup(prop("link", gridRewards))}
                    />
                </CriticalWarningCard>
            )}
            <HistoryCard source={DataSource.electricity} />
            {!isEmpty(electricityScorecardsData) && (
                <ScrollView
                    style={{}}
                    horizontal={true}
                    alwaysBounceHorizontal={true}
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={{ paddingRight: 20 }}
                >
                    <InsightCard type={InsightTypes.projectedBill} source={DataSource.electricity} />
                    <InsightCard type={InsightTypes.usageVsProjected} source={DataSource.electricity} />
                    <InsightCard type={InsightTypes.usageVsYearAgo} source={DataSource.electricity} />
                    <InsightCard type={InsightTypes.usageVsSimilar} source={DataSource.electricity} />
                </ScrollView>
            )}
            {/* Only show the divider if we have some electricity scorecard data visible. */}
            {!isEmpty(electricityScorecardsData) && <Divider style={{ marginHorizontal: 20, marginVertical: 24 }} />}

            <HistoryCard source={DataSource.gas} />

            {!isEmpty(gasScorecardsData) && (
                <ScrollView
                    style={{}}
                    horizontal={true}
                    alwaysBounceHorizontal={true}
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={{ paddingRight: 20 }}
                >
                    <InsightCard type={InsightTypes.projectedBill} source={DataSource.gas} />
                    <InsightCard type={InsightTypes.usageVsProjected} source={DataSource.gas} />
                    <InsightCard type={InsightTypes.usageVsYearAgo} source={DataSource.gas} />
                    <InsightCard type={InsightTypes.usageVsSimilar} source={DataSource.gas} />
                    <InsightCard type={InsightTypes.carbonVsProjected} source={DataSource.gas} />
                    <InsightCard type={InsightTypes.carbonVsYearAgo} source={DataSource.gas} />
                    <InsightCard type={InsightTypes.carbonVsSimilar} source={DataSource.gas} />
                </ScrollView>
            )}
            {/* Only show the divider if we have some gas scorecard data visible. */}
            {!isEmpty(gasScorecardsData) && <Divider style={{ marginHorizontal: 20, marginVertical: 24 }} />}
            {thermostats.map((thermostatData, index) => {
                return <ThermostatCard key={prop("id", thermostatData) + index.toString()} thermostat={thermostatData} />
            })}
            <ThermostatSetupCard />
        </ScrollView>
    )
}
