import { useNavigation } from "@react-navigation/native"
import { prop } from "ramda"
import React, { useContext } from "react"
import { Text, View } from "react-native"
import { useSelector } from "react-redux"
import AppConstants, { ScreenNames } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { rawThemes, ThemeContext } from "../../shared/ThemeContext"
import { openSetup } from "../../shared/Utils"
import Button from "../Button"
import CardView from "./CardView"

export default function OnboardingCard(props) {
    const navigation = useNavigation()
    const { theme } = useContext(ThemeContext)
    // What route does this belong to?
    const { onboardingRoute } = props

    const mainData = useSelector((state) => state.primaryData.mainData)
    const { ami = {} } = prop("nextSteps", mainData) || {}

    var key = ""
    var gradientSource = require("../../assets/images/gradient01.jpg")

    switch (onboardingRoute) {
        case ScreenNames.Home.routeName:
            key = "home"
            gradientSource = require("../../assets/images/gradient01.jpg")
            break
        case ScreenNames.EventsScreen.routeName:
            key = "events"
            gradientSource = require("../../assets/images/gradient02.jpg")
            break
        case ScreenNames.EnergyScreen.routeName:
            key = "energy"
            gradientSource = require("../../assets/images/gradient03.jpg")
            break
        case ScreenNames.CarbonScreen.routeName:
            key = "carbon"
            gradientSource = require("../../assets/images/gradient04.jpg")
            break
        default:
            break
    }
    const description = i18n.t(`onboarding.${key}.description`) || ""

    return (
        <CardView
            includesPadding={false}
            backgroundImage={gradientSource}
            // We do want the border logic (rounding, external margins, etc.), but no visible border in this case.
            includesBorder={true}
            containerStyle={{ borderWidth: 0, minHeight: 405 }}
            contentContainerStyle={[{ paddingHorizontal: 20, paddingTop: 40, paddingBottom: 24 }]}
            {...props}
        >
            <Text style={[TextStyles.h1kobe, { color: theme.textLight, marginBottom: 16 }]}>{i18n.t(`onboarding.${key}.title`) || ""}</Text>
            {description.length > 0 && <Text style={[TextStyles.body2, { color: theme.textLight, marginBottom: 24 }]}>{description}</Text>}
            <View style={{ flex: 1 }} />
            <Button
                type="outline"
                buttonTintColor={theme.textLight}
                contentTintColor={theme.textLight}
                title={i18n.t("connectYourAccount")}
                arrow={true}
                arrowIcon={rawThemes.dark.icons.arrowRight}
                onPress={() => navigation.navigate(ScreenNames.AccountConnectionPrompt.routeName)}
            />
        </CardView>
    )
}
