import * as React from "react"
import { Text } from "react-native"
import { ScrollView } from "react-native-gesture-handler"
import DontaionInfoCard from "../components/cards/DonationInfoCard"
import { DonationOrganizations } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function DonationsDetailScreen(props) {
    const { theme } = React.useContext(ThemeContext)

    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background, paddingTop: 24 }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
        >
            <Text style={[TextStyles.body2, { color: theme.textPrimary, marginHorizontal: 20, marginBottom: 24 }]}>{i18n.t("donationsDetailSubtitle")}</Text>
            {DonationOrganizations.map((orgInfo, index) => {
                return <DontaionInfoCard key={index.toString()} orgInfo={orgInfo} />
            })}
        </ScrollView>
    )
}
