import { useNavigation } from "@react-navigation/native"
import { path, prop } from "ramda"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Platform, Text, View } from "react-native"
import { useSelector } from "react-redux"
import { ScreenNames } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import Button from "../Button"
import CardView from "./CardView"

export default function NeighborhoodCard(props) {
    const navigation = useNavigation()
    const { theme } = useContext(ThemeContext)
    const neighborhoodData = useSelector((state) => state.primaryData.neighborhoodData) || []
    const mapRef = useRef(null)

    const { buttonVisible = true } = props

    const { name = "", geojson, num_members = 0 } = neighborhoodData.length > 0 ? neighborhoodData[0] : {}

    const [boundingBox, setBoundingBox] = useState(null)
    const [neighborhoodPoly, setNeighborhoodPoly] = useState(geojson)

    // Initial setup
    // useEffect(() => {
    //     const unsubscribe = navigation.getParent().addListener("focus", () => {
    //         // Any time we're focused, try to redraw our region
    //         console.log("Map card focused")
    //         if (mapRef != null && mapRef.current != null && boundingBox != null) {
    //             mapRef.current.fitToCoordinates(boundingBox, { edgePadding: { top: 20, left: 20, bottom: 20, right: 20 }, animated: false })
    //         }
    //     })

    //     return unsubscribe
    // }, [])

    useEffect(() => {
        let features = prop("features", geojson)
        if (features == null || features.length == 0) {
            return
        }
        // We need to calculate the bounding box of the GeoJSON polygon(s) ourselves, because React Native Maps will
        // only auto-fit to markers, not paths or other drawn elements.

        // First, flatten all coordinates from all of our features.
        let flatCoords = features
            .map((singleFeature) => path(["geometry", "coordinates"], singleFeature))
            .flat()
            .flat()
        // console.log("flatCoords is " + JSON.stringify(flatCoords) + ", geojson is " + JSON.stringify(geojson))

        var minLongitude = 0
        var maxLongitude = 0
        var minLatitude = 0
        var maxLatitude = 0
        if (flatCoords.length > 0) {
            minLongitude = flatCoords.reduce((min, newCoord) => (newCoord[0] < min ? newCoord[0] : min), flatCoords[0][0])
            maxLongitude = flatCoords.reduce((max, newCoord) => (newCoord[0] > max ? newCoord[0] : max), flatCoords[0][0])

            minLatitude = flatCoords.reduce((min, newCoord) => (newCoord[1] < min ? newCoord[1] : min), flatCoords[0][1])
            maxLatitude = flatCoords.reduce((max, newCoord) => (newCoord[1] > max ? newCoord[1] : max), flatCoords[0][1])
        }

        // console.log("minLongitude is " + minLongitude + ", maxLongitude is " + maxLongitude)

        setBoundingBox([
            { longitude: minLongitude, latitude: minLatitude },
            { longitude: maxLongitude, latitude: maxLatitude },
        ])
    }, [geojson])

    useEffect(() => {
        if (boundingBox != null) {
            // Wait until our bounding box is correct before setting the neighborhood poly, which will trigger a re-render.
            setNeighborhoodPoly(geojson)
            if (mapRef != null && mapRef.current != null && boundingBox != null) {
                mapRef.current.fitToCoordinates(boundingBox, { edgePadding: { top: 20, left: 20, bottom: 20, right: 20 }, animated: false })
            }
        }
    }, [boundingBox])

    // TEST ONLY
    // const neighborhoodName = "North Bronx"
    // const neighborhoodCount = 149
    // const mapRegion = {
    //     latitude: 40.745893,
    //     longitude: -73.991627,
    //     latitudeDelta: 0.04,
    //     longitudeDelta: 0.04,
    // }

    if (Platform.OS !== "web") {
        MapView = require("react-native-maps").default
        Geojson = require("react-native-maps").Geojson
    }

    return (
        <CardView includesBorder={true} includesPadding={true} {...props}>
            <Text style={[TextStyles.body1, { color: theme.textPrimary, marginBottom: 16 }]}>
                {i18n.t(`neighborhood.description.${num_members == 1 ? "singular" : "plural"}`, { name: name, count: num_members })}
            </Text>
            {Platform.OS !== "web" && (
                <View pointerEvents="none">
                    <MapView
                        ref={mapRef}
                        pointerEvents="none"
                        style={{ width: "100%", height: 240, borderRadius: 12 }}
                        onLayout={() => {
                            setTimeout(() => {
                                if (mapRef != null && mapRef.current != null && boundingBox != null) {
                                    mapRef.current.fitToCoordinates(boundingBox, { edgePadding: { top: 20, left: 20, bottom: 20, right: 20 }, animated: false })
                                }
                            }, 200)
                        }}
                    >
                        {geojson != null && neighborhoodPoly != null && (
                            <Geojson geojson={neighborhoodPoly} strokeColor={theme.lavender} fillColor={theme.lavenderTranslucent} strokeWidth={1} />
                        )}
                    </MapView>
                </View>
            )}
            {buttonVisible && (
                <Button
                    type="outline"
                    title={i18n.t("neighborhood.cta")}
                    buttonTintColor={theme.teal}
                    containerStyle={{ marginTop: 16 }}
                    onPress={() => navigation.navigate(ScreenNames.Neighborhood.routeName)}
                />
            )}
        </CardView>
    )
}
