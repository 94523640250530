export const translationStrings = {
    translation: {
        aboutTheSame: "about the same",
        accountStale:
            "We haven’t received new data from your utility account in a while. If you’ve moved, you can connect your new account. If not, let us know.",
        achievement: {
            betterGoal: {
                description: "You did well during a GridRewards event.",
                title: "Good performance",
            },
            connectedAccount: {
                description: "You've connected a utility account. Now you can see your energy data inside GridRewards and start earning money!",
                title: "Connected",
            },
            countMeIn: {
                description: "You checked in to participate in a GridRewards event.",
                title: "Your voice counts",
            },
            countNone: "You haven't gotten this achievement yet",
            countPlural: "Achieved {{date}} and {{count}} other times",
            countMultipleSingular: "Achieved {{date}} and 1 other time",
            countSingular: "Achieved {{date}}",
            eventCount10: {
                description: "You've done well enough to earn money on 10 GridRewards events. Keep it up!",
                title: "10 Successful Events",
            },
            eventCount25: {
                description: "You've done well enough to earn money on 25 GridRewards events. Way to go!",
                title: "25 Successful Events",
            },
            eventCount50: {
                description: "You've done well enough to earn money on 50 GridRewards events. Way to go!",
                title: "50 Successful Events",
            },
            goalReached: {
                description: "You beat your electricity savings goal during a GridRewards event!",
                title: "Energy ninja",
            },
            graphViewed: {
                description: "You checked out the energy graph for the first time.",
                title: "Data Explorer",
            },
            minimumGoal: {
                description: "You met your minimum electricity savings goal during a GridRewards event",
                title: "Minimum goal reached",
            },
            neighborJoined: {
                description: "You got someone in your neighborhood to join GridRewards.",
                title: "Neighborhood leader",
            },
            referralSent: {
                description: "You've sent a referral so that you and a friend can each earn extra cash during events.",
                title: "Referred a friend",
            },
            topNeighborhood: {
                description: "Your neighborhood was one of the best at saving electricity during a GridRewards event.",
                title: "Top neighborhood",
            },
            topNeighborhoodPerCapita: {
                description: "Your neighborhood had some of the best electricity savings per person during a GridRewards event.",
                title: "Top neighborhood per person",
            },
            topPerformer: {
                description: "You saved the most electricity in your neighborhood during a GridRewards event.",
                title: "Top performer",
            },
        },
        achievementsTitle: "Achievements",
        acknowledgements: "Acknowledgements",
        actions: {
            appliances: {
                subtitle: "Shift your dishwashing, laundry, and coffee-making until after the event.",
                subtitleAdvanced: "Stay dirty, friends.",
                title: "Postpone use of large appliances",
            },
            ev: {
                subtitle:
                    "EVs can draw about 30% of a home’s total electricity, so waiting to charge has a big impact. Don’t have an EV yet? This one’s already done ;-).",
                subtitleAdvanced: "Don’t have an EV yet? Go get one, and make school dropoff look less like a battlefield resupply effort.",
                title: "Wait to charge your EV",
            },
            fridge: {
                subtitle: "Set your refrigerator & freezer temps higher. Try to keep the doors closed so that the existing cold air stays inside.",
                subtitleAdvanced: "Warm deli meats are fine, right? You probably won’t die...today.",
                title: "Raise refrigerator & freezer temps",
            },
            lights: {
                subtitle: "Turn off any lights you can. You can also replace incandescent bulbs with LED bulbs for big energy savings.",
                subtitleAdvanced: "You might walk into some walls, but ultimately nothing matters.",
                title: "Flip off your lights",
            },
            preCool: {
                subtitle: "Starting 4 hours before the event, set your thermostat to a lower temperature than normal, then let it rise during the event.",
                subtitleAdvanced: "Anticipate the coming energy crisis.",
                title: "Pre-cool your space",
            },
            raiseAC: {
                subtitle: "Set your thermostat a few degrees higher than normal during the event.",
                subtitleAdvanced: "Profit off of your own discomfort.",
                title: "Raise AC temp",
            },
            unplug: {
                subtitle: "Turn off your TV, cable box, and other large appliances. Take a walk!",
                subtitleAdvanced: "Stop doomscrolling, go outside, and get some vitamin D. Fight your anxiety, with GridRewards™.",
                title: "Unplug",
            },
        },
        actionsInfoSubtitle: "Small change adds up! These are the highest-impact changes you can make for each GridRewards event.",
        addButtonTitle: "Add",
        addPhoto: "Add Photo",
        alreadyHaveAnAccountPrompt: "Already have an account? Sign in",
        amiOnlyWarning: "One more step to start earning money! Finish connecting your utility account to GridRewards.",
        amountOfCO2: "{{amount}} of CO₂",
        amountOfElectricity: "{{amount}} of electricity",
        avatarUpdateFailed: "Couldn't update your profile photo, please try again",
        and: "and",
        backToProfile: "Back to Profile",
        backToSignIn: "Back to sign in",
        bad: "Bad",
        beforeEvent: "Before event",
        billingCycle: "Billing cycle",
        bonuses: "Bonus earnings",
        calendarEventConfirmation: "Added to your calendar",
        calendarEventErrorBody: "There was a problem creating a calendar event. Make sure your calendar is set up, then try again.",
        calendarEventErrorTitle: "Couldn't add to calendar",
        calendarEventTitle: "GridRewards event: Save electricity now!",
        callUs: "Call us",
        cancel: "Cancel",
        carbonComparison1After: "tree seedlings for 10 years",
        carbonComparison1Before: "growing",
        carbonComparison2After: "miles",
        carbonComparison2Before: "preventing a car from driving",
        carbonComparison3After: "cellphones from charging",
        carbonComparison3Before: "preventing",
        carbonComparison4After: "tons of waste instead of throwing it in a landfill",
        carbonComparison4Before: "recycling",
        carbonComparison5After: "acres of forest thriving for a year",
        carbonComparison6After: "homes would use in a year",
        carbonComparison6Before: "the energy that",
        carbonComparison7After: "propane tanks from being used for home barbeques",
        carbonComparison8After: "cars off the road for a year",
        carbonComparison8Before: "taking",
        carbonFootprintTitle: "Carbon footprint",
        carbonFootprintTitleCase: "Carbon footprint",
        carbonGraphAnalysisForecast: "will likely emit",
        carbonGraphAnalysisGrid: " 's power grid",
        carbonGraphAnalysisPast: "emitted",
        carbonGraphAnalysisUnits: "g of CO₂/kWh",
        carbonGraphInfo:
            "Not all electricity is created equal! Higher bars mark the hours with the highest carbon emissions per unit of electricity used—save energy during these times to have the biggest impact.",
        carbonGraphRegionPlaceholder: "Your region",
        carbonPreventedDuringEvents: "kept out of the atmosphere",
        carbonTabTitle: "CO₂",
        cardThermostatCoolTo: "Cool to",
        cardThermostatHeatTo: "Heat to",
        cardThermostatHold: "Hold",
        cardThermostatStayBetween: "Stay between",
        categoryClimateAction: "Climate Action",
        categoryEnvironmentalJustice: "Environmental Justice",
        categoryFoodSecurity: "Food Security",
        categoryLandConservancy: "Land Conservancy",
        categorySustainability: "Sustainability",
        cityHarvestDescription:
            "New York City’s largest food rescue organization, helping to feed the more than 1.5 million New Yorkers who are struggling to put meals on their tables. City Harvest will rescue 111 million pounds of food this year and deliver it, free of charge, to hundreds of food pantries, soup kitchens and other community partners across the five boroughs.",
        cityHarvestLinkName: "cityharvest.org",
        cityHarvestTitle: "City Harvest",
        community: "Community",
        completeAccountSetup: "Complete account setup",
        connectNewAccount: "Connect new account",
        connectYourAccount: "Connect your account",
        connectAccount: {
            title: "Let's get started",
            description: "Connect your power company account so you can start participating in GridRewards events and get paid.",
            noOnlineAccountTitle: "Don't have an online utility account?",
            noOnlineAccountDescription:
                "If you haven’t already, you’ll first need to set up your online power company account and attach your existing account number to the online account — this confirms your meter is actively connected.",
            cta: "Connect utility account",
        },
        createAccount: "Create account",
        createAccountErrorMissingFields: 'Please enter an email and a password, then tap "Create account".',
        createGridRewardsAccount: "Create a GridRewards account",
        currentGridCO2: "Current grid CO₂",
        currentStreak: "Current streak",
        dataFetchTitle: "Retrieving your data. This may take up to 15 minutes.",
        dataSourceNameElectricity: "Electricity",
        dataSourceNameGas: "Gas",
        dataTimeframeDay: "Day",
        dataTimeframeHour: "Hour",
        defaultDisplayName: "GridRewards User",
        deleteAccount: {
            title: "Delete your GridRewards account",
            descriptionBeforeDisconnect:
                "Deleting your account removes your access to the GridRewards program and stops you from earning cash for this and future seasons.\n\nAlong with deleting your GridRewards account, you may wish to",
            descriptionDisconnectLink: "disable sharing utility data from your utility account.",
            descriptionAfterDisconnect: "\n\nIf you’re sure, you can enter your password and delete your account below.",
            button: "Delete your account",
            passwordMissing: "Please enter a password.",
        },
        deleteAccountLinkTitle: "Delete your GridRewards account",
        digitalPaymentTimeoutInstructions: "If you don’t claim your digital payment within 30 days, a paper check will be sent to your physical address:",
        donationsDetailSubtitle:
            "These are the GridRewards nonprofit recipients. If you choose to donate your GridRewards earnings, 100 percent will be divided amongst these organizations.",
        donationsDetailTitle: "About our donations",
        done: "Done",
        duringEvent: "During event",
        earnings: "Earnings",
        earningsPotential: "Potential earnings",
        edit: "Edit",
        editPhoto: "Edit Photo",
        editProfileTitle: "Edit Profile",
        editYourInfo: "Edit your info",
        electricitySources: "Electricity sources",
        email: "Email",
        emailUs: "Email us",
        energySavingActions: "Energy-saving actions",
        energyTabTitle: "Energy",
        energyTitle: "Energy",
        enrollmentComplete: {
            carbon: {
                description:
                    "Keep an eye on your electricity use and carbon footprint. Get personalized energy insights and learn about the best times to use energy.",
                title: "Reduce carbon footprint",
            },
            community: {
                description: "Get $10 for each friend you refer. Work together with your neighborhood to earn extra rewards and multiply your impact!",
                title: "Get your community involved",
            },
            events: {
                description:
                    "GridRewards events are the most important times to save electricity. We’ll tell you when they’re happening — usually during hot summer afternoons, about 5-10 times per year.",
                title: "Watch for upcoming events",
            },
            title: "You're all signed up!\nWhat's next?",
        },
        estimated: "estimated",
        eventCountPlural: "{{count}} events",
        eventCountSingular: "1 event",
        eventLikelihood: "It's {{likelihood}} that an event could happen on {{day}}.",
        eventLikelihoodNone: "There's a low chance of an event happening this week.",
        eventPerformance: "Event performance",
        eventPerformanceDisclaimer:
            "You’ll need to reach an average of 25% or more of your electricity saving goal for all events in a particular month to earn money for that month.",
        events: {
            current: {
                timeFormat: "until {{endTime}}",
                title: "GridRewards event",
            },
            future: {
                timeFormat: "{{startTime}} to {{endTime}}",
                title: "Upcoming GridRewards event",
            },
            cta: "Count me in",
            seeAll: "All GridRewards events",
        },
        eventsTabTitle: "Events",
        eventsTitle: "Events",
        expertMode: "Expert mode",
        expertModeWarning: "You've been warned...",
        faqs: "FAQs",
        findSomeActions: "Find some energy-saving actions",
        firstName: "First name",
        fuelMixDescription:
            "As of {{time}}, {{percentRenewable}}% of {{regionName}}'s electricity came from renewable sources, and {{percentCarbon}}% from carbon-producing fuels.",
        fuelTypeDisplayDualFuel: "Dual fuel",
        fuelTypeDisplayHydro: "Hydro",
        fuelTypeDisplayNaturalGas: "Natural gas",
        fuelTypeDisplayNuclear: "Nuclear",
        fuelTypeDisplayOtherFossil: "Other fossil fuels",
        fuelTypeDisplayOtherRenewables: "Other renewables",
        fuelTypeDisplayWind: "Wind",
        getInTouch: "Get in touch",
        getYourFriendsInvolved: "Get your friends involved",
        gettingStarted: "Getting started",
        good: "Good",
        gramsCO2: "g CO₂",
        graph: {
            label: {
                day: "Past day",
                month: "Past month",
                multiYear: "Past 3 years",
                week: "Past week",
                year: "Past year",
            },
            span: {
                day: "1D",
                month: "1M",
                multiYear: "3Y",
                week: "1W",
                year: "1Y",
            },
        },
        gridRewards: "GridRewards",
        gridRewardsEarnings: "GridRewards earnings",
        gridRewardsEventPerformanceDescriptionBad: "Next event, try to get to 100% for maximum environmental benefit and cash earned.",
        gridRewardsEventPerformanceDescriptionGood: "You did well – next event, try to get to 100% for maximum environmental benefit and cash earned.",
        gridRewardsEventPerformanceDescriptionGreat: "Congratulations, you did great! You met your goal to help the planet and earn cash.",
        gridRewardsEventPerformanceDescriptionOkay: "You did okay – next event, try to get to 100% for maximum environmental benefit and cash earned.",
        gridRewardsEventTitle: "GridRewards Event",
        gridRewardsNoEventsInList: "As events happen during the warmer months, your performance will show up here.",
        gridRewardsSupport: "GridRewards support",
        haveQuestions: "Have questions? We can help.",
        high: "High",
        homeTabTitle: "Home",
        imageSaved: "Saved image to photos",
        incorrectAddressOrPaymentLate: "Incorrect address or payment late?",
        initialFetchDescription: "We’re still gathering information from your utility account. This may take a few minutes to complete.",
        insight: {
            highUsage: "High usage",
            lastBill: "Last bill",
            overallRank: "Overall rank",
            perCapitaRank: "Per capita rank",
            projectedBill: "Projected bill this month",
            vsProjected: "Vs. projected",
            vsSimilar: "Vs. similar homes",
            vsYearAgo: "Vs. 1 year ago",
            yearAgo: "Year ago",
        },
        inviteExplanation: "Did someone invite you?",
        inviteExplanationSubtitle: "Tap their referral link or enter their code",
        invitePlaceholder: "Referral code",
        kilowatts: "kilowatts",
        lastName: "Last name",
        learnAboutEvents: "Learn about GridRewards events ->",
        less: "less",
        lesson: {
            actions: {
                description: "Learn what you can do to master GridRewards events. ->",
                title: "Discover energy-saving actions",
            },
            moreTogether: {
                description: "Learn about how joining together multiplies our impact on the grid and the planet ->",
                title: "We can do more together",
            },
            referral: {
                description: "Multiply your neighborhood’s performance by inviting your friends. You’ll each get $10 and you can go after the top ranking! ->",
                title: "Invite your friends, get $10",
            },
        },
        licensesTitle: "Licenses",
        likely: "possible",
        locationsTitle: "Locations",
        loginButtonCreate: "Create new account",
        loginButtonLoggingIn: "Logging in",
        loginButtonResetPassword: "Reset password",
        loginErrorMissingFields: "Please enter both an email and a password.",
        loginHeaderSubtitle: "Note: This is different than your utility account login.",
        loginResetPassword: "Enter the email you signed up with and we'll send you a link to reset your password.",
        low: "Low",
        mailingAddress: {
            noData: "No address found",
            line1: "Street address",
            line2: "Street address 2",
            city: "City",
            provinceOrState: "State",
            postalOrZip: "Zip",
            prompt: {
                title: "Confirm your mailing address so we can send you a check.",
                buttonConfirm: "Yes, this is my mailing address",
                buttonChange: "No, change address",
                buttonAdd: "Add mailing address",
            },
            confirmation: {
                correctedTitle: "Confirm Address",
                entered: "Address entered",
                found: "Address found",
                useSelected: "Use selected address",
                notFound: "Address not found",
                edit: "Edit address",
                useAnyway: "Use anyway",
                confirmed: "Address confirmed",
            },
            prefs: {
                title: "Mailing address (Required)",
                buttonUpdate: "Update address",
                validationError: "Couldn't validate your address, please try submitting again.",
                updateSuccessful: "Updated your payment address",
                updateError: "Couldn't update your address, please try again.",
            },
        },
        mapApproximateLocation: "Approximate location",
        mapCalloutHeader: "At peak times, this location can save",
        mapDetailHeader: "Together, we can have the same impact as…",
        mapHeaderSubtitle: "At peak times, GridRewards users in this area can save",
        mapInfoBody1:
            "A virtual power plant (VPP) combines all of a community’s energy resources into one network that can generate or save power for the entire community. Those resources might generate electricity (like solar panels and windmills) or save electricity (like people turning off lights). In the GridRewards VPP, we combine the power savings from homes and businesses who have pledged to take energy-saving actions during GridRewards events.",
        mapInfoBody2:
            "We estimate that during a GridRewards event, each participating home or business will be able to save up to 30% of their maximum electricity use. For example, if a typical home might use up to 1 kW, then we would estimate that this home could save 0.3 kW when they take effective enegy-saving actions.",
        mapInfoBody3:
            "During times of peak electricity demand, power companies have to spin up old, innefficient power plants that emit more harmful greenhouse gasses per kWh of electricity than at normal times. By saving electricity during GridRewards events, your actions prevent more CO₂ than usual from being emitted into the atmosphere.",
        mapInfoTitle1: "What is a virtual power plant?",
        mapInfoTitle2: "How do we calculate our collective potential impact?",
        mapInfoTitle3: "How does the GridRewards VPP help the environment?",
        mapLargeCommercial: "Large commercial property",
        mapLargeResidential: "Large residential property",
        mapLegendButtonTitle: "Legend",
        mapLegendGroupTitle: "Group of locations",
        mapLegendTitle: "Potential electricity savings during GridRewards events",
        mapSmallCommercial: "Small commercial property",
        mapSmallResidential: "Small residential property",
        megawatts: "megawatts",
        minutePlural: "minutes",
        minuteSingular: "minute",
        moderate: "Moderate",
        more: "more",
        mothersOutFrontDescription:
            "Founded on the conviction that there is no more powerful force for change than women mobilizing to protect their children. We know that when we come together for climate, racial, and social justice to tell our stories and share our dreams for our children, we cannot fail.",
        mothersOutFrontLinkName: "mothersoutfront.org",
        mothersOutFrontTitle: "Mothers Out Front",
        naturalAreasConservancyDescription:
            "The Natural Areas Conservancy is a champion of NYC’s 20,000 acres of forests and wetlands for the benefit and enjoyment of all. Their team of scientists and experts promote nature’s diversity and resilience across the five boroughs, working in close partnership with the City of New York.",
        naturalAreasConservancyLinkName: "naturalareasnyc.org",
        naturalAreasConservancyTitle: "Natural Areas Conservancy",
        neighborhood: {
            cta: "See your community",
            description: {
                plural: "You're part of the {{name}} neighborhood, along with {{count}} others.",
                singular: "You're part of the {{name}} neighborhood, along with {{count}} other person.",
            },
            message: {
                captainTitle: "Get your neighbors ready!",
                captainDescription: "As the neighborhood captain, you can send a message about this event that everyone will see.",
                captainPlaceholder:
                    "Example: Hey {{neighborhoodName}}, it’s go time {{eventTime}}! If we’re one of the top neighborhoods, we can earn extra cash, so let’s shut everything down and go for a walk!",
                captainButtonTitle: "Update message",
                attribution: "Your neighborhood captain",
                updateSuccess: "Updated the neighborhood message for this event",
                updateFailed: "Couldn't update your neighborhood message, please try again",
                videoUpload: {
                    retry: "Retry upload",
                    recordNew: "Record new video",
                    clear: "Clear video",
                    error: "Uh oh, we had an issue uploading this video.",
                    remove: "Remove video",
                },
            },
        },
        networkUnavailableBody: "No network connection is available. Please connect to cellular or Wi-fi and try again.",
        networkUnavailableTitle: "Network unavailable",
        newYorkAndDC: "New York City & DC",
        newYorkAndNational: "New York & National",
        newYorkCity: "New York City",
        nextGoodTime: "The next good time to use electricity is",
        nextOkayCO2Level: "Next okay CO₂ level",
        noneInNext24Hours: "None in next 24h",
        notification: {
            currentlyViewingTitle: "Currently viewing notifications for",
            authCompleted: "Congratulations, you're all set up! Here's what you need to know to get started with GridRewards events",
            eventEnd: "Congratulations! Your GridRewards event is over now. Check back in a few days to see how you did!",
            eventPerformanceAvailable: "Find out how you did on the {{timeString}} event.",
            eventScheduled: "Incoming! GridRewards event will be happening {{timeRangeString}}",
            eventStart: "Let's go! Your GridRewards event starts {{atTimeString}}",
            fullDataAvailable: "Your energy data is ready to view!",
            initialDataAvailable: "Your recent usage data is ready to view. It may take up to 15 minutes to fetch your full energy history.",
            learnMore: "Learn more about {{subject}}",
            link: "Learn more: {{- linkTitle}}",
            newNeighborhoodMessage: "New message from your neighborhood captain",
            paymentAvailable: "Your {{amount}} payment is available!",
            promptSurvey: "Improve your energy analysis by taking the property survey",
            promptThermostat: "Have an Ecobee smart thermostat? Connect it to GridRewards and control it from inside the app",
            referralPickedUp: "{{userDisplayName}} picked up your referral",
            referralSummary: "This season, you had {{number}} {{referralsSingularOrPlural}} who did well enough to earn you a bonus!",
            reminderAuth: "Connect your utility account so you can start earning money today!",
            reminderReferral: "Refer a friend and you'll each get $10!",
            seasonEarnings: "Your estimated earnings for the season are available",
            seasonStart:
                "The GridRewards season starts on {{date}} this year. Keep an eye out for GridRewards events, they're your chance to earn cash for saving electricity!",
            title: "Notifications",
            unusualUsage: "We saw some unusual usage. DETAILS HERE.",
        },
        now: "Now",
        off: "Off",
        ok: "OK",
        okay: "Okay",
        onboarding: {
            carbon: {
                description: "Learn about the best times to use electricity.",
                title: "Not all electricity is created equal! The carbon footprint of your electricity changes.",
            },
            energy: {
                description: "",
                title: "Your electricity data will show here so you can monitor your energy use.",
            },
            events: {
                description: "Join your community! You can earn extra money and multiply your collective impact on the enviroment.",
                title: "Events are 3-4 hour blocks of time that happen during some hot summer afternoons.",
            },
            home: {
                description:
                    "Reduce your energy use for just a few hours during a few important days, and you’ll get paid at the end of the year! An average household can earn more than $100/year.",
                title: "Save electricity during GridRewards events to earn money and help the environment.",
            },
        },
        onboardingLocationError: "We don't support that zip code yet.",
        onboardingLocationPlaceholder: "Enter your zip code",
        onboardingWelcomeSubtitle: "GridRewards works with your utility company to help you earn money by saving electricity.",
        participants: {
            othersPlural: "{{count}} neighbors are in so far",
            othersSingular: "1 neighbor is in so far",
            youAndOthersPlural: "You and {{count}} neighbors are in!",
            youAndOthersSingular: "You and 1 neighbor are in!",
            youOnly: "You are in!",
        },
        password: "Password",
        paymentPrefDonateSeePartners: "See where we are giving this season",
        paymentPrefDonateSubtitle: "We’ll donate 100% of your earnings to nonprofits doing great work.",
        paymentPrefDonateTitle: "Make a donation",
        paymentPrefFinePrint: "Changes apply to future payments that haven’t yet been initiated or scheduled.",
        paymentPrefInstantFee: "$0.25 fee per payment",
        paymentPrefInstantSubtitle: "We’ll send you a payment link. You can pick up your payment using your PayPal account.",
        paymentPrefInstantTitle: "Instant payment",
        paymentPrefMailSubtitle: "Payments will be sent out at the end of the calendar year.",
        paymentPrefMailTitle: "Get a check in the mail",
        paymentPrefShortDonate: "Donate",
        paymentPrefShortInstant: "Instant",
        paymentPrefShortMail: "Check",
        paymentPrefUpdated: "Your payment preference is now set to {{pref}}",
        paymentPreference: "Payment preference",
        paymentPrefsSubtitle: "Choose how you’d like to receive your GridRewards payments. Payments are calculated and sent at the end of the year.",
        paymentsAtEndOfYear: "Your payment will be sent at the end of the calendar year via {{paymentMethod}}.",
        paymentGeneralTimeframe: "Payments are calculated and sent at the end of the year.",
        payments: {
            title: "Your in-progress earnings",
            rowTitles: {
                performance: "Event performance",
                bonus: "Bonus earnings",
                bonusDescription: "To reach this bonus amount, make sure your referred friends also perform well during each event this season!",
                estimated: "Estimated earnings",
                previous: "Previous payments",
            },
        },
        pendingAnalysis: "Pending analysis",
        percentLess: "{{value}}% less",
        percentMore: "{{value}}% more",
        percentOfGoal: "% of your goal",
        percentOfGoalShort: "{{percent}}% of goal",
        percentRenewable: "{{percent}}% renewable",
        performance: "Performance",
        phoneNumber: "Phone number",
        phoneNumberGenericError: "Please enter a valid phone number.",
        phoneNumberPlaceholder: "Phone number",
        phoneNumberPrompt: "Add for text reminders",
        photoTake: "Take a photo",
        photoLibrary: "Choose from library",
        photoDelete: "Delete photo",
        photoPermissionMissingTitle: "Photos disabled",
        photoPermissionMissing: "Go to Settings to enable camera and library permissions for GridRewards",
        powerGrid: "power grid",
        powerInYourHands: "The power is finally in your hands.",
        profileFirstNameMissing: "Please enter your first name.",
        profileLastNameMissing: "Please enter your last name.",
        profilePhoneNumberDescription:
            "Get notified by text message when GridRewards events are happening. There are usually 5-10 events per year.\n\nMessage and data rates apply. Message frequency varies. Reply HELP for help, and reply STOP to opt out.",
        profileRateReview: "Rate & Review",
        profileSignOut: "Sign out",
        profileUpdateGenericError: "Oops! Your profile update wasn't saved.",
        recent: "recent",
        referral: {
            codeCopied: "Copied referral code!",
            copyYourCode: "Copy your code",
            description:
                "Have your friend use your referral code during signup — you’ll both get a $10 bonus when they save electricity during the GridRewards season!\n\nRefer as many friends as you want.",
            downloadHere: "Download GridRewards here:",
            emailSubject: "Earn cash for saving electricity!",
            literalPlural: "referrals",
            literalSingular: "referral",
            prompt: "Refer a friend",
            screenTitle: "Referrals",
            shareMessage:
                "Unplug. Get paid. Earn cash for saving electricity at key times with GridRewards!\n\nUse code {{code}} when creating your account (or use this referral link to autofill the code) for a $10 bonus!",
            shareYourCode: "Share your referral code",
            title: "Give $10, Get $10",
            errorCreatingLink: "There was a problem creating the share link. Please try again.",
        },
        regionElectricitySourcesTitle: "{{regionName}}'s electricity sources",
        resetPasswordCompletePlaceholder: "your email",
        resetPasswordCompleteSubtitleAfter: "for a link to reset your password.",
        resetPasswordCompleteSubtitleBefore: "Check",
        resetPasswordCompleteTitle: "Password reset link sent!",
        resetPasswordTitle: "Reset your password",
        reviewPromptRate: "It's great!",
        reviewPromptRemind: "Remind me later",
        reviewPromptTitle: "How do you like GridRewards so far?",
        saveImage: "Save image",
        savedDuringEvents: "saved during GridRewards events",
        search: "Search",
        seePaymentPreferences: "See payment preferences",
        seeTerms: "See terms",
        seeYourAchievements: "See your achievements",
        seeYourCarbonFootprint: "See your carbon footprint",
        seeYourEnergyUse: "See your energy use",
        send: "Send",
        sendFeedback: "Send feedback",
        serviceAddressMissing: "We’ll need a physical address to mail you a check.",
        setNickname: "Set nickname",
        setNicknameDescription: "Add a nickname to this location to make it easier to remember.",
        settings: "Settings",
        setYourPaymentPref: "Set your payment preference",
        share: "Share",
        shareAndTag: "Share and tag @getgridrewards to be featured!",
        signIn: "Sign in",
        signInCreatePrompt: "New here? Create an account",
        signInDescription: "Sign in with your GridRewards account — this is different than your utility account login.",
        skip: "Skip",
        special: "Special",
        sustainableWestchesterDescription:
            "Sustainable Westchester is a nonprofit consortium of Westchester County local governments that facilitates effective collaboration on sustainability initiatives. Their goal is to bring socially responsible, environmentally sound, and economically viable solutions that create healthy, resilient, sustainable communities.",
        sustainableWestchesterLinkName: "sustainablewestchester.org",
        sustainableWestchesterTitle: "Sustainable Westchester",
        tbd: "TBD",
        thatsTheSameAs: "That's the same as…",
        theme: {
            dark: "Dark",
            light: "Light",
            system: "System",
            title: "Theme",
        },
        thermostat: {
            mode: {
                off: "Off",
                cool: "Cool",
                heat: "Heat",
                both: "Both",
            },
        },
        thermostatConnectPrompt: "Have an Ecobee smart thermostat? You can control it from the GridRewards app.",
        thermostatConnectButtonTitle: "Connect your Ecobee",
        thisBillingCycle: "this billing cycle",
        thisSeason: "This season",
        timeRangeFormatGeneral: "from {{startTime}} to {{endTime}}",
        timeAt: "at {{time}}",
        timeZoneInfo: "All times for this property are in {{name}}",
        tip: "Tip",
        today: "Today",
        tomorrow: "tomorrow",
        university: {
            courseEvents: {
                mostMoney: {
                    body: "During GridRewards event hours, reduce your electricity by at least a third. You’ll earn the maximum amount of money if you do this for all events during a particular summer.",
                    title: "How do I earn the most money?",
                },
                participate: {
                    body: "Just reduce electricity as much as you can during the event hours. Your savings are measured automatically through your electricity meter.\n\nPre-cool your space starting 4 hours before the event, then use our energy-saving actions as a helpful guide for how to shift your energy use outside the event hours.",
                    title: "How do I participate in events?",
                    tipSave: "Try to save at least one third of your typical electricity use during each GridRewards event to earn the most money.",
                },
                performance: {
                    body: "Your electricity savings will be tracked automatically and compared to your typical use during that time. Try to save at least one third of your typical electricity use during each GridRewards event to earn the most money.",
                    title: "How does my performance get measured?",
                },
                title: "GridRewards events",
                whatIsEvent: {
                    body: "GridRewards events are 3-4 hour blocks of time where you can earn money by saving electricity. They usually happen on a few hot summer afternoons, about 5-10 times per year. We’ll notify you ahead of time so you can get ready.",
                    title: "What is a GridRewards event?",
                },
                whyImportant: {
                    body: 'Events occur when the electricity grid is most stressed, and utilities are forced to fire up dirty, oil- and gas-powered "peaker" plants to deal with the extra demand. Events alleviate stress on the grid, help prevent blackouts and brownouts, and stop these extra-polluting plants from turning on.\n\nGridRewards events are so important that your utility company is willing to pay you real money to reduce at these times.',
                    title: "Why are GridRewards events important?",
                },
            },
            courseMultiplyImpact: {
                neighborhoodPride: {
                    body: "The people in your neighborhood all contribute to how much load is put on the power grid. Join up with other GridRewards users in your neighborhood to get extra rewards by working together, compete with other neighborhoods, and see how much more impact we can have by working together.",
                    title: "Neighborhood pride",
                },
                referral: {
                    body: "If you get someone to sign up for GridRewards and they save enough electricity to earn money during the next GridRewards season, you’ll each get a $10 bonus and you’ll multiply your positive impact. You can invite as many people as you’d like.",
                    title: "Refer a friend",
                },
                shiftThePower: {
                    body: "For the first time, you and your community have control of your energy use and carbon footprint. By shifting your use away from a few key times, you help make the power we all use cleaner (and earn cash for doing it).",
                    title: "You’re part of a movement.",
                },
                title: "Multiply your impact",
            },
            courseOther: {
                carbonFootprint: {
                    body: 'Not all electricity is created equal — check the "Carbon" tab to understand when using electricity will have the smallest carbon footprint.',
                    title: "Reduce your carbon footprint",
                },
                checkYourself: {
                    body: 'Keep an eye on your electricity use and projected monthly bills in the "Energy" tab.',
                    title: "Check yourself",
                },
                title: "What else can I do with this app?",
            },
            coursePowerGrid: {
                peakerPlants: {
                    body: "When the grid is overloaded, power companies rely on additional fossil-fueled power plants for quick relief. Keeping these peaker plants available is not only expensive for the power companies, but that expense gets passed on to you.\n\nThese plants are also older, less efficient, reliant on dirty fuels, and emit twice as much carbon.",
                    title: "What are peaker plants?",
                },
                timeWindow: {
                    body: "By studying historical use and including other information like weather data, power companies predict when their peak electricity use is going to be, and how much will be required. Those peaks are pretty big – around 200% of normal electricity use. If they can get that peak a little lower, it brings down costs all year round.",
                    title: "Why does it matter when I reduce electricity?",
                },
                title: "Power and the electricity grid",
                unplugging: {
                    body: "It sounds like a small thing to unplug your phone or shift the time you’re drying your clothes, but these small changes add up. Reducing your use during GridRewards events has a much larger-than-usual impact on the carbon footprint of the whole grid. Flattening the peak demand means that peaker plants stay offline, saving our air and your wallet.",
                    title: "Does unplugging actually help?",
                },
                whatIsTheGrid: {
                    body: '"The grid" is the combination of power plants that supply you with electricity. Different locations draw from different sets of power sources – some are clean, some are… less so.',
                    title: 'What is "the grid"?',
                },
            },
            courseTitle: "Lesson {{index}}/{{total}}",
        },
        universityTitle: "GridRewards University",
        unknown: "Unknown",
        unlikely: "unlikely",
        usageThisWeek: "Usage this week",
        valuePropEventsBody:
            "These are the most valuable times to save electricity. They often happen on a few hot summer afternoons.\n\nWhile it’s good to save electricity year-round, GridRewards events are so important that your utility company is willing to pay you real money to reduce at peak times.\n\nWhy? When your utility company needs to meet your region’s peak electricity demand, they spin up older, less-efficient power plants that cost more money to maintain and release more carbon into the air per unit of electricity.",
        veryBad: "Very bad",
        veryHigh: "Very high",
        veryLikely: "likely",
        virtualPowerPlant: "Virtual Power Plant",
        weActDescription:
            "WE ACT’s mission is to build healthy communities by ensuring that people of color and/or low-income residents participate meaningfully in the creation of sound and fair environmental health and protection policies and practices.",
        weActLinkName: "weact.org",
        weActTitle: "WE ACT",
        welcome: "Welcome",
        welcomeBack: "Welcome back",
        welcomeToGridRewards: "Welcome to GridRewards",
        westchesterCounty: "Westchester County",
        yesterday: "yesterday",
        yesterdayVsProjection: "Yesterday vs. projection",
        yourAddress: "Your Address",
        yourCarbonFootprint: "Your carbon footprint",
        yourEarningsShort: "Your {{timePeriod}} earnings",
        yourNeighborhood: "Your neighborhood",
        yourPerformance: "Your performance",
        yourProjectedBill: "Your projected bill",
        zipCode: "Zip code",
        zipCodeExplanation: "Let’s confirm you’re in a supported area",
        zipCodeInvalid: "Please enter a valid zip code.",
    },
}
