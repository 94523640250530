import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Text, TouchableOpacity } from "react-native"
import { activeOpacity } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function TappableRow(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    const { title = "", titleTintColor = theme.textPrimary, description = "", descriptionTintColor = theme.textSecondary, onPress, containerStyle } = props

    return (
        <TouchableOpacity
            style={[
                {
                    flex: 1,
                    flexDirection: "row",
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 16,
                },
                containerStyle,
            ]}
            activeOpacity={activeOpacity}
            onPress={onPress}
            {...props}
        >
            <Text style={[TextStyles.body2Medium, { flexShrink: 1, color: titleTintColor, marginRight: 8 }]}>{title}</Text>

            <Text style={[TextStyles.body2, { flex: 1, color: descriptionTintColor, textAlign: "right" }]}>{description}</Text>
        </TouchableOpacity>
    )
}
