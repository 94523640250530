import { useHeaderHeight } from "@react-navigation/elements"
import { useNavigation } from "@react-navigation/native"
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { Dimensions, Image, Platform, StyleSheet, Text, TextInput, View } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import Button from "../components/Button.js"
import ErrorMessageInline from "../components/ErrorMessageInline"
import AppConstants, { ScreenNames } from "../shared/AppConstants"
import Colors from "../shared/Colors"
import { baseHeaderStyle, CommonStyles, detailOptions } from "../shared/CommonStyles"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext.js"
import { sendBranchEvent } from "../shared/Utils.js"

if (Platform.OS !== "web") {
    Adjust = require("react-native-adjust").Adjust
    AdjustConfig = require("react-native-adjust").AdjustConfig
    AdjustEvent = require("react-native-adjust").AdjustEvent
}

export default function LoginScreen(props) {
    const navigation = useNavigation()

    const { theme } = React.useContext(ThemeContext)
    const headerHeight = useHeaderHeight()

    const [initialLoginStateResolved, setInitialLoginStateResolved] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [createAccountPending, setCreateAccountPending] = useState(false)
    const [loginPending, setLoginPending] = useState(false)
    const [resetPasswordVisible, setResetPasswordVisible] = useState(false)
    const [resetPasswordComplete, setResetPasswordComplete] = useState(false)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)

    const [loginFocused, setLoginFocused] = useState(false)
    const [passwordFocused, setPasswordFocused] = useState(false)

    useEffect(() => {
        // FIXME: For some reason, we can't use useContext in the LoginStack containing this component, so we can't set the
        // screen options that require theme context out there. All other stacks work fine... ???
        // For now, set them inside this component.
        navigation.setOptions({ ...detailOptions, headerStyle: { ...baseHeaderStyle, backgroundColor: theme.background }, headerTintColor: theme.textPrimary })

        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged((user) => {
            // Once something loads (with a user or without), we know we can proceed.
            if (!initialLoginStateResolved) {
                setInitialLoginStateResolved(true)
            }
        })
        return () => {
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        if (loginPending) {
            // Start the login process
            signInWithEmailAndPassword(auth, email, password)
                .then(function (firebaseUser) {
                    // Log a custom event
                    if (Platform.OS !== "web") {
                        // Tell Adjust that a user logged in.
                        console.log("Signed in successfully, telling Adjust")
                        Adjust.trackEvent(new AdjustEvent(AppConstants.analytics.logicalBuildingsAccountLogin))
                    }

                    // Tell Branch
                    const customData = {
                        email: email,
                    }
                    sendBranchEvent(AppConstants.analytics.branchEvents.userLogin, null, customData)
                })
                .catch(function (error) {
                    let errorCode = error.code
                    let errorMessage = error.message
                    console.log("Login error: " + errorCode + " " + errorMessage)
                    setErrorMessage(errorMessage)
                    setLoginPending(false)
                })
        }
    }, [loginPending])

    useEffect(() => {
        if (createAccountPending) {
            // Start the create account process
            createUserWithEmailAndPassword(auth, email, password).catch(function (error) {
                let errorCode = error.code
                let errorMessage = error.message
                console.log("Create account error: " + errorCode + " " + errorMessage)
                setErrorMessage(errorMessage)
                setLoginPending(false)
                setCreateAccountPending(false)
            })
        }
    }, [createAccountPending])

    const loginButtonPressed = () => {
        if (email == null || password == null) {
            console.log("Null email or password, skipping request...")
            setErrorMessage(i18n.t("loginErrorMissingFields"))
            setLoginPending(false)
            return
        }

        // Try to log in.
        console.log("Trying to sign in")
        setErrorMessage(null)
        setLoginPending(true)
    }

    const createAccountButtonPressed = () => {
        navigation.navigate(ScreenNames.CreateAccount.routeName, {
            initialEmail: email,
            initialPassword: password,
        })
    }

    const resetPasswordPressed = () => {
        if (email == null) {
            console.log("Null email, skipping request...")
            return
        }

        sendPasswordResetEmail(auth, email)
            .then(function () {
                console.log("Reset email sent")
            })
            .catch(function (error) {
                // An error happened.
                console.log("Error sending password reset email: " + JSON.stringify(error))
            })

        setResetPasswordComplete(true)
    }

    if (!initialLoginStateResolved) {
        // console.log("Login not resolved yet")
        // If we don't know whether we've logged in or not yet, just show a simple-to-render white screen.
        return null
    }

    let screenHeight = Dimensions.get("window").height - headerHeight
    return (
        <KeyboardAwareScrollView
            style={[{ height: screenHeight, backgroundColor: theme.background }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
            showsVerticalScrollIndicator={false}
            alwaysBounceVertical={true}
            extraScrollHeight={50}
            keyboardOpeningTime={1}
            keyboardShouldPersistTaps="always"
        >
            <View style={{ marginHorizontal: 32, paddingTop: 24, backgroundColor: theme.background }}>
                <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 16 }}>
                    {resetPasswordVisible && resetPasswordComplete && (
                        <Image source={require("../assets/images/insightAction.png")} style={{ width: 36, height: 36, marginRight: 8, marginTop: -10 }} />
                    )}
                    <Text style={[TextStyles.h2, { color: theme.textPrimary }]}>
                        {resetPasswordVisible
                            ? resetPasswordComplete
                                ? i18n.t("resetPasswordCompleteTitle")
                                : i18n.t("resetPasswordTitle")
                            : i18n.t("welcomeBack")}
                    </Text>
                </View>
                <View style={{ marginBottom: 32 }}>
                    {resetPasswordVisible ? (
                        resetPasswordComplete ? (
                            <Text style={[TextStyles.body2, { color: theme.textSecondary }]}>
                                {i18n.t("resetPasswordCompleteSubtitleBefore") + " "}
                                <Text style={[TextStyles.body2]}>{email ?? i18n.t("resetPasswordCompletePlaceholder")}</Text>
                                {" " + i18n.t("resetPasswordCompleteSubtitleAfter")}
                            </Text>
                        ) : (
                            <Text style={[TextStyles.body2, { color: theme.textSecondary }]}>{i18n.t("loginResetPassword")}</Text>
                        )
                    ) : (
                        <Text style={[TextStyles.body2, { color: theme.textSecondary }]}>{i18n.t("signInDescription")}</Text>
                    )}
                </View>

                {errorMessage != null && <ErrorMessageInline text={errorMessage} />}

                {(resetPasswordComplete == false || resetPasswordVisible == false) && (
                    <View style={[styles.loginInputFieldContainer, { marginBottom: 16 }]}>
                        <TextInput
                            placeholder={i18n.t("email")}
                            placeholderTextColor={theme.textHint}
                            underlineColorAndroid={"transparent"}
                            autoCorrect={false}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.loginInputField,
                                { color: theme.textPrimary, borderColor: loginFocused ? theme.textSecondary : theme.border },
                            ]}
                            // autofocus
                            onChangeText={(text) => setEmail(text ? text.trim() : null)}
                            onFocus={() => setLoginFocused(true)}
                            onBlur={() => setLoginFocused(false)}
                            autoComplete="username"
                            textContentType="username"
                        />
                    </View>
                )}
                {resetPasswordVisible == false && (
                    <View style={[styles.loginInputFieldContainer, { marginBottom: 32 }]}>
                        <TextInput
                            placeholder={i18n.t("password")}
                            placeholderTextColor={theme.textHint}
                            secureTextEntry={true}
                            underlineColorAndroid={"transparent"}
                            autoCapitalize={"none"}
                            style={[
                                TextStyles.body2,
                                styles.loginInputField,
                                { color: theme.textPrimary, borderColor: passwordFocused ? theme.textSecondary : theme.border },
                            ]}
                            onChangeText={(text) => setPassword(text ? text.trim() : null)}
                            onFocus={() => setPasswordFocused(true)}
                            onBlur={() => setPasswordFocused(false)}
                            autoCompleteType="password"
                            textContentType="password"
                        />
                    </View>
                )}

                {!resetPasswordComplete && (
                    <Button
                        type="fill"
                        buttonTintColor={theme.purple}
                        contentTintColor={theme.textLight}
                        spinnerTintColor={theme.textLight}
                        onPress={resetPasswordVisible ? resetPasswordPressed : loginButtonPressed}
                        title={resetPasswordVisible ? i18n.t("send") : loginPending ? i18n.t("loginButtonLoggingIn") : i18n.t("signIn")}
                        key={"loginButton"}
                        showSpinner={loginPending}
                        containerStyle={{ marginTop: resetPasswordVisible ? 16 : 0, marginBottom: 16, opacity: loginPending ? 0.6 : 1.0 }}
                    />
                )}

                {/* Reset password/back to login */}
                <Button
                    type="ghost"
                    contentTintColor={theme.textSecondary}
                    onPress={() => {
                        setErrorMessage(null)
                        setResetPasswordVisible(!resetPasswordVisible)
                        setResetPasswordComplete(false)
                    }}
                    title={resetPasswordVisible ? i18n.t("backToSignIn") : i18n.t("loginButtonResetPassword")}
                    containerStyle={{ marginBottom: 8 }}
                    key={"resetPasswordButton"}
                />
                {/* Create account */}
                {!resetPasswordVisible && (
                    <Button
                        type="ghost"
                        contentTintColor={theme.textSecondary}
                        title={i18n.t("signInCreatePrompt")}
                        // style={{ marginTop: resetPasswordVisible ? 8 : 24, marginBottom: 16 }}
                        onPress={createAccountButtonPressed}
                        key={"createAccountButton"}
                        showSpinner={createAccountPending}
                    />
                )}
            </View>
        </KeyboardAwareScrollView>
    )
}

const styles = StyleSheet.create({
    // Login screen

    loginInputFieldContainer: {
        height: 50,
    },

    loginInputField: {
        flex: 1,
        width: "100%",
        borderRadius: 8,
        paddingHorizontal: 16,
        justifyContent: "center",
        borderWidth: 1,
    },

    loginButtonContainer: {
        height: 51,
        width: 284,
        marginTop: 24,
        marginBottom: 16,
    },

    loginHelpButton: {
        height: 50,
        width: "100%",
        marginBottom: 50,
        justifyContent: "center",
        alignItems: "center",
    },

    loginErrorContainer: {
        width: "100%",
        height: 70,
        backgroundColor: Colors.white,
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 8,
    },

    loginErrorText: {
        textAlign: "center",
        color: Colors.red,
    },

    loginErrorButton: {
        justifyContent: "center",
        alignItems: "center",
    },
})
