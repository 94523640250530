import { useNavigation } from "@react-navigation/native"
import { path, prop } from "ramda"
import React from "react"
import { Image, Platform, ScrollView, Text, TouchableOpacity, View } from "react-native"
import { useSelector } from "react-redux"
import { Achievements, ScreenNames } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import { activeOpacity, maxContentWidth } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function AchievementsScreen(props) {
    const { theme } = React.useContext(ThemeContext)
    const navigation = useNavigation()

    // FIXME: TEST ONLY
    // const mockedAchievements = require("../sample/sampleAchievements.json")

    const achievementsData = useSelector((state) => state.primaryData.achievementsData) || []
    // console.log("DETAIL: Real achievements data is " + JSON.stringify(achievementsData))

    // NOTE: SectionList can't wrap items. We can't use FlatList + flexWrap because it throws a warning and tells us to use numColumns,
    // but numColums doesn't work with a horizontal list and we can't use a vertical list because we can't embed a vertical list inside a
    // scroll view moving the same direction. react-native-super-grid's SectionGrid can't seem to figure out how to create a list
    // where things are left-aligned and ragged-right.
    // Rolls eyes. We're going to do this manually.

    const iconSize = 118 // The actual icon includes extra space for the on-state's glow
    const iconLayoutSize = 100
    // FIXME: For some reason, the flexWrap behavior on Android doesn't actually allow the items to go full width. If we allow the same padding, even
    // though the calculations should work, we still get the wrong wrapping behavior. Shrinking the padding slightly seems to work for now.
    const spaceBetween = Platform.OS == "android" ? 22 : 24
    const edgePadding = 20
    // Max width, minus borders, minus one (non-margin-ed) cell, divided by the width of a cell plus its margin.
    // const rawColumnCount = (maxContentWidth - iconLayoutSize - 2 * edgePadding) / (iconLayoutSize + spaceBetween)
    // console.log("Max content width is " + maxContentWidth + ", raw column count is " + rawColumnCount)
    // const numColumns = Math.floor(rawColumnCount) + 1
    // console.log("numColumns is " + numColumns + ": raw column calc is " + rawColumnCount.toFixed(5) + " maxContentWidth is " + maxContentWidth)

    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
            contentContainerStyle={[CommonStyles.mainScreenContent, { paddingHorizontal: edgePadding }]}
        >
            {Achievements.map((section, index) => {
                return (
                    <View key={prop("title", section) + index.toString()}>
                        <Text style={[TextStyles.h4, { color: theme.textPrimary, marginBottom: 16 }]}>{prop("title", section) || ""}</Text>
                        <View style={{ flexDirection: "row", flexWrap: "wrap", marginRight: -spaceBetween }}>
                            {prop("data", section).map((item, index) => {
                                const matchingItems = achievementsData.filter(
                                    (possibleItem) => path(["achievement", "type"], possibleItem) == prop("type", item)
                                )
                                const completedCount = (matchingItems || []).length
                                const completed = completedCount > 0
                                return (
                                    <View
                                        key={prop("title", item) + index.toString()}
                                        style={{ alignItems: "center", marginBottom: 24,  marginRight: spaceBetween }}
                                    >
                                        <TouchableOpacity
                                            activeOpacity={completed ? activeOpacity : 1.0}
                                            style={{
                                                width: iconLayoutSize,
                                                height: iconLayoutSize,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                overflow: "visible",
                                            }}
                                            onPress={() => {
                                                navigation.navigate(ScreenNames.AchievementDetail.routeName, {
                                                    achievements: matchingItems,
                                                    achievementConfig: item,
                                                })
                                            }}
                                        >
                                            <Image
                                                source={theme.icons.achievements.small[prop("icon", item) + (completed ? "" : "Off")]}
                                                style={{
                                                    width: iconSize,
                                                    height: iconSize,
                                                }}
                                            />
                                        </TouchableOpacity>
                                        {completedCount > 1 && (
                                            <View
                                                style={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: theme.surface1,
                                                    borderRadius: 1000,
                                                    height: 35,
                                                    minWidth: 35,
                                                    marginTop: 12,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.body2Semi,
                                                        {
                                                            color: theme.textSecondary,
                                                        },
                                                    ]}
                                                >
                                                    {completedCount}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                )
            })}
        </ScrollView>
    )
}
