import { Platform, StyleSheet, Dimensions } from "react-native"
import BackButton from "../components/BackButton"
import { maxContentWidth } from "./Layout"
import { TextStyles } from "./TextStyles"

export const CommonStyles = StyleSheet.create({
    mainScreenScroll: {
        flex: 1,
        // backgroundColor: theme.background,
        paddingTop: 32,
    },

    mainScreenContent: {
        alignSelf: "center",
        width: maxContentWidth,
        paddingBottom: 100,
    },
})

export const detailOptions = {
    // headerStyle: baseHeaderStyle,
    headerTitleStyle: TextStyles.h3,
    headerTitleAlign: "center",
    headerBackTitleVisible: false,
    headerLeft: (props) => <BackButton {...props} />,
    // headerTintColor: theme.textPrimary,
}

export const baseHeaderStyle = {
    elevation: 0, // remove shadow on Android
    shadowOpacity: 0, // remove shadow on iOS
    borderWidth: 0,
}
