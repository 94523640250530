import i18n from "../shared/i18n"

export const ScreenNames = {
    AccountConnectionPrompt: { title: "", routeName: "AccountConnectionPrompt" },
    AchievementDetail: { title: "Achievement", routeName: "AchievementDetail" },
    Achievements: { title: i18n.t("achievementsTitle"), routeName: "Achievements" },
    Actions: { title: "Actions", routeName: "Actions" },
    ActionsInfo: { title: i18n.t("energySavingActions"), routeName: "ActionsInfo" },
    AddressVerification: { title: i18n.t("mailingAddress.confirmation.correctedTitle"), routeName: "AddressVerification" },
    CarbonScreen: { title: i18n.t("carbonFootprintTitleCase"), routeName: "CarbonDetail" },
    CreateAccount: { title: i18n.t("createAccount"), routeName: "CreateAccount" },
    DeleteAccount: { title: "", routeName: "DeleteAccount" },
    DonationsDetail: { title: "About our donations", routeName: "DonationsDetail" },
    EditProfile: { title: i18n.t("editProfileTitle"), routeName: "EditProfile" },
    EditProfileModal: { title: i18n.t("editProfileTitle"), routeName: "EditProfileModal" },
    EnergyScreen: { title: i18n.t("energyTabTitle"), routeName: "EnergyScreen" },
    EnrollmentComplete: { title: "You're all set!", routeName: "EnrollmentComplete" },
    EventsScreen: { title: i18n.t("eventsTabTitle"), routeName: "EventsScreen" },
    GridRewardsEventDetail: { title: i18n.t("gridRewardsEventTitle"), routeName: "GridRewardsEventDetail" },
    GridRewardsEventDetailHomeStack: { title: "Event detail: Home", routeName: "GridRewardsEventDetailHomeStack" },
    History: { title: "History", routeName: "History" },
    Home: { title: i18n.t("homeTabTitle"), routeName: "HomeScreen" },
    InfoDetailActionsStack: { title: "More info: Actions", routeName: "InfoDetailActionsStack" },
    InfoDetailGridRewardsStack: { title: "More info: Events", routeName: "InfoDetailGridRewardsStack" },
    InfoDetailHomeStack: { title: "More info", routeName: "InfoDetailHomeStack" },
    Licenses: { title: i18n.t("licensesTitle"), routeName: "Licenses" },
    Locations: { title: i18n.t("locationsTitle"), routeName: "Locations" },
    Login: { title: i18n.t("signIn"), routeName: "Login" },
    Map: { title: "Map", routeName: "Map" },
    MapInfoDetail: { title: "About Virtual Power Plants", routeName: "MapInfoDetail" },
    MapScreen: { title: "Virtual Power Plant", routeName: "MapScreen" },
    Neighborhood: { title: "Neighborhood", routeName: "Neighborhood" },
    Notifications: { title: i18n.t("notification.title"), routeName: "Notifications" },
    OnboardingNotificationsScreen: { title: "Notifications", routeName: "OnboardingNotificationsScreen" },
    PaymentDetail: { title: "Payment", routeName: "PaymentDetail" },
    PaymentPrefsDetail: { title: "Payment preferences", routeName: "PaymentPrefsDetail" },
    Profile: { title: "Profile", routeName: "Profile" },
    ProfileStack: { title: "", routeName: "ProfileStack" },
    ReferralDetail: { title: i18n.t("referral.screenTitle"), routeName: "ReferralDetail" },
    Root: { title: "GridRewards", routeName: "Root" },
    University: { title: i18n.t("universityTitle"), routeName: "University" },
    UniversityCourse: { title: "", routeName: "UniversityCourse" },
    ValueProposition: { title: "About GridRewards", routeName: "ValueProposition" },
    ValuePropositionDetail: { title: "More about GridRewards", routeName: "ValuePropositionDetail" },
    WelcomeScreen: { title: i18n.t("welcome"), routeName: "WelcomeScreen" },
    HomeTab: { title: i18n.t("homeTabTitle"), routeName: "HomeTab" },
    EventsTab: { title: i18n.t("eventsTabTitle"), routeName: "EventsTab" },
    EnergyTab: { title: i18n.t("energyTabTitle"), routeName: "EnergyTab" },
    CarbonTab: { title: i18n.t("carbonTabTitle"), routeName: "CarbonTab" },
}

export const PropertyType = {
    singleFamily: "singleFamily",
    apartment: "apartment",
    smallCommercial: "smallCommercial",
}

export const GridRewardsEventType = {
    future: "future",
    active: "active",
    pendingData: "pendingData",
    complete: "complete",
}

export const CarbonProducingFuelNames = ["natural gas", "dual fuel", "other fossil fuels"]
export const CleanEnergyFuelNames = ["wind", "hydro", "nuclear", "other renewables"]

export const FuelTypeDisplayNames = {
    "natural gas": i18n.t("fuelTypeDisplayNaturalGas"),
    "dual fuel": i18n.t("fuelTypeDisplayDualFuel"),
    "other fossil fuels": i18n.t("fuelTypeDisplayOtherFossil"),
    wind: i18n.t("fuelTypeDisplayWind"),
    hydro: i18n.t("fuelTypeDisplayHydro"),
    nuclear: i18n.t("fuelTypeDisplayNuclear"),
    "other renewables": i18n.t("fuelTypeDisplayOtherRenewables"),
}

export const InsightTypes = {
    projectedBill: "projectedBill",
    usageVsProjected: "usageVsProjected",
    usageVsYearAgo: "usageVsYearAgo",
    usageVsSimilar: "usageVsSimilar",
    carbonVsProjected: "carbonVsProjected",
    carbonVsYearAgo: "carbonVsYearAgo",
    carbonVsSimilar: "carbonVsSimilar",
    highUsage: "highUsage",
    neighborhoodRankOverall: "neighborhoodRankOverall",
    neighborhoodRankPerCapita: "neighborhoodRankPerCapita",
}

export const ThermostatModesGet = {
    cool: "Cool",
    heat: "Heat",
    both: "HeatCool",
    off: "Off",
}

export const ThermostatModesSet = {
    cool: "cool",
    heat: "heat",
    both: "heatCool",
    off: "off",
}

export const CarbonStatus = {
    good: "good",
    okay: "okay",
    bad: "bad",
    veryBad: "very bad",
}

export const CarbonThresholdManual = {
    good: 250,
    okay: 280,
    bad: 320,
    veryBad: Number.MAX_SAFE_INTEGER,
}

export const EventLikelihoodThresholdManual = {
    likely: 50,
    veryLikely: 100,
}

export const DataSource = {
    electricity: "Electricity",
    gas: "Gas",
}

// Ordered list
export const DataSources = [DataSource.electricity, DataSource.gas]

export const DataMeasurement = {
    use: "Use",
    money: "$",
    carbon: "CO₂",
    weather: "Weather",
}
// Ordered list
// export const DataMeasurements = [DataMeasurement.use, DataMeasurement.money, DataMeasurement.carbon]

export const DataTimeframe = {
    hour: i18n.t("dataTimeframeHour"),
    day: i18n.t("dataTimeframeDay"),
    billingCycle: i18n.t("billingCycle"),
}
// Ordered list
export const DataTimeframes = [DataTimeframe.hour, DataTimeframe.day, DataTimeframe.billingCycle]

export const PaymentOption = {
    // digital: { type: "digital", displayName: i18n.t("paymentPrefShortInstant") },
    physical: { type: "physical", displayName: i18n.t("paymentPrefShortMail") },
    donate: { type: "donate", displayName: i18n.t("paymentPrefShortDonate") },
}
// Ordered list
export const PaymentOptions = [PaymentOption.physical, PaymentOption.donate]

export const ThemeOption = {
    light: { type: "light", displayName: i18n.t("theme.light") },
    dark: { type: "dark", displayName: i18n.t("theme.dark") },
    system: { type: "system", displayName: i18n.t("theme.system") },
}
// Ordered list
export const ThemeOptions = [ThemeOption.light, ThemeOption.dark, ThemeOption.system]

export const EnergySavingActions = {
    before: [
        {
            type: "preCool",
            icon: "fan",
        },
    ],
    during: [
        {
            type: "raiseAC",
            icon: "thermostatArrows",
        },
        {
            type: "appliances",
            icon: "washingMachine",
        },
        {
            type: "fridge",
            icon: "refrigerator",
        },
        {
            type: "lights",
            icon: "lightbulbOff",
        },
        {
            type: "unplug",
            icon: "plugOrange",
        },
        {
            type: "ev",
            icon: "ev",
        },
    ],
}

export const Achievements = [
    {
        title: i18n.t("gettingStarted"),
        data: [
            {
                type: "connectedAccount",
                icon: "plug",
            },
            {
                type: "referralSent",
                icon: "referral",
            },
            {
                type: "graphViewed",
                icon: "graph",
            },
        ],
    },
    {
        title: i18n.t("performance"),
        data: [
            {
                type: "minimumGoal",
                icon: "starPartial",
            },
            {
                type: "betterGoal",
                icon: "star",
            },
            {
                type: "goalReached",
                icon: "oneHundred",
            },
            {
                type: "eventCount10",
                icon: "count10",
            },
            {
                type: "eventCount25",
                icon: "count25",
            },
            {
                type: "eventCount50",
                icon: "count50",
            },
        ],
    },
    {
        title: i18n.t("community"),
        data: [
            {
                type: "countMeIn",
                icon: "checkmark",
            },
            {
                type: "neighborJoined",
                icon: "building",
            },
            {
                type: "topPerformer",
                icon: "crown",
            },
            {
                type: "topNeighborhood",
                icon: "badge",
            },
            {
                type: "topNeighborhoodPerCapita",
                icon: "badge",
            },
        ],
    },
]

export const CarbonComparison = [
    {
        before: i18n.t("carbonComparison1Before"),
        after: i18n.t("carbonComparison1After"),
        multiplier: 0.012,
        link: "https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#seedlings",
        icon: "handSeedling48",
    },

    {
        before: i18n.t("carbonComparison2Before"),
        after: i18n.t("carbonComparison2After"),
        multiplier: 1.8,
        link: "https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#miles",
        icon: "car",
    },
    {
        before: i18n.t("carbonComparison3Before"),
        after: i18n.t("carbonComparison3After"),
        multiplier: 90.2,
        link: "https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#smartphones",
        icon: "phoneCharger",
    },
    {
        before: i18n.t("carbonComparison4Before"),
        after: i18n.t("carbonComparison4After"),
        multiplier: 0.0002,
        link: "https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#recycle",
        icon: "recycling",
    },
    {
        before: "",
        after: i18n.t("carbonComparison5After"),
        multiplier: 0.0009,
        link: "https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#pineforests",
        icon: "trees",
    },
    {
        before: i18n.t("carbonComparison6Before"),
        after: i18n.t("carbonComparison6After"),
        multiplier: 0.0001,
        link: "https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#houseenergy",
        icon: "house",
    },
    {
        before: i18n.t("carbonComparison3Before"),
        after: i18n.t("carbonComparison7After"),
        multiplier: 0.029,
        link: "https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#propane",
        icon: "flameFilled",
    },
    {
        before: i18n.t("carbonComparison8Before"),
        after: i18n.t("carbonComparison8After"),
        multiplier: 0.0002,
        link: "https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#vehicles",
        icon: "cars",
    },
]

export const HomeScreenModals = {
    GridRewardsSetup: "GridRewardsSetup",
    AMISetup: "AMISetup",
    SurveySetup: "SurveySetup",
    ThermostatSetup: "ThermostatSetup",
    ValueProposition: "ValueProposition",
}

export const DonationOrganizations = [
    {
        categoryName: i18n.t("categoryClimateAction"),
        location: i18n.t("newYorkAndNational"),
        title: i18n.t("mothersOutFrontTitle"),
        description: i18n.t("mothersOutFrontDescription"),
        linkName: i18n.t("mothersOutFrontLinkName"),
        url: "https://mothersoutfront.org",
        logo: require("../assets/images/thirdparty/mothersOutFront.png"),
        // RN/Web can't get image dimensions from a local file, so we have to pass the aspect ratio manually.
        logoAspect: 1.712,
        // logoBackgroundColor: "#07295D",
    },
    {
        categoryName: i18n.t("categoryEnvironmentalJustice"),
        location: i18n.t("newYorkAndDC"),
        title: i18n.t("weActTitle"),
        description: i18n.t("weActDescription"),
        linkName: i18n.t("weActLinkName"),
        url: "https://weact.org",
        logo: require("../assets/images/thirdparty/weAct.png"),
        // RN/Web can't get image dimensions from a local file, so we have to pass the aspect ratio manually.
        logoAspect: 1.648,
        // logoBackgroundColor: "#D8E8DF",
    },
    {
        categoryName: i18n.t("categoryLandConservancy"),
        location: i18n.t("newYorkCity"),
        title: i18n.t("naturalAreasConservancyTitle"),
        description: i18n.t("naturalAreasConservancyDescription"),
        linkName: i18n.t("naturalAreasConservancyLinkName"),
        url: "https://naturalareasnyc.org",
        logo: require("../assets/images/thirdparty/naturalAreasConservancy.png"),
        // RN/Web can't get image dimensions from a local file, so we have to pass the aspect ratio manually.
        logoAspect: 1.256,
        // logoBackgroundColor: "#1A3843",
    },
    {
        categoryName: i18n.t("categoryFoodSecurity"),
        location: i18n.t("newYorkCity"),
        title: i18n.t("cityHarvestTitle"),
        description: i18n.t("cityHarvestDescription"),
        linkName: i18n.t("cityHarvestLinkName"),
        url: "https://cityharvest.org",
        logo: require("../assets/images/thirdparty/cityHarvest.png"),
        // RN/Web can't get image dimensions from a local file, so we have to pass the aspect ratio manually.
        logoAspect: 1.632,
        // logoBackgroundColor: "#E3EADE",
    },
    {
        categoryName: i18n.t("categorySustainability"),
        location: i18n.t("westchesterCounty"),
        title: i18n.t("sustainableWestchesterTitle"),
        description: i18n.t("sustainableWestchesterDescription"),
        linkName: i18n.t("sustainableWestchesterLinkName"),
        url: "https://sustainablewestchester.org",
        logo: require("../assets/images/thirdparty/sustainableWestchester.png"),
        // RN/Web can't get image dimensions from a local file, so we have to pass the aspect ratio manually.
        logoAspect: 1.252,
        // logoBackgroundColor: "#E3EADE",
    },
]

export const CustomMapStyle = [
    {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
            {
                saturation: 36,
            },
            {
                color: "#000000",
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "on",
            },
            {
                color: "#000000",
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: "all",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
            {
                saturation: "-100",
            },
            {
                lightness: "30",
            },
        ],
    },
    {
        featureType: "administrative.neighborhood",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
            {
                gamma: "0.00",
            },
            {
                lightness: "74",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: "landscape.man_made",
        elementType: "all",
        stylers: [
            {
                lightness: "3",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 17,
            },
        ],
    },
]

export default {
    apiBase: `https://data-api.logicalbuildings.com`, //`https://animal.logicalbuildings.com`,
    expoPushNotificationExperienceId: "@logicalbuildings/LogicalBuildings",
    // temporaryUserToken: "eyJhbGciOiJIUzI1NiJ9.eyJkYXQiOnsiX3VzZXJJZCI6Imp0aG9tcHNvbkBldHNlbWVyYWxkLmNvbSJ9fQ.yx_Oz63W2KpcctWmQEOp3_J3aTCva_fcNQq65JUEUjs",

    currentUserKey: "currentUser",
    currentFirebaseToken: "currentFirebaseToken",
    currentUtilityAccount: "currentUtilityAccount",
    currentZipCode: "currentZipCode",

    paymentAddressLastConfirmDate: "paymentAddressLastConfirmDate",

    // mailingAddressLocal: "mailingAddressLocal",

    // Time zones
    currentPropertyTimeZone: "currentPropertyTimeZone",
    suppressTimeZoneDisplay: "suppressTimeZoneDisplay",

    // Did the user come in via a link that prepopulates the referral code?
    pendingIncomingReferralCode: "pendingIncomingReferralCode",

    // New account creation requires some temporary data to be stored.
    profileFirstName: "profileFirstName",
    profileLastName: "profileLastName",
    profileIncomingReferralCode: "profileIncomingReferralCode",
    profileUpdateComplete: "profileUpdateComplete",
    profilePaymentPreference: "profilePaymentPreference",

    // How many times has the app been brought to the foreground?
    foregroundCount: "foregroundCount",
    foregroundCountAfterGridRewardsAuth: "foregroundCountAfterGridRewardsAuth",

    // Onboarding completion checks
    onboardingComplete: "onboardingComplete",
    nextStepsHidden: "nextStepsHidden",
    suppressEducationSplashCard: "suppressEducationSplashCard",
    energyGraphFirstViewComplete: "energyGraphFirstViewComplete",
    expoPushToken: "expoPushToken",

    utilityAccountNicknames: "utilityAccountNicknames",

    // We need slightly different behavior during the first fetch because creating the user takes a non-zero amount of time in the DB.
    firstRunDataFetchCompleted: "firstRunDataFetchCompleted",

    // Overall system prompts
    valuePropositionPromptComplete: "valuePropositionPromptComplete",
    ratingsPromptComplete: "ratingsPromptComplete",

    // Enrollment completion
    enrollmentComplete: "enrollmentComplete",

    // Next steps completion
    gridRewardsNextStepComplete: "gridRewardsNextStepComplete",
    valuePropositionNextStepComplete: "valuePropositionNextStepComplete",
    amiNextStepComplete: "amiNextStepComplete",
    yearRoundNextStepComplete: "yearRoundNextStepComplete",
    surveyNextStepComplete: "surveyNextStepComplete",
    thermostatNextStepComplete: "thermostatNextStepComplete",
    showDataProcessingPrompt: "showDataProcessingPrompt",

    // Notifications
    notificationLastDateSeen: "notificationLastDateSeen",

    // University
    universityCoursesFurthestSeen: "universityCoursesFurthestSeen",

    // Neighborhood messages
    videoPendingUploadUri: "videoPendingUploadUri",

    // Possible places a push notification can take us
    pushNotificationTargetScreens: ["Home", "CarbonDetail", "Actions", "GridRewards", "Profile", "History"],

    logicalBuildingsSupportPhone: "9085173730",
    logicalBuildingsSupportEmail: "gridrewards@logicalbuildings.com",

    // Accounts that the user should never be able to delete.
    undeleteableAccounts: ["logicalanimal@logicalbuildings.com", "gridrewards-staging@logicalbuildings.com", "demo@logicalbuildings.com"],

    urlScheme: "gridrewards://",
    urls: {
        mainWebsite: "https://gridrewards.com",
        faq: "https://admin.logicalbuildings.com/gridrewards/faq",
        referralLink: "http://tinyurl.com/gridrewards",
        referralTOS: "https://admin.logicalbuildings.com/gridrewards/referraltos",
        disconnectAccount: "https://www.coned.com/en/accounts-billing/dashboard/billing-and-usage/share-my-data-connections?tab1=sectionActiveConnections",
    },

    firebaseConfig: {
        apiKey: "AIzaSyAOFqCwO3u4-SjZGwcNkrtfzx5cQf9on-I",
        authDomain: "ets-staging-2.firebaseapp.com",
        databaseURL: "https://ets-staging-2.firebaseio.com",
        projectId: "ets-staging-2",
        storageBucket: "ets-staging-2.appspot.com",
        messagingSenderId: "561672253137",
        appId: "1:561672253137:web:778b12708132d0d9775ff0",
    },

    firebaseWebApiKey: "AIzaSyBKrLGeLI-3ldp9E8sO_yV9nhk7jD7DC5I",
    postGridApiKey: "live_sk_uag4kVaHs9pBxjyXFobpXX",

    dynamicLinks: {
        iosBundleId: "com.logicalbuildings.gridrewards.store",
        androidPackageName: "com.logicalbuildings.gridrewards.store",
        appStoreID: "1523704980",
    },

    analytics: {
        adjustToken: "cqccsrhnj3ls",
        logicalBuildingsAccountLogin: "ryn88e",
        logicalBuildingsAccountCreate: "r1in4x",
        amiSetupComplete: "2v3ad1",
        gridRewardsSetupComplete: "98t8r3",
        attAuthorized: "77zhcl",
        attDenied: "y7ob4n",
        attNotDetermined: "flfxrc",
        attRestricted: "rfqlzb",
        branchEvents: {
            userLogin: "User logged in",
            userCreatedAccount: "User created account",
            amiAuthorized: "User authorized AMI",
            enrollmentComplete: "User completed all enrollment steps",
            referralSent: "User sent referral",
        },
    },

    prefKeys: {
        userThemeChoice: "userThemeChoice",
    },
}
