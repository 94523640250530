import { useNavigation } from "@react-navigation/native"
import * as Analytics from "expo-firebase-analytics"
import * as WebBrowser from "expo-web-browser"
import React, { useContext, useEffect } from "react"
import { Platform, ScrollView, Share, StyleSheet, Text, useWindowDimensions, View, TouchableOpacity } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useDispatch, useSelector, useStore } from "react-redux"
import Button from "../components/Button"
import LessonCard from "../components/cards/LessonCard"
import { fetchProfileData } from "../model/primaryDataActions"
import AppConstants, { ScreenNames } from "../shared/AppConstants"
import Colors from "../shared/Colors"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { copyToClipboard, showToast } from "../shared/Utils"
import ReferralShareCard from "../components/cards/ReferralShareCard"

if (Platform.OS !== "web") {
    Adjust = require("react-native-adjust").Adjust
    AdjustConfig = require("react-native-adjust").AdjustConfig
    AdjustEvent = require("react-native-adjust").AdjustEvent
}

export default function ReferralDetailScreen(props) {
    const navigation = useNavigation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProfileData())
    }, [])

    const { theme } = useContext(ThemeContext)

    // return (
    //     <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: theme.background }}>
    //         <Text>Placeholder while restyling the referral screen</Text>
    //     </View>
    // )

    return (
        <ScrollView style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]} contentContainerStyle={CommonStyles.mainScreenContent}>
            <ReferralShareCard />
            <LessonCard
                title={i18n.t("lesson.moreTogether.title")}
                description={i18n.t("lesson.moreTogether.description")}
                onPress={() => {
                    navigation.navigate(ScreenNames.University.routeName)
                }}
            />
            <TouchableOpacity
                onPress={() => {
                    WebBrowser.openBrowserAsync(AppConstants.urls.referralTOS)
                }}
                style={{ alignItems: "center", minHeight: 36 }}
            >
                <Text style={[TextStyles.body3, { color: theme.textHint, flexShrink: 1 }]}>{i18n.t("seeTerms")}</Text>
            </TouchableOpacity>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    inputFieldContainer: {
        height: 50,
    },

    inputField: {
        flex: 1,
        backgroundColor: Colors.white,
        borderRadius: 6,
        fontSize: 18,
        paddingLeft: 15,
        paddingRight: 15,
        justifyContent: "center",
        borderColor: "transparent",
    },

    loginButtonContainer: {
        height: 50,
        marginTop: 24,
        marginBottom: 16,
    },

    loginHelpButton: {
        paddingHorizontal: 24,
        paddingVertical: 16,
        justifyContent: "center",
        alignItems: "center",
    },

    loginErrorContainer: {
        width: "100%",
        height: 70,
        backgroundColor: Colors.white,
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 8,
    },

    loginErrorText: {
        textAlign: "center",
        color: Colors.red,
    },

    loginErrorButton: {
        justifyContent: "center",
        alignItems: "center",
    },
})
