import { StyleSheet } from "react-native"

export const TextStyles = StyleSheet.create({
    h1: {
        fontFamily: "inter-medium",
        fontSize: 32,
    },
    h1kobe: {
        fontFamily: "kobe-bold",
        fontSize: 32,
        lineHeight: 30,
        letterSpacing: -0.6,
    },
    h2: {
        fontFamily: "inter-medium",
        fontSize: 24,
        lineHeight: 30,
    },
    h2kobe: {
        fontFamily: "kobe-bold",
        fontSize: 24,
        letterSpacing: -0.6,
    },
    h3: {
        fontFamily: "inter-medium",
        fontSize: 20,
        lineHeight: 25,
    },
    h4: {
        fontFamily: "inter-medium",
        fontSize: 18,
        lineHeight: 22,
    },
    h5: {
        fontFamily: "inter-semi",
        fontSize: 16,
        lineHeight: 20,
    },
    headerExtraLargeKobe: {
        fontFamily: "kobe-black",
        fontSize: 48,
        lineHeight: 48,
        letterSpacing: -0.6,
    },
    bodyGiant: {
        fontFamily: "kobe-bold",
        fontSize: 96,
        letterSpacing: -2.6,
    },
    bodyExtraLarge: {
        fontFamily: "inter-medium",
        fontSize: 38,
    },
    bodyLarge: {
        fontFamily: "inter-regular",
        fontSize: 20,
        lineHeight: 25,
    },
    bodyLargeSemi: {
        fontFamily: "inter-semi",
        fontSize: 20,
        lineHeight: 25,
    },
    body1: {
        fontFamily: "inter-regular",
        fontSize: 18,
    },
    body2: {
        fontFamily: "inter-regular",
        fontSize: 16,
        lineHeight: 21,
    },
    body2Medium: {
        fontFamily: "inter-medium",
        fontSize: 16,
        lineHeight: 20,
    },
    body2Semi: {
        fontFamily: "inter-semi",
        fontSize: 16,
    },
    body3: {
        fontFamily: "inter-regular",
        fontSize: 15,
        lineHeight: 20,
    },
    body3Medium: {
        fontFamily: "inter-medium",
        fontSize: 15,
        lineHeight: 20,
    },
    body4: {
        fontFamily: "inter-medium",
        fontSize: 12,
        lineHeight: 16,
    },
    buttonText: {
        fontFamily: "inter-medium",
        fontSize: 16,
        lineHeight: 20,
    },
    caption: {
        fontFamily: "inter-medium",
        fontSize: 14,
        lineHeight: 18,
    },
    capsSmall: {
        fontFamily: "inter-medium",
        fontSize: 14,
        letterSpacing: 1,
        textTransform: "uppercase",
    },
    capsExtraSmall: {
        fontFamily: "inter-medium",
        fontSize: 12,
        letterSpacing: 0.8,
        textTransform: "uppercase",
    },
})
