import AsyncStorage from "@react-native-async-storage/async-storage"
import * as Calendar from "expo-calendar"
import * as Clipboard from "expo-clipboard"
import * as Linking from "expo-linking"
import { getLocalizationAsync } from "expo-localization"
import * as Network from "expo-network"
import moment from "moment-timezone"
import { path, prop } from "ramda"
import { Alert, Platform } from "react-native"
import Toast from "react-native-root-toast"
import * as Sentry from "sentry-expo"
import CustomToast from "../components/CustomToast.js"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import AppConstants, { Achievements, DataMeasurement, DataSource, ThermostatModesGet, ThermostatModesSet } from "./AppConstants"
import { maxContentWidth } from "./Layout.js"
import { rawThemes } from "./ThemeContext.js"
// import "intl-pluralrules"

const axios = require("axios").default

// Global timeout for all of our calls.
const timeoutLength = 30 * 1000

if (Platform.OS === "android") {
    // only android needs polyfill
    require("intl") // import intl object
    require("intl/locale-data/jsonp/en-US") // load the required locale details
}

export function radiansToDegrees(radians) {
    var pi = Math.PI
    return radians * (180 / pi)
}

export function copyToClipboard(copiedString) {
    Clipboard.setString(copiedString)
}

export function titleCase(originalString) {
    if (originalString == null || originalString.length == 0) {
        return null
    }
    let words = originalString
        .toLowerCase()
        .split(" ")
        .map((currentValue) => currentValue[0].toUpperCase() + currentValue.substring(1))
    return words.join(" ")
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function sortDataByKey(data, key) {
    data.sort(function (a, b) {
        return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
    })
    return data
}

export function isEmpty(object) {
    return object == null || Object.keys(object).length == 0
}

// Data fetch-related
export function fetchEndpointNoRedux(url, beginFetchCallback, successCallback, errorCallback, responseDataPath = ["data"], showDebug = false) {
    if (typeof beginFetchCallback === "function") {
        beginFetchCallback()
    }

    let currentUser = auth.currentUser
    if (currentUser != null) {
        // First, get the user's token
        currentUser
            .getIdToken()
            .then((token) => {
                axios
                    .get(url, {
                        params: {},
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        timeout: timeoutLength,
                    })
                    .then(function (response) {
                        // If we actually get notifications data, return it!!
                        if (showDebug) {
                            console.log("Got data from " + url + "\n" + JSON.stringify(path(responseDataPath, response)))
                        }
                        if (typeof successCallback === "function") {
                            successCallback(responseDataPath != null ? path(responseDataPath, response) : response)
                        }
                    })
                    .catch(function (error) {
                        // If we failed to get notifications data, throw an error
                        console.log("Failed to get data from " + url + ": " + JSON.stringify(error.message))
                        if (Platform.OS == "web") {
                            Sentry.Browser.captureException(error)
                        } else {
                            Sentry.Native.captureException(error)
                        }
                        if (typeof errorCallback === "function") {
                            errorCallback(error)
                        }
                    })
            })
            .catch(function (error) {
                console.log("getIdToken failed. Error was: " + error)
                if (Platform.OS == "web") {
                    Sentry.Browser.captureException("getIdToken failed: " + error)
                } else {
                    Sentry.Native.captureException("getIdToken failed: " + error)
                }
            })
    } else {
        console.log("No user, ignoring non-redux fetch request. Request was " + url)
        if (Platform.OS == "web") {
            Sentry.Browser.captureException("User invalid, logging out")
        } else {
            Sentry.Native.captureException("User invalid, logging out")
        }
    }
}

// GridRewards event-related
export function nearestUpcomingEvent(eventsData) {
    let furthestAcceptableStartTime = moment().add(4, "days")
    let nearestGridRewardsEvent = eventsData.find((possibleEvent) => {
        let startTime = prop("startTime", possibleEvent)
        let endTime = prop("endTime", possibleEvent)
        let now = moment()
        return startTime != null && moment(startTime).isSameOrBefore(furthestAcceptableStartTime) && moment(endTime).isAfter(now)
    })
    return nearestGridRewardsEvent
}

export function eventsHappeningNow(eventsData) {
    if (eventsData == null) {
        return []
    }
    return (
        eventsData
            .filter((possibleEvent) => {
                let startTime = moment(prop("startTime", possibleEvent))
                let endTime = moment(prop("endTime", possibleEvent))
                let now = moment()
                return startTime.isSameOrBefore(now) && endTime.isAfter(now)
            })
            .sort((a, b) => (a.startTime >= b.startTime ? 1 : -1)) || []
    )
}

export function upcomingEvents(eventsData) {
    if (eventsData == null) {
        return []
    }
    return (
        eventsData
            .filter((possibleEvent) => {
                let startTime = moment(prop("startTime", possibleEvent))
                let endTime = moment(prop("endTime", possibleEvent))
                let now = moment()
                return startTime.isAfter(now) && endTime.isAfter(now)
            })
            .sort((a, b) => (a.startTime >= b.startTime ? 1 : -1)) || []
    )
}

export function pastEvents(eventsData) {
    if (eventsData == null) {
        return []
    }
    return (
        eventsData
            .filter((possibleEvent) => {
                let startTime = moment(prop("startTime", possibleEvent))
                let endTime = moment(prop("endTime", possibleEvent))
                let now = moment()
                return startTime.isBefore(now) && endTime.isBefore(now)
            })
            .sort((a, b) => (a.startTime <= b.startTime ? 1 : -1)) || []
    )
}

export function mostRecentEvent(eventsData, preferFinalizedEvents = false) {
    let pastEventsData = pastEvents(eventsData)
    if (pastEventsData == null || pastEventsData.length == 0) {
        return null
    }
    if (preferFinalizedEvents) {
        return pastEventsData.find((possibleItem) => prop("finalCalculation", possibleItem) == true) || pastEventsData[0]
    } else {
        return pastEventsData[0]
    }
}

export function pastEventStreakLength(eventsData) {
    if (eventsData == null) {
        return 0
    }

    return pastEvents(eventsData).reduce((previousValue, currentItem) => {
        const isFinal = prop("finalCalculation", currentItem) == true
        const performanceIsOkay = prop("performanceFactor", currentItem) >= 0.25

        // if (isFinal && performanceIsOkay) console.log("Streak: performance is ok for finalized event " + prop("eventId", currentItem))

        return isFinal && performanceIsOkay ? previousValue + 1 : previousValue
    }, 0)
}

// There are times when we need to pull out the ids for all events still "in play. For example,
// in order to get event participants, we need to feed it a list of event IDs.
export function currentAndFutureEventIds(eventsData) {
    return eventsData
        .filter((possibleEvent) => {
            let eventId = prop("eventId", possibleEvent)
            let endTime = moment(prop("endTime", possibleEvent))
            let now = moment()
            return eventId != null && endTime.isSameOrAfter(now)
        })
        .map((item) => prop("eventId", item))
}

// Unlike the other raw data functions, this expects a moment object.
function singleEventTimeFormatted(eventMoment) {
    if (eventMoment == null) {
        return null
    }

    let hourFormat = eventMoment.minutes() > 0 ? "h:mma" : "ha"

    let calendarOptions = {
        lastDay: "[" + i18n.t("yesterday") + "] " + hourFormat,
        lastWeek: "dddd " + hourFormat,
        sameDay: hourFormat,
        nextDay: "[" + i18n.t("tomorrow") + "] " + hourFormat,
        nextWeek: "ddd " + hourFormat,
        sameElse: "ddd MMM D, " + hourFormat,
    }
    return eventMoment.calendar(null, calendarOptions)
}

export function eventTimeFormatted(event) {
    const now = moment()
    if (event == null) {
        return null
    } else {
        // Throw away past events.
        let startTime = moment(prop("startTime", event))
        let endTime = moment(prop("endTime", event))
        if (startTime.isBefore(now) && endTime.isBefore(now)) {
            return null
        }
    }

    let startTime = moment(prop("startTime", event))
    let endTime = moment(prop("endTime", event))
    let startTimeFormatted = singleEventTimeFormatted(startTime)
    let endTimeFormatted = startTime.isSame(endTime, "day") ? endTime.format(endTime.minutes() > 0 ? "h:mma" : "ha") : singleEventTimeFormatted(endTime)
    return startTime.isBefore(now)
        ? i18n.t("events.current.timeFormat", { endTime: endTimeFormatted })
        : i18n.t("events.future.timeFormat", { startTime: startTimeFormatted, endTime: endTimeFormatted })
}

export function eventStartTimeFormatted(startString) {
    if (startString == null) {
        return null
    }

    let startMoment = moment(startString)
    let startTimeFormatted = singleEventTimeFormatted(startMoment)
    const now = moment()
    return startMoment.isBefore(now) ? startTimeFormatted : i18n.t("timeAt", { time: startTimeFormatted })
}

export function timeRangeFormatted(startString, endString) {
    if (startString == null && endString == null) {
        return null
    }

    let startTime = moment(startString)
    let endTime = endString != null && endString != "" ? moment(endString) : null
    let startTimeFormatted = singleEventTimeFormatted(startTime)
    let endTimeFormatted =
        endTime != null ? (startTime.isSame(endTime, "day") ? endTime.format(endTime.minutes() > 0 ? "h:mma" : "ha") : singleEventTimeFormatted(endTime)) : null
    return i18n.t("timeRangeFormatGeneral", { startTime: startTimeFormatted, endTime: endTimeFormatted })
}

export function pastEventTimeFormatted(event, compactDisplay = false) {
    const { timestamp, startTime, endTime } = event || {}

    if (startTime == null && endTime == null) {
        return null
    }

    let formatWithAmPm = compactDisplay ? "MMM D, ha" : "MMMM Do ha"
    let formatWithoutAmPm = compactDisplay ? "MMM D, h" : "MMMM Do h"

    let dateString = null
    if (timestamp) {
        let date = moment(timestamp)
        let format = "dddd"
        dateString = date.format(format)
    } else if (startTime != null && endTime != null) {
        let start = moment(startTime)
        let end = moment(endTime)
        if (start.isSame(end, "day")) {
            // In this case, only show the day once.
            dateString = start.format(formatWithoutAmPm) + "–" + end.format("ha")
        } else {
            // Show both days
            dateString = start.format(formatWithAmPm) + " – " + end.format(formatWithAmPm)
        }
    }

    return dateString
}

export function upcomingEventSummaryTimes(eventsData) {
    if (eventsData == null) {
        return []
    }

    return (
        eventsData
            .sort((a, b) => (a.startTime <= b.startTime ? 1 : -1))
            .map((event) => {
                return eventTimeFormatted(event)
            }) || []
    )
}

// Network status
export async function checkNetwork() {
    let networkState = await Network.getNetworkStateAsync()
    let reachable = prop("isInternetReachable", networkState) || false
    if (!reachable) {
        Alert.alert(i18n.t("networkUnavailableTitle"), i18n.t("networkUnavailableBody"), [{ text: i18n.t("ok"), onPress: () => console.log("OK Pressed") }], {
            cancelable: false,
        })
    }
    return reachable
}

// Formatting
const moneyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
const moneyFormatterNoDecimal = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })

export function formatWithUnits(value, source, measurement) {
    // let formattedValue = (value ?? 0).toFixed(measurement == DataMeasurement.money ? 2 : source == DataSource.gas ? 1 : 0)
    let valueAdjusted = value ?? 0
    let kiloUnits = false
    // If we're displaying this in kg instead of g, divide our source number to match.
    if (valueAdjusted >= 1000 && measurement == DataMeasurement.carbon) {
        valueAdjusted /= 1000
        kiloUnits = true
    }
    var numberFormatterConfig = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }
    if (valueAdjusted != 0) {
        if (valueAdjusted > -0.1 && valueAdjusted < 0.1) {
            numberFormatterConfig = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        } else if (valueAdjusted > -10 && valueAdjusted < 10) {
            numberFormatterConfig = {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            }
        }
    }
    const numberFormatter = new Intl.NumberFormat("en-US", numberFormatterConfig)
    switch (source) {
        case DataSource.gas:
            switch (measurement) {
                case DataMeasurement.use:
                    return numberFormatter.format(valueAdjusted) + " " + "therms"
                case DataMeasurement.money:
                    return valueAdjusted % 1 == 0 ? moneyFormatterNoDecimal.format(valueAdjusted) : moneyFormatter.format(valueAdjusted)
                case DataMeasurement.carbon:
                    return i18n.t("amountOfCO2", { amount: numberFormatter.format(valueAdjusted) + " " + (kiloUnits ? "kg" : "g") })
                case DataMeasurement.weather:
                    return Math.round(value) + "º"
                default:
                    return valueAdjusted.toFixed(1)
            }

        case DataSource.electricity:
            switch (measurement) {
                case DataMeasurement.use:
                    return numberFormatter.format(valueAdjusted) + " " + "kWh"
                case DataMeasurement.money:
                    return valueAdjusted % 1 == 0 ? moneyFormatterNoDecimal.format(valueAdjusted) : moneyFormatter.format(valueAdjusted)
                case DataMeasurement.carbon:
                    return i18n.t("amountOfCO2", { amount: numberFormatter.format(valueAdjusted) + " " + (kiloUnits ? "kg" : "g") })
                case DataMeasurement.weather:
                    return Math.round(value) + "º"
                default:
                    return valueAdjusted.toFixed(1)
            }

        default:
            return ""
    }
}

export function setDefaultTimeZone(newTimeZone, store) {
    console.log("Setting default timezone to " + newTimeZone)
    // To reset to local, we need to call setDefault with no arguments.
    if (newTimeZone == null) {
        AsyncStorage.removeItem(AppConstants.currentPropertyTimeZone)
        moment.tz.setDefault()
    } else {
        AsyncStorage.setItem(AppConstants.currentPropertyTimeZone, newTimeZone)
        moment.tz.setDefault(newTimeZone)
    }

    // Reload this into Redux
    const loadAsyncStorage = require("../model/primaryDataActions.js").loadAsyncStorage
    loadAsyncStorage(store)
}

// Data processing & account setup
export function isDataProcessing(mainData, initialFetchOnly = false) {
    // console.log("Data status is "+JSON.stringify(prop("dataStatus", mainData)))
    const { ami = {} } = prop("nextSteps", mainData) || {}

    // Show the flag if:
    // 1) we have data
    // 2) The user has completed the AMI step
    // 3) either the initialIntervalPull or fullIntervalPull values are false.
    let initialIntervalPull = path(["dataStatus", "initialIntervalPull"], mainData)
    let fullIntervalPull = initialFetchOnly || path(["dataStatus", "fullIntervalPull"], mainData) == true
    return mainData != null && ami.status == true && (initialIntervalPull != true || fullIntervalPull != true)
}

export function isCaptain(userId, neighborhoodData) {
    // console.log("Checking userId " + userId + " against " + JSON.stringify(neighborhoodData))
    return userId != null && prop("captain", neighborhoodData) == userId
}

export function openSetup(link, spinnerCallback = () => {}) {
    if (link != null) {
        let url = `${AppConstants.apiBase}${link}`
        const data = {}
        spinnerCallback(true)

        let currentUser = auth.currentUser
        if (currentUser != null) {
            currentUser.getIdToken().then((token) => {
                axios
                    .post(url, data, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                    })
                    .then(function (response) {
                        spinnerCallback(false)

                        let redirectLink = response.data
                        if (redirectLink != null) {
                            // console.log("Got a valid next steps redirect link!")
                            // We've got a valid link, use it.
                            try {
                                // Don't try to embed this as a modal, because Android seems to close it when leaving the app.
                                Linking.openURL(`${redirectLink}`)
                            } catch (error) {
                                Alert.alert(
                                    "Couldn't open web browser",
                                    "Couldn't open browser: " + error,
                                    [{ text: "OK", onPress: () => console.log("OK Pressed") }],
                                    { cancelable: false }
                                )
                            }
                        } else {
                            console.log("Next steps redirect link was empty, but no error in the request!")
                            Alert.alert(
                                "Connection error",
                                "Next steps redirect link was empty, but no error in the request!",
                                [{ text: "OK", onPress: () => console.log("OK Pressed") }],
                                { cancelable: false }
                            )
                        }
                    })
                    .catch(function (error) {
                        spinnerCallback(false)
                        // If we failed to get primary data, throw an error
                        console.log("Failed to get next steps redirect link: " + error)
                        Alert.alert(
                            "Connection error",
                            "Failed to get next steps redirect link: " + error,
                            [{ text: "OK", onPress: () => console.log("OK Pressed") }],
                            { cancelable: false }
                        )
                    })
            })
        } else {
            console.log("No user, skipping request.")
        }
    } else {
        console.log("No link passed to openSetup, skipping request")
    }
}

// Carbon intensity related

export function electricityQualityForValue(value = 0, thresholds = []) {
    if (thresholds.length < 3) {
        return ""
    }

    if (value < thresholds[0]) {
        return i18n.t("good")
    } else if (value < thresholds[1]) {
        return i18n.t("okay")
    } else if (value < thresholds[2]) {
        return i18n.t("bad")
    } else {
        return i18n.t("veryBad")
    }
}

export function gridCarbonLevelForValue(value = 0, thresholds = []) {
    if (thresholds.length < 3) {
        return ""
    }

    if (value < thresholds[0]) {
        return i18n.t("low")
    } else if (value < thresholds[1]) {
        return i18n.t("moderate")
    } else if (value < thresholds[2]) {
        return i18n.t("high")
    } else {
        return i18n.t("veryHigh")
    }
}

// Notification related
export async function hasUnreadNotifications(notificationsData) {
    if (notificationsData == null || notificationsData.length == 0) {
        return false
    }

    let incomingMostRecentDate = prop("created_at", notificationsData[0])
    if (incomingMostRecentDate == null || incomingMostRecentDate.length == 0) {
        // If for some reason we don't have a date to compare to, don't mark this as unread.
        return false
    }

    let latestDate = await AsyncStorage.getItem(AppConstants.notificationLastDateSeen)
    if (latestDate == null) {
        return true
    }
    return incomingMostRecentDate > latestDate
}

// History related

export function eventPerformanceDescriptionForValue(value = 0) {
    if (value < 0.25) {
        return i18n.t("gridRewardsEventPerformanceDescriptionBad")
    } else if (value < 0.5) {
        return i18n.t("gridRewardsEventPerformanceDescriptionOkay")
    } else if (value < 1) {
        return i18n.t("gridRewardsEventPerformanceDescriptionGood")
    } else {
        return i18n.t("gridRewardsEventPerformanceDescriptionGreat")
    }
}

// Achievement-related

// Find the full achievement config object matching this type.
export function fullAchievementForType(type) {
    if (type == null || type.length == 0) {
        return null
    }

    let flatResults = Achievements.flatMap((section) => prop("data", section))
    return flatResults.find((possibleItem) => prop("type", possibleItem) == type)
}

// Thermostat-related
export function thermostatModeDisplayName(mode) {
    switch (mode) {
        case ThermostatModesGet.cool:
        case ThermostatModesSet.cool:
            return i18n.t("thermostat.mode.cool")
        case ThermostatModesGet.heat:
        case ThermostatModesSet.heat:
            return i18n.t("thermostat.mode.heat")
        case ThermostatModesGet.both:
        case ThermostatModesSet.both:
            return i18n.t("thermostat.mode.both")
        case ThermostatModesGet.off:
        case ThermostatModesSet.off:
            return i18n.t("thermostat.mode.off")
        default:
            break
    }
}

// Calendar-related
export async function addEventToCalendar(eventData) {
    if (eventData == null) {
        console.log("Null event, skipping request to add to calendar")
        return
    }

    const { status } = await Calendar.requestCalendarPermissionsAsync()
    if (status === "granted") {
        // First, figure out which calendar to use.
        let activeCalendar = null
        if (Platform.OS == "ios") {
            activeCalendar = await Calendar.getDefaultCalendarAsync()
        } else if (Platform.OS == "android") {
            const calendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT)
            const defaultCalendars = calendars.filter((each) => each.source.name === "Default")
            activeCalendar = defaultCalendars[0]
        }

        if (activeCalendar == null) {
            console.log("Couldn't find a valid calendar, ignoring request to add an event")
            showToast(i18n.t("calendarEventErrorTitle"), rawThemes.light.icons.warning)
            return
        }

        timeZone = await getLocalizationAsync().timezone
        let eventId = await Calendar.createEventAsync(activeCalendar.id, {
            title: "⚡️ " + i18n.t("calendarEventTitle"),
            startDate: Date.parse(eventData.startTime),
            endDate: Date.parse(eventData.endTime),
            notes: i18n.t("valuePropEventsBody") + "\n\n" + AppConstants.urls.referralLink,
            url: AppConstants.urls.referralLink,
            timeZone: timeZone,
        })

        console.log("Created an event with id " + eventId)
        showToast(i18n.t("calendarEventConfirmation"), rawThemes.light.icons.checkmark32)

        return eventId
    } else {
        return null
    }
}

// License-related
export function extractNameFromGithubUrl(url) {
    if (!url) {
        return null
    }

    const reg = /((https?:\/\/)?(www\.)?github\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?/i
    const components = reg.exec(url)

    if (components && components.length > 5) {
        return components[5]
    }
    return null
}

// Toast-related
var currentToast = null
export function showToast(text, iconSource, duration = Toast.durations.LONG) {
    if (text == null || text.length == 0) {
        // Don't show anything.
        return
    }

    // If there's an existing toast, dismiss it before showing a new one.
    if (currentToast != null) {
        Toast.hide(currentToast)
    }

    currentToast = Toast.show(<CustomToast text={text} imageSource={iconSource} />, {
        containerStyle: { marginHorizontal: 40, marginBottom: 34, padding: 0, maxWidth: maxContentWidth },
        duration: duration,
        position: Toast.positions.BOTTOM,
        shadow: false,
        animation: true,
        hideOnPress: true,
        delay: 0,
        opacity: 1.0,
        backgroundColor: "transparent",
        onShow: () => {
            // calls on toast\`s appear animation start
        },
        onShown: () => {
            // calls on toast\`s appear animation end.
        },
        onHide: () => {
            // calls on toast\`s hide animation start.
        },
        onHidden: () => {
            // calls on toast\`s hide animation end.
            currentToast = null
        },
    })
}

// Address-related
export function formattedAddress(addressJSON) {
    if (addressJSON == null || isEmpty(addressJSON)) {
        console.log("Returning a string from the null case of formattedAddress")
        return i18n.t("mailingAddress.noData")
    }
    let result = ""
    if (prop("line1", addressJSON) != null) {
        result += prop("line1", addressJSON) + "\n"
    }
    if (prop("line2", addressJSON) != null) {
        result += prop("line2", addressJSON) + "\n"
    }
    if (prop("city", addressJSON) != null) {
        result += prop("city", addressJSON)
    }
    if (prop("provinceOrState", addressJSON) != null) {
        result += ", " + prop("provinceOrState", addressJSON)
    }
    if (prop("postalOrZip", addressJSON) != null) {
        result += " " + prop("postalOrZip", addressJSON)
    }
    return result
}

// Analytics
export async function sendBranchEvent(eventName, branchUniversalObject, customData) {
    if (Platform.OS != "web") {
        const branch = require("react-native-branch").default
        const BranchEvent = require("react-native-branch").BranchEvent

        // Need to wrap our custom data in a params object.
        const params = { customData: customData }

        const event = new BranchEvent(eventName, branchUniversalObject, params)
        try {
            await event.logEvent()
            console.log("Branch completed the native event")
        } catch (error) {
            console.log("Error sending Branch native event: " + error)
        }
    } else {
        branch.logEvent(AppConstants.analytics.branchEvents.userLogin, customData, (error) => {
            console.log("Error sending Branch web event: " + error)
        })
    }
}
