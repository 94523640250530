import { useNavigation } from "@react-navigation/native"
import moment from "moment-timezone"
import { path } from "ramda"
import * as React from "react"
import { Text, View, Image, Platform } from "react-native"
import { ScrollView } from "react-native-gesture-handler"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ProgressCircle } from "react-native-svg-charts"
import Button from "../components/Button"
import { ScreenNames } from "../shared/AppConstants"
import { colorForPerformanceFactor } from "../shared/Colors"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import { eventPerformanceDescriptionForValue } from "../shared/Utils"

export default function GridRewardsEventDetailScreen(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    const { gridRewardsEvent = {} } = path(["route", "params"], props) || {}
    const { timestamp, startTime, endTime, performanceFactor = 0, finalCalculation = false } = gridRewardsEvent
    const insets = useSafeAreaInsets()

    const performanceFactorClamped = Math.min(1.0, Math.max(0.0, performanceFactor))
    const performancePercent = (performanceFactorClamped * 100).toFixed(0)
    const iconSize = 326 // The actual icon includes extra space for the on-state's glow
    let iconLayoutSize = 278

    const rotationStart = (-3.1 * Math.PI) / 4
    const rotationEnd = (3.1 * Math.PI) / 4
    // Clamp this performance value to a reasonable minimum and maximum for displaying the ring around the outside of the content.
    const performanceFactorDial = Math.max(0.025, performanceFactorClamped)

    let dateString = ""
    if (timestamp) {
        let date = moment(timestamp)
        let format = "dddd"
        dateString = date.format(format)
    } else if (startTime != null && endTime != null) {
        let start = moment(startTime)
        let end = moment(endTime)
        if (start.isSame(end, "day")) {
            // In this case, only show the day once.
            dateString = start.format("MMMM Do, h") + " – " + end.format("ha")
        } else {
            // Show both days
            dateString = start.format("MMMM Do, ha") + " – " + end.format("MMMM, Do ha")
        }
    }

    let progressColor = colorForPerformanceFactor(performanceFactorClamped)
    let textColor = theme.blue
    let goalReached = false
    if (finalCalculation != true) {
        textColor = theme.textHint
    } else if (performanceFactorClamped < 0.25) {
        textColor = theme.red
    } else if (performanceFactorClamped < 0.5) {
        textColor = theme.orange
    } else if (performanceFactorClamped >= 1.0) {
        goalReached = true
    }

    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
            contentContainerStyle={[CommonStyles.mainScreenContent, { paddingHorizontal: 24 }]}
        >
            <View style={{ justifyContent: "center" }}>
                {goalReached ? (
                    <View style={{}}>
                        <View
                            style={{
                                width: iconLayoutSize,
                                height: iconLayoutSize,
                                marginTop: 40,
                                marginBottom: 40,
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "visible",
                                alignSelf: "center",
                            }}
                        >
                            <Image
                                source={rawThemes.dark.icons.achievements.large.oneHundred}
                                style={{
                                    width: iconSize,
                                    height: iconSize,
                                }}
                            />
                        </View>
                        <Button
                            type={"outline"}
                            title={i18n.t("seeYourAchievements")}
                            arrow={true}
                            buttonTintColor={theme.border}
                            onPress={() => {
                                // Dismiss this modal, then go to the acheivements screen.
                                navigation.goBack()
                                navigation.navigate(ScreenNames.HomeTab.routeName, { screen: ScreenNames.Achievements.routeName })
                            }}
                        />
                    </View>
                ) : (
                    <View style={{ justifyContent: "center", alignItems: "center" }}>
                        <ProgressCircle
                            style={{ height: 278, width: 278 }}
                            startAngle={rotationStart}
                            endAngle={rotationEnd}
                            strokeWidth={16}
                            backgroundColor={theme.surface1}
                            progress={performanceFactorDial}
                            progressColor={progressColor}
                        />

                        {/* Android ignores "baseline" here and treats it as "flex-end", so we'll center vertically on Android. */}
                        <View
                            style={{ position: "absolute", flexDirection: "row", alignItems: Platform.OS == "android" ? "center" : "baseline", maxWidth: 240 }}
                        >
                            <Text style={[TextStyles.bodyGiant, { color: textColor, marginLeft: 20, marginRight: 2 }]}>{performancePercent}</Text>
                            <Text style={[TextStyles.bodyExtraLarge, { color: textColor }]}>%</Text>
                        </View>
                    </View>
                )}
            </View>
            <Text style={[TextStyles.h3, { color: theme.textPrimary, marginTop: goalReached ? 40 : 32, marginBottom: 8 }]}>{dateString}</Text>

            <Text style={[TextStyles.body1, { marginBottom: 24, color: theme.textSecondary }]}>
                {eventPerformanceDescriptionForValue(performanceFactorClamped)}
            </Text>
            <Text style={[TextStyles.caption, { color: theme.textHint, marginBottom: 40 }]}>{i18n.t("eventPerformanceDisclaimer")}</Text>
        </ScrollView>
    )
}
