import * as Linking from "expo-linking"
import { ScreenNames } from "../shared/AppConstants"

export default {
    prefixes: ["gridrewards://"],
    config: {
        screens: {
            Root: {
                screens: {
                    HomeTab: {
                        initialRouteName: ScreenNames.Home.routeName,
                        screens: {
                            Home: ScreenNames.Home.routeName,
                            DonationsDetail: ScreenNames.DonationsDetail.routeName,
                            PaymentDetail: ScreenNames.PaymentDetail.routeName,
                            PaymentPrefsDetail: ScreenNames.PaymentPrefsDetail.routeName,
                        },
                    },
                    EventsTab: {
                        initialRouteName: ScreenNames.EventsScreen.routeName,
                        screens: {
                            EventsScreen: ScreenNames.EventsScreen.routeName,
                            GridRewardsEventDetail: ScreenNames.GridRewardsEventDetail.routeName + "/:gridRewardsEvent", // NOTE: This isn't used currently, since we need to pass a complex object as a param. Just using internal navigation routing.
                            Neighborhood: ScreenNames.Neighborhood.routeName,
                        },
                    },
                    EnergyTab: {
                        initialRouteName: ScreenNames.EnergyScreen.routeName,
                        screens: {
                            EnergyScreen: ScreenNames.EnergyScreen.routeName,
                        },
                    },
                    CarbonTab: {
                        initialRouteName: ScreenNames.CarbonScreen.routeName,
                        screens: {
                            CarbonScreen: ScreenNames.CarbonScreen.routeName,
                        },
                    },
                },
            },
            EnrollmentComplete: ScreenNames.EnrollmentComplete.routeName,
            ProfileStack: {
                initialRouteName: ScreenNames.Profile.routeName,
                screens: {
                    Profile: ScreenNames.Profile.routeName,
                    EditProfile: ScreenNames.EditProfile.routeName,
                },
            },
            UniversityScreen: ScreenNames.University.routeName,
            Locations: ScreenNames.Locations.routeName,
            ReferralDetail: ScreenNames.ReferralDetail.routeName,
        },
    },
}
