import { useNavigation } from "@react-navigation/native"
import * as WebBrowser from "expo-web-browser"
import * as React from "react"
import { Image, Text, View, TouchableOpacity, Platform } from "react-native"
import { activeOpacity } from "../../shared/Layout"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
// import "intl-pluralrules"

if (Platform.OS === "android") {
    // only android needs polyfill
    require("intl") // import intl object
    require("intl/locale-data/jsonp/en-US") // load the required locale details
}

export default function CarbonComparisonCard(props) {
    const navigation = useNavigation()
    const { value, comparison } = props
    const { theme } = React.useContext(ThemeContext)

    if (value == null || comparison == null) {
        return null
    }

    const multiplierFormatter = new Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 3,
    })

    let processedValue = value * comparison.multiplier
    return (
        <TouchableOpacity
            style={{
                flexDirection: "row",
                width: 272,
                minHeight: 90,
                alignItems: "center",
                borderRadius: 12,
                overflow: "hidden",
                marginRight: 8,
                backgroundColor: theme.surface1,
                paddingHorizontal: 16,
                paddingVertical: 20,
            }}
            activeOpacity={activeOpacity}
            onPress={() => {
                WebBrowser.openBrowserAsync(comparison.link)
            }}
        >
            <Image source={theme.icons[comparison.icon]} style={{ marginRight: 8, tintColor: theme.textPrimary, width: 48, height: 48 }} />
            <Text style={[TextStyles.body3, { color: theme.textPrimary, flexShrink: 1 }]}>
                {comparison.before + " " + multiplierFormatter.format(processedValue) + " " + comparison.after}
            </Text>
        </TouchableOpacity>
    )
}
