import { useNavigation } from "@react-navigation/native"
import { path } from "ramda"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Text, View, Animated, Touchable, Dimensions, TouchableOpacity, TouchableHighlight, Platform, Image } from "react-native"
// import Swiper from "react-native-swiper"
import CloseButton from "../components/CloseButton"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import i18n from "../shared/i18n"
import ProgressBar from "../components/ProgressBar"
import AsyncStorage from "@react-native-async-storage/async-storage"
import AppConstants from "../shared/AppConstants"
import UniversityLesson from "../components/UniversityLesson"
import { prop } from "ramda"
import { State, TapGestureHandler } from "react-native-gesture-handler"

// Conditional import for swiper.
const Swiper = Platform.OS === "web" ? require("react-native-swiper/src").default : require("react-native-swiper").default

export default function UniversityCourseScreen(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)
    const { course = {} } = path(["route", "params"], props) || {}

    const [lessonCount, setLessonCount] = useState(0)
    const [furthestCompleted, setFurthestCompleted] = useState(null)
    const [progress, setProgress] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const swiper = useRef()
    // Figure out how tall our actual header is after it's drawn (used to ignore part of the screen for the tap handler.)
    const [actualHeaderHeight, setActualHeaderHeight] = useState(0)
    // How big do we want our tap-sensitive areas to be?
    const tapAreaWidth = 48

    useEffect(async () => {
        let courseId = prop("id", course)
        if (course != null && courseId != null) {
            // Update our lesson count
            setLessonCount(Math.max(1, (prop("lessons", course) || []).length))

            // Get the furthest completed item in this course.
            const furthestJSON = await AsyncStorage.getItem(AppConstants.universityCoursesFurthestSeen)
            let furthestCourses = furthestJSON != null ? JSON.parse(furthestJSON) : {}
            if (furthestCourses[courseId] != null) {
                // If we've stored a furthest state for this course, use it.
                setFurthestCompleted(furthestCourses[courseId])
            }
            if (progress == 0) {
                console.log("setting initial progress to " + 1 / Math.max(1, (prop("lessons", course) || []).length))
                setProgress(100 / Math.max(1, (prop("lessons", course) || []).length))
            }
        }
    }, [course])

    const viewWidth = Dimensions.get("window").width

    return (
        <TapGestureHandler
            numberOfTaps={1}
            onHandlerStateChange={({ nativeEvent }) => {
                if (nativeEvent.state === State.ACTIVE) {
                    const { x, y } = nativeEvent
                    if (y > actualHeaderHeight) {
                        if (x <= tapAreaWidth) {
                            swiper?.current?.scrollBy(-1, true)
                        } else if (x >= viewWidth - tapAreaWidth) {
                            swiper?.current?.scrollBy(1, true)
                        }
                    }
                }
            }}
        >
            <View style={{ flex: 1, backgroundColor: theme.background }}>
                {/* Header */}
                <View
                    onLayout={(event) => {
                        const { height } = event.nativeEvent.layout
                        setActualHeaderHeight(height)
                    }}
                >
                    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 24, marginBottom: 14 }}>
                        <CloseButton />
                        <Text
                            style={[
                                TextStyles.capsSmall,
                                { left: 80, right: 80, position: "absolute", justifyContent: "center", textAlign: "center", color: theme.textSecondary },
                            ]}
                        >
                            {i18n.t("university.courseTitle", { index: currentPage, total: lessonCount })}
                        </Text>
                        {currentPage == lessonCount && (
                            <TouchableOpacity style={{ paddingRight: 20 }} onPress={() => navigation.goBack()}>
                                <Text style={[TextStyles.buttonText, { color: theme.buttonText }]}>{i18n.t("done")}</Text>
                            </TouchableOpacity>
                        )}
                    </View>
                    {/* Progress bar */}
                    <ProgressBar height={4} progress={progress} />
                </View>
                {/* Swipeable content */}
                <View style={{ flex: 1 }}>
                    <Swiper
                        ref={swiper}
                        loop={false}
                        showsPagination={false}
                        bounces={true}
                        loadMinimal={true}
                        scrollEventThrottle={16}
                        showsButtons={Platform.OS == "web"}
                        buttonWrapperStyle={{ paddingHorizontal: 20 }}
                        prevButton={
                            <View
                                style={{
                                    width: 48,
                                    height: 48,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: theme.surface1,
                                    borderRadius: 1000,
                                }}
                            >
                                <Image source={theme.icons.arrowLeft24} style={{ width: 24, height: 24 }} />
                            </View>
                        }
                        nextButton={
                            <View
                                style={{
                                    width: 48,
                                    height: 48,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: theme.surface1,
                                    borderRadius: 1000,
                                }}
                            >
                                <Image source={theme.icons.arrowRight24} style={{ width: 24, height: 24 }} />
                            </View>
                        }
                        onScroll={(e) => {
                            if (course != null && course != {}) {
                                const scrolledPercentage = (viewWidth + e.nativeEvent.contentOffset.x) / (viewWidth * lessonCount)
                                setProgress(scrolledPercentage * 100)
                            }
                        }}
                        onIndexChanged={(index) => {
                            setCurrentPage(index + 1)
                        }}
                    >
                        {course.lessons.map((lesson) => {
                            return <UniversityLesson key={prop("id", lesson)} lesson={lesson} />
                        })}
                    </Swiper>
                    {/* Tap areas */}
                    {/* <TouchableHighlight
                    onPress={() => {
                        swiper?.current?.scrollBy(1, true)
                    }}
                    underlayColor={theme.surface1}
                    style={{ position: "absolute", top: 0, right: 0, width: 48, height: "100%", opacity: 0.35 }}
                >
                    <View />
                </TouchableHighlight>
                <TouchableHighlight
                    onPress={() => {
                        swiper?.current?.scrollBy(-1, true)
                    }}
                    underlayColor={theme.surface1}
                    style={{ position: "absolute", top: 0, left: 0, width: 48, height: "100%", opacity: 0.35 }}
                >
                    <View />
                </TouchableHighlight> */}
                </View>
            </View>
        </TapGestureHandler>
        // <ScrollView style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]} contentContainerStyle={CommonStyles.mainScreenContent}>
        //     <Text>{"TEST, course title is " + prop("title", course)}</Text>
        // </ScrollView>
    )
}
