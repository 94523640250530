import { prop } from "ramda"
import AppConstants from "../shared/AppConstants"
import { auth } from "../shared/firebase"
const axios = require("axios").default

// This file contains API calls that don't touch Redux.

// Global timeout for all of our calls.
const timeoutLength = 30 * 1000

export function verifyAddress(line1, line2, city, provinceOrState, postalOrZip, country, successCallback, errorCallback) {
    axios
        .post(
            "https://api.postgrid.com/v1/addver/verifications?properCase=true",
            {
                address: {
                    line1: line1,
                    line2: line2,
                    city: city,
                    provinceOrState: provinceOrState,
                    postalOrZip: postalOrZip,
                    country: country,
                },
            },
            {
                headers: {
                    "x-api-key": AppConstants.postGridApiKey,
                },
            }
        )
        .then((res) => {
            const { data } = res.data

            // All the missing information is automatically appended.
            console.log(data.status)

            console.log(data.line1)
            console.log(data.line2)
            console.log(data.city)
            console.log(data.provinceOrState)
            console.log(data.postalOrZip)
            console.log(data.country)

            typeof successCallback == "function" && successCallback(data)
        })
        .catch(function (error) {
            console.log("Error while validating address: " + error)
            typeof errorCallback == "function" && errorCallback(error)
        })
}

export function getMuxUploadInfo(successCallback, errorCallback) {
    let url = `${AppConstants.apiBase}/mux/upload`

    console.log("About to try getting Mux upload info from " + url)

    let currentUser = auth.currentUser
    if (currentUser != null) {
        // First, get the user's token
        currentUser
            .getIdToken()
            .then((token) => {
                console.log("Got token, ready to post mux upload info request, token is " + JSON.stringify(token))
                axios
                    .post(
                        url,
                        {},
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                            timeout: timeoutLength,
                        }
                    )
                    .then(function (response) {
                        console.log("Got Mux upload info from " + url + "\n" + JSON.stringify(prop("data", response)))
                        typeof successCallback == "function" && successCallback(prop("data", response))
                    })
                    .catch(function (error) {
                        // If we failed to get notifications data, throw an error
                        console.log("Failed to get data from " + url + ": " + JSON.stringify(error.message) + ", request was " + JSON.stringify(error.request))
                        typeof errorCallback == "function" && errorCallback(error.message)
                    })
            })
            .catch(function (error) {
                typeof errorCallback == "function" && errorCallback(error.message)
            })
    } else {
        console.log("getMuxUploadInfo couldn't find current user")
        typeof errorCallback == "function" && errorCallback(new Error("No user, can't get Mux upload URL. Request was " + url))
    }
}

export async function uploadVideoToMux(videoUri, uploadUrl, progressCallback, successCallback, errorCallback) {
    if (videoUri == null || uploadUrl == null) {
        console.log(`Asked to upload a video to Mux, but video uri was ${videoUri} and upload url was ${uploadUrl}, skipping...`)
        typeof errorCallback == "function" &&
            errorCallback(new Error(`Asked to upload a video to Mux, but video uri was ${videoUri} and upload url was ${uploadUrl}, skipping...`))
    }

    // Get a blob from this video uri.
    const videoResponse = await fetch(videoUri)
    const blob = await videoResponse.blob()

    console.log("Would be uploading, blob type is " + blob.type)

    try {
        let response = await fetch(uploadUrl, {
            method: "PUT",
            body: blob,
            headers: { "content-type": blob.type },
        })
        console.log("Upload is complete, response was " + JSON.stringify(response))
        typeof successCallback == "function" && successCallback()
    } catch (error) {
        console.error(error)
        typeof errorCallback == "function" && errorCallback(error)
    }
}
