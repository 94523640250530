import { useNavigation } from "@react-navigation/native"
import * as shape from "d3-shape"
import * as Haptics from "expo-haptics"
import moment from "moment-timezone"
import { path, prop } from "ramda"
import React, { useContext, useEffect, useRef, useState } from "react"
import { ActivityIndicator, AppState, Dimensions, Image, Platform, Text, View, Animated } from "react-native"
import { LongPressGestureHandler, State, TouchableOpacity } from "react-native-gesture-handler"
// import Animated from "react-native-reanimated"
import { LineChart } from "react-native-svg-charts"
import { useDispatch, useSelector } from "react-redux"
import { fetchHistory, fetchWeather } from "../../model/primaryDataActions"
import { DataMeasurement, DataSource, DataTimeframe } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { maxContentWidth } from "../../shared/Layout"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import { formatWithUnits, isEmpty } from "../../shared/Utils"
import CardView from "./CardView"
import { auth } from "../../shared/firebase.js"

function LegendRow(props) {
    const { value, projectionValue, source, measurement, tintColor, containerStyle, showProjection = false } = props
    const { theme } = useContext(ThemeContext)

    // Nothing to render
    if (measurement == null || (value == null && projectionValue == null)) {
        return null
    }

    const formattedValue = formatWithUnits(value, source, measurement)
    const formattedProjectionValue = showProjection ? formatWithUnits(projectionValue, source, measurement) : null

    return (
        <View style={[{ flex: 1, flexDirection: "row", alignItems: "center" }, containerStyle]}>
            <View style={{ width: 12, height: 12, borderRadius: 100, backgroundColor: tintColor, marginRight: 8 }} />
            <Text style={[TextStyles.caption, { color: theme.textSecondary, marginRight: 8 }]}>{formattedValue}</Text>
            {showProjection && (
                <Text style={[TextStyles.caption, { color: theme.textHint }]}>
                    {formattedProjectionValue.length > 0 ? "(" + formattedProjectionValue + " " + i18n.t("estimated") + ")" : ""}
                </Text>
            )}
        </View>
    )
}

const TimeFrame = {
    day: { title: i18n.t("graph.label.day"), buttonTitle: i18n.t("graph.span.day"), daysOfDataFetched: 1, pressedFormat: "dddd, MMM D ha" },
    week: { title: i18n.t("graph.label.week"), buttonTitle: i18n.t("graph.span.week"), daysOfDataFetched: 7, pressedFormat: "dddd, MMM D ha" },
    month: { title: i18n.t("graph.label.month"), buttonTitle: i18n.t("graph.span.month"), daysOfDataFetched: 31, pressedFormat: "dddd, MMM Do" },
    year: {
        title: i18n.t("graph.label.year"),
        buttonTitle: i18n.t("graph.span.year"),
        daysOfDataFetched: 365,
        pressedFormat: "MMMM YYYY",
        pressedFormatCombined: "MMM YYYY",
    },
    multiYear: {
        title: i18n.t("graph.label.multiYear"),
        buttonTitle: i18n.t("graph.span.multiYear"),
        daysOfDataFetched: 365 * 3,
        pressedFormat: "MMMM YYYY",
        pressedFormatCombined: "MMM YYYY",
    },
}

export default function HistoryCard(props) {
    const { source = DataSource.electricity } = props
    const navigation = useNavigation()
    const { theme } = useContext(ThemeContext)
    const dispatch = useDispatch()
    const appState = useRef(AppState.currentState)

    const isElectricity = source == DataSource.electricity

    const historyDataRaw = useSelector((state) => (isElectricity ? state.primaryData.electricityHistoryData : state.primaryData.gasHistoryData)) || []
    const [historyData, setHistoryData] = useState([])
    // const [mostRecentDataPoint, setMostRecentDataPoint] = useState(null)
    const [aggregatedStats, setAggregatedStats] = useState({})
    const weatherData = useSelector((state) => state.primaryData.weatherData) || []

    // Start in week mode.
    const [timeMode, setTimeMode] = useState(TimeFrame.week)
    const [timeModeSwitchPending, setTimeModeSwitchPending] = useState(true)

    const graphHeight = 244
    const graphTopSpacing = 16
    const needleViewWidth = 44

    // This is dynamic, so we store the rendered width here.
    const [graphWidth, setGraphWidth] = useState(0)

    // Position of the user's touch
    const tapX = useRef(new Animated.Value(0)).current

    // This is only used on web to work around react-gesture-handler not delivering animated events correctly.
    const [leftEdgeX, setLeftEdgeX] = useState(Math.max(0, (Dimensions.get("window").width - maxContentWidth) / 2.0))
    const [tapXWeb, setTapXWeb] = useState(0)

    // Which history slice is selected?
    const [selectionActive, setSelectionActive] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedItemWidth, setSelectedItemWidth] = useState(0)

    const longPressHandlerRef = useRef()

    let lastDataFetch = {}
    // console.log("History data is " + JSON.stringify(historyData))

    const measurementColors = {
        [DataMeasurement.money]: theme.green,
        [DataMeasurement.carbon]: theme.pink,
        [DataMeasurement.use]: theme.blue,
        [DataMeasurement.weather]: theme.orange,
    }

    function refreshData() {
        // If timeMode isn't set or we're not logged in yet, we can't do anything.
        if (timeMode == null || auth.currentUser == null) {
            return
        }

        // Throttle this so that we only pull every so often for each time mode.
        if (lastDataFetch[timeMode] == null || lastDataFetch[timeMode].isBefore(moment().subtract(1, "minutes"))) {
            // console.log("Calling refreshData from history card")
            let endDate = moment()
            endDate.subtract(1, "days")
            let startDate = endDate.clone()

            let daysOfDataFetched = prop("daysOfDataFetched", timeMode)
            // console.log("Days visible? " + daysVisible)
            var granularity = DataTimeframe.day
            if (daysOfDataFetched <= 7) {
                granularity = DataTimeframe.hour
            } else if (daysOfDataFetched > 31) {
                granularity = DataTimeframe.billingCycle
            }
            startDate.subtract(daysOfDataFetched, "days")

            console.log("Graph for " + source + " is fetching data")
            dispatch(fetchHistory(startDate, endDate, source, granularity))
            dispatch(fetchWeather(startDate, endDate, granularity))

            lastDataFetch[timeMode] = moment()
        } else {
            console.log("History fetch was too recent, skipping.")
        }
    }

    // Initial setup
    useEffect(() => {
        const unsubscribe = navigation.getParent().addListener("focus", () => {
            // Any time we're focused, try to pull new data
            refreshData()
        })

        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user != null) {
                console.log("Trying to refresh graph data because auth changed")
                refreshData()
            }
        })

        return () => {
            unsubscribe()
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        // Any time we change modes, re-fetch data.
        console.log("About to refresh history card data because timeMode changed")
        refreshData()
    }, [timeMode])

    useEffect(() => {
        // // Any time we get new raw history data, prepare it for use.
        let tempArray = prop("data", historyDataRaw)

        if (isEmpty(tempArray)) {
            return
        }

        // // Sort this oldest to newest.
        tempArray.sort((a, b) => {
            const aTime = prop("timestamp", a) || prop("start", a)
            const bTime = prop("timestamp", b) || prop("start", b)
            return aTime > bTime
        })
        console.log("Sorting history data")

        // // Get the newest data point with a non-null value
        // setMostRecentDataPoint(
        //     tempArray
        //         .filter((item) => {
        //             const usage = prop("usage", item)
        //             return usage != null
        //         })
        //         .slice(-1)[0]
        // )
        setHistoryData(tempArray)

        // Once we've got new data, mark our time switch pending flag to false
        setTimeModeSwitchPending(false)
    }, [historyDataRaw])

    useEffect(() => {
        // console.log("Inside historyData's useEffect, historyData is " + JSON.stringify(historyData))
        // Any time we get new history data, update our aggregated stats.
        setAggregatedStats({
            date: "",
            startDate: moment(prop("start", historyDataRaw)),
            endDate: moment(prop("end", historyDataRaw)),
            cost: historyData.map((item) => prop("cost", item)).reduce((a, b) => a + b, 0),
            usage: historyData.map((item) => prop("usage", item)).reduce((a, b) => a + b, 0),
            carbon: historyData.map((item) => prop("carbon", item)).reduce((a, b) => a + b, 0),
        })
    }, [historyData])

    useEffect(() => {
        if (Platform.OS == "web") {
            // Whenever we change this value, we need to figure out what our selected history item is.
            // Do this relatively cheaply, since accuracy of selection isn't that crucial, and we're going to run this a lot.
            const selectedIndex = Math.min(historyData.length - 1, Math.floor(tapXWeb / selectedItemWidth))
            setSelectedItem(historyData[selectedIndex])
        }
    }, [tapXWeb])

    // useEffect(() => {
    //     console.log("Most recent data point is " + JSON.stringify(mostRecentDataPoint))
    // }, [mostRecentDataPoint])

    useEffect(() => {
        setSelectedItemWidth(graphWidth / historyData.length)
        // console.log("Setting selected item width to " + graphWidth / historyData.length + ", graph width is " + graphWidth)
    }, [graphWidth, historyData])

    const xAccessor = (index) => {
        const fieldName = prop("level", historyDataRaw) == "bp" ? "end" : "timestamp"
        let xMoment = moment(path(["item", fieldName], index))
        // console.log("Returning " + xMoment.toISOString() + " as x, fieldName is " + fieldName)
        return xMoment.valueOf()
    }

    const useSelectedItem = selectionActive && selectedItem != null
    var timeDisplay = timeMode.title
    var weatherDisplayString = null
    if (useSelectedItem) {
        // Figure out which weather slice (if any) to select
        if ([TimeFrame.day, TimeFrame.week, TimeFrame.month].includes(timeMode)) {
            const historyDate = moment(prop("timestamp", selectedItem))
            const matchingWeatherItem = weatherData.find((possibleItem) => {
                const possibleItemDate = moment(prop("timestamp", possibleItem))
                return possibleItemDate.isSameOrAfter(historyDate, "hour")
            })
            if (timeMode == TimeFrame.month) {
                const highTemp = prop("high", matchingWeatherItem)
                const lowTemp = prop("low", matchingWeatherItem)
                if (highTemp != null || lowTemp != null) {
                    // In this case, we've got a high and low temp for the day
                    weatherDisplayString =
                        (highTemp != null ? formatWithUnits(highTemp, DataSource.electricity, DataMeasurement.weather) : "--") +
                        " / " +
                        (lowTemp != null ? formatWithUnits(lowTemp, DataSource.electricity, DataMeasurement.weather) : "--") +
                        "F"
                }
            } else {
                const temperature = prop("temperature", matchingWeatherItem)
                if (temperature != null) {
                    // In this case, we've got a single temp for the hour.
                    weatherDisplayString = formatWithUnits(temperature, DataSource.electricity, DataMeasurement.weather) + "F"
                } else weatherDisplayString = null
            }
        }

        // How do we want to display time?
        if ([TimeFrame.year, TimeFrame.multiYear].includes(timeMode)) {
            let startMoment = moment(prop("start", selectedItem))
            let endMoment = moment(prop("end", selectedItem))
            if (startMoment.isSame(endMoment, "month")) {
                timeDisplay = startMoment.format(timeMode.pressedFormat)
            } else {
                timeDisplay = startMoment.format(timeMode.pressedFormatCombined) + " – " + endMoment.format(timeMode.pressedFormatCombined)
            }
        } else {
            timeDisplay = moment(prop("timestamp", selectedItem)).format(timeMode.pressedFormat)
        }
    }

    if (historyDataRaw == null || historyDataRaw.length == 0) {
        console.log("No history data, rendering nothing for " + source + " history graph")
        return null
    }

    return (
        <CardView includesBorder={false} includesPadding={false} containerStyle={{ marginHorizontal: 0 }} {...props}>
            <View style={{ paddingHorizontal: 20 }}>
                <View style={{ flexDirection: "row", marginBottom: 12, alignItems: "center" }}>
                    <Image
                        source={isElectricity ? theme.icons.chartElectricity : theme.icons.chartGas}
                        style={[{ width: 48, height: 48, marginRight: 12, opacity: 0.7 }]}
                    />
                    <View style={{ flexShrink: 1 }}>
                        <Text style={[TextStyles.h2, { flexShrink: 1, color: theme.textPrimary }]}>
                            {i18n.t(isElectricity ? "dataSourceNameElectricity" : "dataSourceNameGas")}
                        </Text>
                        <Text style={[TextStyles.body2, { flexShrink: 1, color: theme.textSecondary }]}>{timeDisplay}</Text>
                    </View>
                </View>
                <View style={{ borderRadius: 8, backgroundColor: selectionActive ? theme.surface1 : "transparent", padding: 8 }}>
                    <LegendRow
                        value={useSelectedItem ? prop("cost", selectedItem) : aggregatedStats.cost}
                        projectionValue={prop("smartEstimateCost", selectedItem)}
                        source={source}
                        measurement={DataMeasurement.money}
                        tintColor={measurementColors[DataMeasurement.money]}
                        containerStyle={{ marginBottom: 6 }}
                        showProjection={selectionActive}
                    />
                    <LegendRow
                        value={useSelectedItem ? prop("carbon", selectedItem) : aggregatedStats.carbon}
                        projectionValue={prop("smartEstimateCarbon", selectedItem)}
                        source={source}
                        measurement={DataMeasurement.carbon}
                        tintColor={measurementColors[DataMeasurement.carbon]}
                        containerStyle={{ marginBottom: 6 }}
                        showProjection={selectionActive}
                    />
                    <LegendRow
                        value={useSelectedItem ? prop("usage", selectedItem) : aggregatedStats.usage}
                        projectionValue={prop("smartEstimateUsage", selectedItem)}
                        source={source}
                        measurement={DataMeasurement.use}
                        tintColor={measurementColors[DataMeasurement.use]}
                        showProjection={selectionActive}
                    />
                </View>
            </View>
            <View style={{ height: 24, flexDirection: "row", justifyContent: "flex-end", marginTop: 4, marginHorizontal: 20 }}>
                {selectionActive && weatherDisplayString != null && (
                    <View
                        style={{
                            borderRadius: 1000,
                            borderColor: theme.border,
                            borderWidth: 1,
                            paddingHorizontal: 8,
                            paddingTop: 2,
                            paddingBottom: 6,
                        }}
                    >
                        <Text style={[TextStyles.caption, { color: theme.textSecondary }]}>{weatherDisplayString}</Text>
                    </View>
                )}
            </View>

            <LongPressGestureHandler
                ref={longPressHandlerRef}
                minDurationMs={100}
                maxDist={10000}
                onHandlerStateChange={
                    Platform.OS == "web"
                        ? ({ nativeEvent }) => {
                              if (nativeEvent.state === State.ACTIVE) {
                                  setTapXWeb(nativeEvent.absoluteX - leftEdgeX)
                                  setSelectionActive(true)
                                  if (Platform.OS != "web") {
                                      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
                                  }
                              } else if ([State.CANCELLED, State.END].includes(nativeEvent.state)) {
                                  setSelectionActive(false)
                                  setTapXWeb(nativeEvent.absoluteX - leftEdgeX)
                                  if (Platform.OS != "web") {
                                      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
                                  }
                              }
                          }
                        : Animated.event(
                              [
                                  {
                                      nativeEvent: {
                                          x: tapX,
                                      },
                                  },
                              ],
                              {
                                  useNativeDriver: true,
                                  listener: (event, gestureState) => {
                                      // console.log(event.nativeEvent, gestureState)
                                      if (event.nativeEvent.state === State.ACTIVE) {
                                          setSelectionActive(true)
                                          const selectedIndex = Math.min(historyData.length - 1, Math.floor(event.nativeEvent.x / selectedItemWidth))
                                          setSelectedItem(historyData[selectedIndex])
                                          if (Platform.OS != "web") {
                                              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
                                          }
                                      } else if ([State.CANCELLED, State.END].includes(event.nativeEvent.state)) {
                                          setSelectionActive(false)
                                          const selectedIndex = Math.min(historyData.length - 1, Math.floor(event.nativeEvent.x / selectedItemWidth))
                                          setSelectedItem(historyData[selectedIndex])
                                          if (Platform.OS != "web") {
                                              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
                                          }
                                      }
                                  },
                              }
                          )
                }
                onGestureEvent={
                    Platform.OS == "web"
                        ? ({ nativeEvent }) => {
                              setTapXWeb(nativeEvent.absoluteX - leftEdgeX)
                          }
                        : Animated.event(
                              [
                                  {
                                      nativeEvent: {
                                          x: tapX,
                                      },
                                  },
                              ],
                              {
                                  useNativeDriver: true,
                                  listener: (event, gestureState) => {
                                      const selectedIndex = Math.min(historyData.length - 1, Math.floor(event.nativeEvent.x / selectedItemWidth))
                                      setSelectedItem(historyData[selectedIndex])
                                  },
                              }
                          )
                }
            >
                <Animated.View
                    style={{ width: "100%", height: graphHeight, marginTop: graphTopSpacing }}
                    onLayout={(event) => {
                        const { x, y, width, height } = event.nativeEvent.layout
                        setGraphWidth(width)
                    }}
                >
                    <LineChart
                        style={{ width: "100%", height: "100%", position: "absolute" }}
                        animate={Platform.OS != "web"}
                        data={historyData}
                        xAccessor={xAccessor}
                        yAccessor={(item) => {
                            let value = path(["item", "carbon"], item)
                            return value
                        }}
                        curve={shape.curveBumpX}
                        svg={{ stroke: measurementColors[DataMeasurement.carbon], strokeWidth: 2 }}
                        contentInset={{ top: 1, bottom: 1 }}
                    ></LineChart>

                    <LineChart
                        style={{ width: "100%", height: "100%", position: "absolute" }}
                        animate={Platform.OS != "web"}
                        data={historyData}
                        xAccessor={xAccessor}
                        yAccessor={(item) => {
                            let value = path(["item", "usage"], item)
                            return value
                        }}
                        curve={shape.curveBumpX}
                        svg={{ stroke: measurementColors[DataMeasurement.use], strokeWidth: 2 }}
                        contentInset={{ top: 1, bottom: 1 }}
                    ></LineChart>
                    <LineChart
                        style={{ width: "100%", height: "100%", position: "absolute" }}
                        animate={Platform.OS != "web"}
                        data={historyData}
                        xAccessor={xAccessor}
                        yAccessor={(item) => {
                            let value = path(["item", "cost"], item)
                            return value
                        }}
                        curve={shape.curveBumpX}
                        svg={{ stroke: measurementColors[DataMeasurement.money], strokeWidth: 2 }}
                        contentInset={{ top: 1, bottom: 1 }}
                    ></LineChart>

                    {selectionActive && (
                        <Animated.View
                            style={[
                                {
                                    width: needleViewWidth,
                                    height: "100%",

                                    position: "absolute",
                                    left: -(needleViewWidth / 2.0),
                                    alignItems: "center",
                                },
                                {
                                    transform: [
                                        {
                                            translateX: Platform.OS == "web" ? tapXWeb : tapX,
                                        },
                                    ],
                                },
                            ]}
                        >
                            <View style={{ width: 3, height: "100%", borderRadius: 1000, backgroundColor: theme.textHint }} />
                        </Animated.View>
                    )}

                    {timeModeSwitchPending && (
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: theme.background,
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: 0.8,
                            }}
                        >
                            <ActivityIndicator
                                size={"large"}
                                animating={true}
                                color={theme.textDisabled}
                                // style={{ position: "absolute", bottom: 0, right: 0 }}
                            />
                        </View>
                    )}
                </Animated.View>
            </LongPressGestureHandler>

            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 24 }}>
                {[TimeFrame.day, TimeFrame.week, TimeFrame.month, TimeFrame.year, TimeFrame.multiYear].map((timeframe) => {
                    let isSelected = timeframe == timeMode
                    return (
                        <TouchableOpacity
                            key={timeframe.daysOfDataFetched}
                            style={{ marginHorizontal: 12, padding: 8, borderRadius: 1000, backgroundColor: isSelected ? theme.surface1 : null }}
                            onPress={() => {
                                if (!isSelected) {
                                    setTimeModeSwitchPending(true)
                                    setTimeMode(timeframe)
                                    if (Platform.OS != "web") {
                                        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
                                    }
                                }
                            }}
                        >
                            <Text style={[TextStyles.capsSmall, { color: isSelected ? theme.textPrimary : theme.textHint }]}>{timeframe.buttonTitle}</Text>
                        </TouchableOpacity>
                    )
                })}
            </View>
        </CardView>
    )
}
