import { useNavigation } from "@react-navigation/native"
import { prop } from "ramda"
import * as React from "react"
import { Image, Text, TouchableOpacity, View } from "react-native"
import { useSelector } from "react-redux"
import { DataMeasurement, DataSource, InsightTypes } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import { formatWithUnits } from "../../shared/Utils"
import RoundedBar from "../RoundedBar"
import CardView from "./CardView"

export default function InsightCard(props) {
    const navigation = useNavigation()
    const { type, source, infoButtonAction } = props
    const { theme } = React.useContext(ThemeContext)
    const mainData = useSelector((state) => state.primaryData.mainData)

    const scorecardData = prop(
        source == DataSource.gas ? "gasScorecardData" : "electricityScorecardData",
        useSelector((state) => state.primaryData.scorecardsData)
    )
    const carbonScorecardData = prop(
        "carbonScorecardData",
        useSelector((state) => state.primaryData.scorecardsData)
    )

    var title = ""
    switch (type) {
        case InsightTypes.projectedBill:
            title = i18n.t("insight.projectedBill")
            break
        case InsightTypes.usageVsProjected:
        case InsightTypes.carbonVsProjected:
            title = i18n.t("insight.vsProjected")
            break
        case InsightTypes.usageVsYearAgo:
        case InsightTypes.carbonVsYearAgo:
            title = i18n.t("insight.vsYearAgo")
            break
        case InsightTypes.usageVsSimilar:
        case InsightTypes.carbonVsSimilar:
            title = i18n.t("insight.vsSimilar")
            break
        case InsightTypes.highUsage:
            title = i18n.t("insight.highUsage")
            break
        case InsightTypes.neighborhoodRankOverall:
            title = i18n.t("insight.overallRank")
            break
        case InsightTypes.neighborhoodRankPerCapita:
            title = i18n.t("insight.perCapitaRank")
        default:
            break
    }

    const renderCurrentTypeContent = () => {
        switch (type) {
            case InsightTypes.projectedBill:
                const {
                    thisBill = 0,
                    lastYear = 0,
                    lastMonth = 0,
                } = prop(source == DataSource.gas ? "billEstimateDataGas" : "billEstimateDataElectric", mainData) || {}

                // NOTE: maxBarValue can be zero, in which case our `normalizedValue` for the rounded bar is NaN. The bar handles
                // that internally.
                const maxBarValue = Math.max(thisBill, Math.max(lastYear, lastMonth))

                return (
                    <View>
                        <Text style={[TextStyles.h2, { color: theme.green, marginBottom: 1 }]}>
                            {formatWithUnits(thisBill, DataSource.electricity, DataMeasurement.money)}
                        </Text>
                        <RoundedBar normalizedValue={thisBill / maxBarValue} barTintColor={theme.green} containerStyle={{ marginBottom: 16 }} />
                        {lastMonth > 0 && (
                            <View>
                                <Text style={[TextStyles.caption, { color: theme.textHint, marginBottom: 2 }]}>
                                    {i18n.t("insight.lastBill") + ": " + formatWithUnits(lastMonth, DataSource.electricity, DataMeasurement.money)}
                                </Text>

                                <RoundedBar
                                    normalizedValue={lastMonth / maxBarValue}
                                    barTintColor={theme.textHint}
                                    containerStyle={{ marginBottom: 8, opacity: 0.1 }}
                                />
                            </View>
                        )}
                        {lastYear > 0 && (
                            <View>
                                <Text style={[TextStyles.caption, { color: theme.textHint, marginBottom: 2 }]}>
                                    {i18n.t("insight.yearAgo") + ": " + formatWithUnits(lastYear, DataSource.electricity, DataMeasurement.money)}
                                </Text>

                                <RoundedBar normalizedValue={lastYear / maxBarValue} barTintColor={theme.textHint} containerStyle={{ opacity: 0.1 }} />
                            </View>
                        )}
                    </View>
                )
                break
            case InsightTypes.usageVsProjected:
            case InsightTypes.usageVsYearAgo:
            case InsightTypes.usageVsSimilar:
            case InsightTypes.carbonVsProjected:
            case InsightTypes.carbonVsYearAgo:
            case InsightTypes.carbonVsSimilar:
                // console.log("Scorecard data is " + JSON.stringify(scorecardData))

                var comparedAgainstField = "smartEstimate"
                if ([InsightTypes.usageVsYearAgo, InsightTypes.carbonVsYearAgo].includes(type)) {
                    comparedAgainstField = "lastYear"
                } else if ([InsightTypes.usageVsSimilar, InsightTypes.carbonVsSimilar].includes(type)) {
                    comparedAgainstField = "similarHomes"
                }

                var activeScorecardData = scorecardData
                if ([InsightTypes.carbonVsProjected, InsightTypes.carbonVsYearAgo, InsightTypes.carbonVsSimilar].includes(type)) {
                    activeScorecardData = carbonScorecardData
                }

                var formattedYesterdayComparison = ""
                var yesterdayColor = theme.blue
                let yesterdayData = prop("yesterday", activeScorecardData) || {}
                let yesterdayComparison = prop(comparedAgainstField, yesterdayData) || 0

                if (yesterdayComparison > 0) {
                    formattedYesterdayComparison = i18n.t("percentMore", { value: yesterdayComparison })
                    yesterdayColor = theme.red
                } else if (yesterdayComparison < 0) {
                    formattedYesterdayComparison = i18n.t("percentLess", { value: Math.abs(yesterdayComparison) })
                } else {
                    formattedYesterdayComparison = i18n.t("aboutTheSame")
                }

                var formattedBillingCycleComparison = ""
                var billingCycleColor = theme.blue
                let billingCycleData = prop("billingCycle", activeScorecardData) || {}
                let billingCycleComparison = prop(comparedAgainstField, billingCycleData) || 0
                // console.log("Billing cycle comparison is " + billingCycleComparison)

                if (billingCycleComparison > 0) {
                    formattedBillingCycleComparison = i18n.t("percentMore", { value: billingCycleComparison })
                    billingCycleColor = theme.red
                } else if (billingCycleComparison < 0) {
                    formattedBillingCycleComparison = i18n.t("percentLess", { value: Math.abs(billingCycleComparison) })
                } else {
                    formattedBillingCycleComparison = i18n.t("aboutTheSame")
                }

                return (
                    <View>
                        <Text style={[TextStyles.h2, { color: yesterdayColor, marginBottom: 1 }]}>{formattedYesterdayComparison}</Text>
                        <Text style={[TextStyles.caption, { color: theme.textHint, marginBottom: 8 }]}>{i18n.t("yesterday").toLowerCase()}</Text>
                        <Text style={[TextStyles.h2, { color: billingCycleColor, marginBottom: 1 }]}>{formattedBillingCycleComparison}</Text>
                        <Text style={[TextStyles.caption, { color: theme.textHint, marginBottom: 8 }]}>{i18n.t("thisBillingCycle")}</Text>
                    </View>
                )

                break
            case InsightTypes.neighborhoodRankOverall:
            case InsightTypes.neighborhoodRankPerCapita:
                // FIXME: TEST ONLY!! GET THIS DATA FROM THE API!
                const overallRank = 7
                const perCapitaRank = 3

                const rank = type == InsightTypes.neighborhoodRankOverall ? overallRank : perCapitaRank

                return (
                    <View>
                        <Text style={[TextStyles.h1, { color: theme.blue, marginBottom: 8 }]}>{"#" + rank.toFixed(0)}</Text>
                    </View>
                )

                break
            default:
                break
        }
    }

    return (
        <CardView
            includesBorder={true}
            includesPadding={false}
            containerStyle={{ width: 240, padding: 16, backgroundColor: theme.surface1, borderWidth: 0, marginRight: 0 }}
            {...props}
        >
            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 8 }}>
                {title != null && <Text style={[TextStyles.capsSmall, { flexShrink: 1, color: theme.textSecondary }]}>{title}</Text>}
                {infoButtonAction != null && (
                    <TouchableOpacity onPress={infoButtonAction}>
                        <Image source={theme.icons.infoCircle16} style={{ width: 16, height: 16, opacity: 0.7 }} />
                    </TouchableOpacity>
                )}
            </View>
            <View style={{ flexShrink: 1 }}>{renderCurrentTypeContent()}</View>
        </CardView>
    )
}
