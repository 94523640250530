import { useNavigation } from "@react-navigation/native"
import * as Linking from "expo-linking"
import moment from "moment-timezone"
import { prop } from "ramda"
import * as React from "react"
import { Image, Text, View, TouchableOpacity } from "react-native"
import { useSelector } from "react-redux"
import AppConstants, { DataMeasurement, DataSource, ScreenNames } from "../shared/AppConstants"
import i18n from "../shared/i18n"
import { activeOpacity } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"
import { eventStartTimeFormatted, eventTimeFormatted, formatWithUnits, openSetup, pastEventTimeFormatted, timeRangeFormatted } from "../shared/Utils"
import Divider from "./Divider"

export default function NotificationRow(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    const { notificationData, currentUtilityAccount, containerStyle } = props
    const { type, electric_utility_account, payload, created_at } = notificationData
    const mainData = useSelector((state) => state.primaryData.mainData)

    // Only show notifications that either have no associated utility account or one that matches our current account.
    if (electric_utility_account != null && prop("public_id", electric_utility_account) != currentUtilityAccount) {
        return null
    }

    const titlePath = `notification.${type}`
    var title = i18n.t(titlePath)
    var iconSource = null
    var iconOpacity = 0.7
    var targetUrl = null
    var onPress = null
    switch (type) {
        case "unusualUsage":
            iconSource = theme.icons.warningSquareRed
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EnergyTab.routeName, { screen: ScreenNames.EnergyScreen.routeName })
            }
            break
        case "reminderAuth":
            iconSource = theme.icons.infoCircle24
            onPress = () => {
                navigation.navigate(ScreenNames.AccountConnectionPrompt.routeName)
            }
            break
        case "promptThermostat":
            iconSource = theme.icons.infoCircle24
            onPress = () => {
                const { tstat = {} } = prop("nextSteps", mainData) || {}
                openSetup(prop("link", tstat))
            }
            break
        case "promptSurvey":
            iconSource = theme.icons.infoCircle24
            onPress = () => {
                const { survey = {} } = prop("nextSteps", mainData) || {}
                openSetup(prop("link", survey))
            }
            break
        case "link":
            title = i18n.t(titlePath, { linkTitle: prop("linkTitle", payload) || prop("url", payload) || "Tap here" })
            iconSource = theme.icons.infoCircle24
            targetUrl = prop("url", payload)
            break
        case "authCompleted":
            iconSource = theme.icons.partyHornPurple
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EnrollmentComplete.routeName)
            }
            break
        case "initialDataAvailable":
            iconSource = theme.icons.partyHornPurple
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EnergyTab.routeName, { screen: ScreenNames.EnergyScreen.routeName })
            }
            break
        case "fullDataAvailable":
            iconSource = theme.icons.partyHornPurple
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EnergyTab.routeName, { screen: ScreenNames.EnergyScreen.routeName })
            }
            break
        case "seasonStart":
            title = i18n.t(titlePath, { date: moment(prop("startDate", payload)).format("MMMM Do") })
            iconSource = theme.icons.boltOrange
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })
            }
            break
        case "eventScheduled":
            // Payload: {
            //     “eventId”: event_id,
            //     “start”: start,
            //     “end”: end,
            // },
            title = i18n.t(titlePath, { timeRangeString: timeRangeFormatted(prop("start", payload), prop("end", payload)) })
            iconSource = theme.icons.boltOrange
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })
            }
            break

        case "eventStart":
            // Payload: {
            //     “eventId”: event_id,
            //     “start”: start,
            //     “end”: end,
            // },
            title = i18n.t(titlePath, { atTimeString: eventStartTimeFormatted(prop("start", payload)) })
            iconSource = theme.icons.boltOrange
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })
            }
            break

        case "eventEnd":
            // Payload (IGNORED): {
            //     “eventId”: event_id,
            //     “start”: start,
            //     “end”: end,
            // },
            iconSource = theme.icons.boltOrange
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })
            }
            break

        case "eventPerformanceAvailable":
            const activeEvent = payload
            title = i18n.t(titlePath, { timeString: pastEventTimeFormatted(activeEvent) || i18n.t("recent") })
            iconSource = theme.icons.boltOrange
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EventsTab.routeName, {
                    screen: ScreenNames.GridRewardsEventDetail.routeName,
                    params: {
                        gridRewardsEvent: activeEvent,
                    },
                })
            }
            break

        case "seasonEarnings":
            iconSource = theme.icons.moneyGreen
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.HomeTab.routeName, { screen: ScreenNames.PaymentDetail.routeName })
            }
            break

        case "paymentAvailable":
            title = i18n.t(titlePath, {
                amount: prop("earnings", payload) != null ? formatWithUnits(prop("earnings", payload), DataSource.electricity, DataMeasurement.money) : "",
            })
            iconSource = theme.icons.moneyGreen
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.HomeTab.routeName, { screen: ScreenNames.PaymentDetail.routeName })
            }
            break

        case "newNeighborhoodMessage":
            iconSource = theme.icons.seedlingTeal
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })
            }
            break

        case "reminderReferral":
            iconSource = theme.icons.seedlingTeal
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.ReferralDetail.routeName)
            }
            break

        case "referralPickedUp":
            // FIXME: We need to fetch the user matching payload.userId
            title = i18n.t(titlePath, { userDisplayName: "SOME USER" })
            iconSource = theme.icons.seedlingTeal
            iconOpacity = 1.0
            break

        case "referralSummary":
            const referralCount = prop("referralCount", payload)
            title = i18n.t(titlePath, {
                number: referralCount,
                referralsSingularOrPlural: referralCount == 1 ? i18n.t("referral.literalSingular") : i18n.t("referral.literalPlural"),
            })
            iconSource = theme.icons.seedlingTeal
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })
            }
            break

        case "learnMore":
            //FIXME: Need to figure out what to display here.
            title = i18n.t(titlePath, { subject: i18n.t("gridRewards") })
            iconSource = theme.icons.bookBlue
            iconOpacity = 1.0
            onPress = () => {
                navigation.navigate(ScreenNames.University.routeName)
            }
            break

        case "other":
            title = prop("displayString", payload) || ""
            iconSource = theme.icons.infoCircle24
            break

        default:
            break
    }

    // console.log("Title for type " + type + " is " + JSON.stringify(title))

    return (
        <TouchableOpacity
            style={[
                {
                    flex: 1,
                    flexDirection: "row",
                },
                containerStyle,
            ]}
            activeOpacity={activeOpacity}
            onPress={
                onPress ??
                (() => {
                    if (targetUrl == null || targetUrl.length == 0) {
                        return
                    }
                    console.log("Trying to open target: " + targetUrl)
                    Linking.openURL(targetUrl)
                })
            }
            {...props}
        >
            {iconSource != null && <Image source={iconSource} style={[{ width: 24, height: 24, marginRight: 16 }]} />}
            <View style={{ flex: 1 }}>
                <Text style={[TextStyles.body3, { flexShrink: 1, color: theme.textPrimary }]}>{title}</Text>
                <Divider style={{ marginVertical: 16 }} />
            </View>
        </TouchableOpacity>
    )
}
