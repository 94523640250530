import { Ionicons } from "@expo/vector-icons"
import * as Font from "expo-font"
import * as SplashScreen from "expo-splash-screen"
import * as React from "react"
import { Asset } from "expo-asset"
import { Platform } from "react-native"

export default function useCachedResources() {
    const [isLoadingComplete, setLoadingComplete] = React.useState(false)

    // Load any resources or data that we need prior to rendering the app
    React.useEffect(() => {
        console.log("Loading cached resources")
        async function loadResourcesAndDataAsync() {
            try {
                SplashScreen.preventAutoHideAsync()

                // Load fonts
                await Font.loadAsync({
                    "inter-regular": require("../assets/fonts/Inter-Regular.ttf"),
                    "inter-medium": require("../assets/fonts/Inter-Medium.ttf"),
                    "inter-semi": require("../assets/fonts/Inter-SemiBold.ttf"),
                    "kobe-black": require("../assets/fonts/Kobe1.1-Black.otf"),
                    "kobe-bold": require("../assets/fonts/Kobe1.1-Bold.otf"),
                })

                // Load images we know we're going to use.
                await Asset.loadAsync([
                    // // map assets
                    // require("../assets/images/mapNode1.png"),
                    // require("../assets/images/mapNode2.png"),
                    // require("../assets/images/mapNode3.png"),
                    // require("../assets/images/mapNode4.png"),
                    // require("../assets/images/mapNodeCluster.png"),
                ])
            } catch (e) {
                // We might want to provide this error information to an error reporting service
                console.warn(e)
            } finally {
                setLoadingComplete(true)
                setTimeout(() => {
                    SplashScreen.hideAsync()
                }, 1000)
            }
        }

        loadResourcesAndDataAsync()
    }, [])

    return isLoadingComplete
}
