import moment from "moment-timezone"
import { prop } from "ramda"
import * as React from "react"
import { Text, Image, View } from "react-native"
import { useSelector } from "react-redux"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import { CarbonThresholdManual } from "../../shared/AppConstants"
import CardView from "./CardView"

export default function CarbonIntensityNextGoodCard(props) {
    const { theme } = React.useContext(ThemeContext)

    const mainData = useSelector((state) => state.primaryData.mainData)
    const carbonIntensityData = useSelector((state) => state.primaryData.carbonIntensityData)
    const historySlices = prop("actual", carbonIntensityData) || []
    const forecastSlices = prop("forecast", carbonIntensityData) || []
    const thresholds = prop("thresholds", carbonIntensityData) || []

    if (mainData == null || carbonIntensityData == null || (historySlices.length == 0 && forecastSlices.length == 0)) {
        return null
    }

    const threshold = thresholds.length >= 3 ? thresholds[1] : CarbonThresholdManual.okay
    const nowSlice = historySlices.find((element) => {
        let rawTimestamp = prop("timestamp", element)
        if (rawTimestamp != null) {
            let timestamp = moment(rawTimestamp)
            // If an hour from this slice (which will be on an hour boundary) is after now, it represents the current hour.
            return timestamp.add(1, "hours").isSameOrAfter(moment())
        }
    })
    const nextGoodSlice = [nowSlice, ...forecastSlices].find((element) => {
        let carbonIntensity = prop("carbonIntensity", element)
        return carbonIntensity != null && carbonIntensity < threshold
    })

    if (nextGoodSlice == null) {
        return null
    }

    const timeString = nowSlice == nextGoodSlice ? i18n.t("now").toLowerCase() : moment(prop("timestamp", nextGoodSlice)).format("ha")

    return (
        <CardView includesBorder={false} includesPadding={false} containerStyle={{ paddingHorizontal: 4, marginBottom: 24 }} {...props}>
            <View style={{ flexDirection: "row" }}>
                <Image source={theme.icons.clock} style={{ width: 24, height: 24, marginRight: 16 }} />
                <Text style={[TextStyles.bodyLarge, { flexShrink: 1, color: theme.textPrimary }]}>
                    {i18n.t("nextGoodTime")} <Text style={[TextStyles.bodyLargeSemi, { color: theme.blue }]}>{timeString}</Text>
                </Text>
            </View>
        </CardView>
    )
}
