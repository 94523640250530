export const translationStrings = {
    translation: {
        aboutTheSame: "大致相同",
        accountStale: "我们已经有一段时间没有从你的公用事业帐号收到新数据了。如果你已搬家，可以关联新帐号。如果没有搬家，请告诉我们。",
        achievement: {
            betterGoal: {
                description: "你在 GridRewards 活动中表现出色。",
                title: "表现出色"
            },
            connectedAccount: {
                description: "你已经关联一个公用事业帐号。现在，你可以在 GridRewards 中查看能源数据并赚取收益了！",
                title: "已关联"
            },
            countMeIn: {
                description: "你签到参加了一个 GridRewards 活动。",
                title: "你的声音很重要"
            },
            countNone: "你还没有获得此成就",
            countPlural: "于 {{date}} 获得，还有 {{count}} 次",
            countMultipleSingular: "于 {{date}} 获得，还有 1 次",
            countSingular: "于 {{date}} 获得",
            eventCount10: {
                description: "你表现得很棒，已经在 10 个 GridRewards 活动中赚取了收益。保持下去！",
                title: "10 个成功活动"
            },
            eventCount25: {
                description: "你表现得很棒，已经在 25 个 GridRewards 活动中赚取了收益。继续加油！",
                title: "25 个成功活动"
            },
            eventCount50: {
                description: "你表现得很棒，已经在 50 个 GridRewards 活动中赚取了收益。继续加油！",
                title: "50 个成功活动"
            },
            goalReached: {
                description: "你在 GridRewards 活动中实现了节电目标！",
                title: "能源忍者"
            },
            graphViewed: {
                description: "你第一次查看了能源图。",
                title: "数据探索者"
            },
            minimumGoal: {
                description: "你在 GridRewards 活动中实现了最低节电目标",
                title: "达到最低目标"
            },
            neighborJoined: {
                description: "同街区的人在你的邀请下加入了 GridRewards。",
                title: "街区带头人"
            },
            referralSent: {
                description: "你已发送引荐，你和好友在活动期间均可赚取额外现金。",
                title: "引荐好友"
            },
            topNeighborhood: {
                description: "在 GridRewards 活动期间，你所在的街区是最节电的街区之一。",
                title: "最佳街区"
            },
            topNeighborhoodPerCapita: {
                description: "在 GridRewards 活动期间，你所在的街区取得了最佳的人均节电。",
                title: "最佳人均街区"
            },
            topPerformer: {
                description: "在 GridRewards 活动期间，你是所在街区节电最多的人。",
                title: "表现最佳"
            }
        },
        achievementsTitle: "成就",
        acknowledgements: "致谢",
        actions: {
            appliances: {
                subtitle: "活动结束后再洗碗、洗衣服和煮咖啡。",
                subtitleAdvanced: "稍微脏乱一点也没什么。",
                title: "晚些使用大型电器"
            },
            ev: {
                subtitle: "电动汽车消耗的电量大约可以占家庭总电量的 30%，因此，晚些充电会产生很大影响。还没有电动汽车吗？那么恭喜，这项已经完成了 ;-)。",
                subtitleAdvanced: "还没有电动汽车吗？去买一辆，接送孩子的感觉都不一样。",
                title: "晚些时候再给电动汽车充电"
            },
            fridge: {
                subtitle: "调高冰箱和冰柜的温度。把冰箱门关好，让冷空气都能留在里面。",
                subtitleAdvanced: "热熟肉可能还不错？",
                title: "调高冰箱和冰柜温度"
            },
            lights: {
                subtitle: "尽可能把所有灯都关掉。你还可以用 LED 灯泡替换白炽灯泡，节省大量能源。",
                subtitleAdvanced: "小心撞墙。",
                title: "关灯"
            },
            preCool: {
                subtitle: "在活动开始前 4 小时，将温控器设置为低于正常温度，然后让它在活动期间升高。",
                subtitleAdvanced: "提前感受能源危机。",
                title: "将空间预冷"
            },
            raiseAC: {
                subtitle: "在活动期间将温控器设置为比正常温度高几度。",
                subtitleAdvanced: "从自己的不适中获利。",
                title: "调高空调温度"
            },
            unplug: {
                subtitle: "关掉电视、有线电视盒和其他大型电器，出去走走！",
                subtitleAdvanced: "放下手机，到室外走走，获得一些维生素 D。用 GridRewards™ 对抗焦虑。",
                title: "拔掉电源"
            }
        },
        actionsInfoSubtitle: "从点滴做起，积少成多！做出这些改变，为 GridRewards 活动带来最大的影响。",
        addButtonTitle: "添加",
        addPhoto: "添加照片",
        alreadyHaveAnAccountPrompt: "已经有帐户了？立即登录",
        amiOnlyWarning: "离赚取收益更近一步！将公用事业帐号关联到 GridRewards。",
        amountOfCO2: "{{amount}} 的 CO₂",
        amountOfElectricity: "{{amount}} 的能源",
        avatarUpdateFailed: "无法更新个人资料照片，请重试",
        and: "和",
        backToProfile: "返回个人资料",
        backToSignIn: "返回登录界面",
        bad: "差",
        beforeEvent: "活动前",
        billingCycle: "结算周期",
        bonuses: "额外收益",
        calendarEventConfirmation: "已添加到日历",
        calendarEventErrorBody: "创建日历活动时出错。请确保日历已正确设置，然后重试。",
        calendarEventErrorTitle: "无法添加到日历",
        calendarEventTitle: "GridRewards 活动：节约能源，即刻行动！",
        callUs: "致电我们",
        cancel: "取消",
        carbonComparison1After: "棵树苗",
        carbonComparison1Before: "10 年种植",
        carbonComparison2After: "英里",
        carbonComparison2Before: "将汽车停驶",
        carbonComparison3After: "部手机充电",
        carbonComparison3Before: "不为",
        carbonComparison4After: "吨垃圾，而不是将其扔进垃圾填埋场",
        carbonComparison4Before: "循环利用",
        carbonComparison5After: "英亩的森林生长一年",
        carbonComparison6After: "个家庭使用一年",
        carbonComparison6Before: "能源可供",
        carbonComparison7After: "个丙烷罐用于家庭烧烤",
        carbonComparison8After: "辆汽车一年不上路",
        carbonComparison8Before: "将",
        carbonFootprintTitle: "碳足迹",
        carbonFootprintTitleCase: "碳足迹",
        carbonGraphAnalysisForecast: "将很可能排放",
        carbonGraphAnalysisGrid: "的电网",
        carbonGraphAnalysisPast: "排放了",
        carbonGraphAnalysisUnits: "g 的 CO₂/kWh",
        carbonGraphInfo: "并非所有电力都是平等的。较高的柱形表示每单位电力的碳排放量最高的时间，在这些时间里节约能源将产生最大的影响。",
        carbonGraphRegionPlaceholder: "地区",
        carbonPreventedDuringEvents: "远离大气层",
        carbonTabTitle: "CO₂",
        cardThermostatCoolTo: "制冷到",
        cardThermostatHeatTo: "加热到",
        cardThermostatHold: "保持",
        cardThermostatStayBetween: "停留于",
        categoryClimateAction: "气候行动",
        categoryEnvironmentalJustice: "环境正义",
        categoryFoodSecurity: "食品安全",
        categoryLandConservancy: "土地保护",
        categorySustainability: "可持续发展",
        cityHarvestDescription: "纽约市最大的食品救援组织，帮助为超过 150 万食不果腹的纽约人提供食物。City Harvest 今年将拯救 1.11 亿磅食物，并免费提供给五个行政区的数百个食品救济站、慈善厨房和其他社区合作伙伴。",
        cityHarvestLinkName: "cityharvest.org",
        cityHarvestTitle: "City Harvest",
        community: "社区",
        completeAccountSetup: "完成帐户设置",
        connectNewAccount: "关联新帐号",
        connectYourAccount: "关联帐号",
        connectAccount: {
            title: "立即开始",
            description: "关联你的公用事业帐号，开始参与 GridRewards 活动并获得收益。",
            noOnlineAccountTitle: "没有在线公用事业帐号？",
            noOnlineAccountDescription: "如果你还没有设置在线电力公司帐户，你首先需要完成设置并将现有帐号附加到在线帐户，这将确认你的电表已正常关联。",
            cta: "关联公用事业帐号"
        },
        createAccount: "创建帐户",
        createAccountErrorMissingFields: "请输入电子邮件和密码，然后点按“创建帐户”。",
        createGridRewardsAccount: "创建 GridRewards 帐户",
        currentGridCO2: "当前电网 CO₂",
        currentStreak: "当前连胜",
        dataFetchTitle: "正在检索数据。最长可能需要 15 分钟。",
        dataSourceNameElectricity: "电",
        dataSourceNameGas: "燃气",
        dataTimeframeDay: "天",
        dataTimeframeHour: "小时",
        defaultDisplayName: "GridRewards 用户",
        deleteAccount: {
            title: "删除 GridRewards 帐户",
            descriptionBeforeDisconnect: "删除帐户会让你无法访问 GridRewards 计划，并让你无法在本赛季和未来赛季赚取现金。\n\n除了删除 GridRewards 帐户，你可能想要",
            descriptionDisconnectLink: "停止从你的公用事业帐号共享公用事业数据。",
            descriptionAfterDisconnect: "\n\n如果你确定继续，可以在下方输入密码并删除帐户。",
            button: "删除帐户",
            passwordMissing: "请输入密码。"
        },
        deleteAccountLinkTitle: "删除 GridRewards 帐户",
        digitalPaymentTimeoutInstructions: "如果你未在 30 天内领取数字付款，我们将向你的实际地址发送纸质支票：",
        donationsDetailSubtitle: "这些是 GridRewards 的受赠非营利组织。如果你选择捐赠 GridRewards 收益，你的 100% 收益将被分配给这些组织。",
        donationsDetailTitle: "关于我们的捐赠",
        done: "完成",
        duringEvent: "活动期间",
        earnings: "收益",
        earningsPotential: "潜在收益",
        edit: "编辑",
        editPhoto: "编辑照片",
        editProfileTitle: "编辑个人资料",
        editYourInfo: "编辑信息",
        electricitySources: "电力来源",
        email: "电子邮件",
        emailUs: "发邮件给我们",
        energySavingActions: "节能行动",
        energyTabTitle: "能源",
        energyTitle: "能源",
        enrollmentComplete: {
            carbon: {
                description: "关注你的用电量和碳足迹。获得个性化的能源分析并了解使用能源的最佳时间。",
                title: "减少碳足迹"
            },
            community: {
                description: "每引荐一个好友即可获得 10 美元。与邻里携手，获得额外奖励并扩大积极影响！",
                title: "让社区参与进来"
            },
            events: {
                description: "GridRewards 活动是最重要的节电时间。我们会通知你活动时间，通常是在炎热的夏日午后，每年大约 5-10 次。",
                title: "关注即将到来的活动"
            },
            title: "报名成功！\n接下来呢？"
        },
        estimated: "估计",
        eventCountPlural: "{{count}} 个活动",
        eventCountSingular: "1 个活动",
        eventLikelihood: "活动{{likelihood}}于 {{day}} 开展。",
        eventLikelihoodNone: "本周开展活动的可能性很小。",
        eventPerformance: "活动表现",
        eventPerformanceDisclaimer: "你需要在特定月份的所有活动中平均达到 25% 或更多的节电目标，才能在该月赚钱。",
        events: {
            current: {
                timeFormat: "{{endTime}} 结束",
                title: "GridRewards 活动"
            },
            future: {
                timeFormat: "{{startTime}} 到 {{endTime}}",
                title: "即将到来的 GridRewards 活动"
            },
            cta: "算我一个",
            seeAll: "所有 GridRewards 活动"
        },
        eventsTabTitle: "活动",
        eventsTitle: "活动",
        expertMode: "专家模式",
        expertModeWarning: "我们已经警告过你了…",
        faqs: "常见问题解答",
        findSomeActions: "查找节能行动",
        firstName: "名字",
        fuelMixDescription: "截至 {{time}}，{{regionName}}的电力有 {{percentRenewable}}% 来自可再生能源，{{percentCarbon}}% 来自产碳燃料。",
        fuelTypeDisplayDualFuel: "双燃料",
        fuelTypeDisplayHydro: "水电",
        fuelTypeDisplayNaturalGas: "天然气",
        fuelTypeDisplayNuclear: "核能",
        fuelTypeDisplayOtherFossil: "其他化石燃料",
        fuelTypeDisplayOtherRenewables: "其他可再生能源",
        fuelTypeDisplayWind: "风",
        getInTouch: "保持联系",
        getYourFriendsInvolved: "让好友参与进来",
        gettingStarted: "开始",
        good: "良好",
        gramsCO2: "g CO₂",
        graph: {
            label: {
                day: "过去一天",
                month: "过去一个月",
                multiYear: "过去 3 年",
                week: "过去一周",
                year: "过去一年"
            },
            span: {
                day: "1 天",
                month: "1 个月",
                multiYear: "3 年",
                week: "1 周",
                year: "1 年"
            }
        },
        gridRewards: "GridRewards",
        gridRewardsEarnings: "GridRewards 收益",
        gridRewardsEventPerformanceDescriptionBad: "在下一个活动中，尽量达到 100% 以实现最大的环境效益和现金收益。",
        gridRewardsEventPerformanceDescriptionGood: "你表现得很好。在下一个活动中，尽量达到 100% 以实现最大的环境效益和现金收益。",
        gridRewardsEventPerformanceDescriptionGreat: "恭喜，太棒了！你实现了帮助地球和赚取现金的目标。",
        gridRewardsEventPerformanceDescriptionOkay: "你表现得还可以。在下一个活动中，尽量达到 100% 以实现最大的环境效益和现金收益。",
        gridRewardsEventTitle: "GridRewards 活动",
        gridRewardsNoEventsInList: "活动在温暖的月份开展，你的表现将在这里显示。",
        gridRewardsSupport: "GridRewards 支持",
        haveQuestions: "有问题吗？我们可以提供帮助。",
        high: "高",
        homeTabTitle: "首页",
        imageSaved: "已将图像保存到照片",
        incorrectAddressOrPaymentLate: "地址不正确或付款延误？",
        initialFetchDescription: "我们仍在从你的公用事业帐号中收集信息。这可能需要几分钟。",
        insight: {
            highUsage: "高用量",
            lastBill: "上一张帐单",
            overallRank: "总排名",
            perCapitaRank: "人均排名",
            projectedBill: "本月预计帐单",
            vsProjected: "对比预计",
            vsSimilar: "对比类似家庭",
            vsYearAgo: "对比 1 年前",
            yearAgo: "年前"
        },
        inviteExplanation: "有人邀请你吗？",
        inviteExplanationSubtitle: "点按引荐链接或输入代码",
        invitePlaceholder: "引荐代码",
        kilowatts: "千瓦",
        lastName: "姓氏",
        learnAboutEvents: "了解 GridRewards 活动 ->",
        less: "收起",
        lesson: {
            actions: {
                description: "了解你可以在 GridRewards 活动中做些什么。 ->",
                title: "发现节能行动"
            },
            moreTogether: {
                description: "了解团结起来如何使我们对电网和地球的影响倍增 ->",
                title: "我们可以一起做更多"
            },
            referral: {
                description: "邀请好友，让你所在街区表现倍增。每人都将获得 10 美元，你可以追求最高排名！ ->",
                title: "邀请好友，获得 10 美元"
            }
        },
        licensesTitle: "许可证",
        likely: "可能",
        locationsTitle: "位置",
        loginButtonCreate: "新建帐户",
        loginButtonLoggingIn: "正在登录",
        loginButtonResetPassword: "重置密码",
        loginErrorMissingFields: "请同时输入电子邮件和密码。",
        loginHeaderSubtitle: "注意：这与你的公用事业帐号登录不同。",
        loginResetPassword: "输入注册时使用的电子邮件，我们将发送一个密码重置链接。",
        low: "低",
        mailingAddress: {
            noData: "找不到地址",
            line1: "街道地址",
            line2: "街道地址 2",
            city: "城市",
            provinceOrState: "州",
            postalOrZip: "邮编",
            prompt: {
                title: "确认你的邮寄地址，以便我们向你发送支票。",
                buttonConfirm: "是，这是我的邮寄地址",
                buttonChange: "不，更换地址",
                buttonAdd: "添加邮寄地址"
            },
            confirmation: {
                correctedTitle: "确认地址",
                entered: "已输入地址",
                found: "找到地址",
                useSelected: "使用所选地址",
                notFound: "找不到地址",
                edit: "编辑地址",
                useAnyway: "仍然使用",
                confirmed: "已确认地址"
            },
            prefs: {
                title: "邮寄地址（必填）",
                buttonUpdate: "更新地址",
                validationError: "无法验证地址，请尝试重新提交。",
                updateSuccessful: "已更新付款地址",
                updateError: "无法更新地址，请重试."
            }
        },
        mapApproximateLocation: "大致位置",
        mapCalloutHeader: "在尖峰时段，此位置可以节省",
        mapDetailHeader: "我们可以携手产生的影响…",
        mapHeaderSubtitle: "在尖峰时段，此区域的 GridRewards 用户可以节省",
        mapInfoBody1: "虚拟电厂 (VPP) 将社区的所有能源资源整合到同一个网络，为整个社区发电或节电。这些资源可能发电（如太阳能电池板和风车），也可能节电（如关灯）。在 GridRewards VPP 中，我们整合的是在 GridRewards 活动期间采取节能行动的家庭和企业的节电项目。",
        mapInfoBody2: "我们估计，在 GridRewards 活动期间，每个参与的家庭或企业将能够节省最高可达 30% 的最大用电量。例如，如果一个普通家庭的用电功率最高为 1 kW，那么我们估计这个家庭在采取有效节能措施期间可以节省 0.3 kW。",
        mapInfoBody3: "在电力需求高峰期，电力公司不得不启动效率低下的旧电厂，导致每千瓦时电力都会排放比平时更多的有害温室气体。在 GridRewards 活动期间节省电力，就可以防止更多二氧化碳被排放到大气中。",
        mapInfoTitle1: "什么是虚拟电厂？",
        mapInfoTitle2: "我们如何计算集体潜在影响？",
        mapInfoTitle3: "GridRewards VPP 如何帮助环境？",
        mapLargeCommercial: "大型商业地产",
        mapLargeResidential: "大型住宅地产",
        mapLegendButtonTitle: "图例",
        mapLegendGroupTitle: "位置组",
        mapLegendTitle: "GridRewards 活动期间的潜在节电",
        mapSmallCommercial: "小型商业地产",
        mapSmallResidential: "小型住宅地产",
        megawatts: "兆瓦",
        minutePlural: "分钟",
        minuteSingular: "分钟",
        moderate: "中等",
        more: "展开",
        mothersOutFrontDescription: "我们相信，没有比动员妇女保护自己的孩子更强大的变革力量。我们知道，当我们能够齐心协力为气候、种族和社会正义而战，大声讲出我们的故事和孩子的梦想，我们必将获胜。",
        mothersOutFrontLinkName: "mothersoutfront.org",
        mothersOutFrontTitle: "Mothers Out Front",
        naturalAreasConservancyDescription: "Natural Areas Conservancy 保护着纽约市 20,000 英亩的森林和湿地。该组织聘用的科学家和专家团队与市政府密切合作，关注五区自然的多样性和复原力。",
        naturalAreasConservancyLinkName: "naturalareasnyc.org",
        naturalAreasConservancyTitle: "Natural Areas Conservancy",
        neighborhood: {
            cta: "查看社区",
            description: {
                plural: "你是{{name}}街区的一员，街区另外还有 {{count}} 人。",
                singular: "你是{{name}}街区的一员，街区另外还有 {{count}} 人。"
            },
            message: {
                captainTitle: "让邻居们做好准备！",
                captainDescription: "作为街区队长，你可以发送有关此活动的消息，让所有人查看。",
                captainPlaceholder: "示例：你好，{{neighborhoodName}}，活动时间为 {{eventTime}}！我们成为最佳街区就可以赚取额外现金，所以把能关的都关掉，出去散散步吧！",
                captainButtonTitle: "更新消息",
                attribution: "你的街区队长",
                updateSuccess: "已更新此活动的街区消息",
                updateFailed: "无法更新街区消息，请重试",
                videoUpload: {
                    retry: "重试上传",
                    recordNew: "录制新视频",
                    clear: "清除视频",
                    error: "糟糕，我们在上传视频时遇到问题。",
                    remove: "移除视频"
                }
            }
        },
        networkUnavailableBody: "没有可用网络连接。请连接到蜂窝网络或 Wi-Fi，然后重试。",
        networkUnavailableTitle: "网络不可用",
        newYorkAndDC: "纽约市和华盛顿特区",
        newYorkAndNational: "纽约和国家",
        newYorkCity: "纽约市",
        nextGoodTime: "下一个用电的好时机是",
        nextOkayCO2Level: "下一个良好 CO₂ 水平",
        noneInNext24Hours: "未来 24 小时内无",
        notification: {
            currentlyViewingTitle: "正在查看以下地址的通知：",
            authCompleted: "恭喜，一切准备就绪！这是开始 GridRewards 活动前需要了解的信息",
            eventEnd: "恭喜！你的 GridRewards 活动现已结束。过几天回来看看你的表现！",
            eventPerformanceAvailable: "了解你在 {{timeString}} 活动中的表现。",
            eventScheduled: "注意！GridRewards 活动将于 {{timeRangeString}} 开展",
            eventStart: "准备好！你的 GridRewards 活动将于 {{atTimeString}} 开始",
            fullDataAvailable: "你的能源数据已可供查看！",
            initialDataAvailable: "你最近的用量数据已可供查看。获取完整能源历史记录可能需要 15 分钟。",
            learnMore: "详细了解{{subject}}",
            link: "了解详情：{{- linkTitle}}",
            newNeighborhoodMessage: "来自街区队长的新消息",
            paymentAvailable: "你的 {{amount}} 付款已经可用！",
            promptSurvey: "通过地产调查改进你的能源分析",
            promptThermostat: "有 Ecobee 智能温控器？将其关联到 GridRewards 并从应用内部进行控制",
            referralPickedUp: "{{userDisplayName}}接受了你的引荐",
            referralSummary: "本赛季，你有 {{number}} 位{{referralsSingularOrPlural}}表现出色，为你赢得了额外奖金！",
            reminderAuth: "关联公用事业帐号，从今天开始赚取收益！",
            reminderReferral: "引荐一位好友，每人都将获得 10 美元奖励！",
            seasonEarnings: "你的本赛季预估收益现已可用",
            seasonStart: "GridRewards 赛季从今年 {{date}} 开始。参加 GridRewards 活动，节电赚现金！",
            title: "通知",
            unusualUsage: "我们注意到一些异常用量。在这里查看详情。"
        },
        now: "现在",
        off: "关",
        ok: "好",
        okay: "一般",
        onboarding: {
            carbon: {
                description: "了解用电的最佳时间。",
                title: "并非所有电力都是平等的！电力的碳足迹会发生变化。"
            },
            energy: {
                description: "",
                title: "你的电力数据将显示在此处，可供监控能源使用情况。"
            },
            events: {
                description: "加入社区！你可以赚取额外收益，还可以扩大对环境的集体影响。",
                title: "活动是在一些炎热夏日午后进行的 3-4 小时时间段。"
            },
            home: {
                description: "在几个重要的日子里少用几小时能源，年底获得报酬！一个普通家庭每年可以赚取超过 100 美元。",
                title: "在 GridRewards 活动期间节省电力，赚取收益并助力环保。"
            }
        },
        onboardingLocationError: "我们尚不支持该邮政编码。",
        onboardingLocationPlaceholder: "输入邮政编码",
        onboardingWelcomeSubtitle: "GridRewards 与你的公用事业公司合作，帮助你通过节电赚钱。",
        participants: {
            othersPlural: "{{count}} 个邻居已经加入",
            othersSingular: "1 个邻居已经加入",
            youAndOthersPlural: "你和 {{count}} 个邻居已经加入！",
            youAndOthersSingular: "你和 1 个邻居已经加入！",
            youOnly: "你已经加入！"
        },
        password: "密码",
        paymentPrefDonateSeePartners: "看看我们本赛季捐助的组织",
        paymentPrefDonateSubtitle: "我们会将你收益的 100% 捐赠给出色的非营利组织。",
        paymentPrefDonateTitle: "捐赠",
        paymentPrefFinePrint: "更改适用于尚未开始或安排的未来付款。",
        paymentPrefInstantFee: "每次付款有 0.25 美元的费用",
        paymentPrefInstantSubtitle: "我们将向你发送付款链接。你可以使用你的 PayPal 账户提取款项。",
        paymentPrefInstantTitle: "即时付款",
        paymentPrefMailSubtitle: "付款将在日历年年底发出。",
        paymentPrefMailTitle: "在邮件中接收支票",
        paymentPrefShortDonate: "捐赠",
        paymentPrefShortInstant: "即时",
        paymentPrefShortMail: "支票",
        paymentPrefUpdated: "你的付款偏好设置已设置为{{pref}}",
        paymentPreference: "付款偏好设置",
        paymentPrefsSubtitle: "选择你希望如何接收 GridRewards 付款。付款在年底计算并发出。",
        paymentsAtEndOfYear: "你的付款将在日历年年底通过 {{paymentMethod}} 发送。",
        paymentGeneralTimeframe: "付款在年底计算并发送。",
        payments: {
            title: "你正在进行的收益",
            rowTitles: {
                performance: "活动表现",
                bonus: "额外收益",
                bonusDescription: "要达到此额外奖励数额，请确保你引荐的好友也在本赛季的每个活动中都表现出色！",
                estimated: "预估收益",
                previous: "以前的付款"
            }
        },
        pendingAnalysis: "待分析",
        percentLess: "少 {{value}}%",
        percentMore: "多 {{value}}%",
        percentOfGoal: "目标 %",
        percentOfGoalShort: "{{percent}}% 的目标",
        percentRenewable: "{{percent}}% 可再生",
        performance: "表现",
        phoneNumber: "电话号码",
        phoneNumberGenericError: "请输入有效的电话号码。",
        phoneNumberPlaceholder: "电话号码",
        phoneNumberPrompt: "添加文字提醒",
        photoTake: "拍张照片",
        photoLibrary: "从图片库选择",
        photoDelete: "删除照片",
        photoPermissionMissingTitle: "已禁用照片",
        photoPermissionMissing: "转到“设置”启用 GridRewards 的摄像头和图片库权限",
        powerGrid: "电网",
        powerInYourHands: "能源最终掌握在你手中。",
        profileFirstNameMissing: "请输入你的名字。",
        profileLastNameMissing: "请输入你的姓氏。",
        profilePhoneNumberDescription: "获得 GridRewards 活动的短信通知。每年通常有 5-10 个活动。\n\n可能存在短信和数据流量费用。短信频率可能发生变化。回复 HELP 寻求帮助，回复 STOP 取消订阅。",
        profileRateReview: "评分和评论",
        profileSignOut: "退出",
        profileUpdateGenericError: "糟糕！你的个人资料更新未保存。",
        recent: "最近",
        referral: {
            codeCopied: "已复制引荐代码！",
            copyYourCode: "复制代码",
            description: "让好友在注册时使用你的引荐代码。当好友在 GridRewards 赛季节电时，你们都将获得 10 美元的额外奖金！\n\n引荐无上限。",
            downloadHere: "在此处下载 GridRewards：",
            emailSubject: "节电赚现金！",
            literalPlural: "被引荐者",
            literalSingular: "被引荐者",
            prompt: "引荐好友",
            screenTitle: "被引荐者",
            shareMessage: "拔掉电源，赚取收益。在关键时段通过 GridRewards 节电赚现金！\n\n创建帐户时使用代码 {{code}}（或使用此引荐链接自动填充代码）即可获得 10 美元奖励金！",
            shareYourCode: "分享引荐代码",
            title: "捐出 10 美元，得到 10 美元",
            errorCreatingLink: "创建分享链接时出错，请重试。"
        },
        regionElectricitySourcesTitle: "{{regionName}}的电力来源",
        resetPasswordCompletePlaceholder: "电子邮件",
        resetPasswordCompleteSubtitleAfter: "获取密码重置链接。",
        resetPasswordCompleteSubtitleBefore: "查看",
        resetPasswordCompleteTitle: "已发送密码重置链接！",
        resetPasswordTitle: "重置密码",
        reviewPromptRate: "真棒！",
        reviewPromptRemind: "以后再提醒我",
        reviewPromptTitle: "你感觉 GridRewards 怎么样？",
        saveImage: "保存图片",
        savedDuringEvents: "于 GridRewards 活动期间保存",
        search: "搜索",
        seePaymentPreferences: "查看付款偏好设置",
        seeTerms: "查看条款",
        seeYourAchievements: "查看成就",
        seeYourCarbonFootprint: "查看碳足迹",
        seeYourEnergyUse: "查看能源使用情况",
        send: "发送",
        sendFeedback: "发送反馈",
        serviceAddressMissing: "我们需要一个实际地址来给你邮寄支票。",
        setNickname: "设置昵称",
        setNicknameDescription: "向此位置添加更好记的昵称。",
        settings: "设置",
        setYourPaymentPref: "设置付款偏好",
        share: "分享",
        shareAndTag: "分享并提及 @getgridrewards，成为特别精选！",
        signIn: "登录",
        signInCreatePrompt: "新用户？创建帐户",
        signInDescription: "使用 GridRewards 帐户登录，这不同于公用事业帐号登录。",
        skip: "跳过",
        special: "特殊",
        sustainableWestchesterDescription: "Sustainable Westchester 是威彻斯特郡地方政府的一个非营利性财团，旨在促进可持续发展计划的有效合作。他们的目标是提供对社会负责、对环境友好且经济上可行的解决方案，创建健康、有弹性、可持续的社区。",
        sustainableWestchesterLinkName: "sustainablewestchester.org",
        sustainableWestchesterTitle: "Sustainable Westchester",
        tbd: "待定",
        thatsTheSameAs: "这等同于…",
        theme: {
            dark: "深色",
            light: "浅色",
            system: "系统",
            title: "主题"
        },
        thermostat: {
            mode: {
                off: "关",
                cool: "制冷",
                heat: "加热",
                both: "均有"
            }
        },
        thermostatConnectPrompt: "有 Ecobee 智能温控器？你可以从 GridRewards 应用进行控制。",
        thermostatConnectButtonTitle: "关联 Ecobee",
        thisBillingCycle: "此结算周期",
        thisSeason: "本赛季",
        timeRangeFormatGeneral: "从 {{startTime}} 到 {{endTime}}",
        timeAt: "在 {{time}}",
        timeZoneInfo: "此地产的所有时间都位于 {{name}}",
        tip: "小贴士",
        today: "今天",
        tomorrow: "明天",
        university: {
            courseEvents: {
                mostMoney: {
                    body: "在 GridRewards 活动时间内，将用电减少至少三分之一。如果你在特定夏季的所有活动中都能做到，你将获得最高金额。",
                    title: "我怎样才能赚取最高金额的收益？"
                },
                participate: {
                    body: "在活动期间尽可能减少用电，节电将通过电表自动测量。\n\n在活动开始前 4 小时预冷你的空间，然后遵从我们的节能行动，将能源使用转移到活动时间之外。",
                    title: "我如何参加活动？",
                    tipSave: "尝试在每次 GridRewards 活动期间节省至少三分之一的用电，赚取最高金额。"
                },
                performance: {
                    body: "节电将被自动跟踪，并与你在此期间的通常用电进行比较。尝试在每次 GridRewards 活动期间节省至少三分之一的通常用电量，赚取最高金额的收益。",
                    title: "我的表现是被如何衡量的？"
                },
                title: "GridRewards 活动",
                whatIsEvent: {
                    body: "GridRewards 活动长 3-4 小时，你可以通过节电赚取收益。活动通常于几个炎热的夏日午后开展，每年大约 5-10 次。我们会提前通知，让你能做好准备。",
                    title: "什么是 GridRewards 活动？"
                },
                whyImportant: {
                    body: "活动发生在电网压力最大的时候，公用事业被迫启动高污染的石油和天然气驱动的“尖峰负载”工厂来满足额外的需求。活动可以减轻电网的压力，有助于预防停电和断电，并阻止这些污染严重的工厂启动。\n\nGridRewards 活动是如此重要，以至于你的公用事业公司愿意在这些时候向你支付真金白银来减少污染。",
                    title: "GridRewards 活动为什么重要？"
                }
            },
            courseMultiplyImpact: {
                neighborhoodPride: {
                    body: "你的邻居都会对电网负载做出贡献。加入街区的其他 GridRewards 用户，合作获得额外奖励，与其他街区竞争，看看我们可以通过合作带来多大的影响。",
                    title: "街区荣耀"
                },
                referral: {
                    body: "如果其他人在你的邀请下注册了 GridRewards，并且在下一个 GridRewards 赛季省了足够的电来赚取收益，那么此人和你都将获得 10 美元的奖励金，你的积极影响也会成倍增加。邀请无上限。",
                    title: "引荐好友"
                },
                shiftThePower: {
                    body: "你和社区前所未有地可以控制能源使用情况和碳足迹。在几个关键时间停止用电，就可以让我们所有人使用的电力更清洁（你还将为此赚取现金）。",
                    title: "你是运动的一部分。"
                },
                title: "倍增影响"
            },
            courseOther: {
                carbonFootprint: {
                    body: "并非所有电力都是平等的。查看“碳”标签页，了解何时使用电力碳足迹最小。",
                    title: "减少碳足迹"
                },
                checkYourself: {
                    body: "在“能源”标签页中密切关注用电量和预计每月帐单。",
                    title: "查看"
                },
                title: "我还能用这个应用做什么？"
            },
            coursePowerGrid: {
                peakerPlants: {
                    body: "电网过载时，电力公司依靠额外的化石燃料发电厂来实现快速缓解。运作这些可用的尖峰负载电厂不仅对电力公司来说较为昂贵，而且这笔费用也会转嫁给你。\n\n此外，这些工厂更老旧更低效，依赖高污染的燃料，并且碳排放量是原来的两倍。",
                    title: "什么是尖峰负载电厂？"
                },
                timeWindow: {
                    body: "通过研究历史使用情况并考虑天气数据等其他信息，电力公司可以预测用电高峰时间以及所需电量。这些峰值相当大，大约是正常用电量的 200%。只要峰值能稍微降低，全年成本都会随之减少。",
                    title: "为什么减少用电量是有意义的？"
                },
                title: "电力和电网",
                unplugging: {
                    body: "拔掉手机电源或改变烘干衣服的时间虽然单看起来微不足道，但这些细节累积起来就会带来巨大改变。在 GridRewards 活动期间，减少用电量对整个电网的碳足迹都会产生格外显著的影响。压平高峰需求意味着尖峰负载电厂不会投入使用，因此，这既有利于空气，也有利于你的钱包。",
                    title: "拔掉电源真的有用吗？"
                },
                whatIsTheGrid: {
                    body: "“电网”是提供电力的电厂的组合。不同的位置使用不同的电力来源组合，有些是干净的，有些则不太干净。",
                    title: "什么是“电网”？"
                }
            },
            courseTitle: "第 {{index}} 课，共 {{total}} 课"
        },
        universityTitle: "GridRewards 大学",
        unknown: "未知",
        unlikely: "不太可能",
        usageThisWeek: "本周用量",
        valuePropEventsBody: "炎热的夏日午后通常是最有价值的节电时间。\n\n虽然全年节电是件好事，但 GridRewards 活动非常重要，公用事业公司愿意付钱让你在高峰时段减少用电。\n\n为什么？当公用事业公司需要满足你所在地区的峰值电力需求时，他们会启动效率较低的旧电厂，这些电厂需要更多资金来维护，每单位电力还会向空气中释放更多的碳。",
        veryBad: "非常差",
        veryHigh: "非常高",
        veryLikely: "很可能",
        virtualPowerPlant: "虚拟电厂",
        weActDescription: "WE ACT 的使命是建立充满活力的社区，确保有色人种和/或低收入居民积极参与制定健全和公平的环境保护政策和实践。",
        weActLinkName: "weact.org",
        weActTitle: "WE ACT",
        welcome: "欢迎",
        welcomeBack: "欢迎回来",
        welcomeToGridRewards: "欢迎使用 GridRewards",
        westchesterCounty: "威彻斯特郡",
        yesterday: "昨天",
        yesterdayVsProjection: "昨天对比预计",
        yourAddress: "地址",
        yourCarbonFootprint: "碳足迹",
        yourEarningsShort: "{{timePeriod}} 收益",
        yourNeighborhood: "街区",
        yourPerformance: "表现",
        yourProjectedBill: "预计帐单",
        zipCode: "邮政编码",
        zipCodeExplanation: "确认你位于受支持的区域",
        zipCodeInvalid: "请输入有效的邮政编码。"
    }
};