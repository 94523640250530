import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, View, TouchableOpacity, ScrollView } from "react-native"
import { Achievements, ScreenNames } from "../../shared/AppConstants"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import Button from "../Button"
import CardView from "./CardView"
import { prop, path } from "ramda"
import ScreenSummaryCard from "./ScreenSummaryCard"
import i18n from "../../shared/i18n"
import { activeOpacity } from "../../shared/Layout"
import { useDispatch, useSelector } from "react-redux"

export default function AchievementsSummaryCard(props) {
    const { numVisible = 3, onButtonPress } = props
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)

    // // FIXME: TEST ONLY
    // const mockedAchievements = require("../../sample/sampleAchievements.json")

    const achievementsData = useSelector((state) => state.primaryData.achievementsData) || []
    // console.log("SUMMARY: Real achievements data is " + JSON.stringify(achievementsData))

    const flatConfigs = Achievements.flatMap((section) => prop("data", section))
    // Get up to (numVisible - 1) completed achievements, and fill out the rest up to numVisible with un-completed achievements.
    // FIXME: This is massively inefficient, although it's only going to be searching a small number of records.
    let visibleItems =
        flatConfigs
            .filter((possibleConfig) => {
                // Do we have a matching item in the server response?
                return achievementsData.some((possibleItem) => path(["achievement", "type"], possibleItem) == prop("type", possibleConfig))
            })
            .slice(0, numVisible - 1) || []

    visibleItems = [...visibleItems, ...flatConfigs.filter((possibleItem) => !visibleItems.includes(possibleItem)).slice(0, numVisible - visibleItems.length)]

    const iconSize = 118 // The actual icon includes extra space for the on-state's glow
    const iconLayoutSize = 100

    return (
        <View style={{ overflow: "visible" }}>
            <TouchableOpacity
                style={{ flexDirection: "row", paddingBottom: 16, marginHorizontal: 16, justifyContent: "flex-start", alignItems: "center" }}
                onPress={() => {
                    navigation.navigate(ScreenNames.HomeTab.routeName, { screen: ScreenNames.Achievements.routeName })
                }}
            >
                <Text style={[TextStyles.body2Semi, { flexShrink: 1, color: theme.textSecondary }]}>{i18n.t("achievementsTitle")}</Text>
                {/* <Image source={theme.icons.infoCircle16} style={[{ width: 16, height: 16, marginLeft: 8, opacity: 0.7 }]} /> */}
            </TouchableOpacity>
            <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                style={{ overflow: "visible" }}
                contentContainerStyle={{ paddingBottom: 24, paddingLeft: 16 }}
            >
                {visibleItems != null &&
                    visibleItems.map((item, index) => {
                        const matchingLiveData = achievementsData.find((possibleItem) => path(["achievement", "type"], possibleItem) == prop("type", item))
                        const isOn = matchingLiveData != null
                        return (
                            <TouchableOpacity
                                activeOpacity={isOn ? activeOpacity : 1.0}
                                key={prop("type", item) + index.toString()}
                                style={{
                                    width: iconLayoutSize,
                                    height: iconLayoutSize,
                                    marginRight: 16,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    overflow: "visible",
                                }}
                                onPress={() => {
                                    navigation.navigate(ScreenNames.HomeTab.routeName, { screen: ScreenNames.Achievements.routeName })
                                }}
                            >
                                <Image
                                    source={theme.icons.achievements.small[prop("icon", item) + (isOn ? "" : "Off")]}
                                    style={{
                                        width: iconSize,
                                        height: iconSize,
                                        overflow: "visible",
                                    }}
                                />
                            </TouchableOpacity>
                        )
                    })}
            </ScrollView>
        </View>
    )
}
