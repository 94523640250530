import AsyncStorage from "@react-native-async-storage/async-storage"
import { useIsFocused, useNavigation, useRoute, useScrollToTop } from "@react-navigation/native"
import * as shape from "d3-shape"
import * as Device from "expo-device"
import * as Analytics from "expo-firebase-analytics"
import * as Linking from "expo-linking"
import * as StoreReview from "expo-store-review"
import moment from "moment-timezone"
import { path, prop } from "ramda"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { ActivityIndicator, Alert, AppState, Platform, RefreshControl, ScrollView, View, Text } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { LineChart } from "react-native-svg-charts"
import { useDispatch, useSelector, useStore } from "react-redux"
import Button from "../components/Button"
import AchievementsSummaryCard from "../components/cards/AchievementsSummaryCard"
import ContactUsCard from "../components/cards/ContactUsCard"
import CriticalWarningCard from "../components/cards/CriticalWarningCard"
import EventBannerCard from "../components/cards/EventBannerCard"
import InitialDataFetchCard from "../components/cards/InitialDataFetchCard"
import LessonCard from "../components/cards/LessonCard"
import OnboardingCard from "../components/cards/OnboardingCard"
import ScreenSummaryCard from "../components/cards/ScreenSummaryCard"
import SplashCard from "../components/cards/SplashCard"
import SummaryRow from "../components/SummaryRow"
import TimeZonePill from "../components/TimeZonePill"
import { verifyAddress } from "../model/directAPICalls"
import {
    fetchAccountsData,
    fetchDataForRoute,
    fetchNeighborhoodEventPerformance,
    fetchNeighborhoodMessages,
    fetchParticpants,
    loadAsyncStorage,
    sendExpoToken,
    setNewAccountCreationPending,
    updatePaymentAddress,
} from "../model/primaryDataActions"
import AppConstants, { CarbonThresholdManual, DataMeasurement, DataSource, ScreenNames } from "../shared/AppConstants"
import { colorForPerformanceFactor } from "../shared/Colors"
import { CommonStyles } from "../shared/CommonStyles"
import { auth } from "../shared/firebase.js"
import i18n from "../shared/i18n"
import { TextStyles } from "../shared/TextStyles"
import { rawThemes, ThemeContext } from "../shared/ThemeContext"
import {
    currentAndFutureEventIds,
    eventsHappeningNow,
    formattedAddress,
    formatWithUnits,
    gridCarbonLevelForValue,
    isDataProcessing,
    isEmpty,
    mostRecentEvent,
    openSetup,
    pastEventStreakLength,
    pastEventTimeFormatted,
    sendBranchEvent,
    showToast,
    upcomingEvents,
} from "../shared/Utils"

export default function HomeScreen() {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const isFocused = useIsFocused()
    const store = useStore()
    const { theme } = useContext(ThemeContext)
    const route = useRoute()

    // Track our current app state so we can refresh data, etc.
    const appState = useRef(AppState.currentState)
    const [initialDataRequested, setInitialDataRequested] = useState(false)

    const mainData = useSelector((state) => state.primaryData.mainData)
    const accountsList = useSelector((state) => state.primaryData.accountsData)
    const [needsInitialAccountsFetch, setNeedsInitialAccountsFetch] = useState(false)
    const { ami = {}, gridRewards = {} } = prop("nextSteps", mainData) || {}
    const amiComplete = useSelector((state) => state.primaryData.amiComplete)
    const { enrolled = false } = prop("enrollmentStatus", mainData) || {}
    const enrollmentComplete = useSelector((state) => state.primaryData.enrollmentComplete)
    const suppressEducationSplashCard = useSelector((state) => state.primaryData.suppressEducationSplashCard)

    const events = prop("gridRewardsEvents", mainData) || []
    const eventParticipants = useSelector((state) => state.primaryData.eventParticipantData)

    const gridRewardsHistoryData = useSelector((state) => state.primaryData.gridRewardsHistoryData) || []

    // const paymentsData = useSelector((state) => state.primaryData.paymentsData)
    // console.log("Estimated payments: "+JSON.stringify(estimatedPayments))

    // const mockedPayments = require("../shared/samplePayments.json")

    const neighborhoodData = useSelector((state) => state.primaryData.neighborhoodData) || []
    const activeNeighborhoodData = neighborhoodData.length > 0 ? neighborhoodData[0] : {}
    const neighborhoodMessagesData = useSelector((state) => state.primaryData.neighborhoodMessagesData) || []
    const neighborhoodEventPerformanceData = useSelector((state) => state.primaryData.neighborhoodEventPerformanceData) || []

    const newAccountCreationPending = useSelector((state) => state.primaryData.newAccountCreationPending)

    // When can we show them the payment-address confirmation prompt?
    const profileData = useSelector((state) => state.primaryData.profileData)
    const { addressPromptStartDate } = profileData || {}

    const paymentAddressData = useSelector((state) => state.primaryData.paymentAddressData) || {}
    // console.log("Payment address data is " + JSON.stringify(paymentAddressData))
    const [paymentAddressLastConfirmDate, setPaymentAddressLastConfirmDate] = useState(null)
    const [addressValidationPending, setAddressValidationPending] = useState(false)

    // // Do we have a launch action?
    // const { initialAction } = path(["route", "params"], props) || {}
    // console.log("route parses out as "+JSON.stringify(path(["route"], props)))
    const [refreshing, setRefreshing] = useState(false)

    // Allow scrolling to top when tapping our tab bar icon
    const scrollRef = React.useRef(null)
    useScrollToTop(scrollRef)

    // Initial setup
    useEffect(() => {
        const appStateChangeListener = AppState.addEventListener("change", _handleAppStateChange)

        // Listen for authentication state to change.
        const authListenerUnsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user != null) {
                // We only want to do this fetch once.
                if (!initialDataRequested) {
                    console.log("Home screen auth state changed in a way we care about")
                    setInitialDataRequested(true)
                }
            }
        })

        const focusUnsub = navigation.addListener("focus", () => {
            console.log("Home screen is focused")

            // When was the last time we confirmed an address?
            AsyncStorage.getItem(AppConstants.paymentAddressLastConfirmDate).then((isoDateString) => {
                setPaymentAddressLastConfirmDate(isoDateString)
            })
        })

        const blurUnsub = navigation.addListener("blur", () => {
            // Try to play the video when we're focused.
            console.log("Home screen is blurred")
        })

        // Increment our foreground count.
        incrementForegroundCount()

        // AsyncStorage.getItem(AppConstants.expoPushToken).then((token) => {
        //     console.log("Expo token is " + token)
        // })

        // Register for push. Do this after a delay to make sure we're not blocking any other network calls.
        // NOTE: There's a bug in Expo's notification setup currently that blows up the dev app on reload.
        // https://github.com/expo/expo/issues/15788

        const IOS_NOTIFICATION_ISSUE = Platform.OS === "ios" && __DEV__
        if (!IOS_NOTIFICATION_ISSUE) {
            setTimeout(() => {
                registerForPushNotificationsAsync()
            }, 5000)
        }

        // TEST ONLY: Address verification
        // verifyAddress("263 Eastern pkwy", null, "brooklyn", "ny", null, null, (data) => {
        //     console.log("Home screen thinks verified address data is " + JSON.stringify(data))
        // })
        // navigation.navigate(ScreenNames.AddressVerification.routeName)
        // navigation.navigate(ScreenNames.EnrollmentComplete.routeName)

        return () => {
            if (appStateChangeListener != null) {
                appStateChangeListener.remove()
            }
            if (authListenerUnsubscribe != null) {
                authListenerUnsubscribe()
            }
            focusUnsub()
            blurUnsub()
        }
    }, [])

    useEffect(() => {
        console.log("Initial data request changed to " + initialDataRequested)
        if (initialDataRequested == true) {
            // Do our initial data fetch.

            //If we've just created a new account, wait a few seconds and run the fetch twice just in case.
            if (newAccountCreationPending == true) {
                console.log("Fetching delayed info because we created a new account")
                setTimeout(() => {
                    dispatch(setNewAccountCreationPending(false))
                    dispatch(fetchDataForRoute(ScreenNames.Home.routeName))

                    // Add a second data fetch for good measure just in case the first one fails.
                    setTimeout(() => {
                        dispatch(fetchDataForRoute(ScreenNames.Home.routeName))
                    }, 2500)
                }, 2500)
            } else {
                // In this case, we're loading an existing account. If we don't have any utility accounts yet, try to fetch them first.
                AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((currentAccount) => {
                    if (currentAccount == null) {
                        // Go get accounts first.
                        console.log("Fetching accounts list since we don't have anything stored locally.")
                        setNeedsInitialAccountsFetch(true)
                    } else {
                        // We've got a connected account, just fetch.
                        // console.log("Fetching normal cold-start data with no delay.")
                        // dispatch(fetchDataForRoute(ScreenNames.Home.routeName))
                    }
                })
            }
        }
    }, [initialDataRequested])

    useEffect(() => {
        if (needsInitialAccountsFetch) {
            dispatch(fetchAccountsData())
        }
    }, [needsInitialAccountsFetch])

    useEffect(() => {
        if (needsInitialAccountsFetch) {
            setNeedsInitialAccountsFetch(false)
            // Regardless of whether there are accounts here, we need to go get our main data set now.
            // The main data has things like links to connect their account, etc.
            console.log("Got a new accounts list, fetching new home data.")
            dispatch(fetchDataForRoute(ScreenNames.Home.routeName))
        }
    }, [accountsList])

    // Show the enrollment-complete modal if this is the first time we've completed it.
    useEffect(() => {
        if (enrollmentComplete != "true" && enrolled) {
            // Completed enrollment for the first time
            Analytics.logEvent("enrollmentCompleted")

            console.log("Home screen thinks we completed enrollment just now.")

            // Tell Branch
            sendBranchEvent(AppConstants.analytics.branchEvents.enrollmentComplete, null, null)

            AsyncStorage.setItem(AppConstants.enrollmentComplete, "true").then(() => {
                // If we update the enrollmentComplete variable in AsyncStorage, reload async storage into Redux
                loadAsyncStorage(store)
            })

            setTimeout(() => {
                navigation.navigate(ScreenNames.EnrollmentComplete.routeName)
            }, 2000)
        }
    }, [enrolled])

    useEffect(() => {
        if (amiComplete != "true" && prop("status", ami) == true) {
            // Completed AMI for the first time
            Analytics.logEvent("amiCompleted")

            // Tell Branch
            sendBranchEvent(AppConstants.analytics.branchEvents.amiAuthorized, null, null)

            console.log("Home screen thinks we completed AMI just now.")

            AsyncStorage.setItem(AppConstants.amiNextStepComplete, "true").then(() => {
                // If we update the amiNextStepComplete variable in AsyncStorage, reload async storage into Redux
                loadAsyncStorage(store)
            })
        }
    }, [ami])

    useEffect(() => {
        setRefreshing(false)
    }, [mainData])

    // Whenever we change our list of events, go get participants.
    useEffect(() => {
        if (isFocused && amiComplete == "true" && auth.currentUser != null && mainData != null && events != null && events.length > 0) {
            console.log("Events list changed, getting participants")
            dispatch(fetchParticpants(currentAndFutureEventIds(events)))
        }
    }, [events])

    // Whenever we get new neighborhood data, we need to refresh our list of neighborhood messages
    useEffect(() => {
        if (amiComplete == "true" && auth.currentUser != null && mainData != null) {
            const neighborhoodId = prop("id", activeNeighborhoodData)
            if (neighborhoodId != null) {
                console.log("Neighborhood data changed, getting neighborhood messages and event performance")
                dispatch(fetchNeighborhoodMessages(neighborhoodId))
                dispatch(fetchNeighborhoodEventPerformance(neighborhoodId))
            }
        }
    }, [activeNeighborhoodData])

    const registerForPushNotificationsAsync = async () => {
        // Expo doesn't support push notifications on web, despite some half-baked error messages in code. Skip them if we're on the web.
        if (Platform.OS == "web") {
            return
        }

        const Notifications = require("expo-notifications")

        const { status: existingStatus } = await Notifications.getPermissionsAsync()
        let finalStatus = existingStatus
        if (existingStatus !== "granted") {
            const { status } = await Notifications.getPermissionsAsync()
            finalStatus = status
        }
        if (finalStatus !== "granted" && finalStatus !== "undetermined") {
            console.log("Failed to get push token for push notification! Final status was " + finalStatus)

            // We need to fail silently.
            return
        }
        if (Device.isDevice) {
            const experienceId = AppConstants.expoPushNotificationExperienceId
            let token = (
                await Notifications.getExpoPushTokenAsync({
                    experienceId,
                })
            ).data
            // console.log("Push token is " + token)
            await AsyncStorage.setItem(AppConstants.expoPushToken, token)

            // Send the token to the API
            dispatch(sendExpoToken(token))
        } else {
            // We're on a simulator, which can't do push.
            console.log("Skipping push token checks because we're on a simulator")
        }

        // If we already have permission, no need to take them to the prompt screen.
        await AsyncStorage.setItem(AppConstants.onboardingComplete, "true")

        if (Platform.OS === "android") {
            Notifications.setNotificationChannelAsync("default", {
                name: "default",
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: "#FF231F7C",
            })
        }

        // Reload this state into Redux. This will force a re-render and move us to the Home screen.
        loadAsyncStorage(store)
    }

    const _handleAppStateChange = (nextAppState) => {
        if (appState.current.match(/inactive|background/) && nextAppState === "active") {
            // console.log("Home screen has come to the foreground, refreshing data!")
            // Refresh our data.
            // setHeaderSpinnerVisible(true)
            // dispatch(fetchDataForRoute(ScreenNames.Home.routeName))

            // Only increment our foreground count if we've completed showing the value prop screen.
            AsyncStorage.getItem(AppConstants.valuePropositionPromptComplete).then((valuePropPromptComplete) => {
                if (valuePropPromptComplete == "true") {
                    incrementForegroundCount()
                }
            })
        }

        appState.current = nextAppState
    }

    async function incrementForegroundCount() {
        // Update our foreground count
        let oldCount = JSON.parse(await AsyncStorage.getItem(AppConstants.foregroundCount)) || 0
        await AsyncStorage.setItem(AppConstants.foregroundCount, JSON.stringify(oldCount + 1))

        // If we're authorized for GridRewards, update the count of how many times we've foregrounded since then.
        const { gridRewards = {} } = prop("nextSteps", mainData) || {}
        if (gridRewards.status == true) {
            oldCount = JSON.parse(await AsyncStorage.getItem(AppConstants.foregroundCountAfterGridRewardsAuth)) || 0
            console.log("Incrementing post-GR foreground count from " + oldCount + " to " + (oldCount + 1))
            await AsyncStorage.setItem(AppConstants.foregroundCountAfterGridRewardsAuth, JSON.stringify(oldCount + 1))

            // Any decisions based on the new foreground count go here.
        }

        // We may need to show a review prompt here.
        showReviewPromptIfNecessary()
    }

    async function showReviewPromptIfNecessary() {
        // Check our foreground count, and try to show the prompt if necessary.
        let foregroundCount = JSON.parse(await AsyncStorage.getItem(AppConstants.foregroundCount)) || 0
        let promptComplete = await AsyncStorage.getItem(AppConstants.ratingsPromptComplete)

        if (promptComplete != "true" && [4, 9, 15].includes(foregroundCount)) {
            console.log("Found a matching foreground count, trying to show review prompt.")
            // Show the prompt after a delay.
            setTimeout(() => {
                Alert.alert(
                    i18n.t("reviewPromptTitle"),
                    null,
                    [
                        {
                            text: i18n.t("reviewPromptRate"),
                            onPress: () => {
                                Analytics.logEvent("ratingsPromptRateTapped")
                                AsyncStorage.setItem(AppConstants.ratingsPromptComplete, "true")
                                StoreReview.requestReview()
                            },
                        },
                        {
                            text: i18n.t("sendFeedback"),
                            onPress: () => {
                                Analytics.logEvent("ratingsPromptSendFeedbackTapped")
                                AsyncStorage.setItem(AppConstants.ratingsPromptComplete, "true")
                                Linking.openURL("mailto:gridrewards@logicalbuildings.com?subject=GridRewards%20app%20feedback")
                            },
                            style: "default",
                        },
                        {
                            text: i18n.t("reviewPromptRemind"),
                            onPress: () => {
                                Analytics.logEvent("ratingsPromptRemindLaterTapped")
                            },
                            style: "default",
                        },
                    ],
                    { cancelable: false }
                )
            }, 4000)
        }
    }

    const onRefresh = useCallback(() => {
        setRefreshing(true)
        console.log("Refreshing home screen from refresh callback")
        dispatch(fetchDataForRoute(ScreenNames.Home.routeName))
    }, [])

    const showDataProcessingFlag = isDataProcessing(mainData)

    // Events summary
    const streakLength = pastEventStreakLength(gridRewardsHistoryData)
    let summaryEvent = mostRecentEvent(gridRewardsHistoryData, true)
    let summaryEventTime = pastEventTimeFormatted(summaryEvent, true)
    let summaryPerformanceRaw = Math.min(1.0, Math.max(0, prop("performanceFactor", summaryEvent) || 0))
    let summaryPercentage = (summaryPerformanceRaw * 100).toFixed(0)
    let summaryNeighborhoodEvent = neighborhoodEventPerformanceData.find(
        (possibleEvent) => path(["event", "id"], possibleEvent) == prop("eventId", summaryEvent)
    )
    let summaryNeighborhoodPercentageRaw = Math.min(1.0, Math.max(0, prop("performance_factor", summaryNeighborhoodEvent) || 0))
    let summaryNeighborhoodPercentage = (summaryNeighborhoodPercentageRaw * 100).toFixed(0)

    // Electricity summary
    const electricityShortHistoryDataRaw = useSelector((state) => state.primaryData.electricityShortHistoryData) || {}
    const electricityShortHistoryData = prop("data", electricityShortHistoryDataRaw)
    // console.log("electricity short history is " + JSON.stringify(electricityShortHistoryData))
    const { thisBill = 0 } = prop("billEstimateDataElectric", mainData) || {}
    const electricityScorecardData = prop(
        "electricityScorecardData",
        useSelector((state) => state.primaryData.scorecardsData)
    )
    var formattedYesterdayComparison = ""
    let yesterdayData = prop("yesterday", electricityScorecardData) || {}
    let yesterdayComparison = prop("smartEstimate", yesterdayData) || 0
    if (yesterdayComparison > 0) {
        formattedYesterdayComparison = i18n.t("percentMore", { value: yesterdayComparison })
    } else if (yesterdayComparison < 0) {
        formattedYesterdayComparison = i18n.t("percentLess", { value: Math.abs(yesterdayComparison) })
    } else {
        formattedYesterdayComparison = i18n.t("aboutTheSame")
    }

    // Carbon summary
    const carbonIntensityData = useSelector((state) => state.primaryData.carbonIntensityData)
    const historySlices = prop("actual", carbonIntensityData) || []
    const forecastSlices = prop("forecast", carbonIntensityData) || []
    const thresholds = prop("thresholds", carbonIntensityData) || []
    const nextGoodThreshold = thresholds.length >= 3 ? thresholds[1] : CarbonThresholdManual.okay
    const nowSlice = historySlices.find((element) => {
        let rawTimestamp = prop("timestamp", element)
        if (rawTimestamp != null) {
            let timestamp = moment(rawTimestamp)
            // If an hour from this slice (which will be on an hour boundary) is after now, it represents the current hour.
            return timestamp.add(1, "hours").isSameOrAfter(moment())
        }
    })
    const currentCarbonIntensity = prop("value", nowSlice)
    let currentCarbonString = gridCarbonLevelForValue(currentCarbonIntensity, thresholds)
    let currentCarbonColor = thresholds.length > 2 && currentCarbonIntensity > thresholds[2] ? theme.red : theme.blue

    const nextGoodSlice = [nowSlice, ...forecastSlices].find((element) => {
        let carbonIntensity = prop("carbonIntensity", element)
        return carbonIntensity != null && carbonIntensity < nextGoodThreshold
    })

    const nextGoodString =
        nextGoodSlice == null ? i18n.t("noneInNext24Hours") : nowSlice == nextGoodSlice ? i18n.t("now") : moment(prop("timestamp", nextGoodSlice)).format("ha")
    const carbonData = prop("carbonData", mainData)

    if (amiComplete != "true") {
        if (mainData == null || needsInitialAccountsFetch) {
            // We don't know yet whether the user is actually enrolled, because our initial data fetch hasn't completed.
            return (
                <View
                    style={{
                        flex: 1,
                        backgroundColor: theme.background,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ActivityIndicator
                        size={"large"}
                        animating={true}
                        color={theme.textDisabled}
                        // style={{ position: "absolute", bottom: 0, right: 0 }}
                    />
                </View>
            )
        } else {
            // If we're not enrolled yet, just show the correct onboarding card.
            return (
                <ScrollView
                    style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background, paddingTop: 24 }]}
                    contentContainerStyle={CommonStyles.mainScreenContent}
                    refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={theme.textHint} colors={[theme.textHint]} />}
                    ref={scrollRef}
                >
                    <OnboardingCard onboardingRoute={route.name} />
                </ScrollView>
            )
        }
    }

    return (
        <KeyboardAwareScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
            contentContainerStyle={CommonStyles.mainScreenContent}
            refreshControl={
                Platform.OS != "web" && <RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={theme.textHint} colors={[theme.textHint]} />
            }
            ref={scrollRef}
            extraScrollHeight={150}
            keyboardShouldPersistTaps="always"
        >
            {/* If their account exists and is stale, warn the user. */}
            {prop("isAccountStale", mainData) == true && (
                <CriticalWarningCard title={i18n.t("accountStale")}>
                    <Button
                        containerStyle={{ marginBottom: 16 }}
                        title={i18n.t("connectNewAccount")}
                        buttonTintColor={rawThemes.dark.surface1}
                        contentTintColor={rawThemes.dark.textPrimary}
                        onPress={() => navigation.navigate(ScreenNames.AccountConnectionPrompt.routeName)}
                    />
                    <Button
                        type={"outline"}
                        title={i18n.t("getInTouch")}
                        buttonTintColor={rawThemes.light.border}
                        contentTintColor={rawThemes.dark.textPrimary}
                        onPress={() => {
                            Linking.openURL(`mailto:${AppConstants.logicalBuildingsSupportEmail}?subject=GridRewards%20account%20data%20question`)
                        }}
                    />
                </CriticalWarningCard>
            )}

            {!enrollmentComplete && (
                <CriticalWarningCard title={i18n.t("amiOnlyWarning")}>
                    <Button
                        title={i18n.t("completeAccountSetup")}
                        buttonTintColor={rawThemes.dark.surface1}
                        contentTintColor={rawThemes.dark.textPrimary}
                        onPress={() => openSetup(prop("link", gridRewards))}
                    />
                </CriticalWarningCard>
            )}

            {/* If the user hasn't confirmed their mailing address, and we need them to, show this. */}
            {/* LOGIC: we have a local date stored in AsyncStorage for the last time they confirmed an address.
             * If that date is after the "show the prompt" date from the server, then they've confirmed, and we don't show this prompt.
             * Otherwise, we show the prompt. */}
            {(addressPromptStartDate == null || paymentAddressLastConfirmDate <= addressPromptStartDate) && (
                <CriticalWarningCard title={i18n.t("mailingAddress.prompt.title")} icon={rawThemes.dark.icons.moneyEnvelope}>
                    <View style={{ marginBottom: 16, borderRadius: 8, borderWidth: 1, borderColor: rawThemes.dark.border, padding: 16 }}>
                        <Text style={[TextStyles.body2, { color: theme.white }]}>{formattedAddress(prop("address", paymentAddressData))}</Text>
                    </View>
                    {!isEmpty(prop("address", paymentAddressData)) && (
                        <Button
                            title={i18n.t("mailingAddress.prompt.buttonConfirm")}
                            buttonTintColor={rawThemes.dark.surface1}
                            contentTintColor={rawThemes.dark.textPrimary}
                            containerStyle={{ marginBottom: 8 }}
                            showSpinner={addressValidationPending}
                            spinnerTintColor={rawThemes.dark.textPrimary}
                            onPress={() => {
                                console.log("Address confirm button pressed")
                                if (prop("isValidated", paymentAddressData) == true) {
                                    console.log("In the validated + confirm case")
                                    // This is already a validated address, and we got it from the server, so just update our last confirm date.
                                    const lastConfirmDate = moment().toISOString()
                                    AsyncStorage.setItem(AppConstants.paymentAddressLastConfirmDate, lastConfirmDate)
                                    // Update our local state to match. This should hide this card.
                                    setPaymentAddressLastConfirmDate(lastConfirmDate)
                                    // Tell the user
                                    showToast(i18n.t("mailingAddress.confirmation.confirmed"), theme.icons.checkmark32)
                                } else {
                                    // We need to try to validate this address.
                                    setAddressValidationPending(true)
                                    const originalAddress = prop("address", paymentAddressData)
                                    const { line1, line2, city, provinceOrState, postalOrZip } = originalAddress || {}
                                    verifyAddress(
                                        line1,
                                        line2,
                                        city,
                                        provinceOrState,
                                        postalOrZip,
                                        null,
                                        (data) => {
                                            console.log("Prompt card thinks verified address data is " + JSON.stringify(data))
                                            setAddressValidationPending(false)

                                            switch (prop("status", data)) {
                                                case "verified":
                                                    dispatch(updatePaymentAddress(data, prop("id", paymentAddressData)))
                                                    showToast(i18n.t("mailingAddress.prefs.updateSuccessful"), theme.icons.checkmark32)
                                                    // Update our last confirm date.
                                                    const lastConfirmDate = moment().toISOString()
                                                    AsyncStorage.setItem(AppConstants.paymentAddressLastConfirmDate, lastConfirmDate)
                                                    // Update our local state to match. This should hide this card.
                                                    setPaymentAddressLastConfirmDate(lastConfirmDate)
                                                    break
                                                case "corrected":
                                                    navigation.navigate(ScreenNames.AddressVerification.routeName, {
                                                        originalAddress,
                                                        correctedAddress: data,
                                                        existingAddressId: prop("id", paymentAddressData),
                                                    })
                                                    break
                                                case "failed":
                                                    console.log("Couldn't validate address: " + JSON.stringify(data))
                                                    navigation.navigate(ScreenNames.AddressVerification.routeName, {
                                                        originalAddress,
                                                        correctedAddress: data,
                                                        existingAddressId: prop("id", paymentAddressData),
                                                    })
                                                    break
                                                default:
                                                    break
                                            }
                                        },
                                        (error) => {
                                            console.log("Prompt card couldn't validate address: " + JSON.stringify(error))
                                            setAddressValidationPending(false)
                                            navigation.navigate(ScreenNames.AddressVerification.routeName, {
                                                originalAddress,
                                                correctedAddress: data,
                                                existingAddressId: prop("id", paymentAddressData),
                                            })
                                            // showToast(i18n.t("mailingAddress.prefs.validationError"), theme.icons.warning)
                                        }
                                    )
                                }
                            }}
                        />
                    )}
                    <Button
                        title={i18n.t(!isEmpty(prop("address", paymentAddressData)) ? "mailingAddress.prompt.buttonChange" : "mailingAddress.prompt.buttonAdd")}
                        buttonTintColor={rawThemes.dark.surface1}
                        contentTintColor={rawThemes.dark.textPrimary}
                        onPress={() => {
                            console.log("Address change button pressed")
                            navigation.navigate(ScreenNames.PaymentPrefsDetail.routeName)
                        }}
                    />
                </CriticalWarningCard>
            )}

            {suppressEducationSplashCard != "true" && enrollmentComplete && (
                <SplashCard
                    title={i18n.t("powerInYourHands")}
                    buttonText={i18n.t("learnAboutEvents")}
                    onPress={() => {
                        navigation.navigate(ScreenNames.University.routeName)
                    }}
                    onClosePress={() => {
                        AsyncStorage.setItem(AppConstants.suppressEducationSplashCard, "true").then(() => {
                            // Bring this into Redux
                            loadAsyncStorage(store)
                        })
                    }}
                />
            )}

            {showDataProcessingFlag && <InitialDataFetchCard />}

            <TimeZonePill />

            {[...eventsHappeningNow(events), ...upcomingEvents(events)].map((event) => {
                const participantData = (eventParticipants || []).find((possibleItem) => prop("id", possibleItem) == prop("eventId", event)) || {}
                // Include the first message matching this event. Messages are sorted newest-to-oldest.
                const incomingCaptainMessage = neighborhoodMessagesData.find((possibleMessage) => prop("event", possibleMessage) == prop("eventId", event))
                return (
                    <View key={prop("startTime", event)}>
                        <EventBannerCard event={event} participants={prop("participants", participantData)} incomingMessage={incomingCaptainMessage} />
                    </View>
                )
            })}

            <AchievementsSummaryCard numVisible={3} />

            {summaryEvent != null && (
                <ScreenSummaryCard
                    title={i18n.t("eventsTitle")}
                    buttonTitle={i18n.t("events.seeAll")}
                    onHeaderPress={() => navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })}
                    onButtonPress={() => navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })}
                >
                    <SummaryRow
                        title={i18n.t("currentStreak")}
                        description={streakLength == 1 ? i18n.t("eventCountSingular") : i18n.t("eventCountPlural", { count: streakLength })}
                        descriptionTintColor={theme.textPrimary}
                        onPress={() => navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })}
                    />
                    <SummaryRow
                        title={i18n.t("yourPerformance")}
                        descriptionHeader={summaryEventTime}
                        description={i18n.t("percentOfGoalShort", { percent: summaryPercentage })}
                        descriptionTintColor={colorForPerformanceFactor(prop("performanceFactor", summaryEvent))}
                        onPress={() => navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })}
                    />
                    {/* {summaryNeighborhoodEvent != null && (
                        <SummaryRow
                            title={i18n.t("yourNeighborhood")}
                            descriptionHeader={summaryEventTime}
                            description={i18n.t("percentOfGoalShort", { percent: summaryNeighborhoodPercentage })}
                            descriptionTintColor={colorForPerformanceFactor(prop("performance_factor", summaryNeighborhoodEvent))}
                            onPress={() => navigation.navigate(ScreenNames.EventsTab.routeName, { screen: ScreenNames.EventsScreen.routeName })}
                        />
                    )} */}
                </ScreenSummaryCard>
            )}

            <ScreenSummaryCard
                title={i18n.t("energyTitle")}
                buttonTitle={i18n.t("seeYourEnergyUse")}
                onHeaderPress={() => navigation.navigate(ScreenNames.EnergyTab.routeName, { screen: ScreenNames.EnergyScreen.routeName })}
                onButtonPress={() => navigation.navigate(ScreenNames.EnergyTab.routeName, { screen: ScreenNames.EnergyScreen.routeName })}
            >
                <SummaryRow
                    title={i18n.t("usageThisWeek")}
                    onPress={() => navigation.navigate(ScreenNames.EnergyTab.routeName, { screen: ScreenNames.EnergyScreen.routeName })}
                >
                    {electricityShortHistoryData != null && electricityShortHistoryData.length > 0 && (
                        <LineChart
                            style={{ width: 100, height: 22 }}
                            animate={false}
                            data={electricityShortHistoryData}
                            xAccessor={(index) => {
                                const fieldName = prop("level", electricityShortHistoryData) == "bp" ? "end" : "timestamp"
                                let xMoment = moment(path(["item", fieldName], index))
                                // console.log("Returning " + xMoment.toISOString() + " as x, fieldName is " + fieldName)
                                return xMoment.valueOf()
                            }}
                            yAccessor={(item) => {
                                let value = path(["item", "usage"], item)
                                // console.log("Returning " + value + " as graph y value, item was " + JSON.stringify(item))
                                return value
                            }}
                            curve={shape.curveBumpX}
                            svg={{ stroke: theme.textPrimary, strokeWidth: 1.5 }}
                            contentInset={{ top: 1, bottom: 1 }}
                        />
                    )}
                </SummaryRow>
                <SummaryRow
                    title={i18n.t("yourProjectedBill")}
                    description={formatWithUnits(thisBill, DataSource.electricity, DataMeasurement.money)}
                    descriptionTintColor={theme.green}
                    onPress={() => navigation.navigate(ScreenNames.EnergyTab.routeName, { screen: ScreenNames.EnergyScreen.routeName })}
                />
                <SummaryRow
                    title={i18n.t("yesterdayVsProjection")}
                    description={formattedYesterdayComparison}
                    descriptionTintColor={theme.blue}
                    onPress={() => navigation.navigate(ScreenNames.EnergyTab.routeName, { screen: ScreenNames.EnergyScreen.routeName })}
                />
            </ScreenSummaryCard>

            <ScreenSummaryCard
                title={i18n.t("carbonFootprintTitle")}
                buttonTitle={i18n.t("seeYourCarbonFootprint")}
                onHeaderPress={() => navigation.navigate(ScreenNames.CarbonTab.routeName, { screen: ScreenNames.CarbonScreen.routeName })}
                onButtonPress={() => navigation.navigate(ScreenNames.CarbonTab.routeName, { screen: ScreenNames.CarbonScreen.routeName })}
            >
                <SummaryRow
                    title={i18n.t("currentGridCO2")}
                    description={currentCarbonString}
                    descriptionTintColor={currentCarbonColor}
                    onPress={() => navigation.navigate(ScreenNames.CarbonTab.routeName, { screen: ScreenNames.CarbonScreen.routeName })}
                />
                <SummaryRow
                    title={i18n.t("nextOkayCO2Level")}
                    description={nextGoodString}
                    descriptionTintColor={theme.green}
                    onPress={() => navigation.navigate(ScreenNames.CarbonTab.routeName, { screen: ScreenNames.CarbonScreen.routeName })}
                />
                <SummaryRow
                    title={i18n.t("electricitySources")}
                    description={i18n.t("percentRenewable", {
                        percent: 100 - Number(prop("carbonFuelPercent", carbonData) || 0).toFixed(0),
                    })}
                    descriptionTintColor={theme.textPrimary}
                    onPress={() => navigation.navigate(ScreenNames.CarbonTab.routeName, { screen: ScreenNames.CarbonScreen.routeName })}
                />
            </ScreenSummaryCard>

            <LessonCard
                icon={theme.icons.coin}
                title={i18n.t("lesson.actions.title")}
                description={i18n.t("lesson.actions.description")}
                onPress={() => navigation.navigate(ScreenNames.ActionsInfo.routeName)}
            />

            <ContactUsCard />

            {/* <StatusBar style={showDataProcessingFlag ? "dark" : "light"} /> */}
        </KeyboardAwareScrollView>
    )
}
