import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, View } from "react-native"
import { TextStyles } from "../../shared/TextStyles"
import { rawThemes } from "../../shared/ThemeContext"
import CardView from "./CardView"

export default function CriticalWarningCard(props) {
    var { title, icon = rawThemes.dark.icons.warning } = props
    const navigation = useNavigation()

    return (
        <CardView includesBorder={true} includesPadding={false} containerStyle={{ padding: 16, backgroundColor: rawThemes.dark.blue }} {...props}>
            <View style={{ flexDirection: "row", marginBottom: 16 }}>
                <Image source={icon} style={[{ width: 32, height: 32, marginRight: 16 }]} />
                {title != null && <Text style={[TextStyles.body2, { flexShrink: 1, color: rawThemes.dark.textPrimary }]}>{title}</Text>}
            </View>
            {props.children}
        </CardView>
    )
}
