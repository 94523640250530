import * as React from "react"
import { View } from "react-native"
import { ThemeContext } from "../shared/ThemeContext"

export default function Divider(props) {
    const { theme } = React.useContext(ThemeContext)

    return (
        <View
            style={[
                {
                    height: 1.0,
                    backgroundColor: theme.divider,
                },
                props.style,
            ]}
        ></View>
    )
}
