import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
import moment from "moment-timezone"
import { prop } from "ramda"
import React, { useEffect, useState } from "react"
import { Image, Platform, Text, TouchableOpacity, View } from "react-native"
import { ProgressCircle } from "react-native-svg-charts"
import { useDispatch } from "react-redux"
import { addParticipant } from "../../model/primaryDataActions"
import AppConstants, { ScreenNames } from "../../shared/AppConstants"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { rawThemes, ThemeContext } from "../../shared/ThemeContext"
import { addEventToCalendar, eventTimeFormatted } from "../../shared/Utils"
import Button from "../Button"
import MessageContainer from "../MessageContainer"
import CardView from "./CardView"

export default function EventBannerCard(props) {
    const { targetScreen, event, participants, incomingMessage } = props
    const navigation = useNavigation()
    const dispatch = useDispatch()

    const { theme } = React.useContext(ThemeContext)

    // No event, don't try to render anything.
    if (event == null) {
        return null
    }

    const startTime = moment(prop("startTime", event))
    const endTime = moment(prop("endTime", event))

    const now = moment()
    if (startTime.isBefore(now) && endTime.isBefore(now)) {
        // Don't show events in the past.
        return null
    }

    const timeString = eventTimeFormatted(event)

    const [isActive, setIsActive] = useState(startTime.isBefore(now) && endTime.isAfter(now))

    const [totalEventTime, setTotalEventTime] = useState(moment.duration(endTime.diff(startTime)))
    const [eventTimeRemaining, setEventTimeRemaining] = useState(moment.duration(endTime.diff(moment())))
    const [countedIn, setCountedIn] = useState(false)
    const [captainMessage, setCaptainMessage] = useState(prop("text", incomingMessage))

    // Initial setup
    useEffect(() => {
        // Create a refresh timer to update the time once every X seconds
        var countdownInterval = setInterval(() => {
            const startTime = moment(prop("startTime", event))
            const endTime = moment(prop("endTime", event))
            const now = moment()
            setEventTimeRemaining(moment.duration(endTime.diff(now)))
            // Recalculate whether we should even consider this active.
            setIsActive(startTime.isBefore(now) && endTime.isAfter(now))
        }, 30 * 1000)

        return () => {
            if (countdownInterval != null) {
                clearTimeout(countdownInterval)
            }
        }
    }, [])

    useEffect(() => {
        // console.log("Card thinks participant data for event " + prop("eventId", event) + " is " + JSON.stringify(participants))
        if (participants != null) {
            // Figure out which utility account to use, and see if it appears in the participants array.
            AsyncStorage.getItem(AppConstants.currentUtilityAccount).then((currentAccount) => {
                setCountedIn(participants.includes(currentAccount))
            })
        }
    }, [participants])

    useEffect(() => {
        if (incomingMessage != null && prop("text", incomingMessage) != captainMessage) {
            // console.log("Event card's incoming message changed to " + prop("text", incomingMessage))
            setCaptainMessage(prop("text", incomingMessage))
        }
    }, [incomingMessage])

    function ctaContainer() {
        const participantCount = (participants || []).length

        if (countedIn) {
            let displayString = i18n.t("participants.youOnly")
            if (participantCount > 2) {
                displayString = i18n.t("participants.youAndOthersPlural", { count: participantCount - 1 })
            } else if (participantCount == 2) {
                displayString = i18n.t("participants.youAndOthersSingular")
            }
            return (
                <TouchableOpacity style={{ flexDirection: "row", alignItems: "center" }} onPress={() => navigation.navigate(ScreenNames.ActionsInfo.routeName)}>
                    <Image source={theme.icons.handSeedlingPurple} style={{ width: 24, height: 24, marginRight: 8 }} />
                    <Text style={[TextStyles.capsExtraSmall, { flexShrink: 1, color: theme.textHint }]}>{displayString}</Text>
                    <Image source={theme.icons.arrowRight} style={{ width: 12, height: 12, marginLeft: 8, opacity: 0.5 }} />
                </TouchableOpacity>
            )
        } else {
            return (
                <View style={{ width: "100%" }}>
                    <Button
                        type="fill"
                        containerStyle={{ flex: 1 }}
                        buttonTintColor={theme.purple}
                        contentTintColor={theme.textLight}
                        title={i18n.t("events.cta")}
                        arrow={false}
                        icon={rawThemes.dark.icons.handSeedling}
                        onPress={() => {
                            console.log("Count me in pressed")
                            dispatch(addParticipant(prop("eventId", event)))
                        }}
                    />
                    {participantCount > 0 && (
                        <Text style={[TextStyles.capsExtraSmall, { color: theme.textHint, marginTop: 8, textAlign: "center" }]}>
                            {participantCount == 1 ? i18n.t("participants.othersSingular") : i18n.t("participants.othersPlural", { count: participantCount })}
                        </Text>
                    )}
                </View>
            )
        }
    }

    if (eventTimeRemaining.asSeconds() <= 0) {
        // If our timer has gone past zero, stop rendering this card.
        return null
    }

    return (
        <CardView includesBorder={true} includesPadding={true} {...props}>
            <View style={{ flexDirection: "row", marginBottom: 24, alignItems: "center" }}>
                {isActive && (
                    <View style={{ width: 64, height: 64, marginRight: 16, justifyContent: "center", alignItems: "center" }}>
                        <ProgressCircle
                            style={{ height: "100%", width: "100%" }}
                            startAngle={0}
                            endAngle={2 * Math.PI}
                            strokeWidth={8}
                            backgroundColor={theme.purpleHint}
                            progress={eventTimeRemaining / totalEventTime}
                            progressColor={theme.purple}
                        />
                        <Text style={[TextStyles.caption, { color: theme.purple, position: "absolute" }]}>
                            {Math.round(eventTimeRemaining.hours()) + ":" + String(Math.round(eventTimeRemaining.minutes())).padStart(2, '0')}
                        </Text>
                    </View>
                )}
                <Text style={[TextStyles.bodyLarge, { flexShrink: 1, color: theme.textPrimary }]}>
                    {i18n.t(`events.${isActive ? "current" : "future"}.title`) + " "}
                    <Text style={[TextStyles.bodyLargeSemi, { color: theme.purple }]}>{timeString}</Text>
                </Text>
                {!isActive && <View style={{ flex: 1 }} />}
                {/* Expo-calendar doesn't support web, so hide this on web. */}
                {!isActive && Platform.OS != "web" && (
                    <TouchableOpacity
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                            height: 48,
                            width: 48,
                            backgroundColor: theme.surface1,
                            borderRadius: 1000,
                            marginLeft: 16,
                        }}
                        onPress={() => {
                            addEventToCalendar(event)
                        }}
                    >
                        <Image source={theme.icons.calendarPlus} style={{ width: 32, height: 32 }} />
                    </TouchableOpacity>
                )}
            </View>
            {ctaContainer()}
            <MessageContainer event={event} incomingMessage={incomingMessage} containerStyle={{ marginTop: 24 }} />
        </CardView>
    )
}
