import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, View, TouchableOpacity } from "react-native"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import CardView from "./CardView"
import { openSetup } from "../../shared/Utils"
import { useSelector } from "react-redux"
import { prop } from "ramda"
import i18n from "../../shared/i18n"
import Button from "../Button"

export default function ThermostatSetupCard(props) {
    const navigation = useNavigation()
    const { theme } = React.useContext(ThemeContext)
    const mainData = useSelector((state) => state.primaryData.mainData)
    const { tstat = {} } = prop("nextSteps", mainData) || {}

    return (
        <CardView {...props}>
            <Image source={theme.icons.thermostatLarge} style={[{ width: 160, height: 160, alignSelf: "center" }]} />
            <Text style={[TextStyles.body2, {textAlign: "center", color: theme.textPrimary, marginBottom: 24 }]}>{i18n.t("thermostatConnectPrompt")}</Text>
            <Button type="fill" title={i18n.t("thermostatConnectButtonTitle")} arrow={true} onPress={() => openSetup(prop("link", tstat))} />
        </CardView>
    )
}
