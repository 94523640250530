import * as Linking from "expo-linking"
import { prop } from "ramda"
import * as React from "react"
import { Image, Text, View, TouchableOpacity } from "react-native"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import Divider from "../Divider"
import CardView from "./CardView"

export default function DontaionInfoCard(props) {
    const { orgInfo = {} } = props || {}
    const imageSource = prop("logo", orgInfo)
    const aspectRatio = prop("logoAspect", orgInfo)
    const targetImageHeight = 80
    const { theme } = React.useContext(ThemeContext)

    return (
        <CardView {...props}>
            <View style={{ flexDirection: "row", marginBottom: 16 }}>
                <Image source={imageSource} style={{ width: targetImageHeight * aspectRatio, height: targetImageHeight }} />
                <View style={{ width: 0, flexGrow: 1, marginLeft: 8 }}>
                    <Text style={[TextStyles.caption, { textAlign: "right", color: theme.textSecondary, marginBottom: 4 }]}>
                        {prop("categoryName", orgInfo)}
                    </Text>
                    <Text style={[TextStyles.capsExtraSmall, { textAlign: "right", color: theme.textHint }]}>{prop("location", orgInfo)}</Text>
                </View>
            </View>
            <Text style={[TextStyles.h4, { color: theme.textPrimary, marginBottom: 8 }]}>{prop("title", orgInfo)}</Text>
            <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>{prop("description", orgInfo)}</Text>
            <Divider style={{ marginVertical: 16 }} />
            <TouchableOpacity
                style={{ flexDirection: "row", paddingBottom: 4 }}
                onPress={() => {
                    const url = prop("url", orgInfo)
                    if (url != null) {
                        Linking.openURL(url)
                    }
                }}
            >
                <Text style={[TextStyles.body3, { textAlign: "right", color: theme.textSecondary }]}>{prop("linkName", orgInfo) + " ->"}</Text>
            </TouchableOpacity>
        </CardView>
    )
}
