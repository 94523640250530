import * as React from "react"
import { Image, View, StyleSheet, ImageBackground, TouchableOpacity } from "react-native"
import { ThemeContext } from "../../shared/ThemeContext"
import { activeOpacity } from "../../shared/Layout"

export default function CardView(props) {
    const {
        includesPadding = true,
        includesBorder = true,
        backgroundImage,
        backgroundImageResizeMode = "stretch",
        backgroundImageStyle,
        containerStyle,
        onCardPress,
    } = props
    const { theme } = React.useContext(ThemeContext)

    return (
        <TouchableOpacity
            activeOpacity={onCardPress != null ? activeOpacity : 1.0}
            onPress={onCardPress}
            style={[
                {
                    padding: includesPadding == true ? 20 : 0,
                    marginTop: includesBorder == true ? 0 : 8,
                    marginBottom: includesBorder == true ? 24 : 32,
                    marginHorizontal: 16,
                    borderWidth: includesBorder == true ? 1 : 0,
                    borderColor: theme.border,
                    borderRadius: includesBorder == true ? 16 : 0,
                    overflow: "hidden",
                },
                containerStyle,
            ]}
            {...props}
        >
            {backgroundImage != null ? (
                <ImageBackground source={backgroundImage} resizeMode={backgroundImageResizeMode} style={[{ flex: 1 }, backgroundImageStyle]}>
                    <View style={[{ flex: 1 }, props.contentContainerStyle]}>{props.children}</View>
                </ImageBackground>
            ) : (
                <View style={[{ flex: 1 }, props.contentContainerStyle]}>{props.children}</View>
            )}
        </TouchableOpacity>
    )
}
