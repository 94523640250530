import { ScreenNames } from "../shared/AppConstants"
import i18n from "../shared/i18n"

/*
The content is an array of courses, each of which can contain:
* ID (required)
* title (required)
* image (used for the preview card)

Each course contains an array of one or more lessons, each of which can contain:
* ID (required)
* icon
* title (required)
* video
* leadingImage
* body
* buttonTitle (and if included, buttonRoute and buttonRouteParams)
* trailingImage
* an array of 0-n "tip" strings, each of which will get its own box.
* an array of 0-n links, each of which will get its own box.

*/
export const UniversityContent = [
    {
        id: "university.courseEvents",
        title: i18n.t("university.courseEvents.title"),
        image: require("../assets/images/university/womanBicycle.jpg"),
        lessons: [
            {
                id: "university.courseEvents.whatIsEvent",
                icon: "whatIsEvent",
                title: i18n.t("university.courseEvents.whatIsEvent.title"),
                body: i18n.t("university.courseEvents.whatIsEvent.body"),
            },
            {
                id: "university.courseEvents.participate",
                icon: "participate",
                title: i18n.t("university.courseEvents.participate.title"),
                body: i18n.t("university.courseEvents.participate.body"),
                tips: [i18n.t("university.courseEvents.participate.tipSave")],
            },
            {
                id: "university.courseEvents.whyImportant",
                icon: "whyImportant",
                title: i18n.t("university.courseEvents.whyImportant.title"),
                body: i18n.t("university.courseEvents.whyImportant.body"),
            },
            {
                id: "university.courseEvents.mostMoney",
                icon: "mostMoney",
                title: i18n.t("university.courseEvents.mostMoney.title"),
                body: i18n.t("university.courseEvents.mostMoney.body"),
            },
            {
                id: "university.courseEvents.performance",
                icon: "performance",
                title: i18n.t("university.courseEvents.performance.title"),
                body: i18n.t("university.courseEvents.performance.body"),
            },
        ],
    },
    {
        id: "university.coursePowerGrid",
        title: i18n.t("university.coursePowerGrid.title"),
        image: require("../assets/images/university/powerLines.jpg"),
        lessons: [
            {
                id: "university.coursePowerGrid.whatIsTheGrid",
                icon: "whatIsTheGrid",
                title: i18n.t("university.coursePowerGrid.whatIsTheGrid.title"),
                body: i18n.t("university.coursePowerGrid.whatIsTheGrid.body"),
            },
            {
                id: "university.coursePowerGrid.peakerPlants",
                icon: "peakerPlants",
                title: i18n.t("university.coursePowerGrid.peakerPlants.title"),
                body: i18n.t("university.coursePowerGrid.peakerPlants.body"),
            },
            {
                id: "university.coursePowerGrid.unplugging",
                icon: "unplugging",
                title: i18n.t("university.coursePowerGrid.unplugging.title"),
                body: i18n.t("university.coursePowerGrid.unplugging.body"),
            },
            {
                id: "university.courseEvents.timeWindow",
                icon: "timeWindow",
                title: i18n.t("university.coursePowerGrid.timeWindow.title"),
                body: i18n.t("university.coursePowerGrid.timeWindow.body"),
            },
        ],
    },
    {
        id: "university.courseMultiplyImpact",
        title: i18n.t("university.courseMultiplyImpact.title"),
        image: require("../assets/images/university/crowdOverhead.jpg"),
        lessons: [
            {
                id: "university.courseMultiplyImpact.shiftThePower",
                leadingImage: require("../assets/images/shiftThePower.png"),
                title: i18n.t("university.courseMultiplyImpact.shiftThePower.title"),
                body: i18n.t("university.courseMultiplyImpact.shiftThePower.body"),
            },
            {
                id: "university.courseMultiplyImpact.neighborhoodPride",
                icon: "neighborhoodPride",
                title: i18n.t("university.courseMultiplyImpact.neighborhoodPride.title"),
                body: i18n.t("university.courseMultiplyImpact.neighborhoodPride.body"),
                buttonTitle: i18n.t("neighborhood.cta"),
                buttonRoute: ScreenNames.EventsTab.routeName,
                buttonRouteParams: { screen: ScreenNames.Neighborhood.routeName },
            },
            {
                id: "university.courseMultiplyImpact.referral",
                icon: "referral",
                title: i18n.t("university.courseMultiplyImpact.referral.title"),
                body: i18n.t("university.courseMultiplyImpact.referral.body"),
                buttonTitle: i18n.t("referral.prompt"),
                buttonRoute: ScreenNames.ReferralDetail.routeName,
                buttonRouteParams: {},
            },
        ],
    },
    {
        id: "university.courseOther",
        title: i18n.t("university.courseOther.title"),
        image: require("../assets/images/university/womanCityPhone.jpg"),
        lessons: [
            {
                id: "university.courseOther.carbonFootprint",
                icon: "carbonFootprint",
                title: i18n.t("university.courseOther.carbonFootprint.title"),
                body: i18n.t("university.courseOther.carbonFootprint.body"),
                buttonTitle: i18n.t("seeYourCarbonFootprint"),
                buttonRoute: ScreenNames.CarbonTab.routeName,
                buttonRouteParams: { screen: ScreenNames.CarbonScreen.routeName },
            },
            {
                id: "university.courseOther.checkYourself",
                icon: "checkYourself",
                title: i18n.t("university.courseOther.checkYourself.title"),
                body: i18n.t("university.courseOther.checkYourself.body"),
                buttonTitle: i18n.t("seeYourEnergyUse"),
                buttonRoute: ScreenNames.EnergyTab.routeName,
                buttonRouteParams: { screen: ScreenNames.EnergyScreen.routeName },
            },
        ],
    },
]
