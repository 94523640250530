import * as React from "react"
import { Appearance, useColorScheme } from "react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import AppConstants, { ThemeOption } from "./AppConstants"
import { useState, useEffect, useContext } from "react"

const accentColors = {
    green: "#3fdeA9",
    greenDark: "#22C48E",
    greenHint: "rgba(63, 222, 169, 0.1)",
    greenMint: "#B2F2DD",
    orange: "#ff8a00",
    lavender: "#b07ffd",
    lavenderTranslucent: "rgba(134, 89, 229, 0.2)",
    pink: "#e56fbf",
    purple: "#6943d1",
    purpleHint: "rgba(105, 67, 209, 0.1)",
    blue: "#486ef6",
    blueTranslucent: "rgba(72, 110, 246, 0.2)",
    red: "#ec5b76",
    redTranslucent: "rgba(236, 91, 118, 0.2)",
    yellow: "#dae559",
    yellowTranslucent: "rgba(218, 229, 89, 0.2)",
    teal: "#0ed5d5",
    tealTranslucent: "rgba(14, 213, 213, 0.2)",
    textDark: "#07001b",
    textLight: "#ffffff",
    textLightTranslucent: "rgba(255, 255, 255, 0.8)",
    white: "rgba(255, 255, 255, 1.0)",
    white50: "rgba(255, 255, 255, 0.5)",
    accentDark: "#07001b",
    gradient: {
        day: {
            colors: ["#bae5fe", "#dbd3ea", "#fdd0ba", "#ffec90"],
            locations: [0, 0.28, 0.6, 1.0],
        },
        blueAquaYellow: {
            colors: ["#4CCAF2", "#4CF2E1", "#F4E76A"],
            locations: [0, 0.3, 1.0],
        },
    },
    fuelMix: {
        "natural gas": "#ec5b76",
        "dual fuel": "#F18498",
        "other fossil fuels": "#F5ADBA",
        wind: "#486ef6",
        hydro: "#7692F8",
        nuclear: "#A3B7FA",
        "other renewables": "#D1DBFD",
    },
}

const staticColorIcons = {
    ac: require("../assets/icons/staticColor/AC_32.png"),
    bellOn: require("../assets/icons/staticColor/bellOn_36.png"),
    boltOrange: require("../assets/icons/staticColor/bolt_orange_24.png"),
    bookBlue: require("../assets/icons/staticColor/book_blue_24.png"),
    buildingBlue: require("../assets/icons/staticColor/building_blue_20.png"),
    calendarPlus: require("../assets/icons/staticColor/calendar_plus_32.png"),
    checklist: require("../assets/icons/staticColor/checklist_40.png"),
    comments: require("../assets/icons/staticColor/comments_40.png"),
    commentsDarkGreen: require("../assets/icons/staticColor/comments_darkGreen_40.png"),
    earth: require("../assets/icons/staticColor/earth_40.png"),
    ev: require("../assets/icons/staticColor/EV_32.png"),
    fan: require("../assets/icons/staticColor/fan_32.png"),
    giftLavender: require("../assets/icons/staticColor/gift_lavender_36.png"),
    handSeedlingPurple: require("../assets/icons/staticColor/handSeedling_purple_24.png"),
    homeBlue: require("../assets/icons/staticColor/home_blue_20.png"),
    lightbulbOff: require("../assets/icons/staticColor/lightbulbOff_32.png"),
    moneyGreen: require("../assets/icons/staticColor/money_green_24.png"),
    partyHornPurple: require("../assets/icons/staticColor/partyHorn_purple_24.png"),
    play: require("../assets/icons/staticColor/play_48.png"),
    plugOrange: require("../assets/icons/staticColor/plug_orange_32.png"),
    refrigerator: require("../assets/icons/staticColor/refrigerator_32.png"),
    ringingBell: require("../assets/icons/staticColor/ringingBell_40.png"),
    seedlingTeal: require("../assets/icons/staticColor/seedling_teal_24.png"),
    thermostatArrows: require("../assets/icons/staticColor/thermostatArrows_32.png"),
    warningSquareRed: require("../assets/icons/staticColor/warningSquare_red_24.png"),
    washingMachine: require("../assets/icons/staticColor/washingMachine_32.png"),
    coinGreen: require("../assets/icons/staticColor/coin_green_32.png"),
    chatMoneyGreen: require("../assets/icons/staticColor/chatMoney_green_32.png"),
    moneyEnvelopeGreen: require("../assets/icons/staticColor/moneyEnvelope_green_32.png"),
    thermostatLarge: require("../assets/icons/staticColor/thermostatLarge_160.png"),
    treeCity: require("../assets/icons/staticColor/treeCity_greenDark_32.png"),
    videoGreen: require("../assets/icons/staticColor/video_greenDark_24.png"),
    videoPlay: require("../assets/icons/staticColor/videoPlay_48.png"),
    videoPause: require("../assets/icons/staticColor/videoPause_48.png"),

    achievements: {
        large: {
            plug: require("../assets/icons/staticColor/achievements/plug_326.png"),
            referral: require("../assets/icons/staticColor/achievements/referral_326.png"),
            graph: require("../assets/icons/staticColor/achievements/graph_326.png"),
            count10: require("../assets/icons/staticColor/achievements/count10_326.png"),
            count25: require("../assets/icons/staticColor/achievements/count25_326.png"),
            count50: require("../assets/icons/staticColor/achievements/count50_326.png"),
            checkmark: require("../assets/icons/staticColor/achievements/checkmark_326.png"),
            building: require("../assets/icons/staticColor/achievements/building_326.png"),
            badge: require("../assets/icons/staticColor/achievements/badge_326.png"),
            crown: require("../assets/icons/staticColor/achievements/crown_326.png"),
            starPartial: require("../assets/icons/staticColor/achievements/starPartial_326.png"),
            star: require("../assets/icons/staticColor/achievements/star_326.png"),
            oneHundred: require("../assets/icons/staticColor/achievements/oneHundred_326.png"),
            bolt: require("../assets/icons/staticColor/achievements/bolt_326.png"),
        },
        small: {
            plug: require("../assets/icons/staticColor/achievements/plug_118.png"),
            referral: require("../assets/icons/staticColor/achievements/referral_118.png"),
            graph: require("../assets/icons/staticColor/achievements/graph_118.png"),
            count10: require("../assets/icons/staticColor/achievements/count10_118.png"),
            count25: require("../assets/icons/staticColor/achievements/count25_118.png"),
            count50: require("../assets/icons/staticColor/achievements/count50_118.png"),
            checkmark: require("../assets/icons/staticColor/achievements/checkmark_118.png"),
            building: require("../assets/icons/staticColor/achievements/building_118.png"),
            badge: require("../assets/icons/staticColor/achievements/badge_118.png"),
            crown: require("../assets/icons/staticColor/achievements/crown_118.png"),
            starPartial: require("../assets/icons/staticColor/achievements/starPartial_118.png"),
            star: require("../assets/icons/staticColor/achievements/star_118.png"),
            oneHundred: require("../assets/icons/staticColor/achievements/oneHundred_118.png"),
            bolt: require("../assets/icons/staticColor/achievements/bolt_118.png"),
        },
    },
}

const rawThemes = {
    light: {
        ...accentColors,
        background: "#fbfbf9",
        surface1: "rgba(7, 0, 27, 0.04)",
        textPrimary: "#07001b",
        textSecondary: "rgba(7, 0, 27, 0.7)",
        textHint: "rgba(7, 0, 27, 0.5)",
        textDisabled: "rgba(7, 0, 27, 0.4)",
        divider: "rgba(7, 0, 27, 0.12)",
        border: "rgba(7, 0, 27, 0.12)",
        icons: {
            ...staticColorIcons,
            alarmExclamation: require("../assets/icons/light/alarmExclamation_24.png"),
            arrowDown: require("../assets/icons/light/arrowDown_16.png"),
            arrowLeft: require("../assets/icons/light/arrowLeft_16.png"),
            arrowRight: require("../assets/icons/light/arrowRight_16.png"),
            arrowLeft24: require("../assets/icons/light/arrowLeft_24.png"),
            arrowRight24: require("../assets/icons/light/arrowRight_24.png"),
            arrowUp: require("../assets/icons/light/arrowUp_16.png"),
            backCaret: require("../assets/icons/light/backCaret_24.png"),
            bell: require("../assets/icons/light/bell_36.png"),
            bolt: require("../assets/icons/light/bolt_24.png"),
            book: require("../assets/icons/light/book_24.png"),
            bookSkull: require("../assets/icons/light/bookSkull_32.png"),
            building: require("../assets/icons/light/building_20.png"),
            calendar: require("../assets/icons/light/calendar_24.png"),
            car: require("../assets/icons/light/car_48.png"),
            caretDown: require("../assets/icons/light/caretDown_16.png"),
            cars: require("../assets/icons/light/cars_48.png"),
            chartElectricity: require("../assets/icons/light/chartElectricity_48.png"),
            chartGas: require("../assets/icons/light/chartGas_48.png"),
            chatMoney: require("../assets/icons/light/chatMoney_32.png"),
            checkCircle: require("../assets/icons/light/checkCircle_24.png"),
            checkmark24: require("../assets/icons/light/checkmark_24.png"),
            checkmark32: require("../assets/icons/light/checkmark_32.png"),
            clock: require("../assets/icons/light/clock_24.png"),
            close: require("../assets/icons/light/close_24.png"),
            coin: require("../assets/icons/light/coin_32.png"),
            compose: require("../assets/icons/light/compose_36.png"),
            copy: require("../assets/icons/light/copy_24.png"),
            dollarSign: require("../assets/icons/light/dollarSign_24.png"),
            ellipsis24: require("../assets/icons/light/ellipsis_24.png"),
            ellipsis32: require("../assets/icons/light/ellipsis_32.png"),
            email: require("../assets/icons/light/email_24.png"),
            flame: require("../assets/icons/light/flame_24.png"),
            flameFilled: require("../assets/icons/light/flame_filled_48.png"),
            gift: require("../assets/icons/light/gift_36.png"),
            handSeedling: require("../assets/icons/light/handSeedling_24.png"),
            handSeedling48: require("../assets/icons/light/seedlingHand_48.png"),
            home: require("../assets/icons/light/home_20.png"),
            hourglass: require("../assets/icons/light/hourglass_24.png"),
            house: require("../assets/icons/light/house_48.png"),
            infoCircle16: require("../assets/icons/light/infoCircle_16.png"),
            infoCircle24: require("../assets/icons/light/infoCircle_24.png"),
            money: require("../assets/icons/light/money_24.png"),
            moneyCheck: require("../assets/icons/light/moneyCheck_24.png"),
            moneyEnvelope: require("../assets/icons/light/moneyEnvelope_32.png"),
            partyHorn: require("../assets/icons/light/partyHorn_24.png"),
            phone: require("../assets/icons/light/phone_24.png"),
            phoneCharger: require("../assets/icons/light/phoneCharger_48.png"),
            plug24: require("../assets/icons/light/plug_24.png"),
            plug36: require("../assets/icons/light/plug_36.png"),
            plus24: require("../assets/icons/light/plus_24.png"),
            plus32: require("../assets/icons/light/plus_32.png"),
            recycling: require("../assets/icons/light/recycling_48.png"),
            refresh: require("../assets/icons/light/refresh_32.png"),
            seedling: require("../assets/icons/light/seedling_24.png"),
            share: require("../assets/icons/light/share_24.png"),
            star: require("../assets/icons/light/star_36.png"),
            stars: require("../assets/icons/light/stars_36.png"),
            tabCarbon: require("../assets/icons/light/tabCarbon_26.png"),
            tabCarbonActive: require("../assets/icons/light/tabCarbonActive_26.png"),
            tabEnergy: require("../assets/icons/light/tabEnergy_26.png"),
            tabEnergyActive: require("../assets/icons/light/tabEnergyActive_26.png"),
            tabEvents: require("../assets/icons/light/tabEvents_26.png"),
            tabEventsActive: require("../assets/icons/light/tabEventsActive_26.png"),
            tabHome: require("../assets/icons/light/tabHome_26.png"),
            tabHomeActive: require("../assets/icons/light/tabHomeActive_26.png"),
            trees: require("../assets/icons/light/trees_48.png"),
            trophy: require("../assets/icons/light/trophy_36.png"),
            user32: require("../assets/icons/light/user_32.png"),
            user36: require("../assets/icons/light/user_36.png"),
            video: require("../assets/icons/light/video_36.png"),
            warning: require("../assets/icons/light/warning_32.png"),
            warningSquare: require("../assets/icons/light/warningSquare_24.png"),
            achievements: {
                large: {
                    ...staticColorIcons.achievements.large,
                    plugOff: require("../assets/icons/light/achievements/plugOff_326.png"),
                    referralOff: require("../assets/icons/light/achievements/referralOff_326.png"),
                    graphOff: require("../assets/icons/light/achievements/graphOff_326.png"),
                    count10Off: require("../assets/icons/light/achievements/count10Off_326.png"),
                    count25Off: require("../assets/icons/light/achievements/count25Off_326.png"),
                    count50Off: require("../assets/icons/light/achievements/count50Off_326.png"),
                    checkmarkOff: require("../assets/icons/light/achievements/checkmarkOff_326.png"),
                    buildingOff: require("../assets/icons/light/achievements/buildingOff_326.png"),
                    badgeOff: require("../assets/icons/light/achievements/badgeOff_326.png"),
                    crownOff: require("../assets/icons/light/achievements/crownOff_326.png"),
                    starPartialOff: require("../assets/icons/light/achievements/starPartialOff_326.png"),
                    starOff: require("../assets/icons/light/achievements/starOff_326.png"),
                    oneHundredOff: require("../assets/icons/light/achievements/oneHundredOff_326.png"),
                    boltOff: require("../assets/icons/light/achievements/boltOff_326.png"),
                },
                small: {
                    ...staticColorIcons.achievements.small,
                    plugOff: require("../assets/icons/light/achievements/plugOff_118.png"),
                    referralOff: require("../assets/icons/light/achievements/referralOff_118.png"),
                    graphOff: require("../assets/icons/light/achievements/graphOff_118.png"),
                    count10Off: require("../assets/icons/light/achievements/count10Off_118.png"),
                    count25Off: require("../assets/icons/light/achievements/count25Off_118.png"),
                    count50Off: require("../assets/icons/light/achievements/count50Off_118.png"),
                    checkmarkOff: require("../assets/icons/light/achievements/checkmarkOff_118.png"),
                    buildingOff: require("../assets/icons/light/achievements/buildingOff_118.png"),
                    badgeOff: require("../assets/icons/light/achievements/badgeOff_118.png"),
                    crownOff: require("../assets/icons/light/achievements/crownOff_118.png"),
                    starPartialOff: require("../assets/icons/light/achievements/starPartialOff_118.png"),
                    starOff: require("../assets/icons/light/achievements/starOff_118.png"),
                    oneHundredOff: require("../assets/icons/light/achievements/oneHundredOff_118.png"),
                    boltOff: require("../assets/icons/light/achievements/boltOff_118.png"),
                },
            },
            university: {
                whatIsEvent: require("../assets/icons/light/university/whatIsEvent_48.png"),
                participate: require("../assets/icons/light/university/participate_48.png"),
                whyImportant: require("../assets/icons/light/university/whyImportant_48.png"),
                mostMoney: require("../assets/icons/light/university/mostMoney_48.png"),
                performance: require("../assets/icons/light/university/performance_48.png"),
                whatIsTheGrid: require("../assets/icons/light/university/whatIsTheGrid_48.png"),
                peakerPlants: require("../assets/icons/light/university/peakerPlants_48.png"),
                unplugging: require("../assets/icons/light/university/unplugging_48.png"),
                timeWindow: require("../assets/icons/light/university/timeWindow_48.png"),
                neighborhoodPride: require("../assets/icons/light/university/neighborhoodPride_48.png"),
                referral: require("../assets/icons/light/university/referral_48.png"),
                carbonFootprint: require("../assets/icons/light/university/carbonFootprint_48.png"),
                checkYourself: require("../assets/icons/light/university/checkYourself_48.png"),
            },
        },
    },
    dark: {
        ...accentColors,
        background: "#07001b",
        surface1: "rgba(255, 255, 255, 0.1)",
        textPrimary: "#ffffff",
        textSecondary: "rgba(255, 255, 255, 0.7)",
        textHint: "rgba(255, 255, 255, 0.5)",
        textDisabled: "rgba(255, 255, 255, 0.4)",
        divider: "rgba(255, 255, 255, 0.18)",
        border: "rgba(255, 255, 255, 0.2)",
        icons: {
            ...staticColorIcons,
            alarmExclamation: require("../assets/icons/dark/alarmExclamation_24.png"),
            arrowDown: require("../assets/icons/dark/arrowDown_16.png"),
            arrowLeft: require("../assets/icons/dark/arrowLeft_16.png"),
            arrowRight: require("../assets/icons/dark/arrowRight_16.png"),
            arrowLeft24: require("../assets/icons/dark/arrowLeft_24.png"),
            arrowRight24: require("../assets/icons/dark/arrowRight_24.png"),
            arrowUp: require("../assets/icons/dark/arrowUp_16.png"),
            backCaret: require("../assets/icons/dark/backCaret_24.png"),
            bell: require("../assets/icons/dark/bell_36.png"),
            bolt: require("../assets/icons/dark/bolt_24.png"),
            book: require("../assets/icons/dark/book_24.png"),
            bookSkull: require("../assets/icons/dark/bookSkull_32.png"),
            building: require("../assets/icons/dark/building_20.png"),
            calendar: require("../assets/icons/dark/calendar_24.png"),
            car: require("../assets/icons/dark/car_48.png"),
            caretDown: require("../assets/icons/dark/caretDown_16.png"),
            cars: require("../assets/icons/dark/cars_48.png"),
            chartElectricity: require("../assets/icons/dark/chartElectricity_48.png"),
            chartGas: require("../assets/icons/dark/chartGas_48.png"),
            chatMoney: require("../assets/icons/dark/chatMoney_32.png"),
            checkCircle: require("../assets/icons/dark/checkCircle_24.png"),
            checkmark24: require("../assets/icons/dark/checkmark_24.png"),
            checkmark32: require("../assets/icons/dark/checkmark_32.png"),
            clock: require("../assets/icons/dark/clock_24.png"),
            close: require("../assets/icons/dark/close_24.png"),
            coin: require("../assets/icons/dark/coin_32.png"),
            compose: require("../assets/icons/dark/compose_36.png"),
            copy: require("../assets/icons/dark/copy_24.png"),
            dollarSign: require("../assets/icons/dark/dollarSign_24.png"),
            ellipsis24: require("../assets/icons/dark/ellipsis_24.png"),
            ellipsis32: require("../assets/icons/dark/ellipsis_32.png"),
            email: require("../assets/icons/dark/email_24.png"),
            flame: require("../assets/icons/dark/flame_24.png"),
            flameFilled: require("../assets/icons/dark/flame_filled_48.png"),
            gift: require("../assets/icons/dark/gift_36.png"),
            handSeedling: require("../assets/icons/dark/handSeedling_24.png"),
            handSeedling48: require("../assets/icons/dark/seedlingHand_48.png"),
            home: require("../assets/icons/dark/home_20.png"),
            hourglass: require("../assets/icons/dark/hourglass_24.png"),
            house: require("../assets/icons/dark/house_48.png"),
            infoCircle16: require("../assets/icons/dark/infoCircle_16.png"),
            infoCircle24: require("../assets/icons/dark/infoCircle_24.png"),
            money: require("../assets/icons/dark/money_24.png"),
            moneyCheck: require("../assets/icons/dark/moneyCheck_24.png"),
            moneyEnvelope: require("../assets/icons/dark/moneyEnvelope_32.png"),
            partyHorn: require("../assets/icons/dark/partyHorn_24.png"),
            phone: require("../assets/icons/dark/phone_24.png"),
            phoneCharger: require("../assets/icons/dark/phoneCharger_48.png"),
            plug24: require("../assets/icons/dark/plug_24.png"),
            plug36: require("../assets/icons/dark/plug_36.png"),
            plus24: require("../assets/icons/dark/plus_24.png"),
            plus32: require("../assets/icons/dark/plus_32.png"),
            recycling: require("../assets/icons/dark/recycling_48.png"),
            refresh: require("../assets/icons/dark/refresh_32.png"),
            seedling: require("../assets/icons/dark/seedling_24.png"),
            share: require("../assets/icons/dark/share_24.png"),
            star: require("../assets/icons/dark/star_36.png"),
            stars: require("../assets/icons/dark/stars_36.png"),
            tabCarbon: require("../assets/icons/dark/tabCarbon_26.png"),
            tabCarbonActive: require("../assets/icons/dark/tabCarbonActive_26.png"),
            tabEnergy: require("../assets/icons/dark/tabEnergy_26.png"),
            tabEnergyActive: require("../assets/icons/dark/tabEnergyActive_26.png"),
            tabEvents: require("../assets/icons/dark/tabEvents_26.png"),
            tabEventsActive: require("../assets/icons/dark/tabEventsActive_26.png"),
            tabHome: require("../assets/icons/dark/tabHome_26.png"),
            tabHomeActive: require("../assets/icons/dark/tabHomeActive_26.png"),
            trees: require("../assets/icons/dark/trees_48.png"),
            trophy: require("../assets/icons/dark/trophy_36.png"),
            user32: require("../assets/icons/dark/user_32.png"),
            user36: require("../assets/icons/dark/user_36.png"),
            video: require("../assets/icons/dark/video_36.png"),
            warning: require("../assets/icons/dark/warning_32.png"),
            warningSquare: require("../assets/icons/dark/warningSquare_24.png"),
            achievements: {
                large: {
                    ...staticColorIcons.achievements.large,
                    plugOff: require("../assets/icons/dark/achievements/plugOff_326.png"),
                    referralOff: require("../assets/icons/dark/achievements/referralOff_326.png"),
                    graphOff: require("../assets/icons/dark/achievements/graphOff_326.png"),
                    count10Off: require("../assets/icons/dark/achievements/count10Off_326.png"),
                    count25Off: require("../assets/icons/dark/achievements/count25Off_326.png"),
                    count50Off: require("../assets/icons/dark/achievements/count50Off_326.png"),
                    checkmarkOff: require("../assets/icons/dark/achievements/checkmarkOff_326.png"),
                    buildingOff: require("../assets/icons/dark/achievements/buildingOff_326.png"),
                    badgeOff: require("../assets/icons/dark/achievements/badgeOff_326.png"),
                    crownOff: require("../assets/icons/dark/achievements/crownOff_326.png"),
                    starPartialOff: require("../assets/icons/dark/achievements/starPartialOff_326.png"),
                    starOff: require("../assets/icons/dark/achievements/starOff_326.png"),
                    oneHundredOff: require("../assets/icons/dark/achievements/oneHundredOff_326.png"),
                    boltOff: require("../assets/icons/dark/achievements/boltOff_326.png"),
                },
                small: {
                    ...staticColorIcons.achievements.small,
                    plugOff: require("../assets/icons/dark/achievements/plugOff_118.png"),
                    referralOff: require("../assets/icons/dark/achievements/referralOff_118.png"),
                    graphOff: require("../assets/icons/dark/achievements/graphOff_118.png"),
                    count10Off: require("../assets/icons/dark/achievements/count10Off_118.png"),
                    count25Off: require("../assets/icons/dark/achievements/count25Off_118.png"),
                    count50Off: require("../assets/icons/dark/achievements/count50Off_118.png"),
                    checkmarkOff: require("../assets/icons/dark/achievements/checkmarkOff_118.png"),
                    buildingOff: require("../assets/icons/dark/achievements/buildingOff_118.png"),
                    badgeOff: require("../assets/icons/dark/achievements/badgeOff_118.png"),
                    crownOff: require("../assets/icons/dark/achievements/crownOff_118.png"),
                    starPartialOff: require("../assets/icons/dark/achievements/starPartialOff_118.png"),
                    starOff: require("../assets/icons/dark/achievements/starOff_118.png"),
                    oneHundredOff: require("../assets/icons/dark/achievements/oneHundredOff_118.png"),
                    boltOff: require("../assets/icons/dark/achievements/boltOff_118.png"),
                },
            },
            university: {
                whatIsEvent: require("../assets/icons/dark/university/whatIsEvent_48.png"),
                participate: require("../assets/icons/dark/university/participate_48.png"),
                whyImportant: require("../assets/icons/dark/university/whyImportant_48.png"),
                mostMoney: require("../assets/icons/dark/university/mostMoney_48.png"),
                performance: require("../assets/icons/dark/university/performance_48.png"),
                whatIsTheGrid: require("../assets/icons/dark/university/whatIsTheGrid_48.png"),
                peakerPlants: require("../assets/icons/dark/university/peakerPlants_48.png"),
                unplugging: require("../assets/icons/dark/university/unplugging_48.png"),
                timeWindow: require("../assets/icons/dark/university/timeWindow_48.png"),
                neighborhoodPride: require("../assets/icons/dark/university/neighborhoodPride_48.png"),
                referral: require("../assets/icons/dark/university/referral_48.png"),
                carbonFootprint: require("../assets/icons/dark/university/carbonFootprint_48.png"),
                checkYourself: require("../assets/icons/dark/university/checkYourself_48.png"),
            },
        },
    },
}

const ThemeContext = React.createContext({
    theme: rawThemes.light,
})

function ThemeContextProvider({ children }) {
    // const [storedUserChoice, setStoredUserChoice] = React.useState(UserThemeOptions.system)
    const systemTheme = useColorScheme()
    const [userThemeName, setUserThemeName] = React.useState(null)

    // On load, get the user's theme preference out of async storage.
    useEffect(() => {
        AsyncStorage.getItem(AppConstants.prefKeys.userThemeChoice).then((userChoice) => {
            setUserThemeName(userChoice)
        })
    }, [])

    function setUserThemePreference(newTheme) {
        AsyncStorage.setItem(AppConstants.prefKeys.userThemeChoice, newTheme)
        setUserThemeName(newTheme)
    }

    var theme = systemTheme == ThemeOption.dark.type ? rawThemes.dark : rawThemes.light
    if (userThemeName == ThemeOption.light.type) {
        // Force light mode.
        theme = rawThemes.light
    } else if (userThemeName == ThemeOption.dark.type) {
        theme = rawThemes.dark
    }

    return <ThemeContext.Provider value={{ theme, userThemeName, setUserThemePreference }}>{children}</ThemeContext.Provider>
}

export { accentColors, rawThemes, ThemeContextProvider, ThemeContext }
