import { useNavigation } from "@react-navigation/native"
import * as React from "react"
import { Image, Text, View } from "react-native"
import i18n from "../../shared/i18n"
import { TextStyles } from "../../shared/TextStyles"
import { ThemeContext } from "../../shared/ThemeContext"
import { CarbonIntensityGraph } from "../CarbonIntensityGraph"
import CardView from "./CardView"

export default function CarbonIntensityGraphCard(props) {
    const { theme } = React.useContext(ThemeContext)

    return (
        <CardView includesBorder={true} includesPadding={false} {...props}>
            <Text style={[TextStyles.h5, { flexShrink: 1, color: theme.textSecondary, marginHorizontal: 16, marginTop: 20, marginBottom: 16 }]}>
                {i18n.t("currentGridCO2")}
            </Text>

            <CarbonIntensityGraph />
            <View style={{ flexDirection: "row", marginTop: 24, marginHorizontal: 16, marginBottom: 20 }}>
                <Image source={theme.icons.seedling} style={[{ width: 24, height: 24, marginRight: 16, opacity: 0.7 }]} />
                <Text style={[TextStyles.caption, { flexShrink: 1, color: theme.textSecondary }]}>{i18n.t("carbonGraphInfo")}</Text>
            </View>
        </CardView>
    )
}
