import * as React from "react"
import { View, Text, StyleSheet, Image } from "react-native"
import Colors, { colorForTemperature } from "../shared/Colors"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function TemperatureIndicator({ minTemperature = 50, maxTemperature = 95, temperature, containerStyle = {} }) {
    const { theme } = React.useContext(ThemeContext)

    let totalRange = maxTemperature - minTemperature
    let minPercent = (temperature - minTemperature) / totalRange
    let maxPercent = 1.0 - minPercent

    // let tintColor = colorForTemperature(temperature)

    let temperatureDisplay = Math.round(temperature ?? 0).toFixed(0)

    if (temperature == null) {
        // Don't draw this if we don't have current temp info.
        return null
    }

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={[styles.spacer, { flex: minPercent }]} />
            <View style={[styles.indicatorContainer]}>
                <Text style={[TextStyles.capsExtraSmall, { color: theme.textSecondary, marginBottom: -2 }]}>Now</Text>
                <Text style={[TextStyles.body2Semi, { color: theme.textSecondary, marginLeft: 5, marginBottom: 2 }]}>{temperatureDisplay + "º"}</Text>
                <Image resizeMode="contain" source={require("../assets/images/temperatureIndicatorPointer.png")} style={{ tintColor: theme.textSecondary, width: 13, height: 6 }} />
            </View>
            <View style={[styles.spacer, { flex: maxPercent }]} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        width: "100%",
        minHeight: 40,
        alignItems: "center",
    },
    indicatorContainer: {
        height: "100%",
        alignItems: "center",
    },
    spacer: {
        flex: 0,
        height: "100%",
    },
})
