import { useNavigation } from "@react-navigation/native"
import React, { useState } from "react"
import { Dimensions, Image, ScrollView, StyleSheet, Switch, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import Button from "../components/Button"
import CloseButton from "../components/CloseButton"
import { EnergySavingActions, ScreenNames } from "../shared/AppConstants"
import { CommonStyles } from "../shared/CommonStyles"
import i18n from "../shared/i18n"
import { maxContentWidth } from "../shared/Layout"
import { TextStyles } from "../shared/TextStyles"
import { ThemeContext } from "../shared/ThemeContext"

export default function ActionsInfoScreen(props) {
    const insets = useSafeAreaInsets()
    const { theme } = React.useContext(ThemeContext)
    const navigation = useNavigation()
    const [advancedMode, setAdvancedMode] = useState(false)

    // const { enrolled = false, enrolledForThisYear = false } = prop("enrollmentStatus", mainData) || {}

    // if (!enrolled) {
    //     // Don't render anything unless the user is enrolled.
    //     return null
    // }

    // return <View style={{ width: 200, height: 200, alignSelf: "center", backgroundColor: "#f00" }}></View>

    const renderAction = (item) => {
        const { type, icon } = item

        let displayIcon = advancedMode ? theme.icons.bookSkull : theme.icons[icon]
        const displaySubtitle = i18n.t(`actions.${type}.${advancedMode ? "subtitleAdvanced" : "subtitle"}`)

        return (
            <View key={type} style={{ flexDirection: "row", marginBottom: 24 }}>
                <Image source={displayIcon} style={[{ width: 32, height: 32, marginRight: 16 }]} />
                <View style={{ flexShrink: 1 }}>
                    <Text style={[TextStyles.body2Medium, { color: theme.textPrimary, marginBottom: 8 }]}>{i18n.t(`actions.${type}.title`)}</Text>
                    {displaySubtitle != null && <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>{displaySubtitle}</Text>}
                </View>
            </View>
        )
    }

    return (
        <ScrollView
            style={[CommonStyles.mainScreenScroll, { backgroundColor: theme.background }]}
            contentContainerStyle={[CommonStyles.mainScreenContent, { paddingHorizontal: 20 }]}
        >
            <Text style={[TextStyles.body2, { color: theme.textPrimary, marginBottom: 24 }]}>{i18n.t("actionsInfoSubtitle")}</Text>
            <Button
                containerStyle={{ marginBottom: 32 }}
                type="outline"
                contentTintColor={theme.textPrimary}
                buttonTintColor={theme.teal}
                title={i18n.t("getYourFriendsInvolved")}
                onPress={() => {
                    navigation.navigate(ScreenNames.ReferralDetail.routeName)
                }}
                {...props}
            />
            <Text style={[TextStyles.body2Semi, { color: theme.textPrimary, marginBottom: 24 }]}>{i18n.t("beforeEvent")}</Text>
            {EnergySavingActions.before.map((item) => renderAction(item))}
            <Text style={[TextStyles.body2Semi, { color: theme.textPrimary, marginBottom: 24 }]}>{i18n.t("duringEvent")}</Text>
            {EnergySavingActions.during.map((item) => renderAction(item))}
            <View
                style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                    borderWidth: 1,
                    borderColor: theme.border,
                    borderRadius: 8,
                    marginTop: 8,
                }}
            >
                <View>
                    <Text style={[TextStyles.body2Semi, { color: theme.textPrimary }]}>{i18n.t("expertMode")}</Text>
                    <Text style={[TextStyles.body3, { color: theme.textSecondary }]}>{i18n.t("expertModeWarning")}</Text>
                </View>
                <Switch
                    value={advancedMode}
                    onValueChange={(newValue) => {
                        setAdvancedMode(newValue)
                    }}
                    trackColor={{ true: theme.red }}
                />
            </View>
        </ScrollView>
    )
}
